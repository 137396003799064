const tabList = [
    { url: '/home', key: 1 },
    { url: '/insights', key: 1 },
    { url: '/interview', key: 1 },
    { url: '/job-detail', key: 1 },
    { url: '/job/job-info', key: 1 },
    { url: '/job/select-template/1', key: 1 },
    { url: '/job/select-template/2', key: 1 },
    { url: '/job/select-template/3', key: 1 },
    { url: '/job/select-template/4', key: 1 },
    //
    { url: '/talent-pool-info?type=add', key: 2 },
    { url: '/talent-pool-info?type=edit', key: 2 },
    { url: '/talent-pool', key: 2 },
    { url: '/pipelines-board', key: 2 },
    { url: '/candidate-info?from=pool', key: 2 },
    //
    { url: '/talent-database', key: 3 },
    { url: '/talent-database-info', key: 3 },
    { url: '/candidate-info?from=database', key: 3 },
    { url: '/talent-database', key: 3 },
    //
    { url: '/message-chat', key: 4 },
    { url: '/message-chat?type=1', key: 4 },
    //
    { url: '/talent-teamManagement', key: 5 },
    { url: '/employee-detail', key: 5 },
    { url: '/job-detail?from=team', key: 5 },
    //
    { url: '/admin-settings', key: 6 },
    { url: '/change-subscriptionPlan', key: 6 },
];
const getActiveIndex = (url: string) => {
    return tabList.find(v => v.url === url)?.key || 1;
};
export default getActiveIndex;
