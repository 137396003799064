import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'antd';
import './index.scss';
function VideoPlayModal({ isModalOpen, handleOk, handleCancel, videoUrl, autoPlay }: any) {
    const videoRef = useRef<any>(null);
    const [isPaused, setIsPaused] = useState(true);

    const togglePlay = () => {
        if (videoRef.current.paused) {
            videoRef.current?.play();
            setIsPaused(false);
        } else {
            videoRef.current?.pause();
            setIsPaused(true);
        }
    };
    useEffect(() => {
        if (autoPlay && isModalOpen) {
            setIsPaused(false);
        }
    }, [isModalOpen]);
    return (
        <Modal
            className="r-modal-video"
            maskClosable={false}
            centered={true}
            closeIcon={
                <i
                    className="r-modal-close"
                    onClick={() => {
                        videoRef.current.pause();
                        setIsPaused(true);
                        handleCancel();
                    }}
                ></i>
            }
            footer={''}
            width={780}
            title={null}
            open={isModalOpen}
            key={'video-play-modal'}
            maskProps={{
                style: {
                    backgroundColor: 'rgba(30, 34, 45, 0.8)',
                    backdropFilter: 'blur(1px)',
                },
            }}
        >
            <div className={`video-container ${isPaused ? 'paused' : ''}`}>
                <video
                    ref={videoRef}
                    controls
                    loop
                    autoPlay={autoPlay ? autoPlay : false}
                    muted={autoPlay ? autoPlay : false}
                    src={videoUrl ? videoUrl : require('@/assets/img/6502.mp4')}
                    // onClick={togglePlay}
                />
                {/* {isPaused ? <div className="custom-play-button" onClick={togglePlay}></div> : null} */}
            </div>
        </Modal>
    );
}

export default VideoPlayModal;
