const env = process.env.REACT_APP_NODE_ENV;
const config = {
    localUrl:
        env === 'development'
            ? 'http://localhost:3000'
            : env === 'test'
            ? 'http://192.168.50.101/talenaut-business'
            : 'https://business.talenaut.com',
    googleAuthKey: '604511413297-f340od3fdtsns3damb73s6q610kommu2.apps.googleusercontent.com',
    googleAuthKey1: '566752370138-qv3bm4ajm1oohqsus1n01qa113l81den.apps.googleusercontent.com',
    linkinedAuthKey: '78w81o8t60w0q9',
    outlookAuthKey: '61989628-4057-434f-ab13-0e2cee4fbe75',
    stripeKey:
        'pk_test_51PoQxiEqaprY8t0qMmFHFd2rTVCINlyfvqCGvSrzPcc8hWa5jWOx7Y2AAJbbv3RBOAOeBOJLqVeLArsMmnCsjJoG001KuJ26ku',
};
export default config;
