import { useGlobalMessage } from '@/hook/useGlobalMessage';
import { Button, Form, FormProps, Input } from 'antd';
import JSEncrypt from 'jsencrypt';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '@/api/login';
import { setChartInfo, setProfileInfo } from '@/store/stateSlice';
import { roleList } from '@/config/role';
import { useAppDispatch } from '@/store/hooks';
function ResetPassword() {
    const dispatch = useAppDispatch();
    const messageApi = useGlobalMessage();
    const passwordEncrypt = new JSEncrypt();
    const [loading, setLoading] = useState(false);
    const [eyeOpen, setEyeOpen] = useState(false);
    const [eyeOpen1, setEyeOpen1] = useState(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const back = () => {
        navigate('/login');
    };
    const [state, setState] = useState({
        password: '',
        rePassword: '',
    });
    const onFinish: FormProps['onFinish'] = () => {
        if (state.password !== state.rePassword) {
            messageApi.error('The 2 passwords entered are not the same');
            setState({ password: '', rePassword: '' });
            form.setFieldsValue({ password: '', rePassword: '' });
            return;
        }
        setLoading(true);
        api.getPublicKey(res => {
            const { code, data } = res;
            if (code === 200 && data) {
                passwordEncrypt.setPublicKey(data);
                api.resetPassword(
                    {
                        code: window.sessionStorage.getItem('code'),
                        email: window.sessionStorage.getItem('email'),
                        password: passwordEncrypt.encrypt(state.password),
                    },
                    res => {
                        setLoading(false);
                        const { code, data } = res;
                        if (code === 200) {
                            // navigate('/login');
                            login({
                                email: window.sessionStorage.getItem('email'),
                                password: passwordEncrypt.encrypt(state.password),
                            });
                        } else {
                            window.utils.alert('error', res.message);
                        }
                    }
                );
            } else {
                setLoading(false);
                messageApi.error(res.message);
            }
        });
    };
    const getSendBirdKey = async id => {
        try {
            const res = await api.getSendBirdKey();
            const { code, data, message } = res.data;
            if (code === 200) {
                dispatch(
                    setChartInfo({
                        adminId: id,
                        sendbirdId: data.APPLICATION_ID,
                        sendbirdAccessToken: data.API_TOKEN,
                    })
                );
            } else {
                window.utils.alert('error', message);
            }
        } catch (error) {
            window.utils.alert('error', error);
        }
    };
    const login = pat => {
        api.login({ ...pat }, loginRes => {
            if (loginRes.code === 200) {
                window.sessionStorage.setItem('admin-satoken', loginRes.data.token);
                window.sessionStorage.setItem('accountId', loginRes.data.accountId);
                getSendBirdKey(loginRes.data.accountId);
                if (
                    loginRes.data.businessAccountAdminPOJO &&
                    loginRes.data.businessAccountAdminPOJO.businessInfoPOJO.registerStepNumber !== 2
                ) {
                    window.sessionStorage.setItem(
                        'accountInfo',
                        JSON.stringify(loginRes.data.businessAccountAdminPOJO)
                    );
                    const val = JSON.parse(JSON.stringify(loginRes.data.businessAccountAdminPOJO));
                    val.showName = window.utils.getInitials(val.firstName, val.lastName);
                    val.roleNum = roleList[val.role];
                    dispatch(setProfileInfo({ ...val }));
                    const step = loginRes.data.businessAccountAdminPOJO.businessInfoPOJO.registerStepNumber;
                    navigate(step === 0 ? '/team-management' : step === 1 ? '/subscription-plan' : '');
                    return;
                }
                navigate('/home');
            } else {
                messageApi.error(loginRes.message);
            }
            setLoading(false);
        });
    };
    const validatePassword = (_: any, value: string) => {
        const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z\d]{8,12}$/;
        if (!value || !passwordPattern.test(value)) {
            return Promise.reject(
                new Error(
                    'Please enter a password of 8-12, which must contain one uppercase letter, one lowercase letter, and one number.'
                )
            );
        }
        return Promise.resolve();
    };
    return (
        <div className="login-main r-center">
            <div className="center-box">
                <img className="logo-img" src={require('@/assets/img/Logo.png')} alt="" />
                <div className="login-formBox">
                    <h2>Reset Password</h2>
                    <Form
                        form={form}
                        name="basic-resetPassword"
                        layout="vertical"
                        style={{ maxWidth: 380 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        validateTrigger="onBlur"
                    >
                        <Form.Item label="New Password" name="password" rules={[{ validator: validatePassword }]}>
                            <Input
                                className={`r-input `}
                                maxLength={12}
                                autoComplete="off"
                                placeholder={'Enter 8-12 digit password'}
                                value={state.password}
                                onInput={(e: any) => setState({ ...state, password: e.target.value })}
                                type={eyeOpen ? 'text' : 'password'}
                                suffix={
                                    eyeOpen ? (
                                        <i className="eye-open" onClick={() => setEyeOpen(false)}></i>
                                    ) : (
                                        <i className="eye-close" onClick={() => setEyeOpen(true)}></i>
                                    )
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: 30 }}
                            label="Confirm New Password"
                            name="rePassword"
                            rules={[{ validator: validatePassword }]}
                        >
                            <Input
                                className={`r-input `}
                                maxLength={12}
                                autoComplete="off"
                                placeholder={'Re-enter the 8-12 digit password'}
                                value={state.rePassword}
                                onChange={(e: any) => setState({ ...state, rePassword: e.target.value })}
                                type={eyeOpen1 ? 'text' : 'password'}
                                suffix={
                                    eyeOpen1 ? (
                                        <i className="eye-open" onClick={() => setEyeOpen1(false)}></i>
                                    ) : (
                                        <i className="eye-close" onClick={() => setEyeOpen1(true)}></i>
                                    )
                                }
                            />
                        </Form.Item>
                        <Form.Item style={{ margin: 0 }}>
                            <Button
                                className="r-primary_btn"
                                htmlType="submit"
                                disabled={!state.rePassword || !state.password}
                                loading={loading}
                            >
                                Save New Password
                            </Button>
                            <Button
                                className="r-primaryNoBg_btn"
                                style={{ marginTop: 15 }}
                                onClick={() => {
                                    back();
                                }}
                            >
                                Back To Login
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
