import VideoPlayModal from '@/components/modal/video-play-modal';
import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Image, Spin, TimePicker, Tooltip } from 'antd';
import CompanyIcon from '@/assets/img/candidate-img/company.png';
import SchoolIcon from '@/assets/img/candidate-img/school.png';
import LicenseIcon from '@/assets/img/candidate-img/license.png';
import Check from '@/assets/img/candidate-img/check-succ.png';
import LinkIcon from '@/assets/img/candidate-img/link.png';
import ShareIcon from '@/assets/img/candidate-img/share.png';
import Bookmark from '@/assets/img/candidate-img/book.png';
import Bookmarked from '@/assets/img/candidate-img/booked.png';
import FrameIcon from '@/assets/img/candidate-img/frame.png';
import LocationIcon from '@/assets/img/candidate-img/location.png';
import MailIcon from '@/assets/img/candidate-img/mail-mini.png';
import LinkedInMiniIcon from '@/assets/img/candidate-img/LinkedIn-mini.png';
import CheckSuccicon from '@/assets/img/candidate-img/check-succ.png';
import FileIcon from '@/assets/img/candidate-img/file.png';
import ChatMiniIcon from '@/assets/img/candidate-img/chat-mini.png';
import MailOIcon from '@/assets/img/candidate-img/mail-o.png';
import TimeMiniIcon from '@/assets/img/candidate-img/time-mini.png';
import NoteImg1 from '@/assets/img/candidate-img/note1.png';
import NoteImg2 from '@/assets/img/candidate-img/note2.png';
import NoteImg3 from '@/assets/img/candidate-img/note3.png';
import NoteImg4 from '@/assets/img/candidate-img/note4.png';
import DownIcon from '@/assets/img/arrow-down.png';
import RadioButton from '@/assets/img/RadioButton.png';
import RadioButtonWhite from '@/assets/img/RadioButton-w.png';
import PortFolioDrawer from '@/components/r-drawer/index';
import AlertModal from '@/components/modal/alert-modal';
import FormModal from '@/components/modal/form-modal';
import TextArea from 'antd/es/input/TextArea';
import api from '@/api/job';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import { cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import { defaultInfo } from '@/typings/allType';
import { useSelector } from 'react-redux';
import { RangePickerProps } from 'antd/es/date-picker';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/store/hooks';
import { setNowChartUser } from '@/store/stateSlice';
const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current < dayjs().startOf('day');
};
const fallbackCopyToClipboard = (text: string) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.position = 'fixed'; // 避免影响页面布局
    textarea.style.left = '-9999px';
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    try {
        document.execCommand('copy');
        window.utils.alert('success', 'Copy success.');
    } catch (err) {
        window.utils.alert('error', 'Replication failure.');
    }
    document.body.removeChild(textarea);
};
function CandidateDetail() {
    const messageApi = useGlobalMessage();
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
    const [videoModalUrl, setVideoModalUrl] = useState('');
    const [openDrawer, setOpenDrawer] = useState(false);
    const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
    const [state, setState] = useState({
        isBookMark: false,
        status: 1,
        videoList: [
            { tit: 'Self Introduction', url: '', coverPic: '', desc: '' },
            { tit: 'Key Skills and Experience ', url: '', coverPic: '', desc: '' },
            { tit: 'Key Personal Strength', url: '', coverPic: '', desc: '' },
        ],
    });
    const [modalType, setModalType] = useState(1); // 1 修改工作状态进入下一阶段(开始面试) 2 拒绝候选人 3 提示绑定日历 4 面试日有期冲突 5 聊天  6<selectType =1 下一步。selectType=2 约面试> 7 发offer 8 标记已面试
    const alertModalTipList = [
        {
            type: 1,
            title: 'Are you sure you want to take the recruitment process for this job to the interview stage?',
            cancelText: 'Cancel',
            confirmText: 'Confirm',
        },
        {
            type: 2,
            title: 'Are you sure you want to reject this candidate?',
            cancelText: 'Cancel',
            confirmText: 'Confirm',
        },
        {
            type: 3,
            title: 'You can start by linking Google with your Outlook calendar to alert you when an interview is coming up.',
            cancelText: 'Cancel',
            confirmText: 'Connected Calendar',
        },
        {
            type: 4,
            title: 'You already have an interview scheduled between 12:45 pm to 1:30 pm on January 30, 2024. Do you need to reschedule this interview?',
            cancelText: 'Modification time',
            confirmText: 'Confirmation interview ',
        },
    ];
    const navigate = useNavigate();
    const [modalText, setModalText] = useState(alertModalTipList[0]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    // 提示弹窗二次确认
    const handleOk = () => {
        setOpLoading(true);
        if (modalType === 2) {
            // 拒绝候选人
            api.setRejectToUser({ applicationsId: nowUserInfo.applicationsId }, res => {
                setOpLoading(false);
                const { code, data, message } = res;
                if (code === 200) {
                    getLocalCandidateInfo();
                } else {
                    messageApi.error(message);
                }
            });
        } else if (modalType === 3) {
            navigate('/admin-settings');
        }
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        if (modalType === 3) {
            setIsFormModalOpen(true);
        }
    };
    // 面试时间
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [interViewTime, setInterviewTime] = useState({
        day: '',
        startTime: '' as any,
        endTime: '' as any,
    });
    const [opLoading, setOpLoading] = useState(false);
    // 发送面试邀请
    const [isInterViewModalOpen, setIsInterViewModalOpen] = useState(false);
    const [interViewModalMsg, setinterViewModalMsg] = useState(
        <>
            You already have an interview scheduled between 12:45 pm to 1:30 pm on January 30, 2024. Do you need to
            reschedule this interview?
        </>
    );
    const confirmSendInterview = () => {
        const { startTime, endTime } = interViewTime;
        if (dayjs(startTime).isAfter(endTime)) {
            messageApi.error('Please set the right time for the interview.');
            return;
        }
        const day = dayjs(interViewTime.day).format('YYYY-MM-DD');
        const start = dayjs(interViewTime.startTime).format('HH:mm');
        const end = dayjs(interViewTime.endTime).format('HH:mm');
        const pat = {
            applicationsId: nowUserInfo.applicationsId,
            interviewStartTime: dayjs(`${day} ${start}`).valueOf() / 1000,
            interviewEndTime: dayjs(`${day} ${end}`).valueOf() / 1000,
        };
        setOpLoading(true);
        api.setInterviewToUserCheck(pat, res => {
            if (res.code === 200) {
                confirmInterView();
                // setOpLoading(false);
            } else if (res.code === 50000) {
                setOpLoading(false);
                messageApi.error(res.message);
            } else {
                setOpLoading(false);
                // messageApi.error(res.message);
                setinterViewModalMsg(
                    <>
                        You already have an interview scheduled between{' '}
                        <span className="blue-text">{res.data.startDateTime || '-'}</span> to{' '}
                        <span className="blue-text">{res.data.endDateTime || '-'}</span> on{' '}
                        <span className="blue-text">{res.data.date || '-'}</span>. Do you need to reschedule this
                        interview?
                    </>
                );
                setIsInterViewModalOpen(true);
            }
        });
    };
    const confirmInterView = () => {
        setOpLoading(true);
        const day = dayjs(interViewTime.day).format('YYYY-MM-DD');
        const start = dayjs(interViewTime.startTime).format('HH:mm');
        const end = dayjs(interViewTime.endTime).format('HH:mm');
        console.log(`${day} ${start}`);
        const pat = {
            applicationsId: nowUserInfo.applicationsId,
            interviewStartTime: dayjs(`${day} ${start}`).valueOf() / 1000,
            interviewEndTime: dayjs(`${day} ${end}`).valueOf() / 1000,
        };
        api.setInterviewToUser(pat, res => {
            setOpLoading(false);
            const { code, data, message } = res;
            if (code === 200) {
                setIsInterViewModalOpen(false);
                setIsFormModalOpen(false);
                setInterviewTime({ day: '', startTime: '', endTime: '' });
                getLocalCandidateInfo();
            } else {
                messageApi.error(message);
            }
        });
    };
    // 评分
    const [noteScore, setNoteScore] = useState<any>([]);
    const [internalReviewInfo, setInternalReviewInfo] = useState({
        note: '',
    });
    const addJobNoteInfo = () => {
        const scourCheckList: number[] = [];
        noteScore.forEach(item => {
            const index = item.scores.findIndex(v => v.isCheck);
            scourCheckList.push(item.scores[index].label);
        });
        const pat = {
            applicationsId: nowUserInfo.applicationsId,
            note: internalReviewInfo.note,
            scoreArray: scourCheckList.join(','),
        };
        setOpLoading(true);
        api.addJobNoteInfo(pat, res => {
            setOpLoading(false);
            const { code, data, message } = res;
            if (code === 200) {
                setIsNoteModalOpen(false);
                setInternalReviewInfo({ note: '' });
                getLocalCandidateInfo();
            } else {
                messageApi.error(message);
            }
        });
    };
    const { profileInfo } = useSelector((state: any) => state.state);
    const changeModalType = (type: number) => {
        setModalType(type);
        if (type == 6) {
            // 前2个状态操作按钮
            if (applicationsStatus === 'applied') {
                // 把候选人放到screening列表
                api.setScreenToUser({ applicationsId: nowUserInfo.applicationsId }, res => {
                    const { code, data, message } = res;
                    if (code === 200) {
                        getLocalCandidateInfo();
                    } else {
                        messageApi.error(message);
                    }
                });
                return;
            }
            // 约面试
            const isAlertLinkCalendar = window.localStorage.getItem('isAlertLinkCalendar') || '0';
            if (isAlertLinkCalendar === '0' && !profileInfo.calendarGoogle && !profileInfo.calendarOutlook) {
                // 提示绑定日历
                changeModalType(3);
                window.localStorage.setItem('isAlertLinkCalendar', '1');
            } else {
                // 打开面试时间表单
                setIsFormModalOpen(true);
            }
            return;
        }
        if (type === 7) {
            // 发offer
            api.setOfferToUser({ applicationsId: nowUserInfo.applicationsId }, res => {
                const { code, data, message } = res;
                if (code === 200) {
                    getLocalCandidateInfo();
                } else {
                    messageApi.error(message);
                }
            });
            return;
        }
        setModalText(alertModalTipList[type - 1]);
        setIsModalOpen(true);
    };
    // 收藏
    const changeUserFavorite = () => {
        if (!nowUserInfo.businessAccountFavoriteFlag) {
            api.addUserFavorite({ accountId: nowUserInfo.accountId }, res => {
                const { code, data, message } = res;
                if (code === 200) {
                    setNowUserInfo({ ...nowUserInfo, businessAccountFavoriteFlag: true });
                } else {
                    messageApi.error(message);
                }
            });
            return;
        }
        api.removeUserFavorite({ accountId: nowUserInfo.accountId }, res => {
            const { code, data, message } = res;
            if (code === 200) {
                setNowUserInfo({ ...nowUserInfo, businessAccountFavoriteFlag: false });
            } else {
                messageApi.error(message);
            }
        });
    };
    // 分享
    const [userLink, setUserLink] = useState('');
    const shareUser = () => {
        // messageApi.success('分享成功');
        const text = `${
            process.env.REACT_APP_NODE_ENV === 'test'
                ? 'http://192.168.50.101/talenaut-candidate/'
                : 'http://192.168.50.101/talenaut-candidate/'
        }/userprofile?id=${userLink}`;
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    window.utils.alert('success', 'Copy success.');
                })
                .catch(err => {
                    window.utils.alert('error', 'Replication failure.');
                });
        } else {
            fallbackCopyToClipboard(text); // 使用备用方案
        }
    };
    //
    const dispatch = useAppDispatch();
    const sendMessage = val => {
        dispatch(setNowChartUser({ ...val }));
        navigate('/message-chat?type=1');
    };
    const openUser = type => {
        if (type === 1) {
            window.open(nowUserInfo.accountPOJO.accountInformationPOJO.cvFileUrl, '_target');
        } else if (type === 2) {
            // messageApi.success('去聊天');
            sendMessage(nowUserInfo);
        } else {
            const recipient = nowUserInfo.accountPOJO.accountInformationPOJO.email
                ? nowUserInfo.accountPOJO.accountInformationPOJO.email
                : nowUserInfo.accountPOJO.accountInformationPOJO.emailTwo;
            const mailtoLink = `mailto:${recipient}}}`;
            window.open(mailtoLink, '_blank');
        }
    };
    const [loading, setLoading] = useState(false);
    const [nowUserInfo, setNowUserInfo] = useState({
        accountId: '',
        interviewTimeShow: '',
        myScoreFlag: true,
        applicationsScoreTotal: 0,
        applicationsId: '',
        appliedTime: 0,
        accountPOJO: {
            accountInformationPOJO: {} as any,
        },
        jobsPOJO: {
            jobStepStatus: '',
        },
        hardSkill: [],
        softSkill: [],
        screeningQuestionsListPOJO: [] as defaultInfo[],
        businessAccountFavoriteFlag: false,
    });
    const [accountCertificatePOJOList, setAccountCertificatePOJOList] = useState<any>([]);
    const [relevantExperience, setRelevantExperience] = useState<any>([]);
    const [relevantExperienceTime, setRelevantExperienceTime] = useState<string>('');
    const [accountProjectPOJOList, setAccountProjectPOJOList] = useState<any>([]);
    const [showMoreProject, setShowMoreProject] = useState(false);
    const [projectInfo, setProjectInfo] = useState<any>({});
    const [scoreListPOJO, setScoreListPOJO] = useState<any>([]);
    // applied,screening,inReview,interviewScheduled,offerReceived,rejected
    const [applicationsStatus, setApplicationsStatus] = useState('applied');
    const getUserInfo = val => {
        api.getUserInfoForJob({ accountId: val.accountId, applicationsId: val.applicationsId }, res => {
            setLoading(false);
            const { code, data, message } = res;
            if (code === 200) {
                console.log(data);
                // 候选人标记为已读
                readInfo({ applicationsId: val.applicationsId });
                const hardSkill: any = [];
                const softSkill: any = [];
                if (data.accountPOJO.accountSkillPOJOList && data.accountPOJO.accountSkillPOJOList.length) {
                    data.accountPOJO.accountSkillPOJOList.forEach(item => {
                        if (item.skillType === 'softSkills') {
                            softSkill.push(item);
                        } else {
                            hardSkill.push(item);
                        }
                    });
                }
                data.hardSkill = hardSkill;
                data.softSkill = softSkill;
                if (data.interviewTime) {
                    data.interviewTimeShow = data.interviewTime
                        ? `${window.utils.foramtTimeDMY(data.interviewStartTime * 1000)} ${dayjs(
                              data.interviewStartTime * 1000
                          ).format('hh:mm a')} - ${dayjs(data.interviewEndTime * 1000).format('hh:mm a')}`
                        : '';
                }
                setNowUserInfo(data);
                const userIdName = data.accountPOJO.accountInformationPOJO.personalName.split('/userprofile/')[1];
                setUserLink(userIdName);
                setApplicationsStatus(data.applicationsStatus || 'applied');
                if (data.accountPOJO.accountProjectPOJOList && data.accountPOJO.accountProjectPOJOList !== null) {
                    setAccountProjectPOJOList([...data.accountPOJO.accountProjectPOJOList]);
                }
                if (data.applicationsScorePOJO && data.applicationsScorePOJO.length) {
                    const arr = data.applicationsScorePOJO.map(item => {
                        return Object.assign(item, {
                            namelabel: `${item.firstName || '-'} ${item.middleName} ${item.lastName}`,
                            isOpen: false,
                            scoreArrayList: item.scoreArray ? item.scoreArray.split(',') : [],
                        });
                    });
                    console.log(arr);

                    setScoreListPOJO([...arr]);
                }
                if (data.interviewScorecardQuestionsPOJOList && data.interviewScorecardQuestionsPOJOList.length) {
                    const objList = data.interviewScorecardQuestionsPOJOList.map(item => {
                        return Object.assign(item, {
                            label: item.question,
                            scores: [
                                {
                                    label: 4,
                                    isShow: item.excellentFlag === 1,
                                    icon: NoteImg1,
                                    text: item.excellent,
                                    isCheck: false,
                                },
                                {
                                    label: 3,
                                    isShow: item.goodFlag === 1,
                                    icon: NoteImg2,
                                    text: item.good,
                                    isCheck: false,
                                },
                                {
                                    label: 2,
                                    isShow: item.averageFlag === 1,
                                    icon: NoteImg3,
                                    text: item.average,
                                    isCheck: false,
                                },
                                {
                                    label: 1,
                                    isShow: item.belowAverageFlag === 1,
                                    icon: NoteImg4,
                                    text: item.belowAverage,
                                    isCheck: false,
                                },
                            ],
                        });
                    });
                    setNoteScore(objList);
                }
                if (data.accountPOJO.accountExperiencePOJO) {
                    const year = data.accountPOJO.accountExperiencePOJO.yearsOfExperience;
                    const month = data.accountPOJO.accountExperiencePOJO.monthOfExperience;
                    const time = `${year ? `${year}${year > 1 ? 'Years' : 'Year'}` : ''}${
                        month ? ` ${month}${month > 1 ? ' Months' : ' Month'}` : ''
                    }`;
                    setRelevantExperienceTime(time);
                    setRelevantExperience([...data.accountPOJO.accountExperiencePOJO.accountExperienceInfoPOJOList]);
                }
                if (
                    data.accountPOJO.accountCertificatePOJOList &&
                    data.accountPOJO.accountCertificatePOJOList !== null
                ) {
                    const val = cloneDeep(data.accountPOJO.accountCertificatePOJOList);
                    setAccountCertificatePOJOList(val);
                }
            } else {
                messageApi.error(message);
            }
        });
    };
    const readInfo = val => {
        api.readUserInfo({ applicationsId: val.applicationsId }, res => {
            const { code, data, message } = res;
            if (code !== 200) {
                messageApi.error(message);
            }
        });
    };
    const [localUserInfo, setLocalUserInfo] = useState<any>({
        accountPOJO: {
            accountInformationPOJO: {} as any,
        },
    });
    const [localJobInfo, setLocalJobInfo] = useState<any>({
        jobsPOJO: {
            businessAccountPOJO: {} as any,
        } as any,
    });
    const getLocalCandidateInfo = () => {
        const info = JSON.parse(window.sessionStorage.getItem('candidateUserInfo') || "{accountId:''}");
        if (info.accountId) {
            setLoading(true);
            setLocalUserInfo(info);
            console.log(info);
            getUserInfo(info);
            const videos = [
                {
                    tit: 'Self Introduction',
                    url: info.accountPOJO.accountInformationPOJO.selfIntroductionUrl,
                    coverPic: info.accountPOJO.accountInformationPOJO.selfIntroductionUrlImage,
                    desc: info.accountPOJO.accountInformationPOJO?.selfIntroduction || '',
                },
                {
                    tit: 'Key Skills and Experience ',
                    url: info.accountPOJO.accountInformationPOJO.keySkillsExperiencesUrl,
                    coverPic: info.accountPOJO.accountInformationPOJO.keySkillsExperiencesUrlImage,
                    desc: info.accountPOJO.accountInformationPOJO?.keySkillsExperiences || '',
                },
                {
                    tit: 'Key Personal Strength',
                    url: info.accountPOJO.accountInformationPOJO.keyPersonalStrengthUrl,
                    coverPic: info.accountPOJO.accountInformationPOJO.keyPersonalStrengthUrlImage,
                    desc: info.accountPOJO.accountInformationPOJO?.keyPersonalStrength || '',
                },
            ];
            setState({ ...state, videoList: videos });
        }
        if (info.from === '/job-detail') {
            const jobInfo = JSON.parse(window.sessionStorage.getItem('jobInfo') || "{jobId:''}");
            setLocalJobInfo(jobInfo);
        }
    };
    useEffect(() => {
        getLocalCandidateInfo();
    }, []);
    // 禁用时间
    const getDisabledHours = () => {
        if (!interViewTime.day) return [];
        const now = dayjs();
        if (dayjs(interViewTime.day).isSame(now, 'day')) {
            const limitHour = now.add(2, 'hour').hour();
            return Array.from({ length: limitHour }, (_, i) => i);
        }
        return [];
    };
    const getDisabledMinutes = selectedHour => {
        if (!interViewTime.day) return [];
        const now = dayjs();
        if (dayjs(interViewTime.day).isSame(now, 'day')) {
            const currentHour = now.hour();
            const limitMinutes = now.minute();
            if (selectedHour === currentHour + 2) {
                return Array.from({ length: limitMinutes }, (_, i) => i);
            }
        }
        return [];
    };
    return (
        <div className="r-pageContent candidate-detailBox r-top">
            {loading ? (
                <div className="no-message-tabBox r-center">
                    <Spin style={{ position: 'static' }}></Spin>
                </div>
            ) : (
                <>
                    <div>
                        {/* 侧边栏 */}
                        <div className="userOPeation-box">
                            <div className="r-top">
                                <Image
                                    width={128}
                                    height={128}
                                    preview={false}
                                    style={{ borderRadius: 12, minWidth: 128 }}
                                    src={
                                        localUserInfo.accountPOJO.accountInformationPOJO.profilePhoto
                                            ? localUserInfo.accountPOJO.accountInformationPOJO.profilePhoto
                                            : ''
                                    }
                                    fallback={require('@/assets/img/default-img.png')}
                                />
                                <div className="marLeft r-left">
                                    <img src={ShareIcon} className="shareIcon-box" alt="" onClick={shareUser} />
                                    <div
                                        className="shareIcon-box r-center"
                                        style={{ marginLeft: 8 }}
                                        onClick={() => setState({ ...state, isBookMark: !state.isBookMark })}
                                    >
                                        {nowUserInfo.businessAccountFavoriteFlag ? (
                                            <img
                                                src={Bookmarked}
                                                onClick={() => changeUserFavorite()}
                                                className="shareIcon-box"
                                                alt=""
                                            />
                                        ) : (
                                            <img
                                                src={Bookmark}
                                                onClick={() => changeUserFavorite()}
                                                className="shareIcon-box"
                                                alt=""
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* 状态 */}
                            <div className="r-left" style={{ margin: '12px 0' }}>
                                <span
                                    className={`job-statusTipBox ${
                                        applicationsStatus === 'inReview' || applicationsStatus === 'interviewScheduled'
                                            ? 'sourcingBox-tip'
                                            : applicationsStatus === 'rejected'
                                            ? 'rejectBox-tip'
                                            : ''
                                    }`}
                                >
                                    {applicationsStatus === 'applied'
                                        ? 'Sourcing'
                                        : applicationsStatus === 'screening'
                                        ? 'Screening'
                                        : applicationsStatus === 'inReview'
                                        ? 'Pending Internal Review'
                                        : applicationsStatus === 'interviewScheduled'
                                        ? 'Pending Internal Review'
                                        : applicationsStatus === 'offerReceived'
                                        ? 'Offer Accepted'
                                        : 'Rejected'}
                                </span>
                            </div>
                            <div>
                                <div className="job-name">{localJobInfo.jobsPOJO.jobTitle || '-'}</div>
                                <div className="jobUser-tip">
                                    {localUserInfo.namelabel || '-'}
                                    {/* ({' '}
                                    {nowUserInfo.accountPOJO.accountInformationPOJO.preferredPronounsCustomFlag
                                        ? nowUserInfo.accountPOJO.accountInformationPOJO.preferredPronounsCustom
                                        : nowUserInfo.accountPOJO.accountInformationPOJO.preferredPronounsList}
                                    ) */}
                                    {nowUserInfo.accountPOJO.accountInformationPOJO.preferredPronounsFlag
                                        ? `(${
                                              nowUserInfo.accountPOJO.accountInformationPOJO.preferredPronounsCustomFlag
                                                  ? nowUserInfo.accountPOJO.accountInformationPOJO
                                                        .preferredPronounsCustom
                                                  : nowUserInfo.accountPOJO.accountInformationPOJO.preferredPronounsList
                                          })`
                                        : ''}
                                    {applicationsStatus === 'applied' ? (
                                        <div className="userMini-msg r-left" style={{ marginTop: 8, opacity: 0.5 }}>
                                            Applied at{' '}
                                            {dayjs((nowUserInfo.appliedTime * 1000) as any).format('DD MMM YYYY')}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="userMini-msg r-left">
                                    <img src={FrameIcon} className="userInfoIcon-img" alt="" />
                                    <span className="oneLineText">
                                        {localUserInfo.accountPOJO.accountInformationPOJO.jobRole || '-'}
                                    </span>
                                    {nowUserInfo.accountPOJO.accountInformationPOJO.jobRoleMatchJobFlag ? (
                                        <img
                                            src={CheckSuccicon}
                                            className="userInfoIcon-img"
                                            style={{ margin: '0 0 0 4px' }}
                                            alt=""
                                        />
                                    ) : null}
                                </div>
                                <div className="userMini-msg r-left">
                                    <img src={LocationIcon} className="userInfoIcon-img" alt="" />
                                    {localUserInfo.accountPOJO.accountInformationPOJO.remoteFlag
                                        ? localUserInfo.accountPOJO.accountInformationPOJO.gmt
                                        : localUserInfo.accountPOJO.accountInformationPOJO.location}
                                </div>
                                <div className="userMini-msg r-left">
                                    <img src={MailOIcon} className="userInfoIcon-img" alt="" />
                                    {nowUserInfo.accountPOJO.accountInformationPOJO.email
                                        ? nowUserInfo.accountPOJO.accountInformationPOJO.email
                                        : nowUserInfo.accountPOJO.accountInformationPOJO.emailTwo || '-'}
                                </div>
                                {nowUserInfo.accountPOJO.accountInformationPOJO.linkedInUrl ? (
                                    <div className="userMini-msg r-left">
                                        <img src={LinkedInMiniIcon} className="userInfoIcon-img" alt="" />
                                        <span
                                            className="oneLineText"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                window.open(
                                                    `https://${nowUserInfo.accountPOJO.accountInformationPOJO.linkedInUrl}`,
                                                    '_target'
                                                );
                                            }}
                                        >
                                            {nowUserInfo.accountPOJO.accountInformationPOJO.linkedInUrl || '-'}
                                        </span>
                                    </div>
                                ) : null}
                                {nowUserInfo.interviewTimeShow ? (
                                    <div className="userMini-msg r-top">
                                        <img src={TimeMiniIcon} className="userInfoIcon-img" alt="" />
                                        <span className="oneLineText">
                                            Interview Date/Time
                                            <div className="op5Time">{nowUserInfo.interviewTimeShow || '-'}</div>
                                        </span>
                                    </div>
                                ) : null}
                            </div>
                            <div>
                                <div className="userOpeation-btn r-left marb12" onClick={() => openUser(1)}>
                                    View CV
                                    <img src={FileIcon} className="iconImg" alt="" />
                                </div>
                                <div className="userOpeation-btn r-left marb12" onClick={() => openUser(2)}>
                                    Message
                                    <img src={ChatMiniIcon} className="iconImg" alt="" />
                                </div>
                                <div className="userOpeation-btn r-left" onClick={() => openUser(3)}>
                                    Send Email
                                    <img src={MailIcon} className="iconImg" alt="" />
                                </div>
                                {nowUserInfo.jobsPOJO.jobStepStatus === 'Archvie' ? null : (
                                    <>
                                        {applicationsStatus !== 'offerReceived' && applicationsStatus !== 'rejected' ? (
                                            <div className="marb12" style={{ marginTop: 12 }}>
                                                <Button
                                                    className="r-danger_btn reject-btn"
                                                    onClick={() => changeModalType(2)}
                                                >
                                                    Reject <i className="reject_icon marLeft"></i>
                                                </Button>
                                            </div>
                                        ) : null}
                                        {applicationsStatus === 'inReview' ||
                                        applicationsStatus === 'interviewScheduled' ? (
                                            <div className="marb12">
                                                <Button
                                                    className="r-primary_btn moveStep-btn"
                                                    onClick={() => changeModalType(7)}
                                                >
                                                    Offer Position
                                                    <i className="step_icon marLeft"></i>
                                                </Button>
                                            </div>
                                        ) : applicationsStatus === 'applied' || applicationsStatus === 'screening' ? (
                                            <div>
                                                <Button
                                                    className="r-primary_btn moveStep-btn"
                                                    onClick={() => changeModalType(6)}
                                                >
                                                    Move to {applicationsStatus === 'applied' ? 'Stage' : 'Interview'}
                                                    <i className="step_icon marLeft"></i>
                                                </Button>
                                            </div>
                                        ) : null}
                                    </>
                                )}
                            </div>
                        </div>
                        {/* 评分按钮，只有面试阶段显示 */}
                        {(applicationsStatus === 'inReview' || applicationsStatus === 'interviewScheduled') &&
                        !nowUserInfo.myScoreFlag ? (
                            <div className="userOPeation-box mart12">
                                <div>
                                    <p className="note-tit primary-color">Notes</p>
                                    <div
                                        className="userOpeation-btn r-center marb12"
                                        onClick={() => setIsNoteModalOpen(true)}
                                    >
                                        + Add Notes
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {/* 评分列表详情 */}
                        {scoreListPOJO && scoreListPOJO.length ? (
                            <div className="userOPeation-box mart12">
                                <div>
                                    <p className="note-tit primary-color">Score</p>
                                    {scoreListPOJO.length
                                        ? scoreListPOJO.map(item => {
                                              return (
                                                  <div className="userMini-msg r-left">
                                                      {item.namelabel}
                                                      <span className="marLeft">{item.scoreAll}</span>
                                                  </div>
                                              );
                                          })
                                        : null}
                                    <div className="line-boxNew r-left"></div>
                                    <div className="total-score r-left">
                                        Total
                                        <div className="marLeft">{nowUserInfo.applicationsScoreTotal || 0}</div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="candidateInfo-right">
                        {/* video */}
                        <div className="r-left">
                            {state.videoList.map((item, i) => {
                                return (
                                    <div key={`candidateVideo-item${i}`} className="videoCard-box">
                                        <p className="video-tip">{item.tit}</p>
                                        <div
                                            className="videoCard-bg"
                                            style={{
                                                background: `url(${item.coverPic ? item.coverPic : ''})`,
                                                backgroundPosition: 'center center',
                                                backgroundSize: 'cover',
                                                backgroundColor: '#ccc',
                                            }}
                                            onClick={() => {
                                                setVideoModalUrl(item.url);
                                                setIsVideoModalOpen(true);
                                            }}
                                        ></div>
                                    </div>
                                );
                            })}
                        </div>
                        {/* video desc */}
                        <div className="candidateVideo-info">
                            <div className="video-infoTit">For the Business Interface</div>
                            <div className="r-top" style={{ alignItems: 'stretch' }}>
                                {state.videoList.map((item, i) => {
                                    return (
                                        <div key={`videoInfo-msg_${i}`} className="videoInfo-msg">
                                            <p className="msg-tit">{item.tit}</p>
                                            <p className="msg-tip">{item.desc || '-'}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {/* 面试官评分 */}
                        {scoreListPOJO.length ? (
                            <div className="candidateVideo-info">
                                <div className="video-infoTit">Internal Review</div>
                                <div>
                                    {scoreListPOJO.map((itemScore, i) => {
                                        return (
                                            <div key={`scoreBox-msg_${i}`} className="scoreList-box">
                                                <div
                                                    className="scoreInfo-top r-left"
                                                    onClick={() => {
                                                        scoreListPOJO.forEach((val1, k) => {
                                                            if (k === i) return;
                                                            val1.isOpen = false;
                                                        });
                                                        itemScore.isOpen = !itemScore.isOpen;
                                                        setScoreListPOJO([...scoreListPOJO]);
                                                    }}
                                                >
                                                    <div>
                                                        <p className="scoreName">{itemScore.namelabel || '-'}</p>
                                                        <p className="jobName-score">{itemScore.position || '-'}</p>
                                                    </div>
                                                    <div className="marLeft">
                                                        <p className="scoreName r-end">{itemScore.scoreAll || 0}</p>
                                                        <p className="jobName-score op05 r-end">avg. score</p>
                                                    </div>
                                                    <img src={DownIcon} style={{ marginLeft: 15, width: 12 }} alt="" />
                                                </div>
                                                {itemScore.isOpen ? (
                                                    <div style={{ marginTop: 12 }}>
                                                        <div className="line-boxNew"></div>
                                                        <div className="noteTip-box">Note</div>
                                                        <p className="note-msgTip">{itemScore.note || '-'}</p>
                                                        <div className="noteTip-box" style={{ paddingBottom: 0 }}>
                                                            Interview Scorecard Questions
                                                        </div>
                                                        <div className="note-modalBox">
                                                            {noteScore.map((item, i) => {
                                                                return (
                                                                    <div key={`notecard-box-${i}`}>
                                                                        <p className="form-tip">{item.label}</p>
                                                                        <div>
                                                                            {item.scores.map((val, k) => {
                                                                                return (
                                                                                    <>
                                                                                        {val.isShow ? (
                                                                                            <div
                                                                                                key={`score-item-box-${k}-${i}`}
                                                                                                className={`score-item-box r-left op05`}
                                                                                            >
                                                                                                <i
                                                                                                    style={{
                                                                                                        cursor: 'initial',
                                                                                                    }}
                                                                                                    className={`radioBox-icon ${
                                                                                                        +itemScore
                                                                                                            .scoreArrayList[
                                                                                                            i
                                                                                                        ] === val.label
                                                                                                            ? 'radioedBox-icon'
                                                                                                            : ''
                                                                                                    }`}
                                                                                                ></i>
                                                                                                <img
                                                                                                    src={val.icon}
                                                                                                    className="scoreImg-box"
                                                                                                    alt=""
                                                                                                />
                                                                                                {val.text}
                                                                                            </div>
                                                                                        ) : null}
                                                                                    </>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : null}
                        {/* 工作经验中的其他job */}
                        <div className="candidateVideo-info">
                            <div className="video-infoTit">Other Roles</div>
                            <div>
                                {relevantExperience.map((item, i) => {
                                    return (
                                        <div key={`roleBox-msg_${i}`} className="r-top roleList-box">
                                            <img src={CompanyIcon} className="roleImg" alt="" />
                                            <div>
                                                <p className="msg-tit">
                                                    {item.jobRoles || '-'} (
                                                    {`${item.skills ? item.skills.split(',').length : 0} ${
                                                        item.skills && item.skills.split(',').length > 1
                                                            ? 'skills'
                                                            : 'skill'
                                                    }`}
                                                    )
                                                </p>
                                                <p className="msg-tip" style={{ marginTop: 8 }}>
                                                    {item.skills || '-'}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {/* 技能 */}
                        <div className="candidateVideo-info">
                            <div className="video-infoTit r-left">
                                Skills
                                <Tooltip
                                    overlayClassName="skill-tooltipBox"
                                    // placement="topLeft"
                                    title={
                                        <div className="tooltip-text">
                                            <div className="title-box">Showcase Your Skills</div>
                                            <div>
                                                Highlight your soft skills (like teamwork and leadership) and hard
                                                skills (like technical expertise) to stand out to employers.
                                            </div>
                                            <br />
                                            <div>Why It Matters</div>
                                            <div>
                                                <span className="title-box">· Better Matches</span>: Talenaut’s AI
                                                connects you with roles that align perfectly with your skills and
                                                expertise, increasing your chances of landing the right job.
                                            </div>
                                            <div>
                                                <span className="title-box">· Stand Out</span>: Go beyond a traditional
                                                resume to showcase your full capabilities, potential, and value. ·
                                                Appeal to Employers: Present a well-rounded profile that highlights both
                                                technical expertise and personal attributes, showing how you’ll
                                                contribute to their team.
                                            </div>
                                            <div>
                                                <span className="title-box">· Attract Opportunities</span>: Engage
                                                hiring managers by giving them a comprehensive view of what makes you
                                                unique.
                                            </div>
                                        </div>
                                    }
                                >
                                    <i className="round-tipIcon"></i>
                                </Tooltip>
                            </div>
                            <div>
                                <p className="msg-tit">Hard Skills</p>
                                <div className="r-left">
                                    {nowUserInfo.hardSkill.map((item: any, i) => {
                                        return (
                                            <div
                                                key={`skillHardBox-msg_${i}`}
                                                className={`r-center skillList-box ${
                                                    item.skillNameMatchJobFlag ? 'active' : ''
                                                }`}
                                            >
                                                {item.skillNameMatchJobFlag ? <i className="check-boxIcon"></i> : null}
                                                <p className="msg-tip">{item.skillName || '-'}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <p className="msg-tit">Soft Skills</p>
                                <div className="r-left">
                                    {nowUserInfo.softSkill.map((item: any, i) => {
                                        return (
                                            <div
                                                key={`skillSoftBox-msg_${i}`}
                                                className={`r-center skillList-box ${
                                                    item.skillNameMatchJobFlag ? 'active' : ''
                                                }`}
                                            >
                                                {item.skillNameMatchJobFlag ? <i className="check-boxIcon"></i> : null}
                                                <p className="msg-tip">{item.skillName || '-'}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="candidateVideo-info">
                            <div className="video-infoTit break-text">About Me</div>
                            <div className="msg-tip" style={{ marginTop: 8 }}>
                                {nowUserInfo.accountPOJO.accountInformationPOJO.aboutMe || '-'}
                            </div>
                        </div>
                        {/* 工作经验 */}
                        <div className="candidateVideo-info" style={{ padding: '24px 16px 8px 16px' }}>
                            <div className="video-infoTit r-left">
                                Relevant Experience
                                <span className="marLeft black-color">
                                    {relevantExperienceTime ? relevantExperienceTime : '-'}
                                </span>
                            </div>
                            <div className="r-left relevantExperienceList">
                                {relevantExperience.map((item, i) => {
                                    return (
                                        <div
                                            key={`relevantExperience-profile-item-${i}`}
                                            className={`relevantExperience-item ${
                                                item.occupationalType !== 'Freelancer' ? 'greenBg' : ''
                                            }`}
                                        >
                                            <div className="experie-tit r-left">
                                                <span className="oneLineText">
                                                    {item.occupationalType !== 'Freelancer'
                                                        ? item.companyName
                                                        : 'Freelancer'}
                                                </span>
                                                {item.occupationalType !== 'Freelancer' ? (
                                                    <img src={Check} className="jobCheck-icon" alt="" />
                                                ) : null}
                                            </div>
                                            <div className="experie-job oneLineText">
                                                {item.jobRoles ? item.jobRoles : '-'}
                                            </div>
                                            <div className="experie-jobTime">
                                                {item.currentEmployedHereFlag === 1 ? (
                                                    'Currently Employed Here'
                                                ) : (
                                                    <>
                                                        {item.yearsOfExperience
                                                            ? `${item.yearsOfExperience} ${
                                                                  item.yearsOfExperience > 1 ? ' Years ' : ' Year '
                                                              }`
                                                            : ''}
                                                        {item.monthOfExperience
                                                            ? `${item.monthOfExperience} ${
                                                                  item.monthOfExperience > 1 ? ' Months' : ' month'
                                                              }`
                                                            : ''}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {/* 工作项目 */}
                        {accountProjectPOJOList.length ? (
                            <div
                                className="candidateVideo-info"
                                style={{
                                    paddingBottom: accountProjectPOJOList.length > 6 && !showMoreProject ? 24 : 0,
                                }}
                            >
                                <div className="video-infoTit">Portfolio</div>
                                <div className="r-left portList-box">
                                    {accountProjectPOJOList.map((item, i) => {
                                        return (
                                            <div
                                                className="portImg-box"
                                                key={item.projectId}
                                                style={{
                                                    display: i > 5 && !showMoreProject ? 'none' : 'initial',
                                                    border: '1px solid #ccc',
                                                    backgroundImage: `url(${item.coverImageUrl})`,
                                                    backgroundPosition: 'center center',
                                                }}
                                                onClick={() => {
                                                    setProjectInfo(item);
                                                    setOpenDrawer(true);
                                                }}
                                            ></div>
                                        );
                                    })}
                                </div>
                                {accountProjectPOJOList.length < 7 || showMoreProject ? null : (
                                    <div className="viewMore-btn r-center" onClick={() => setShowMoreProject(true)}>
                                        View More
                                    </div>
                                )}
                            </div>
                        ) : null}
                        {/* 候选人回答的问题 */}
                        {nowUserInfo.screeningQuestionsListPOJO && nowUserInfo.screeningQuestionsListPOJO.length ? (
                            <div className="candidateVideo-info">
                                <div className="video-infoTit">Screening Questions</div>
                                {nowUserInfo.screeningQuestionsListPOJO.map((item, i) => {
                                    return (
                                        <div
                                            key={`screening-box-${i}`}
                                            style={{
                                                marginBottom: `${
                                                    i === nowUserInfo.screeningQuestionsListPOJO.length - 1 ? 0 : '20px'
                                                }`,
                                            }}
                                        >
                                            {item.questionTypes === 'Short Answer' ? (
                                                <div>
                                                    <p className="msg-tit" style={{ marginBottom: 8 }}>
                                                        {item.questionDescription}({item.questionTypes})
                                                    </p>
                                                    <p className="msg-tip break-text">
                                                        {item.questionsOptionsPOJOList[0]?.optionsValue || '-'}
                                                    </p>
                                                </div>
                                            ) : (
                                                <div>
                                                    <p className="msg-tit" style={{ marginBottom: 8 }}>
                                                        {item.questionDescription}({item.questionTypes})
                                                    </p>
                                                    <div>
                                                        {item.questionsOptionsPOJOList.map((val, k) => {
                                                            return (
                                                                <div
                                                                    className="r-left seclectItem"
                                                                    key={`jobscreening-op-${i}-${k}`}
                                                                >
                                                                    <img
                                                                        src={
                                                                            !val.optionsValue ||
                                                                            !val.optionsValue.includes(val.optionsId)
                                                                                ? RadioButtonWhite
                                                                                : RadioButton
                                                                        }
                                                                        className="selected_icon"
                                                                        alt=""
                                                                    />
                                                                    <span>{val.options}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        ) : null}
                        {/* 学校信息 */}
                        <div className="candidateVideo-info">
                            <div className="video-infoTit">Education</div>
                            <div className="r-top roleList-box">
                                <img src={SchoolIcon} className="roleImg" alt="" />
                                <div>
                                    <p className="msg-tit">
                                        {nowUserInfo.accountPOJO.accountInformationPOJO.graduation || '-'}{' '}
                                        {nowUserInfo.accountPOJO.accountInformationPOJO.major
                                            ? `- ${nowUserInfo.accountPOJO.accountInformationPOJO.major}`
                                            : ''}
                                    </p>
                                    <p className="msg-tip" style={{ marginTop: 8 }}>
                                        {nowUserInfo.accountPOJO.accountInformationPOJO.highestDegree || '-'}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* 证书信息 */}
                        {accountCertificatePOJOList.length ? (
                            <div className="candidateVideo-info">
                                <div className="video-infoTit">Licenses, Certifications and External Assessments</div>
                                <div>
                                    {accountCertificatePOJOList.length ? (
                                        accountCertificatePOJOList.map((item, i) => {
                                            return (
                                                <div key={`licenseBox-msg_${i}`} className="r-top roleList-box">
                                                    <img src={LicenseIcon} className="roleImg" alt="" />
                                                    <div>
                                                        <p className="msg-tit">
                                                            {item.certificateName ? item.certificateName : '-'}
                                                        </p>
                                                        <p className="msg-tip" style={{ marginTop: 8 }}>
                                                            {dayjs(item.validPeriodFrom, 'MMM YYYY').format(
                                                                'MMMM YYYY'
                                                            )}{' '}
                                                            -{' '}
                                                            {dayjs(item.validPeriodTo, 'MMM YYYY').format('MMMM YYYY')}
                                                        </p>
                                                    </div>
                                                    {item.certificateUrl ? (
                                                        <img
                                                            src={LinkIcon}
                                                            className="roleImg marLeft"
                                                            style={{
                                                                width: 28,
                                                                height: 28,
                                                                marginRight: 0,
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() =>
                                                                window.open(`https://${item.certificateUrl}`, '_target')
                                                            }
                                                            alt=""
                                                        />
                                                    ) : null}
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="profile-info-pri op5 r-center">Not yet</div>
                                    )}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </>
            )}

            <VideoPlayModal
                videoUrl={videoModalUrl}
                isModalOpen={isVideoModalOpen}
                handleOk={() => setIsVideoModalOpen(false)}
                handleCancel={() => setIsVideoModalOpen(false)}
            />
            <PortFolioDrawer
                type="jobUser"
                userInfo={localUserInfo}
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                projectInfo={projectInfo}
            />
            <AlertModal
                title=""
                message={modalText.title}
                cancelText={modalText.cancelText}
                confirmText={modalText.confirmText}
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
            {/* 面试时间冲突弹窗 */}
            <AlertModal
                title=""
                message={interViewModalMsg}
                cancelText="Modification time"
                confirmText="Confirmation interview"
                isModalOpen={isInterViewModalOpen}
                handleOk={confirmInterView}
                loading={opLoading}
                handleCancel={() => {
                    setIsInterViewModalOpen(false);
                }}
            />
            <FormModal
                title="Interview Score Card"
                align="left"
                width={780}
                isModalOpen={isNoteModalOpen}
                handleCancel={() => {
                    setIsNoteModalOpen(false);
                    setInternalReviewInfo({ note: '' });
                }}
                children={
                    <div className="note-modalBox">
                        {noteScore.map((item, i) => {
                            return (
                                <div key={`notecard-box-${i}`}>
                                    <p className="form-tip">{item.label}</p>
                                    <div>
                                        {item.scores.map((val, k) => {
                                            return (
                                                <>
                                                    {val.isShow ? (
                                                        <div
                                                            key={`score-item-box-${i}-${k}`}
                                                            className="score-item-box r-left"
                                                            onClick={() => {
                                                                item.scores.forEach(val => (val.isCheck = false));
                                                                item.scores[k].isCheck = true;
                                                                setNoteScore([...noteScore]);
                                                            }}
                                                        >
                                                            <i
                                                                className={`radioBox-icon ${
                                                                    val.isCheck ? 'radioedBox-icon' : ''
                                                                }`}
                                                            ></i>
                                                            <img src={val.icon} className="scoreImg-box" alt="" />
                                                            {val.text}
                                                        </div>
                                                    ) : null}
                                                </>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                        <div className="note-tips">Notes</div>
                        <div className="textArea-box">
                            <TextArea
                                className={`r-inputTextArea r-input`}
                                maxLength={1000}
                                autoComplete="off"
                                autoSize={false}
                                placeholder={''}
                                value={internalReviewInfo.note}
                                onChange={(e: any) =>
                                    setInternalReviewInfo({ ...internalReviewInfo, note: e.target.value })
                                }
                            />
                            <span className="text-count">{internalReviewInfo.note.length || 0}/1000</span>
                        </div>
                        <div className="r-end">
                            <Button
                                style={{ width: 93, marginTop: 40 }}
                                className="r-primary_btn r-primary_largeBtn"
                                loading={opLoading}
                                disabled={
                                    !internalReviewInfo.note ||
                                    noteScore.findIndex(v => v.scores.filter(m => m.isCheck).length < 1) !== -1
                                }
                                onClick={() => addJobNoteInfo()}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                }
            />
            <FormModal
                title="Schedule An Interview"
                width={380}
                isModalOpen={isFormModalOpen}
                handleCancel={() => {
                    setInterviewTime({ day: '', startTime: '', endTime: '' });
                    setIsFormModalOpen(false);
                }}
                children={
                    <div>
                        <p className="form-tip">Interview Date</p>
                        <DatePicker
                            placeholder=""
                            disabledDate={disabledDate}
                            className="r-selDatePicker"
                            value={interViewTime.day ? interViewTime.day : null}
                            format={'DD MMM YYYY'}
                            onChange={val => setInterviewTime({ ...interViewTime, day: val })}
                            suffixIcon={<i className="downIcon"></i>}
                        />
                        <p className="form-tip" style={{ marginTop: 20 }}>
                            Interview Start Time
                        </p>
                        <TimePicker
                            placeholder=""
                            type="time"
                            className="r-selDatePicker"
                            disabled={!interViewTime.day}
                            value={interViewTime.startTime}
                            format={'h:mm a'}
                            disabledTime={() => ({
                                disabledHours: getDisabledHours,
                                disabledMinutes: getDisabledMinutes,
                            })}
                            onChange={val => setInterviewTime({ ...interViewTime, startTime: val })}
                            suffixIcon={<i className="downIcon"></i>}
                        />
                        <p className="form-tip" style={{ marginTop: 20 }}>
                            Interview Finish Time
                        </p>
                        <TimePicker
                            placeholder=""
                            type="time"
                            disabled={!interViewTime.day}
                            value={interViewTime.endTime}
                            format={'h:mm a'}
                            disabledTime={() => ({
                                disabledHours: getDisabledHours,
                                disabledMinutes: getDisabledMinutes,
                            })}
                            onChange={val => setInterviewTime({ ...interViewTime, endTime: val })}
                            className="r-selDatePicker"
                            suffixIcon={<i className="downIcon"></i>}
                        />
                        <div className="r-center">
                            <Button
                                style={{ width: 106, marginTop: 40 }}
                                className="r-primary_btn r-primary_largeBtn"
                                disabled={!interViewTime.day || !interViewTime.startTime || !interViewTime.endTime}
                                onClick={() => {
                                    confirmSendInterview();
                                }}
                                loading={opLoading}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                }
            />
        </div>
    );
}

export default CandidateDetail;
