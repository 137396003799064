import React, { useEffect, useState } from 'react';
import { Button, Input, Modal } from 'antd';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
function SubscriptionDetailModal({
    isModalOpen,
    handleOk,
    handleCancel,
    title,
    isUpgrade,
    subType,
    planInfo,
    setUserTeamNum,
    updateSubInfo,
}: any) {
    const [teamNum, setTeamNum] = useState('');
    const { profileInfo } = useSelector((state: any) => state.state);
    useEffect(() => {
        if (isModalOpen) {
            setTeamNum('');
        }
    }, [isModalOpen]);
    return (
        <Modal
            className="r-modal sub-info-modal"
            maskClosable={false}
            centered={true}
            closeIcon={<i className="r-modal-close"></i>}
            footer={''}
            width={540}
            title={
                <div className="modal-top-title" style={{ marginBottom: 30 }}>
                    {title ? title : 'Subscription Details'}
                </div>
            }
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            maskProps={{
                style: {
                    backgroundColor: 'rgba(30, 34, 45, 0.8)',
                    backdropFilter: 'blur(1px)',
                },
            }}
        >
            {/* 升级 对比 */}
            {!subType ? (
                <div>
                    <div className="r-top">
                        <div className="flex1">
                            <p className="mini-tip">Subscription Plan</p>
                            <p className="sub-boldTip">{profileInfo.businessInfoPOJO.subscriptionPlanName || '-'}</p>
                            <p className="mini-tip">Type</p>
                            <p className="sub-boldTip">
                                {profileInfo.businessInfoPOJO.subscriptionPlanType !== 'annualPlan'
                                    ? 'Monthly'
                                    : 'Annual'}{' '}
                                Plan
                            </p>
                            <p className="mini-tip">Price</p>
                            <p className="sub-boldTip">
                                {planInfo.priceCurrency}
                                {window.utils.formatAmount(profileInfo.businessInfoPOJO.subscriptionAmount || 0)}
                            </p>
                            <p className="mini-tip">Team Seats</p>
                            <p className="sub-boldTip">
                                {profileInfo.businessInfoPOJO.seatType === 'bySeats'
                                    ? '1+'
                                    : profileInfo.businessInfoPOJO.seatType === 'limited'
                                    ? profileInfo.businessInfoPOJO.seatsNumber
                                    : 'Unlimited'}
                            </p>
                            {isUpgrade ? (
                                <>
                                    <p className="mini-tip">End Date</p>
                                    <p className="sub-boldTip">
                                        {profileInfo.businessInfoPOJO.subscriptionEnd
                                            ? dayjs(
                                                  new Date(profileInfo.businessInfoPOJO.subscriptionEnd * 1000)
                                              ).format('MMMM D, YYYY')
                                            : '-'}
                                    </p>
                                </>
                            ) : null}
                        </div>
                        {/* 新套餐 */}
                        <div className="flex1">
                            <p className="mini-tip">Subscription Plan</p>
                            <p className="sub-boldTip">{planInfo.planName || '-'}</p>
                            <p className="mini-tip">Type</p>
                            <p className="sub-boldTip">
                                {planInfo.planType === 'monthlyPlan' ? 'Monthly' : 'Annual'} Plan
                            </p>
                            <p className="mini-tip">Price</p>
                            <p className="sub-boldTip">
                                {planInfo.priceCurrency}
                                {window.utils.formatAmount(planInfo.price || 0)}
                            </p>
                            <p className="mini-tip">Team Seats</p>
                            <p className="sub-boldTip">
                                {planInfo.seatType === 'bySeats'
                                    ? '1+'
                                    : planInfo.seatType === 'limited'
                                    ? planInfo.seatsNumber
                                    : 'Unlimited'}
                            </p>
                            {isUpgrade ? (
                                <>
                                    <p className="mini-tip">End Date</p>
                                    <p className="sub-boldTip">
                                        {updateSubInfo.subscriptionNowPOJO.periodEnd
                                            ? dayjs(
                                                  new Date(updateSubInfo.subscriptionNowPOJO.periodEnd * 1000)
                                              ).format('MMMM D, YYYY')
                                            : '-'}
                                    </p>
                                </>
                            ) : null}
                        </div>
                    </div>
                    <p className="line-boxSub"></p>
                    <div className="r-end" style={{ margin: '5px 0 30px 0' }}>
                        <p className="mini-tip totalTit-box">Actual:</p>
                        <p className="sub-boldTip noMar">
                            {updateSubInfo.subscriptionNowPOJO.priceCurrency}
                            {window.utils.formatAmount(updateSubInfo.total || 0)}
                        </p>
                    </div>
                </div>
            ) : (
                // 按席位订阅
                <div>
                    <p className="mini-tip">Subscription Plan</p>
                    <p className="sub-boldTip">{planInfo.planName || '-'}</p>
                    <p className="mini-tip">Type</p>
                    <p className="sub-boldTip">{planInfo.planType === 'monthlyPlan' ? 'Monthly' : 'Annual'} Plan</p>
                    <p className="mini-tip">Unit Price Of Seat</p>
                    <p className="sub-boldTip">
                        {planInfo.priceCurrency}
                        {window.utils.formatAmount(planInfo.price || 0)}
                    </p>
                    <p className="mini-tip">Number of team seats</p>
                    <div style={{ margin: '8px 0 30px 0' }}>
                        <Input
                            className="r-input"
                            maxLength={10}
                            value={teamNum}
                            onInput={(e: any) => {
                                e.target.value = e.target.value.replace(/\D/g, '');
                                setTeamNum(e.target.value);
                                setUserTeamNum(e.target.value);
                            }}
                        />
                    </div>
                    {isUpgrade ? (
                        <div>
                            <p className="mini-tip">End Date</p>
                            <p className="sub-boldTip">
                                {profileInfo.businessInfoPOJO.subscriptionEnd
                                    ? dayjs(new Date(profileInfo.businessInfoPOJO.subscriptionEnd * 1000)).format(
                                          'MMMM D, YYYY'
                                      )
                                    : '-'}
                            </p>
                            <p className="line-boxSub" style={{ marginTop: 30 }}></p>
                        </div>
                    ) : null}

                    {!isUpgrade ? (
                        <div className="r-end" style={{ margin: '5px 0 30px 0' }}>
                            <p className="mini-tip totalTit-box">{isUpgrade ? 'Actual:' : 'Total price:'}</p>
                            <p className="sub-boldTip noMar">
                                {planInfo.priceCurrency}
                                {(planInfo.price * +teamNum).toFixed(2)}
                            </p>
                        </div>
                    ) : null}
                </div>
            )}
            <div className="r-end">
                <Button
                    className="r-primary_btn"
                    style={{ width: 108 }}
                    disabled={(!teamNum || Number(teamNum) === 0) && subType}
                    onClick={() => handleOk()}
                >
                    Go to pay
                </Button>
            </div>
        </Modal>
    );
}

export default SubscriptionDetailModal;
