import React, { useCallback, useState } from 'react';
import { debounce } from 'lodash';
import { defaultInfo } from '@/typings/allType';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import jobApi from '@/api/job';
import { AutoComplete, Spin } from 'antd';

function SkillInp({ setInfo, classStr, suffixIcon, type, isFinshClear, placeholder, height, disabled, jobId }: any) {
    const messageApi = useGlobalMessage();
    const [autoLoading, setAutoLoading] = useState(false);
    const [inpValue, setInpValue] = useState('');
    const [options, setOptions] = useState<any[]>([]);
    const getLocationOption = search => {
        if (!search) {
            setOptions([]);
            return;
        }
        setAutoLoading(true);
        if (type === 'hard') {
            if (jobId) {
                jobApi.getSkillHardListForJobSearch({ ...search, jobId }, res => {
                    setAutoLoading(false);
                    if (res.code === 200) {
                        const results = res.data.map((item: defaultInfo, i: number) =>
                            Object.assign(item, { value: `${item.skillName}`, label: item.skillName })
                        );
                        setOptions(results);
                    } else {
                        setOptions([]);
                        messageApi.error(res.message);
                    }
                });
                return;
            }
            jobApi.getSkillHardListForSearch(search, res => {
                setAutoLoading(false);
                if (res.code === 200) {
                    const results = res.data.map((item: defaultInfo, i: number) =>
                        Object.assign(item, { value: `${item.skillName}`, label: item.skillName })
                    );
                    setOptions(results);
                } else {
                    setOptions([]);
                    messageApi.error(res.message);
                }
            });
        } else {
            if (jobId) {
                jobApi.getSkillSoftListForJobSearch({ ...search, jobId }, res => {
                    setAutoLoading(false);
                    if (res.code === 200) {
                        const results = res.data.map((item: defaultInfo, i: number) =>
                            Object.assign(item, { value: `${item.skillName}`, label: item.skillName })
                        );
                        setOptions(results);
                    } else {
                        setOptions([]);
                        messageApi.error(res.message);
                    }
                });
                return;
            }
            jobApi.getSkillSoftListForSearch(search, res => {
                setAutoLoading(false);
                if (res.code === 200) {
                    const results = res.data.map((item: defaultInfo, i: number) =>
                        Object.assign(item, { value: `${item.skillName}`, label: item.skillName })
                    );
                    setOptions(results);
                } else {
                    setOptions([]);
                    messageApi.error(res.message);
                }
            });
        }
    };
    const searchOptionsList: any = useCallback(
        debounce(params => {
            getLocationOption({ keyWord: params });
            console.log('search');
        }, 1000),
        []
    );
    return (
        <>
            <AutoComplete
                options={options}
                className={classStr ? classStr : 'r-autoComplate'}
                style={{ width: '100%', height: height || 30 }}
                value={inpValue}
                disabled={disabled}
                onChange={setInpValue}
                suffixIcon={suffixIcon ? suffixIcon : <i className="down-right-icon"></i>}
                onSelect={val => {
                    const info = options.find(v => v.value === val);
                    if (isFinshClear) {
                        setInpValue('');
                        setOptions([]);
                    } else {
                        setInpValue(info.label);
                    }
                    setInfo({ val: info.label });
                }}
                onSearch={val => {
                    searchOptionsList(val);
                }}
                onBlur={() => setOptions([])}
                placeholder={placeholder || 'Any'}
                notFoundContent={
                    <>
                        {autoLoading && (
                            <div className="r-center">
                                <Spin />
                            </div>
                        )}
                        {!autoLoading && <div>No data</div>}
                    </>
                }
            ></AutoComplete>
        </>
    );
}

export default SkillInp;
