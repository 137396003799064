import React, { useEffect, useState } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import OutlookIcon from '@/assets/img/Outlook.png';
import GoogleIcon from '@/assets/img/Google.png';
import { Button, Input, Spin } from 'antd';
import EditAccount from './components/edit-account';
import VerificationCodeDialog from '@/components/modal/verification-code-dialog';
import EditPassword from './components/edit-password';
import SubscriptionInfomation from './components/subscription-infomation';
import { useCountDown } from 'ahooks';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import api from '@/api/login';
import { useSelector } from 'react-redux';
import { setProfileInfo } from '@/store/stateSlice';
import { useAppDispatch } from '@/store/hooks';
import { roleList } from '@/config/role';
import useOutlookAuth from '@/hook/useOutlookAuth';
import useGoogleAuth from '@/hook/useGoogleAuth';
function AdminSettings() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { profileInfo } = useSelector((state: any) => state.state);
    const messageApi = useGlobalMessage();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [isSending, setIsSending] = useState(true);
    const [loading, setLoading] = useState(false);
    const [editTit, setEditTit] = useState('Edit');
    const [outlookLoading, setOutlookLoading] = useState(false);
    const [googleLoading, setGoogleLoading] = useState(false);
    const [calendarOutlook, setCalendarOutlook] = useState(false);
    const [calendarGoogle, setCalendarGoogle] = useState(false);
    const [state, setState] = useState({
        connectOutlook: false,
        connectGoogle: true,
        isSub: true,
        isExpired: true,
        isCancel: false,
    });
    // link calendar
    const { handleOutLookLogin, outlookCode, setOutlookCode } = useOutlookAuth();
    const { handleGoogleLogin, googleCode, setGoogleCode } = useGoogleAuth();
    useEffect(() => {
        if (outlookCode) {
            linkThired('outlook', outlookCode);
        }
    }, [outlookCode]);
    useEffect(() => {
        if (googleCode) {
            linkThired('google', googleCode);
        }
    }, [googleCode]);
    const linkThired = (type: string, code: string) => {
        console.log('====================================');
        console.log(type);
        console.log('====================================');
        if (type === 'outlook') {
            setOutlookLoading(true);
            api.connectCalendarOutlook({ keyWord: code }, res => {
                setOutlookLoading(false);
                if (res.code === 200) {
                    setCalendarOutlook(true);
                    setOutlookCode('');
                } else {
                    messageApi.error(res.message);
                }
            });
            return;
        }
        setGoogleLoading(true);
        api.connectCalendarGoogle({ keyWord: code }, res => {
            setGoogleLoading(false);
            if (res.code === 200) {
                setCalendarGoogle(true);
                setGoogleCode('');
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const unlink = (type: string) => {
        if (type === 'outlook') {
            setOutlookLoading(true);
            api.disconnectCalendarOutlook({}, res => {
                setOutlookLoading(false);
                if (res.code === 200) {
                    setCalendarOutlook(false);
                } else {
                    messageApi.error(res.message);
                }
            });
            return;
        }
        setGoogleLoading(true);
        api.disconnectCalendarGoogle({}, res => {
            setGoogleLoading(false);
            if (res.code === 200) {
                setCalendarGoogle(false);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    //
    const editInfo = tit => {
        setEditTit(tit);
        setIsModalOpen(true);
    };
    const [isCodeModalOpen, setIsCodeModalOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [seconds, setSeconds] = useState<number>();
    const [codeArr, setCodeArr] = useState(['', '', '', '', '', '']);
    const [countdown] = useCountDown({
        targetDate: seconds,
        onEnd: () => {
            setSeconds(0);
            setIsActive(false);
        },
    });
    const sendForgetEmail = () => {
        if (seconds || isActive) return;
        api.sendEmailCode({ email: profileInfo.email }, res => {
            if (res.code === 200) {
                setIsCodeModalOpen(true);
                setSeconds(Date.now() + 60000);
                setIsActive(true);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const verificationEmailCode = () => {
        console.log(codeArr);
        api.verifyEmailCode(
            {
                email: profileInfo.email,
                code: codeArr.join(''),
            },
            res => {
                const { code, data } = res;
                if (code === 200) {
                    setCodeArr(['', '', '', '', '', '']);
                    setIsCodeModalOpen(false);
                    window.sessionStorage.setItem('code', codeArr.join(''));
                    setIsPasswordModalOpen(true);
                } else {
                    window.utils.alert('error', res.message);
                }
            }
        );
    };
    const getInfo = () => {
        setLoading(true);
        api.getBusinessInfo({}, res => {
            setLoading(false);
            const { code, data, message } = res;
            if (code === 200) {
                console.log(data);
                setCalendarGoogle(data.calendarGoogle ? true : false);
                setCalendarOutlook(data.calendarOutlook ? true : false);
                data.roleNum = roleList[data.role];
                data.showName = window.utils.getInitials(data.firstName, data.lastName);
                dispatch(setProfileInfo(data));
            } else {
                window.utils.alert('error', message);
            }
        });
    };
    useEffect(() => {
        getInfo();
    }, []);
    return (
        <div className="admin-settings-box">
            <div>
                <div className="setting-tit">Account</div>
                {loading ? (
                    <div className="r-center no-message-tabBox">
                        <Spin></Spin>
                    </div>
                ) : (
                    <>
                        <div className="account-form">
                            <p className="form-tip">Full Name</p>
                            <div className="account-infoInp r-left">
                                {`${profileInfo.firstName} ${profileInfo.middleName} ${profileInfo.lastName}`}
                                <i className="edit-acc" onClick={() => editInfo('Edit')}></i>
                            </div>
                            {profileInfo.roleNum === 0 ? (
                                <div>
                                    <p className="form-tip">Business Name</p>
                                    <div className="account-infoInp r-left">
                                        {profileInfo?.businessInfoPOJO?.businessName}
                                        <i className="edit-acc" onClick={() => editInfo('Edit Business Name')}></i>
                                    </div>
                                </div>
                            ) : null}
                            <p className="form-tip">Position</p>
                            <div className="account-infoInp r-left">
                                {profileInfo.position ?? '-'}
                                <i className="edit-acc" onClick={() => editInfo('Edit Position')}></i>
                            </div>
                            <p className="form-tip">Email</p>
                            <div className="account-infoInp r-left">
                                <span className="disabled-inp">{profileInfo.email ?? '-'}</span>
                            </div>
                            <p className="form-tip">Password</p>
                            <div className="account-infoInp r-left">
                                <span className="disabled-inp">**********</span>
                                <i
                                    className="edit-acc"
                                    onClick={() => {
                                        if (isActive) {
                                            messageApi.error(
                                                'The operation is too frequent. Please try again later.  60s'
                                            );
                                            return;
                                        }
                                        sendForgetEmail();
                                    }}
                                ></i>
                            </div>
                        </div>
                        <div className="setting-tit calendar-tit">Calendar Integration</div>
                        <div className="r-left">
                            <div className="calendar-cardbox">
                                <p className="r-center">Outlook</p>
                                <div className="r-center">
                                    <img src={OutlookIcon} className="calendar-imgIcon" alt="" />
                                </div>
                                <div className="r-center">
                                    {!calendarOutlook ? (
                                        <Button
                                            className="r-primary_btn calendarC-btn"
                                            style={{ width: 100 }}
                                            onClick={() => handleOutLookLogin()}
                                            loading={outlookLoading}
                                        >
                                            Connect
                                        </Button>
                                    ) : (
                                        <Button
                                            className="r-primary_btn calendarC-btn r-primaryNoBg_btn"
                                            style={{ width: 120 }}
                                            loading={outlookLoading}
                                            onClick={() => unlink('outlook')}
                                        >
                                            Disconnect
                                        </Button>
                                    )}
                                </div>
                            </div>
                            <div className="calendar-cardbox" style={{ marginLeft: 40 }}>
                                <p className="r-center">Google</p>
                                <div className="r-center">
                                    <img src={GoogleIcon} className="calendar-imgIcon" alt="" />
                                </div>
                                <div className="r-center">
                                    {!calendarGoogle ? (
                                        <Button
                                            className="r-primary_btn calendarC-btn"
                                            style={{ width: 100 }}
                                            loading={googleLoading}
                                            onClick={() => handleGoogleLogin()}
                                        >
                                            Connect
                                        </Button>
                                    ) : (
                                        <Button
                                            className="r-primary_btn calendarC-btn r-primaryNoBg_btn"
                                            style={{ width: 120 }}
                                            loading={googleLoading}
                                            onClick={() => unlink('google')}
                                        >
                                            Disconnect
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                        {profileInfo.roleNum > 1 ? null : (
                            <>
                                <div className="sub-titTop calendar-tit">Subscription</div>
                                <SubscriptionInfomation
                                    getInfo={getInfo}
                                    isSub={state.isSub}
                                    isExpired={state.isExpired}
                                    isCancel={state.isCancel}
                                />
                            </>
                        )}
                    </>
                )}
            </div>

            <EditAccount
                title={editTit}
                isModalOpen={isModalOpen}
                handleOk={() => {
                    setIsModalOpen(false);
                    getInfo();
                }}
                handleCancel={() => setIsModalOpen(false)}
            />
            <EditPassword
                isModalOpen={isPasswordModalOpen}
                handleOk={() => setIsPasswordModalOpen(false)}
                handleCancel={() => setIsPasswordModalOpen(false)}
            />
            <VerificationCodeDialog
                setCodeArr={setCodeArr}
                sendForgetEmail={sendForgetEmail}
                seconds={countdown}
                codeArr={codeArr}
                isActive={isActive}
                loading={loading}
                verificationEmailCode={verificationEmailCode}
                isModalOpen={isCodeModalOpen}
                handleCancel={() => setIsCodeModalOpen(false)}
            />
        </div>
    );
}

export default AdminSettings;
