import React, { useEffect, useState } from 'react';
import './index.scss';
import { Popover, Spin } from 'antd';
import Pagination from '@/components/pagination';
import { useFeedback } from '@/context/notificationContext';
import api from '@/api/login';
import dayjs from 'dayjs';
function NotificationList() {
    const { refreshFeedbackCount } = useFeedback();
    const [totalRecord, setTotalRecord] = useState(100);
    const [tableData, setTableData] = useState<any>([]);
    const [state, setState] = useState({
        pageNo: 1,
    });
    const read = () => {
        api.readAllCount(res => {
            const { code, data, message } = res;
            if (code === 200) {
                refreshFeedbackCount();
            } else {
                window.utils.alert('error', message);
            }
        });
    };
    const delMsg = val => {
        api.delNoticaion({ id: val.id }, res => {
            const { code, data, message } = res;
            if (code === 200) {
                refreshFeedbackCount();
                setState({ pageNo: 1 });
                getList({ ...state, pageNo: 1 });
            } else {
                window.utils.alert('error', message);
            }
        });
    };
    const handlePageChange = prop => {
        setTableData([]);
        setState({ ...state, pageNo: prop });
        getList({ pageNo: prop });
    };
    const [listLoading, setListLoading] = useState(false);
    const getList = val => {
        setListLoading(true);
        const pat = {
            pageSize: 10,
            pageNo: val.pageNo,
        };
        api.getNoticationList(pat, res => {
            setListLoading(false);
            const { code, data, message } = res;
            if (code === 200) {
                setTotalRecord(data.totalRecord || 0);
                if (data.list) {
                    setTableData(
                        data.list.map(item => {
                            return Object.assign(item, {
                                createTimeShow: dayjs(new Date(item.createTime * 1000)).format('DD MMM YYYY h:mm a'),
                            });
                        })
                    );
                } else {
                    setTableData([]);
                }
            } else {
                window.utils.alert('error', message);
            }
        });
    };
    useEffect(() => {
        getList({ ...state });
        read();
    }, []);
    return (
        <div className="talent-notificationBox">
            <div className="team-topBox">
                <div className="r-left">
                    <h2>Notifications</h2>
                </div>
            </div>
            <div className="team-table-box">
                <div className="team-table-th r-left">
                    <div className="thade-cell flex1">Content</div>
                    <div className="thade-cell w320">Date</div>
                    <div className="thade-cell" style={{ width: 50 }}></div>
                </div>
                <div className="team-table-body">
                    {listLoading ? (
                        <>
                            <div className="r-center no-message-tabBox">
                                <Spin style={{ position: 'static' }}></Spin>
                            </div>
                        </>
                    ) : tableData.length ? (
                        tableData.map((val, i) => {
                            return (
                                <div className="team-table-tr r-left" key={`team-table-tr-${i}`}>
                                    <div className="team-tb-cell flex1">
                                        {val.notificationType === 'candidateApplied' ? (
                                            <div className="r-left">
                                                A new candidate applied for the
                                                <span className="blue-textNotic">{` ${val.message} `}</span>
                                                job
                                            </div>
                                        ) : val.notificationType === 'interviewStart' ? (
                                            <div className="r-left">
                                                You have an interview that will start at
                                                <span className="blue-textNotic">{` ${val.message}.`}</span>
                                            </div>
                                        ) : (
                                            val.message
                                        )}
                                    </div>
                                    <div className="team-tb-cell w320">{val.createTimeShow || '-'}</div>
                                    <div className="team-tb-cell r-end">
                                        <Popover
                                            placement="bottomRight"
                                            title={''}
                                            trigger="hover"
                                            content={
                                                <div className="tableOpeation-box remove-opBox">
                                                    <div
                                                        className="tb_item danger"
                                                        onClick={() => {
                                                            delMsg(val);
                                                        }}
                                                    >
                                                        Remove
                                                    </div>
                                                </div>
                                            }
                                            arrow={false}
                                        >
                                            <i className={`more_icon ${val.popoverOpen ? 'more_iconSeled' : ''}`}></i>
                                        </Popover>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="r-center no-message-tabBox">No data</div>
                    )}
                </div>
            </div>
            {totalRecord > 0 && totalRecord > 10 ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={state.pageNo} />
            ) : (
                <></>
            )}
        </div>
    );
}

export default NotificationList;
