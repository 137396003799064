import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import { useState } from 'react';
import { useAppDispatch } from '@/store/hooks';
import { useSelector } from 'react-redux';
import { setDataBaseInfo } from '@/store/stateSlice';
const useFireBase = () => {
    const dispatch = useAppDispatch();
    const { feedBackCount, locationFeedBackCount } = useSelector((state: any) => state.state);
    const firebaseConfig = {
        apiKey: 'AIzaSyDX48vQcrm1J3z2OhxzdHzW3aQITnHgZ-4',
        authDomain: 'talenaut-in-use.firebaseapp.com',
        databaseURL: 'https://talenaut-in-use-default-rtdb.europe-west1.firebasedatabase.app',
        projectId: 'talenaut-in-use',
        storageBucket: 'talenaut-in-use.appspot.com',
        messagingSenderId: '604511413297',
        appId: '1:604511413297:web:d5e80cda49b14ffdef2110',
        measurementId: 'G-WNDYD1P1X7',
    };
    const [state, setState] = useState({
        time: Date.parse(new Date() as any) / 1000,
        isLocationCountFirst: true,
        isCountFirst: true,
        facebackCountRef: '' as any,
    });
    let jobCountRef: any = null;
    const app = initializeApp(firebaseConfig);
    const database = getDatabase(app);
    const initDataBase = accountId => {
        const jobCountRefNew = ref(database, `business/${accountId}/application`);
        // setJobCountRef(jobCountRefNew);
        jobCountRef = jobCountRefNew;
        onValue(jobCountRefNew, snapshot => {
            const data = snapshot.val();
            console.log('job-update', data);
            setState({ ...state, isLocationCountFirst: false });
            dispatch(setDataBaseInfo(data));
        });
    };
    const offDataBase = () => {
        off(jobCountRef);
        jobCountRef = null;
        console.log('offline count dataBase');
    };
    return {
        initDataBase,
        offDataBase,
    };
};

export default useFireBase;
