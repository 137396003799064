import React, { useState } from 'react';
import { Button, Form, FormProps, Input, message, Modal } from 'antd';
import VerificationCodeDialog from '@/components/modal/verification-code-dialog';
import { useNavigate } from 'react-router-dom';
import api from '@/api/login';
import { useCountDown } from 'ahooks';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import usePasswordEncrypt from '@/hook/usePasswordEncrypt';
import { setChartInfo } from '@/store/stateSlice';
import { useAppDispatch } from '@/store/hooks';
function SignUp() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { encryptPassword, contextHolder } = usePasswordEncrypt();
    const messageApi = useGlobalMessage();
    const [isActive, setIsActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sendLoading, setSendLoading] = useState(false);
    const [eyeOpen, setEyeOpen] = useState(false);
    const [eyeOpen1, setEyeOpen1] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [state, setState] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        position: '',
        business: '',
        email: '',
        password: '',
        rePassword: '',
    });
    const [seconds, setSeconds] = useState<number>();
    const [codeArr, setCodeArr] = useState(['', '', '', '', '', '']);
    const [countdown] = useCountDown({
        targetDate: seconds,
        onEnd: () => {
            setSeconds(0);
            setIsActive(false);
        },
    });
    const sendSignEmail = () => {
        if (seconds || isActive) {
            messageApi.error('The operation is too frequent. Please try again later.  60s');
            return;
        }
        setSendLoading(true);
        api.signUpSendCode({ email: state.email }, res => {
            setSendLoading(false);
            if (res.code === 200) {
                setIsModalOpen(true);
                setSeconds(Date.now() + 60000);
                setIsActive(true);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const verificationEmailCode = () => {
        setLoading(true);
        api.signUpCheckCode(
            {
                email: state.email,
                code: codeArr.join(''),
            },
            res => {
                const { code, data } = res;
                if (code === 200) {
                    signAcc();
                } else {
                    window.utils.alert('error', res.message);
                    setLoading(false);
                }
            }
        );
    };
    const signAcc = () => {
        const pat = {
            code: codeArr.join(''),
            email: state.email,
            password: encryptPassword(state.password),
            businessName: state.business,
            firstName: state.firstName,
            lastName: state.lastName,
            middleName: state.middleName,
            position: state.position,
        };
        api.signUp(pat, loginRes => {
            setLoading(false);
            if (loginRes.code === 200) {
                setCodeArr(['', '', '', '', '', '']);
                window.sessionStorage.setItem('admin-satoken', loginRes.data.token);
                window.sessionStorage.setItem('accountId', loginRes.data.accountId);
                window.sessionStorage.setItem('code', codeArr.join(''));
                window.sessionStorage.setItem('email', state.email);
                setIsModalOpen(false);
                navigate('/team-management');
                getSendBirdKey(loginRes.data.accountId);
            } else {
                messageApi.error(loginRes.message);
            }
        });
    };
    const dispatch = useAppDispatch();
    const getSendBirdKey = async id => {
        try {
            const res = await api.getSendBirdKey();
            const { code, data, message } = res.data;
            if (code === 200) {
                dispatch(
                    setChartInfo({
                        adminId: id,
                        sendbirdId: data.APPLICATION_ID,
                        sendbirdAccessToken: data.API_TOKEN,
                    })
                );
            } else {
                window.utils.alert('error', message);
            }
        } catch (error) {
            window.utils.alert('error', error);
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onFinish: FormProps['onFinish'] = values => {
        if (state.password !== state.rePassword) {
            message.error('The 2 passwords entered are not the same');
            setState({ ...state, password: '', rePassword: '' });
            form.setFieldsValue({ password: '', rePassword: '' });
            return;
        }
        sendSignEmail();
    };
    const validatePassword = (_: any, value: string) => {
        const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z\d]{8,12}$/;
        if (!value || !passwordPattern.test(value)) {
            return Promise.reject(
                new Error(
                    'Please enter a password of 8-12, which must contain one uppercase letter, one lowercase letter, and one number.'
                )
            );
        }
        return Promise.resolve();
    };

    return (
        <div className="login-main r-center">
            <div className="center-box">
                <img className="logo-img" src={require('@/assets/img/Logo.png')} alt="" />
                <div className="login-formBox">
                    <Form
                        form={form}
                        name="basic-sign"
                        layout="vertical"
                        style={{ maxWidth: 380 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        autoComplete="off"
                        validateTrigger="onBlur"
                    >
                        <Form.Item
                            label={<div>First Name</div>}
                            name="firstName"
                            rules={[{ required: true, message: 'Please enter a first name' }]}
                        >
                            <Input
                                className="r-input"
                                value={state.firstName}
                                maxLength={20}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const str = e.target.value;
                                    const val = str.charAt(0).toUpperCase() + str.slice(1);
                                    setState({ ...state, firstName: val });
                                    form.setFieldsValue({ firstName: val });
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Middle Name (Optional)"
                            name="middleName"
                            rules={[{ required: false, message: ' ' }]}
                        >
                            <Input
                                className="r-input"
                                maxLength={20}
                                value={state.middleName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const str = e.target.value;
                                    const val = str.charAt(0).toUpperCase() + str.slice(1);
                                    setState({ ...state, middleName: val });
                                    form.setFieldsValue({ middleName: val });
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Last Name"
                            name="lastName"
                            rules={[{ required: true, message: 'Please enter a last name' }]}
                        >
                            <Input
                                className="r-input"
                                maxLength={20}
                                value={state.lastName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const str = e.target.value;
                                    const val = str.charAt(0).toUpperCase() + str.slice(1);
                                    setState({ ...state, lastName: val });
                                    form.setFieldsValue({ lastName: val });
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Position"
                            name="position"
                            rules={[{ required: true, message: 'Please enter the position' }]}
                        >
                            <Input
                                className="r-input"
                                maxLength={50}
                                value={state.position}
                                onInput={(e: any) => setState({ ...state, position: e.target.value })}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Business Name"
                            name="business"
                            rules={[{ required: true, message: 'Please enter the business name' }]}
                        >
                            <Input
                                className="r-input"
                                maxLength={100}
                                value={state.business}
                                onInput={(e: any) => setState({ ...state, business: e.target.value })}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Email Address"
                            name="email"
                            rules={[
                                { required: true, message: 'Please enter a valid email address' },
                                {
                                    type: 'email',
                                    message: 'Please enter a valid email address',
                                },
                            ]}
                        >
                            <Input
                                className="r-input"
                                value={state.email}
                                onInput={(e: any) => setState({ ...state, email: e.target.value })}
                            />
                        </Form.Item>
                        <Form.Item label="Create A Password" name="password" rules={[{ validator: validatePassword }]}>
                            <Input
                                className={`r-input `}
                                maxLength={12}
                                autoComplete="off"
                                placeholder={'Enter 8-12 digit password'}
                                type={eyeOpen ? 'text' : 'password'}
                                value={state.password}
                                onInput={(e: any) => setState({ ...state, password: e.target.value })}
                                suffix={
                                    eyeOpen ? (
                                        <i className="eye-open" onClick={() => setEyeOpen(false)}></i>
                                    ) : (
                                        <i className="eye-close" onClick={() => setEyeOpen(true)}></i>
                                    )
                                }
                            />
                        </Form.Item>
                        <Form.Item label="Confirm Password" name="rePassword" rules={[{ validator: validatePassword }]}>
                            <Input
                                className={`r-input `}
                                maxLength={12}
                                autoComplete="off"
                                placeholder={'Re-enter the 8-12 digit password'}
                                type={eyeOpen1 ? 'text' : 'password'}
                                value={state.rePassword}
                                onInput={(e: any) => setState({ ...state, rePassword: e.target.value })}
                                suffix={
                                    eyeOpen1 ? (
                                        <i className="eye-open" onClick={() => setEyeOpen1(false)}></i>
                                    ) : (
                                        <i className="eye-close" onClick={() => setEyeOpen1(true)}></i>
                                    )
                                }
                            />
                        </Form.Item>
                        <Form.Item style={{ marginTop: 30 }}>
                            <Button
                                className="r-primary_btn"
                                type="primary"
                                loading={loading}
                                disabled={
                                    !state.email ||
                                    !state.password ||
                                    !state.rePassword ||
                                    !state.firstName ||
                                    !state.lastName ||
                                    !state.business ||
                                    !state.position
                                }
                                htmlType="submit"
                            >
                                Sign Up
                            </Button>
                        </Form.Item>
                    </Form>
                    <div className="r-center btm-tip">
                        Already have an account?
                        <div className="forgetBox" style={{ margin: '0 0 0 8px' }}>
                            <span onClick={() => navigate('/login')}>Log In</span>
                        </div>
                    </div>
                </div>
            </div>
            <VerificationCodeDialog
                setCodeArr={setCodeArr}
                sendForgetEmail={sendSignEmail}
                seconds={countdown}
                codeArr={codeArr}
                isActive={isActive}
                loading={loading}
                verificationEmailCode={verificationEmailCode}
                isModalOpen={isModalOpen}
                handleCancel={handleCancel}
            />
        </div>
    );
}

export default SignUp;
