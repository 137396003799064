import React, { useEffect, useState } from 'react';
import UserDefaultImg from '@/assets/img/user-default.png';
import './index.scss';
function PoolCardImage({ userList }) {
    const [showList, setShowList] = useState<any>([]);
    useEffect(() => {
        console.log('====================================');
        console.log(userList);
        console.log('====================================');
        if (userList && userList.length) {
            const arr: any = [];
            userList.forEach(item => {
                const url = item?.accountPOJO?.accountInformationPOJO?.profilePhoto || '';
                arr.push(url);
            });
            setShowList([...arr]);
        }
    }, [userList]);
    return (
        <div className="r-left PoolCardImageBox">
            {showList.length === 0 ? (
                <div className="r-center w100-img">
                    <img src={UserDefaultImg} className="UserDefaultImg-box" alt="" />
                </div>
            ) : showList.length === 1 ? (
                <div className="r-center w100-img">
                    <img src={showList[0] || UserDefaultImg} className="oneImg-box" alt="" />
                </div>
            ) : showList.length === 2 ? (
                <div className="r-center w100-img">
                    <img src={showList[0] || UserDefaultImg} className="twoImg-box" alt="" />
                    <img src={showList[1] || UserDefaultImg} className="twoImg-box" alt="" />
                </div>
            ) : showList.length === 3 ? (
                <div className="r-center w100-img">
                    <img src={showList[0] || UserDefaultImg} style={{ width: '23%' }} className="img-box" alt="" />
                    <img
                        src={showList[1] || UserDefaultImg}
                        style={{ width: '47%', margin: '0 1px' }}
                        className="img-box"
                        alt=""
                    />
                    <img src={showList[2] || UserDefaultImg} style={{ width: '30%' }} className="img-box" alt="" />
                </div>
            ) : showList.length === 4 ? (
                <div className="r-center w100-img">
                    <div style={{ width: '23%', height: '100%' }}>
                        <img src={showList[0] || UserDefaultImg} className="img-box h50-img" alt="" />
                        <img
                            src={showList[1] || UserDefaultImg}
                            style={{ marginTop: 1 }}
                            className="img-box h50-img"
                            alt=""
                        />
                    </div>
                    <img
                        src={showList[2] || UserDefaultImg}
                        style={{ width: '47%', margin: '0 1px' }}
                        className="img-box"
                        alt=""
                    />
                    <img src={showList[3] || UserDefaultImg} style={{ width: '30%' }} className="img-box" alt="" />
                </div>
            ) : showList.length === 5 ? (
                <div className="r-center w100-img">
                    <img src={showList[0] || UserDefaultImg} style={{ width: '23%' }} className="img-box" alt="" />
                    <img
                        src={showList[1] || UserDefaultImg}
                        style={{ width: '47%', margin: '0 1px' }}
                        className="img-box"
                        alt=""
                    />
                    <div style={{ width: '30%' }}>
                        <div className="r-left" style={{ marginBottom: 1, height: 45 }}>
                            <img
                                src={showList[2] || UserDefaultImg}
                                style={{ width: '50%' }}
                                className="img-box"
                                alt=""
                            />
                            <img
                                src={showList[3] || UserDefaultImg}
                                style={{ marginLeft: 1, width: '50%' }}
                                className="img-box"
                                alt=""
                            />
                        </div>
                        <img src={showList[4] || UserDefaultImg} className="img-box" style={{ height: 95 }} alt="" />
                    </div>
                </div>
            ) : (
                <div className="r-center w100-img">
                    <div style={{ width: '23%', height: '100%' }}>
                        <img src={showList[0] || UserDefaultImg} className="img-box h50-img" alt="" />
                        <img
                            src={showList[1] || UserDefaultImg}
                            style={{ marginTop: 1 }}
                            className="img-box h50-img"
                            alt=""
                        />
                    </div>
                    <img
                        src={showList[2] || UserDefaultImg}
                        style={{ width: '47%', margin: '0 1px' }}
                        className="img-box"
                        alt=""
                    />
                    <div style={{ width: '30%' }}>
                        <div className="r-left" style={{ marginBottom: 1, height: 45 }}>
                            <img
                                src={showList[3] || UserDefaultImg}
                                style={{ width: '50%' }}
                                className="img-box"
                                alt=""
                            />
                            <img
                                src={showList[4] || UserDefaultImg}
                                style={{ marginLeft: 1, width: '50%' }}
                                className="img-box"
                                alt=""
                            />
                        </div>
                        <img src={showList[5] || UserDefaultImg} className="img-box" style={{ height: 95 }} alt="" />
                    </div>
                </div>
            )}
        </div>
    );
}

export default PoolCardImage;
