import React, { useEffect, useState } from 'react';
import './index.scss';
import { Button, Form, Input, Select } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import api from '@/api/talentPool';
import AlertModal from '@/components/modal/alert-modal';
function TalentPoolInfo() {
    const navigate = useNavigate();
    const location = useLocation(); // 获取 location 对象
    const queryParams = new URLSearchParams(location.search);
    const pageType = queryParams.get('type');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [delIndex, setDelIndex] = useState(-1);
    const [form] = Form.useForm();
    const [state, setState] = useState({
        poolName: '',
        role: '',
        id: '',
    });
    const handleValidate = () => {
        form.validateFields()
            .then(values => {
                setLoading(true);
                const pat: any = {
                    poolName: state.poolName,
                    groupVOList: jobRoleList.length
                        ? jobRoleList.map(item => {
                              return { jobTitle: item.jobTitle, groupId: item.groupId ? item.groupId : '' };
                          })
                        : [],
                };
                if (pageType === 'edit') {
                    pat.poolId = state.id;
                    api.editTalentPool(pat, res => {
                        setLoading(false);
                        const { code, data } = res;
                        if (code === 200) {
                            navigate(-1);
                        } else {
                            window.utils.alert('error', res.message);
                        }
                    });
                    return;
                }
                api.addTalentPool(pat, res => {
                    setLoading(false);
                    const { code, data } = res;
                    if (code === 200) {
                        navigate(-1);
                    } else {
                        window.utils.alert('error', res.message);
                    }
                });
            })
            .catch(errorInfo => {
                console.log('Validation Failed:', errorInfo);
            });
    };
    const [roleList, setRoleList] = useState<any>([]);
    const [jobRoleList, setJobRoleList] = useState<any>([]);
    const getOplist = () => {
        api.getMyJobList({}, res => {
            const { code, data } = res;
            if (code === 200) {
                setRoleList(
                    data.map(item => {
                        return Object.assign(item, {
                            value: item.jobTitle,
                            label: item.jobTitle,
                        });
                    })
                );
            } else {
                window.utils.alert('error', res.message);
            }
        });
    };
    useEffect(() => {
        getOplist();
        if (pageType === 'edit') {
            const info = JSON.parse(window.sessionStorage.getItem('poolInfo') ?? "{poolId:''}");
            setState({ id: info.poolId, poolName: info.poolName ?? '', role: '' });
            form.setFieldsValue({ poolName: info.poolName ?? '', jobTitle: '' });
            setJobRoleList(
                info.groupPOJOList
                    ? info.groupPOJOList.map(item => {
                          return {
                              jobTitle: item.jobTitle,
                              candidates: item.candidates,
                              groupId: item.groupId,
                          };
                      })
                    : []
            );
        }
    }, []);
    return (
        <div className="talentPool-infoBox">
            <div>
                <div className="talentPool-card">
                    <div className="poolInfo-tit">{pageType === 'edit' ? 'Edit' : 'Create New'} Talent Pool</div>
                    <div>
                        <Form
                            name="pool-form"
                            layout="vertical"
                            initialValues={{ remember: true }}
                            form={form}
                            autoComplete="off"
                        >
                            <p className="job-formTit">Talent Pool</p>
                            <Form.Item
                                className="flex1"
                                label="Talent Pool Name"
                                name="poolName"
                                rules={[{ required: true }]}
                            >
                                <Input
                                    className="r-input"
                                    maxLength={50}
                                    value={state.poolName}
                                    onChange={(e: any) => {
                                        setState({ ...state, poolName: e.target.value });
                                    }}
                                    placeholder=""
                                />
                            </Form.Item>
                            <div className="line-boxNew" style={{ marginTop: 32 }}></div>
                            <p className="job-formTit">Job Group</p>
                            <div className="poolJOb-selBox">
                                <Form.Item
                                    className="flex1"
                                    label="Job Role"
                                    name="jobTitle"
                                    rules={[{ required: false, message: '' }]}
                                >
                                    <Select
                                        className="r-select r-sel-white"
                                        value={state.role}
                                        onChange={val => {
                                            setState({ ...state, role: val });
                                        }}
                                        suffixIcon={<i className="down-right-icon"></i>}
                                        options={roleList}
                                    />
                                </Form.Item>
                                <div className="r-end">
                                    <Button
                                        className="r-primary_btn r-primaryNoBg_btn"
                                        style={{ width: 78 }}
                                        disabled={!state.role}
                                        onClick={() => {
                                            const info = roleList.find(v => v.jobTitle === state.role);
                                            const index = jobRoleList.findIndex(v => v.jobTitle === state.role);
                                            if (info) {
                                                if (index === -1) {
                                                    jobRoleList.push(info);
                                                    setJobRoleList([...jobRoleList]);
                                                }
                                                form.setFieldsValue({ jobTitle: '' });
                                                setState({ ...state, role: '' });
                                            }
                                        }}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </Form>
                        <div style={{ marginTop: 20 }}>
                            {jobRoleList.length
                                ? jobRoleList.map((val, i) => {
                                      return (
                                          <div
                                              key={`candidate-cardBoxLeftSel-jobrole-${i}`}
                                              className="candidate-cardBox-pool r-left"
                                          >
                                              <div>
                                                  <p className="candidatename-box">{val.jobTitle ?? '-'}</p>
                                                  <p className="candidate-minitip">
                                                      {val.candidates ? val.candidates : 0}{' '}
                                                      {val.candidates > 1 ? 'Candidates' : 'Candidate'}{' '}
                                                  </p>
                                              </div>
                                              <i
                                                  className="candidate-delIcon marLeft"
                                                  onClick={() => {
                                                      setDelIndex(i);
                                                      setIsModalOpen(true);
                                                  }}
                                              ></i>
                                          </div>
                                      );
                                  })
                                : null}
                        </div>
                    </div>
                </div>

                <div className="r-end" style={{ marginTop: 40 }}>
                    <Button
                        className="r-primary_btn r-dangerNoBg_btn r-primary_largeBtn w208"
                        onClick={() => navigate(-1)}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="r-primary_btn r-primary_largeBtn w208 marL24"
                        disabled={!state.poolName}
                        onClick={handleValidate}
                        loading={loading}
                    >
                        Save
                    </Button>
                </div>
            </div>

            <AlertModal
                title=""
                message="Are you sure you want to remove the talent pool for this job?"
                cancelText="Cancel"
                confirmText="Confirm"
                isModalOpen={isModalOpen}
                handleOk={() => {
                    jobRoleList.splice(delIndex, 1);
                    setJobRoleList([...jobRoleList]);
                    setIsModalOpen(false);
                    setDelIndex(-1);
                }}
                handleCancel={() => {
                    setIsModalOpen(false);
                    setDelIndex(-1);
                }}
            />
        </div>
    );
}

export default TalentPoolInfo;
