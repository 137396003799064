import { defaultInfo } from '@/typings/allType';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface stateObj {
    currentUserId: string;
    deviceType: string;
    userInfo: defaultInfo;
    profileInfo: defaultInfo;
    mainLoading: boolean;
    userStatus: number;
    chartInfo: defaultInfo;
    unReadCount: number;
}
const initialState: defaultInfo = {
    currentUserId: '',
    deviceType: '',
    profileInfo: {
        businessInfoPOJO: {
            subscriptionExpireFlag: 0,
            subscriptionCancelFlag: 0,
        },
    },
    mainLoading: false,
    userStatus: 1,
    userStatusTip: '',
    chartInfo: {
        adminId: '',
        sendbirdId: '',
        sendbirdAccessToken: '',
    },
    unReadCount: 0,
    nowChartUser: {},
    dataBaseInfo: {},
};

export const stateSlice = createSlice({
    name: 'state',
    initialState,
    reducers: {
        setUnReadCount: (state, action: PayloadAction<number>) => {
            state.unReadCount = action.payload;
        },
        setChartInfo: (state, action: PayloadAction<defaultInfo>) => {
            state.chartInfo = action.payload;
        },
        setNowChartUser: (state, action: PayloadAction<defaultInfo>) => {
            state.nowChartUser = action.payload;
        },
        setCurrentUserId: (state, action: PayloadAction<string>) => {
            state.currentUserId = action.payload;
        },
        setDeviceType: (state, action: PayloadAction<string>) => {
            state.deviceType = action.payload;
        },
        setMainLoading: (state, action: PayloadAction<boolean>) => {
            state.mainLoading = action.payload;
        },
        setProfileInfo: (state, action: PayloadAction<defaultInfo>) => {
            state.profileInfo = action.payload;
        },
        setUserStatus: (state, action: PayloadAction<number>) => {
            state.userStatus = action.payload;
        },
        setUserStatusTip: (state, action: PayloadAction<string>) => {
            state.userStatusTip = action.payload;
        },
        setDataBaseInfo: (state, action: PayloadAction<number>) => {
            state.dataBaseInfo = action.payload;
        },
        clearStore: state => {
            state.currentUserId = '';
            state.profileInfo = {
                businessInfoPOJO: {
                    subscriptionExpireFlag: 0,
                    subscriptionCancelFlag: 0,
                },
            };
            state.mainLoading = false;
            state.userStatus = 1;
            state.userStatusTip = '';
            state.chartInfo = {
                adminId: '',
                sendbirdId: '',
                sendbirdAccessToken: '',
            };
            state.unReadCount = 0;
            state.nowChartUser = {};
            state.dataBaseInfo = {};
        },
    },
});

export const {
    setDeviceType,
    setProfileInfo,
    setMainLoading,
    setUserStatus,
    setUserStatusTip,
    setChartInfo,
    setUnReadCount,
    setNowChartUser,
    setDataBaseInfo,
    clearStore,
} = stateSlice.actions;

export default stateSlice.reducer;
