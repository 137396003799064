import axios from '../utils/index';
import { Pat, HttpResponse } from '@/typings/request';
const subscriptionApi = {
    getSubscriptionAnnualPlan(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/v1/business/subscriptionPlan-annual-list`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getSubscriptionMonthPlan(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/v1/business/subscriptionPlan-list`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    createSubscriptionPlan(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/subscription-create-session`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    checkPayStatus(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/subscriptionPlan-paySuccess-check/${params.orderId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    cancelSubscription(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/business/subscription-cancel`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    // subscription-Upgrade-upcoming
    upgradeSubscription(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/subscription-Upgrade-upcoming`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default subscriptionApi;
