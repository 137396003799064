import React, { useEffect, useState } from 'react';
import './index.scss';
import { Button, Spin } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import SliderTabs from '@/components/slideeTabs';
import SubscriptionCard from '@/components/subscription-card';
import AlertModal from '@/components/modal/alert-modal';
import SubscriptionDetailModal from '@/components/modal/subscription-detail-modal';
import api from '@/api/subscription';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import { loadStripe } from '@stripe/stripe-js';
import config from '@/config/config';
import useLoading from '@/hook/useLoading';
const stripePromise = loadStripe(
    config.stripeKey ||
        'pk_test_51PoQxiEqaprY8t0qMmFHFd2rTVCINlyfvqCGvSrzPcc8hWa5jWOx7Y2AAJbbv3RBOAOeBOJLqVeLArsMmnCsjJoG001KuJ26ku'
);
function SubscriptionPlan() {
    const navigate = useNavigate();
    const messageApi = useGlobalMessage();
    const { setLoadingStatus } = useLoading();
    const [loading, setLoading] = useState(false);
    const [planloading, setPlanLoading] = useState(false);
    const [activePlan, setActivePlan] = useState<any>({});
    const [selectedType, setSelectedType] = useState(1);
    const [userTeamNum, setUserTeamNum] = useState('');
    const [defaultMonthPlan, setDefaultMonthPlan] = useState(0);
    const [defaultYearPlan, setDefaultYearPlan] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isShowDetail, setIsShowDetail] = useState(false);
    const changeTab = val => {
        setSelectedType(val);
        choosePlan(val === 1 ? defaultMonthPlan : defaultYearPlan, val);
    };
    const handleOk = () => {
        setIsModalOpen(false);
        choosePlan(selectedType === 1 ? defaultMonthPlan : defaultYearPlan, selectedType);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        navigate('/team-management');
    };
    const subscription = () => {
        console.log(activePlan);
        if (activePlan.seatType === 'bySeats') {
            setIsShowDetail(true);
            return;
        }
        setLoading(true);
        const pat = {
            cancel_url: `${config.localUrl}/subscription-plan`,
            planId: activePlan.planId,
            priceCurrency: activePlan.priceCurrency,
            success_url: `${config.localUrl}/subscription-plan?status=success`,
        };
        api.createSubscriptionPlan(pat, res => {
            if (res.code === 200) {
                console.log(res.data);
                window.localStorage.setItem('orderId', res.data.orderId);
                selectPlan(res.data.sessionId);
            } else if (res.code === 50009) {
                setLoading(false);
                setIsModalOpen(true);
            } else {
                setLoading(false);
                messageApi.error(res.message);
            }
        });
    };
    // 按数量订阅
    const subConfirm = () => {
        setLoading(true);
        const pat = {
            cancel_url: `${config.localUrl}/subscription-plan`,
            planId: activePlan.planId,
            quantity: userTeamNum,
            priceCurrency: activePlan.priceCurrency,
            success_url: `${config.localUrl}/subscription-plan?status=success`,
        };
        api.createSubscriptionPlan(pat, res => {
            if (res.code === 200) {
                console.log(res.data);
                window.localStorage.setItem('orderId', res.data.orderId);
                selectPlan(res.data.sessionId);
            } else if (res.code === 50009) {
                setLoading(false);
                setIsModalOpen(true);
            } else {
                setLoading(false);
                messageApi.error(res.message);
            }
        });
    };
    const [subscriptionPlanList, setSubscriptionPlanList] = useState<any>([]);
    const [subscriptionYearPlanList, setSubscriptionYearPlanList] = useState<any>([]);

    const selectPlan = async (CHECKOUT_SESSION_ID: string) => {
        const stripe = await stripePromise;
        const { error } = await stripe!.redirectToCheckout({
            sessionId: CHECKOUT_SESSION_ID,
        });
        setLoading(false);
        // 如果redirectToCheckout失败，显示错误信息
        if (error) {
            console.error('Redirect to Checkout error:', error.message);
        }
    };
    // 选择套餐
    const choosePlan = (i: number, val: number) => {
        if (val === 1) {
            subscriptionPlanList.forEach((item, k) => {
                item.recommendFlag = k == i ? true : false;
            });
            setSubscriptionPlanList([...subscriptionPlanList]);
            setActivePlan(subscriptionPlanList[i]);
            return;
        }
        subscriptionYearPlanList.forEach((item, k) => {
            item.recommendFlag = k == i ? true : false;
        });
        setSubscriptionYearPlanList([...subscriptionYearPlanList]);
        setActivePlan(subscriptionYearPlanList[i]);
    };
    const getAnnualList = () => {
        api.getSubscriptionAnnualPlan({}, res => {
            if (res.code === 200) {
                const index = res.data.findIndex(v => v.recommendFlag);
                setDefaultYearPlan(index);
                setSubscriptionYearPlanList(res.data);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const getMonthlList = () => {
        setPlanLoading(true);
        api.getSubscriptionMonthPlan({}, res => {
            setPlanLoading(false);
            if (res.code === 200) {
                setSubscriptionPlanList(res.data);
                const index = res.data.findIndex(v => v.recommendFlag);
                setDefaultMonthPlan(index !== -1 ? index : 0);
                const planDefault = res.data.find(v => v.recommendFlag);
                setActivePlan(planDefault !== undefined ? planDefault : res.data[0]);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const checkPay = () => {
        window.utils.updateUrlParams('status', null);
        // setLoading(true);
        api.checkPayStatus({ orderId: window.localStorage.getItem('orderId') }, res => {
            setLoadingStatus(false);
            if (res.code === 200) {
                if (res.data) {
                    messageApi.success('Subscription successful! You have received a 25-day trial period.');
                    navigate('/home');
                    return;
                }
                messageApi.error('pay failed');
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const location = useLocation();
    function getQueryParam(param: string, location): string | null {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get(param);
    }
    useEffect(() => {
        getAnnualList();
        getMonthlList();
        const status = getQueryParam('status', location);
        if (status === 'success') {
            setLoadingStatus(true);
            setTimeout(() => {
                checkPay();
            }, 5000);
        }
    }, []);
    return (
        <div className="information-main">
            <div className="top-nav">
                <img src={require('@/assets/img/Logo.png')} className="logo-img" alt="" />
            </div>
            <div className="subscriptionContent-box">
                <h2>Subscription Plan</h2>
                {planloading ? (
                    <div className="r-center no-message-tabBox">
                        <Spin></Spin>
                    </div>
                ) : (
                    <>
                        <div style={{ margin: '30px 0' }}>
                            <SliderTabs
                                setSelectedType={val => changeTab(val)}
                                selectedType={selectedType}
                                tabList={[
                                    { label: 'Monthly Plan', key: 1 },
                                    { label: 'Annual Plan', key: 2 },
                                ]}
                            />
                        </div>
                        <div>
                            <SubscriptionCard
                                choosePlan={(i: number) => choosePlan(i, selectedType)}
                                selectedType={selectedType}
                                subscriptionPlanList={
                                    selectedType === 1 ? subscriptionPlanList : subscriptionYearPlanList
                                }
                            />
                        </div>
                        <div className="r-center">
                            <Button
                                className="r-primary_btn r-primary_largeBtn"
                                style={{ width: 208 }}
                                onClick={() => subscription()}
                                loading={loading}
                                disabled={loading}
                            >
                                Subscribe
                            </Button>
                        </div>
                    </>
                )}
            </div>
            <AlertModal
                message="You have more team members than your planned number of seats. 
                Need to recommend a new plan for you?"
                cancelText="Modify Team"
                confirmText="Recommended"
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
            {activePlan ? (
                <SubscriptionDetailModal
                    subType={1}
                    setUserTeamNum={val => {
                        setUserTeamNum(val);
                    }}
                    planInfo={activePlan}
                    isModalOpen={isShowDetail}
                    handleOk={() => {
                        setIsShowDetail(false);
                        subConfirm();
                    }}
                    handleCancel={() => setIsShowDetail(false)}
                />
            ) : null}
        </div>
    );
}

export default SubscriptionPlan;
