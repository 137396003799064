import { Modal } from 'antd';
import './index.scss';
function FormModal({
    isModalOpen,
    handleOk,
    handleCancel,
    children,
    width,
    title,
    align,
    className,
    top,
    marBtm,
}: any) {
    return (
        <Modal
            className={`r-modal modal_no_padding team-modalBox ${className} ${top ? 'top28' : ''}`}
            maskClosable={false}
            centered={true}
            key={'team-member-modal'}
            closeIcon={<i className="r-modal-close"></i>}
            footer={''}
            width={width ? width : 380}
            title={
                <div
                    className="modal-top-title"
                    style={{ marginBottom: marBtm ? marBtm : 30, textAlign: align ? align : 'center' }}
                >
                    {title}
                </div>
            }
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            maskProps={{
                style: {
                    backgroundColor: 'rgba(30, 34, 45, 0.8)',
                    backdropFilter: 'blur(1px)',
                },
            }}
        >
            {children}
        </Modal>
    );
}

export default FormModal;
