/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import './index.scss';
import { Button, Input, Popover, Image, Spin } from 'antd';
import { debounce } from 'lodash';
import Notifications from '@/assets/img/Notifications.png';
import CaretDownIcon from '@/assets/img/CaretDown.png';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AlertModal from '../modal/alert-modal';
import api from '@/api/login';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import { clearStore } from '@/store/stateSlice';
import { useFeedback } from '@/context/notificationContext';
import { useAppDispatch } from '@/store/hooks';
function ContentTopBar({ searchPlaceholder }: { searchPlaceholder?: string }) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const messageApi = useGlobalMessage();
    const { feedbackCount } = useFeedback();
    const { profileInfo } = useSelector((state: any) => state.state);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [state, setState] = useState({
        search: '',
        pageNo: 1,
    });
    const searchList: any = useCallback(
        debounce(params => {
            getSearchResult({ ...params });
        }, 1000),
        []
    );
    const highlightText = (text, highlight) => {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return parts.map((part, index) =>
            part.toLowerCase() === highlight.toLowerCase() ? (
                <span key={index} className="highlight-box">
                    {part}
                </span>
            ) : (
                part
            )
        );
    };
    const [searchLoading, setSearchLoading] = useState(false);
    const [resultList, setResultList] = useState<any>([]);
    const handleKeyDown = (event): void => {
        if (event.key === 'Enter' && resultList.length) {
            window.sessionStorage.setItem('searchReault', JSON.stringify({ search: state.search, list: resultList }));
            navigate(`/search-result`);
            setResultList([]);
            setState({ ...state, search: '' });
            setIsSearch(false);
        }
    };
    const toAllView = (val, type) => {
        let linkType: any;
        if (resultList[type].type === 'Candidates') {
            linkType = 1;
        } else if (resultList[type].type === 'Jobs') {
            linkType = 2;
        } else if (resultList[type].type === 'Team Members') {
            linkType = 3;
        } else {
            linkType = 4;
        }
        // type 0 = Candidates 1 Jobs 2 Team Members 3 Talent Database
        window.sessionStorage.setItem('searchReault', JSON.stringify({ search: state.search, list: resultList }));
        // navigate(`/search-result`);
        navigate(`/search-result-all/${linkType}`);
        setResultList([]);
        setState({ ...state, search: '' });
        setIsSearch(false);
    };
    const [isEmpty, setIsEmpty] = useState(false);
    useEffect(() => {
        const index = resultList.findIndex(v => v.list.length);
        setIsEmpty(index === -1 ? true : false);
    }, [resultList]);
    const getSearchResult = val => {
        setResultList([]);
        setSearchLoading(true);
        api.getSearchList({ ...val }, res => {
            setSearchLoading(false);
            const { code, data, message } = res;
            if (code === 200) {
                if (!data) {
                    setIsEmpty(true);
                    return;
                }
                setResultList([
                    {
                        type: 'Candidates',
                        list:
                            data.candidatesPOJOList && data.candidatesPOJOList.length
                                ? data.candidatesPOJOList.map(item => {
                                      let time = '';
                                      if (item.accountPOJO.accountExperiencePOJO) {
                                          const year = item.accountPOJO.accountExperiencePOJO.yearsOfExperience;
                                          const month = item.accountPOJO.accountExperiencePOJO.monthOfExperience;
                                          time = `${year ? `${year}${year > 1 ? 'Years' : 'Year'}` : ''}${
                                              month ? ` ${month}${month > 1 ? ' Months' : ' Month'}` : ''
                                          }`;
                                      }
                                      return Object.assign(item, {
                                          namelabel: `${item.accountPOJO.accountInformationPOJO.firstName} ${item.accountPOJO.accountInformationPOJO.middleName} ${item.accountPOJO.accountInformationPOJO.lastName}`,
                                          pic: item.accountPOJO.accountInformationPOJO.profilePhoto
                                              ? item.accountPOJO.accountInformationPOJO.profilePhoto
                                              : '',
                                          time,
                                          skillShow:
                                              item.accountPOJO.accountSkillPOJOList &&
                                              item.accountPOJO.accountSkillPOJOList.length < 3
                                                  ? item.accountPOJO.accountSkillPOJOList
                                                        .map(v => v.skillName)
                                                        .join(', ')
                                                  : item.accountPOJO.accountSkillPOJOList[0].skillName,
                                      });
                                  })
                                : [],
                    },
                    {
                        type: 'Jobs',
                        list:
                            data.jobsInfoPOJOList && data.jobsInfoPOJOList.length
                                ? data.jobsInfoPOJOList.map(item => {
                                      let applicationsPOJOListList: any = [];
                                      if (item.applicationsPOJOList && item.applicationsPOJOList.length) {
                                          applicationsPOJOListList =
                                              item.applicationsPOJOList.length > 11
                                                  ? item.applicationsPOJOList.slice(-11)
                                                  : item.applicationsPOJOList;
                                      }
                                      return Object.assign(item, {
                                          namelabel: `${item.jobsPOJO.jobTitle}`,
                                          pic: item.employerLogo ? item.employerLogo : '',
                                          jobTypeShow:
                                              item.jobsPOJO.jobType === '1'
                                                  ? 'Full-Time'
                                                  : item.jobsPOJO.jobType === '2'
                                                  ? 'Part-Time'
                                                  : item.jobsPOJO.jobType === '3'
                                                  ? 'Contractor'
                                                  : 'Full-Time',
                                          deadlineTimeShow: item.jobsPOJO.deadline
                                              ? window.utils.formatTimeDifference(item.jobsPOJO.deadline)
                                              : '-',
                                          businessNameLabel: `${item.jobsPOJO.businessAccountPOJO.firstName} ${item.jobsPOJO.businessAccountPOJO.middleName} ${item.jobsPOJO.businessAccountPOJO.lastName}`,
                                          applicationsPOJOList: applicationsPOJOListList,
                                      });
                                  })
                                : [],
                    },
                    {
                        type: 'Team Members',
                        list:
                            data.businessAccountPOJOList && data.businessAccountPOJOList.length
                                ? data.businessAccountPOJOList.map(item => {
                                      return Object.assign(item, {
                                          namelabel: `${item.firstName} ${item.middleName} ${item.lastName}`,
                                          pic: item.profilePhoto ? item.profilePhoto : '',
                                      });
                                  })
                                : [],
                    },
                    {
                        type: 'Talent Database',
                        list:
                            data.vtalentDatabaseAccountPOJOList && data.vtalentDatabaseAccountPOJOList.length
                                ? data.vtalentDatabaseAccountPOJOList.map(item => {
                                      return Object.assign(item, {
                                          namelabel: `${item.firstName} ${item.middleName} ${item.lastName}`,
                                          pic: item.profilePhoto ? item.profilePhoto : '',
                                          skillShow: !item.accountSkillPOJOList
                                              ? '-'
                                              : item.accountSkillPOJOList && item.accountSkillPOJOList.length < 3
                                              ? item.accountSkillPOJOList.map(v => v.skillName).join(', ')
                                              : item.accountSkillPOJOList[0].skillName,
                                      });
                                  })
                                : [],
                    },
                ]);
            } else {
                messageApi.error(message);
            }
        });
    };
    const logout = () => {
        setLoading(true);
        api.logOut(res => {
            setLoading(false);
            if (res.code === 200) {
                setIsModalOpen(false);
                window.sessionStorage.clear();
                navigate('/login');
                dispatch(clearStore());
            } else {
                messageApi.error(res.message);
            }
        });
    };
    return (
        <div className="content-topBar-info">
            <div className="r-left">
                <Input
                    disabled={profileInfo.businessInfoPOJO.subscriptionExpireFlag}
                    placeholder={searchPlaceholder ? searchPlaceholder : 'Search'}
                    style={{ width: 347 }}
                    readOnly
                    value={''}
                    onFocus={() => setIsSearch(true)}
                    className="r-input r-input_serach"
                    prefix={<span className="search_icon"></span>}
                />
                <Button
                    className="r-primary_btn create-job-btn"
                    disabled={profileInfo.businessInfoPOJO.subscriptionExpireFlag}
                    onClick={() => {
                        window.sessionStorage.setItem('isTopAdd', '1');
                        window.sessionStorage.removeItem('jobInfoForm');
                        navigate('/job/job-info');
                    }}
                >
                    Create new job
                </Button>
                <div
                    className="Notifications-box"
                    onClick={() => {
                        navigate('/notification');
                    }}
                >
                    <img src={Notifications} className="Notifications-icon" alt="" />
                    {feedbackCount ? (
                        <span className="notification-num">{feedbackCount > 99 ? '99+' : feedbackCount}</span>
                    ) : null}
                </div>
                <Popover
                    placement="bottomRight"
                    title={''}
                    trigger="hover"
                    content={
                        <div className="tableOpeation-box login-box">
                            <div className={`tb_item`} style={{ width: 130 }} onClick={() => setIsModalOpen(true)}>
                                Logout
                            </div>
                        </div>
                    }
                    arrow={false}
                >
                    <div className="r-left logout-popTip">
                        {/* <div className="ad-name">TJ</div> */}
                        {profileInfo.avatarUrl ? (
                            <Image
                                width={40}
                                height={40}
                                preview={false}
                                // className="ad-name"
                                style={{ borderRadius: '50%', minWidth: 40 }}
                                src={profileInfo.avatarUrl}
                                fallback={require('@/assets/img/default-min.png')}
                            />
                        ) : (
                            <div className="ad-name">{profileInfo.showName}</div>
                        )}
                        <img src={CaretDownIcon} className="CaretDown-icon" alt="" />
                    </div>
                </Popover>
            </div>
            {/* search  */}
            <div>
                <div className={`search-resultBox ${isSearch ? 'show' : ''}`}>
                    <div>
                        <Input
                            placeholder={searchPlaceholder ? searchPlaceholder : 'Search'}
                            style={{ width: '100%', height: 48 }}
                            value={state.search}
                            autoFocus
                            onKeyDown={handleKeyDown}
                            onChange={e => {
                                searchList({ ...state, keyWord: e.target.value, pageNo: 1 });
                                setState({ ...state, search: e.target.value, pageNo: 1 });
                            }}
                            className="r-modal_search"
                            suffix={
                                <i
                                    className="close-icon_modal"
                                    onClick={() => {
                                        setSearchLoading(false);
                                        setResultList([]);
                                        setState({ ...state, search: '' });
                                        setIsSearch(false);
                                    }}
                                ></i>
                            }
                        />
                        <div className="r-left result-list">
                            {searchLoading ? (
                                <div className="r-center" style={{ textAlign: 'center', marginTop: 70, width: '100%' }}>
                                    <Spin style={{ position: 'static' }}></Spin>
                                </div>
                            ) : resultList.length && !isEmpty ? (
                                resultList.map((item, i) => {
                                    return (
                                        <>
                                            {!item.list.length ? null : (
                                                <div key={`result-item-${i}`} className="result-itemBox">
                                                    <div className="result-type">{item.type}</div>
                                                    {item.list.map((val, k) => {
                                                        return (
                                                            <div
                                                                className="r-left marb8"
                                                                style={{ display: k > 3 ? 'none' : '' }}
                                                                key={`result-label${i}-${k}`}
                                                            >
                                                                <Image
                                                                    width={24}
                                                                    height={24}
                                                                    preview={false}
                                                                    style={{ borderRadius: '50%', minWidth: 24 }}
                                                                    src={val.pic}
                                                                    fallback={require('@/assets/img/user-default.png')}
                                                                />
                                                                <span className="result-label oneLineText">
                                                                    {highlightText(val.namelabel, state.search)}
                                                                </span>
                                                            </div>
                                                        );
                                                    })}
                                                    {item.list.length > 4 ? (
                                                        <div
                                                            className="viewMore-box"
                                                            onClick={() => toAllView(item, i)}
                                                        >
                                                            View More
                                                        </div>
                                                    ) : null}
                                                </div>
                                            )}
                                        </>
                                    );
                                })
                            ) : (
                                <div className="r-center" style={{ textAlign: 'center', marginTop: 70, width: '100%' }}>
                                    {state.search
                                        ? 'Sorry, we were unable to find any results based on your conditions.'
                                        : 'No data'}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={`desk-box ${isSearch ? 'show' : ''}`}></div>
            </div>

            <AlertModal
                title=""
                message="Are you sure you want to log out of the current account?"
                cancelText="Cancel"
                confirmText="Confirm Logout"
                isModalOpen={isModalOpen}
                loading={loading}
                handleOk={() => logout()}
                handleCancel={() => setIsModalOpen(false)}
            />
        </div>
    );
}

export default ContentTopBar;
