import dayjs from 'dayjs';
/* eslint-disable */
import { message } from 'antd';

export default {
    alert(type: any, msg: string, code?: number) {
        if (!msg || code === 30002) return;
        message[type](msg);
    },
    isNumber(value: any): boolean {
        const reg = /^[0-9]*$/;
        return reg.test(value);
    },
    removeFormatMoney(s: string) {
        return parseFloat(s.replace(/[^\d\.-]/g, ''));
    },
    formatMoney(s: string, type?: number) {
        if (/[^0-9\.]/.test(s)) return '0.00';
        if (s == null || s == 'null' || s == '') return '0.00';
        s = s.toString().replace(/^(\d*)$/, '$1.');
        s = (s + '00').replace(/(\d*\.\d\d)\d*/, '$1');
        s = s.replace('.', ',');
        var re = /(\d)(\d{3},)/;
        while (re.test(s)) s = s.replace(re, '$1,$2');
        s = s.replace(/,(\d\d)$/, '.$1');
        if (type == 0) {
            var a = s.split('.');
            if (a[1] == '00') {
                s = a[0];
            }
        }
        return s;
    },
    isEmail(str: string) {
        const emailRegex = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(str);
    },
    isWebSite(str: string) {
        const reg =
            /^\b(((https?|ftp):\/\/)?[-a-z0-9]+(\.[-a-z0-9]+)*\.(?:com|co|io|edu|gov|int|mil|net|org|biz|info|name|museum|asia|coop|aero|[a-z][a-z]|((25[0-5])|(2[0-4]\d)|(1\d\d)|([1-9]\d)|\d))\b(\/[-a-z0-9_:\@&?=+,#.!\/~%\$]*)?)$/i;
        return reg.test(str);
    },
    isPassword(str: string) {
        const len: number = str ? str.split('').length : 0;
        return len >= 8 && len <= 16;
    },
    isStartsWith04(number) {
        const strNumber = number.toString();
        return strNumber.startsWith('0');
    },
    isBlank(str: string): boolean {
        return str == null || false || str === '' || str.trim() === '' || str.toLocaleLowerCase().trim() === 'null';
    },
    getPageQuery(url: string) {
        const regex = /from=([^&#]+)/;
        const match = url.match(regex);
        if (match && match[1]) {
            const fromValue = match[1];
            return decodeURIComponent(fromValue);
        }
        return '';
    },
    debounce(fnName: any, time: number) {
        let timeout: any = null;
        return function () {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                fnName();
            }, time);
        };
    },
    timeFormatYear(val: any) {
        let date: any = new Date(val * 1000);
        if (!date) {
            date = Date.now();
        }
        const time = date.toLocaleDateString().replace(/\//g, '/');
        return time;
    },
    formatNumber(s: string | null): string | undefined {
        if (!s) {
            return '';
        }
        if (s.length == 11) {
            return s.slice(0, 3) + ' ' + s.slice(3, 7) + ' ' + s.slice(7, 11);
        } else if (s.length == 8) {
            return s.slice(0, 4) + ' ' + s.slice(4, 8);
        } else if (s.length == 9) {
            return s.slice(0, 3) + ' ' + s.slice(3, 6) + ' ' + s.slice(6, 9);
        } else if (s.length == 10) {
            return s.slice(0, 4) + ' ' + s.slice(4, 7) + ' ' + s.slice(7, 10);
        } else {
            return s;
        }
    },
    getCurremtYear(): number {
        const year = dayjs().year();
        return year;
    },
    foramtTimeDMY(date: any) {
        date = date ? Number(date) : new Date();
        return dayjs(date).format('DD/MM/YYYY');
    },
    foramtTimeDMYhm(date: any) {
        date = date ? Number(date) : new Date();
        return dayjs(date).format('DD MMM YYYY hh:mm a');
    },
    formatTimeDifference(timestamp: number) {
        const now = dayjs();
        const targetDate = dayjs(timestamp * 1000);
        const daysDiff = targetDate.diff(now, 'day');
        const formattedDate = targetDate.format('D MMM');
        if (daysDiff <= -1) {
            return `- ${Math.abs(daysDiff)} ${Math.abs(daysDiff) > 1 ? 'days' : 'day'} left (${formattedDate})`;
        }
        return `${daysDiff} ${daysDiff > 1 ? 'days' : 'day'} left (${formattedDate})`;
    },
    isExpired(dateNum: number) {
        const nowDate = Date.now();
        return nowDate - dateNum > 0;
    },
    validateInput(input) {
        if (!input.toString()) {
            return '';
        }
        input = input
            .replace(/[^\d.]/g, '')
            .replace(/^\./g, '')
            .replace(/\.{2,}/g, '')
            .replace('.', '$#$')
            .replace(/\./g, '')
            .replace('$#$', '.')
            .replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
        return input;
    },
    getInitials(str1, str2) {
        const getInitial = str => str.charAt(0).toUpperCase();
        return `${getInitial(str1)}${getInitial(str2)}`;
    },
    filterArrayByString(array, searchString) {
        const regex = new RegExp(searchString, 'i'); // 'i' flag for case-insensitive matching
        return array.filter(item => regex.test(item.label));
    },
    paginateArray(array, page, size) {
        const startIndex = (page - 1) * size;
        const endIndex = startIndex + size;
        const paginatedData = array.slice(startIndex, endIndex);
        const maxPage = Math.ceil(array.length / size);

        return { paginatedData, maxPage };
    },
    formatAmount(amount) {
        return amount
            .toFixed(2) // 保留两位小数
            .replace(/\d(?=(\d{3})+\.)/g, '$&,'); // 使用正则添加千位分隔符
    },
    updateUrlParams(key, value) {
        const url = new URL(window.location.href);
        if (value === null || value === undefined) {
            url.searchParams.delete(key);
        } else {
            url.searchParams.set(key, value);
        }
        window.history.replaceState(null, '', url.toString());
    },
};
