import React, { useState } from 'react';
import { Button, Form, FormProps, Input, Modal } from 'antd';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import JSEncrypt from 'jsencrypt';
import { useNavigate } from 'react-router-dom';
import api from '@/api/login';
import { useSelector } from 'react-redux';

function EditPassword({ isModalOpen, handleOk, handleCancel }) {
    const { profileInfo } = useSelector((state: any) => state.state);
    const messageApi = useGlobalMessage();
    const passwordEncrypt = new JSEncrypt();
    const [loading, setLoading] = useState(false);
    const [eyeOpen, setEyeOpen] = useState(false);
    const [eyeOpen1, setEyeOpen1] = useState(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [state, setState] = useState({
        password: '',
        rePassword: '',
    });
    const resetForm = () => {
        setState({ password: '', rePassword: '' });
        form.setFieldsValue({ password: '', rePassword: '' });
    };
    const onFinish: FormProps['onFinish'] = () => {
        if (state.password !== state.rePassword) {
            messageApi.error('The 2 passwords entered are not the same');
            resetForm();
            return;
        }
        setLoading(true);
        api.getPublicKey(res => {
            const { code, data } = res;
            if (code === 200 && data) {
                passwordEncrypt.setPublicKey(data);
                api.resetPassword(
                    {
                        code: window.sessionStorage.getItem('code'),
                        email: profileInfo.email,
                        password: passwordEncrypt.encrypt(state.password),
                    },
                    res => {
                        setLoading(false);
                        const { code, data } = res;
                        if (code === 200) {
                            handleOk();
                            resetForm();
                        } else {
                            window.utils.alert('error', res.message);
                        }
                    }
                );
            } else {
                setLoading(false);
                messageApi.error(res.message);
            }
        });
    };
    const validatePassword = (_: any, value: string) => {
        const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z\d]{8,12}$/;
        if (!value || !passwordPattern.test(value)) {
            return Promise.reject(
                new Error(
                    'Please enter a password of 8-12, which must contain one uppercase letter, one lowercase letter, and one number.'
                )
            );
        }
        return Promise.resolve();
    };
    return (
        <Modal
            className="r-modal modal_no_padding team-modalBox"
            maskClosable={false}
            centered={true}
            key={'team-member-modal'}
            closeIcon={<i className="r-modal-close"></i>}
            footer={''}
            width={380}
            title={
                <div className="modal-top-title" style={{ marginBottom: 30 }}>
                    Change Password
                </div>
            }
            open={isModalOpen}
            onOk={handleOk}
            onCancel={() => {
                handleCancel();
                resetForm();
            }}
            maskProps={{
                style: {
                    backgroundColor: 'rgba(30, 34, 45, 0.8)',
                    backdropFilter: 'blur(1px)',
                },
            }}
        >
            <Form
                name="team-member"
                layout="vertical"
                style={{ maxWidth: 380 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
                validateTrigger="onBlur"
            >
                <Form.Item label="New Password" name="password" rules={[{ validator: validatePassword }]}>
                    <Input
                        className={`r-input `}
                        maxLength={12}
                        autoComplete="off"
                        placeholder={'Enter 8-12 digit password'}
                        value={state.password}
                        onInput={(e: any) => setState({ ...state, password: e.target.value })}
                        type={eyeOpen ? 'text' : 'password'}
                        suffix={
                            eyeOpen ? (
                                <i className="eye-open" onClick={() => setEyeOpen(false)}></i>
                            ) : (
                                <i className="eye-close" onClick={() => setEyeOpen(true)}></i>
                            )
                        }
                    />
                </Form.Item>
                <Form.Item
                    style={{ marginBottom: 30 }}
                    label="Confirm New Password"
                    name="rePassword"
                    rules={[{ validator: validatePassword }]}
                >
                    <Input
                        className={`r-input `}
                        maxLength={12}
                        autoComplete="off"
                        placeholder={'Re-enter the 8-12 digit password'}
                        value={state.rePassword}
                        onChange={(e: any) => setState({ ...state, rePassword: e.target.value })}
                        type={eyeOpen1 ? 'text' : 'password'}
                        suffix={
                            eyeOpen1 ? (
                                <i className="eye-open" onClick={() => setEyeOpen1(false)}></i>
                            ) : (
                                <i className="eye-close" onClick={() => setEyeOpen1(true)}></i>
                            )
                        }
                    />
                </Form.Item>
                <Form.Item style={{ margin: '30px 0 0 0 ' }}>
                    <div className="r-center">
                        <Button
                            className="r-primary_btn teamSubmit_btn"
                            type="primary"
                            htmlType="submit"
                            style={{ width: 83, borderRadius: 12 }}
                            disabled={!state.rePassword || !state.password}
                            loading={loading}
                        >
                            Save
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default EditPassword;
