import { useState, useEffect, useCallback } from 'react';
import { useBlocker } from 'react-router-dom';
type UsePromptProps = {
    when: boolean;
    message?: string;
    customFn?: (resolve: () => void, reject: () => void) => void;
};

export const usePrompt = ({ when, message, customFn }: UsePromptProps) => {
    const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
    const blocker: any = useBlocker(when as any);

    const leaveConfirm = useCallback(() => {
        setIsLeaveModalOpen(false);
        blocker.proceed();
    }, [blocker]);

    const leaveCancel = useCallback(() => {
        setIsLeaveModalOpen(false);
        blocker.reset();
    }, [blocker]);

    useEffect(() => {
        console.log('====================================');
        console.log(blocker);
        console.log('====================================');
        if (when && blocker.state === 'blocked') {
            if (blocker.location.pathname === '/login') {
                leaveConfirm();
                return;
            }
            if (customFn) {
                // 如果提供了自定义函数，使用它
                customFn(leaveConfirm, leaveCancel);
            } else {
                setIsLeaveModalOpen(true);
            }
        }
    }, [when, blocker.state, customFn, leaveConfirm, leaveCancel]);

    // 监听浏览器刷新或外部导航事件
    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (when) {
                e.preventDefault();
                e.returnValue = message || 'Are you sure you want to leave?';
            }
        };

        if (when) {
            window.addEventListener('beforeunload', handleBeforeUnload);
        }

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [when, message]);

    return {
        isLeaveModalOpen,
        leaveConfirm,
        leaveCancel,
    };
};
