import React, { useState } from 'react';
import './index.scss';
function SliderTabs({ tabList, setSelectedType, height, selectedType }: any) {
    const [activeIndex, setActiveIndex] = useState(1);
    const changeActive = (key: number) => {
        setActiveIndex(key);
        if (!setSelectedType) return;
        setSelectedType(key);
    };
    return (
        <div className="sliderTabs-ul r-left" style={{ height: height ? height : 40 }}>
            {tabList.map(item => {
                return (
                    <span
                        key={item.key}
                        className={`tabs-item ${selectedType === item.key ? 'active' : ''}`}
                        onClick={() => {
                            changeActive(item.key);
                        }}
                    >
                        {item.label}
                    </span>
                );
            })}
        </div>
    );
}

export default SliderTabs;
