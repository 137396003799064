import React, { useEffect, useState } from 'react';
import { Outlet, Route, Routes, useLocation, useNavigate, useParams, useRouteError } from 'react-router-dom';
import Home from '@/views/pages/home';
import './index.scss';
import JobIcon from '@/assets/img/job.png';
import JobActiveIcon from '@/assets/img/job1.png';
import TemplateIcon from '@/assets/img/template.png';
import TemplateActiveIcon from '@/assets/img/template1.png';
import DraftIcon from '@/assets/img/dart.png';
import DraftActiveIcon from '@/assets/img/dart1.png';
import ArchiveIcon from '@/assets/img/archive.png';
import ArchiveActiveIcon from '@/assets/img/archive1.png';
import ExistingIcon from '@/assets/img/existing.png';
import ExistingActiveIcon from '@/assets/img/existing1.png';
import JobInfo from './components/jobInfo';
import SelectTemplate from './components/select-template';
import { Switch } from 'antd';
function ErrorBoundary() {
    const error = useRouteError();
    console.error(error);
    return <div>failed</div>;
}
function JobIndex() {
    const navagite = useNavigate();
    const location = useLocation();
    const [selectIndex, setSelectedType] = useState(0);
    const jonNavList = [
        { label: 'Create new job', icon: JobIcon, activeIcon: JobActiveIcon, component: JobInfo },
        { label: 'Create from template', icon: TemplateIcon, activeIcon: TemplateActiveIcon, component: '' },
        { label: 'Create from draft', icon: DraftIcon, activeIcon: DraftActiveIcon, component: '' },
        { label: 'Create from archive', icon: ArchiveIcon, activeIcon: ArchiveActiveIcon, component: '' },
        { label: 'Create from Existing', icon: ExistingIcon, activeIcon: ExistingActiveIcon, component: '' },
    ];
    const changeSelectType = (type: number) => {
        // setSelectedType(type);
        const url = type > 0 ? `/job/select-template/${type}` : '/job/job-info';
        if (type === 0) {
            // 为0是直接添加，不是填充
            window.sessionStorage.setItem('isTopAdd', '1');
        }
        // 相同地址不跳转，防止弹出离开弹窗
        if (url === location.pathname) return;
        window.sessionStorage.removeItem('jobInfoForm');
        navagite(type > 0 ? `/job/select-template/${type}` : '/job/job-info');
    };
    const getAcitve = () => {
        const arr = location.pathname.split('/');
        const type = arr[arr.length - 1];
        // 预览保持当前选中
        if (
            type === 'job-info' &&
            (location.search === '?f=preview' ||
                location.search === '?f=addFromTem' ||
                location.search === '?f=editDart')
        )
            return;
        const activeIndex = type !== 'job-info' ? Number(type) : 0;
        setSelectedType(activeIndex);
        window.sessionStorage.setItem('jobTmpActive', activeIndex.toString());
    };
    useEffect(() => {
        getAcitve();
    }, [location]);
    useEffect(() => {
        const jobTmpActive = window.sessionStorage.getItem('jobTmpActive');
        setSelectedType(Number(jobTmpActive) || 0);
    }, []);
    return (
        <div className="r-jobMain-box r-top">
            <div className="jobLeft-nav">
                {jonNavList.map((val, i) => {
                    return (
                        <div
                            key={`jonNav-card-${val.label}${i}`}
                            onClick={() => changeSelectType(i)}
                            className={`jobNav-card r-left ${selectIndex === i ? 'active-jobCard' : ''}`}
                        >
                            <img src={selectIndex === i ? val.activeIcon : val.icon} className="label-iconImg" alt="" />
                            <span>{val.label}</span>
                        </div>
                    );
                })}
            </div>
            <Routes>
                <Route path="/*" element={<JobInfo />} errorElement={<ErrorBoundary />} />;
                <Route path="/job-info" element={<JobInfo />} errorElement={<ErrorBoundary />} />;
                <Route
                    path="/select-template/:selectType"
                    element={<SelectTemplate />}
                    errorElement={<ErrorBoundary />}
                />
                ;
            </Routes>
            <Outlet />
        </div>
    );
}

export default JobIndex;
