import React, { Suspense, useState, useEffect } from 'react';
import { HashRouter, BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { AxiosInterceptor } from './utils/index';
import auto from '@/utils/autofont';
import '@/assets/fonts/font.scss';
import routes from './router';
import { MessageProvider } from '@/hook/useGlobalMessage';
import { useDispatch, useSelector } from 'react-redux';
import { setDeviceType, setUserStatus, setUserStatusTip } from '@/store/stateSlice';
import { Spin } from 'antd';
import useLoading from './hook/useLoading';
import { FeedbackProvider } from './context/notificationContext';
import { SendBirdProvider } from './context/SendBirdProvider';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import AlertModal from './components/modal/alert-modal';
const App = () => {
    const navigate = useNavigate();
    const { userStatus, userStatusTip } = useSelector((state: any) => state.state);
    const dispatch = useDispatch();
    const commonplace = useSelector((state: any) => state.commonplace);
    const [device, setDevice] = useState('pc');
    const [showPage, setShowPage] = useState(true);
    const { mainLoading, setLoadingStatus } = useLoading();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const statusTip = {
        0: 'Access to this account has been revoked, please contact your team administrator.',
        2: `Your team master administrator account has been suspended. Please contact admin@talenaut.com`,
        3: `You've been removed from the team.`,
        4: `The team master administrator account has been deleted. Please contact admin@talenaut.com`,
    };
    const handleOk = () => {
        setIsModalOpen(false);
        dispatch(setUserStatus(1));
        dispatch(setUserStatusTip(''));
        navigate('/login');
    };
    // const getDevice = () => {
    //     const clientWidth = document.documentElement.clientWidth;
    //     setDevice(clientWidth < 745 ? 'mb' : 'pc');
    //     dispatch(setDeviceType(clientWidth < 745 ? 'mb' : 'pc'));
    //     setTimeout(() => {
    //         setShowPage(true);
    //     }, 200);
    // };
    // window.onresize = function () {
    //     auto.autoFont();
    //     getDevice();
    // };
    // useEffect(() => {
    //     setLoadingStatus(false);
    //     auto.autoFont();
    //     getDevice();
    // }, []);
    useEffect(() => {
        if (userStatus !== 1) {
            setIsModalOpen(true);
        }
    }, [userStatus]);
    return (
        <SendBirdProvider>
            <MessageProvider>
                <FeedbackProvider>
                    {/* <Router> */}
                    <AxiosInterceptor>
                        <Spin spinning={mainLoading} style={{ zIndex: 9999 }} wrapperClassName={'fullLoading-box'}>
                            <Suspense>
                                <Routes>
                                    {routes.map((route, index) => {
                                        return (
                                            <Route
                                                path={route.path}
                                                key={index}
                                                element={
                                                    <div
                                                        className={`${device}-main ${
                                                            showPage ? 'show-page' : 'hidden-page'
                                                        }`}
                                                    >
                                                        <route.component
                                                            routes={route.childrens ? route.childrens : []}
                                                        />
                                                    </div>
                                                }
                                            ></Route>
                                        );
                                    })}
                                </Routes>

                                <AlertModal
                                    title=""
                                    message={userStatusTip}
                                    cancelText=""
                                    confirmText="Continue"
                                    isModalOpen={isModalOpen}
                                    handleOk={handleOk}
                                />
                            </Suspense>
                        </Spin>
                    </AxiosInterceptor>
                    {/* </Router> */}
                </FeedbackProvider>
            </MessageProvider>
        </SendBirdProvider>
    );
};

export default App;
