import VideoPlayModal from '@/components/modal/video-play-modal';
import React, { useEffect, useState } from 'react';
import { Image, Spin } from 'antd';
import CompanyIcon from '@/assets/img/candidate-img/company.png';
import SchoolIcon from '@/assets/img/candidate-img/school.png';
import LicenseIcon from '@/assets/img/candidate-img/license.png';
import Check from '@/assets/img/candidate-img/check-succ.png';
import LinkIcon from '@/assets/img/candidate-img/link.png';
import ShareIcon from '@/assets/img/candidate-img/share.png';
import Bookmark from '@/assets/img/candidate-img/book.png';
import Bookmarked from '@/assets/img/candidate-img/booked.png';
import FrameIcon from '@/assets/img/candidate-img/frame.png';
import LocationIcon from '@/assets/img/candidate-img/location.png';
import MailIcon from '@/assets/img/candidate-img/mail-mini.png';
import LinkedInMiniIcon from '@/assets/img/candidate-img/LinkedIn-mini.png';
import FileIcon from '@/assets/img/candidate-img/file.png';
import ChatMiniIcon from '@/assets/img/candidate-img/chat-mini.png';
import MailOIcon from '@/assets/img/candidate-img/mail-o.png';
import PortFolioDrawer from '@/components/r-drawer/index';
import api from '@/api/job';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import { cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import { defaultInfo } from '@/typings/allType';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@/store/hooks';
import { useNavigate } from 'react-router-dom';
import { setNowChartUser } from '@/store/stateSlice';
const fallbackCopyToClipboard = (text: string) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.position = 'fixed'; // 避免影响页面布局
    textarea.style.left = '-9999px';
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    try {
        document.execCommand('copy');
        window.utils.alert('success', 'Copy success.');
    } catch (err) {
        window.utils.alert('error', 'Replication failure.');
    }
    document.body.removeChild(textarea);
};
function CandidateDetail() {
    const messageApi = useGlobalMessage();
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
    const [videoModalUrl, setVideoModalUrl] = useState('');
    const [openDrawer, setOpenDrawer] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const sendMessage = val => {
        dispatch(setNowChartUser({ ...val }));
        navigate('/message-chat?type=1');
    };
    // 是否是导入人员
    const [isUploadUser, setIsUploadUser] = useState(false);
    const [state, setState] = useState({
        isBookMark: false,
        status: 1,
        videoList: [
            { tit: 'Self Introduction', url: '', coverPic: '', desc: '' },
            { tit: 'Key Skills and Experience ', url: '', coverPic: '', desc: '' },
            { tit: 'Key Personal Strength', url: '', coverPic: '', desc: '' },
        ],
    });
    // 收藏
    const changeUserFavorite = () => {
        if (!nowUserInfo.businessAccountFavoriteFlag) {
            api.addUserFavorite({ accountId: nowUserInfo.accountId }, res => {
                const { code, data, message } = res;
                if (code === 200) {
                    setNowUserInfo({ ...nowUserInfo, businessAccountFavoriteFlag: true });
                } else {
                    messageApi.error(message);
                }
            });
            return;
        }
        api.removeUserFavorite({ accountId: nowUserInfo.accountId }, res => {
            const { code, data, message } = res;
            if (code === 200) {
                setNowUserInfo({ ...nowUserInfo, businessAccountFavoriteFlag: false });
            } else {
                messageApi.error(message);
            }
        });
    };
    // 分享
    const [userLink, setUserLink] = useState('');
    const shareUser = () => {
        // messageApi.success('分享成功');
        const text = `${
            process.env.REACT_APP_NODE_ENV === 'test'
                ? 'http://192.168.50.101/talenaut-candidate/#'
                : 'http://192.168.50.101/talenaut-candidate/#'
        }/userprofile?id=${userLink}`;
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    window.utils.alert('success', 'Copy success.');
                })
                .catch(err => {
                    window.utils.alert('error', 'Replication failure.');
                });
        } else {
            fallbackCopyToClipboard(text); // 使用备用方案
        }
    };
    //
    const openUser = type => {
        if (type === 1) {
            window.open(nowUserInfo.accountInformationPOJO.cvFileUrl, '_target');
        } else if (type === 2) {
            // messageApi.success('去聊天');
            sendMessage(nowUserInfo);
        } else {
            const recipient = nowUserInfo.accountInformationPOJO.email
                ? nowUserInfo.accountInformationPOJO.email
                : nowUserInfo.accountInformationPOJO.emailTwo;
            const mailtoLink = `mailto:${recipient}}}`;
            window.open(mailtoLink, '_blank');
        }
    };
    const [loading, setLoading] = useState(false);
    const [nowUserInfo, setNowUserInfo] = useState({
        accountId: '',
        interviewTimeShow: '',
        myScoreFlag: true,
        applicationsScoreTotal: 0,
        applicationsId: '',
        appliedTime: 0,
        accountPOJO: {
            accountInformationPOJO: {} as any,
        },
        accountInformationPOJO: {} as defaultInfo,
        jobsPOJO: {},
        hardSkill: [],
        softSkill: [],
        screeningQuestionsListPOJO: [] as defaultInfo[],
        businessAccountFavoriteFlag: false,
    });
    const [accountCertificatePOJOList, setAccountCertificatePOJOList] = useState<any>([]);
    const [relevantExperience, setRelevantExperience] = useState<any>([]);
    const [relevantExperienceTime, setRelevantExperienceTime] = useState<string>('');
    const [accountProjectPOJOList, setAccountProjectPOJOList] = useState<any>([]);
    const [showMoreProject, setShowMoreProject] = useState(false);
    const [projectInfo, setProjectInfo] = useState<any>({});
    const getUserInfo = val => {
        api.getUserInfo({ accountId: val.accountId, applicationsId: val.applicationsId }, res => {
            setLoading(false);
            const { code, data, message } = res;
            if (code === 200) {
                const hardSkill: any = [];
                const softSkill: any = [];
                if (data.accountSkillPOJOList && data.accountSkillPOJOList.length) {
                    data.accountSkillPOJOList.forEach(item => {
                        if (item.skillType === 'softSkills') {
                            softSkill.push(item);
                        } else {
                            hardSkill.push(item);
                        }
                    });
                }
                data.hardSkill = hardSkill;
                data.softSkill = softSkill;
                data.namelabel = `${data.accountInformationPOJO.firstName || '-'} ${
                    data.accountInformationPOJO.middleName || ''
                } ${data.accountInformationPOJO.lastName || ''}`;
                data.accountPOJO = { accountInformationPOJO: data.accountInformationPOJO };
                setNowUserInfo({ ...data });
                const userIdName = data.accountInformationPOJO.personalName.split('/userprofile/')[1];
                setUserLink(userIdName);
                if (data.accountExperiencePOJO) {
                    const year = data.accountExperiencePOJO.yearsOfExperience;
                    const month = data.accountExperiencePOJO.monthOfExperience;
                    const time = `${year ? `${year}${year > 1 ? 'Years' : 'Year'}` : ''}${
                        month ? ` ${month}${month > 1 ? ' Months' : ' Month'}` : ''
                    }`;
                    setRelevantExperienceTime(time);
                    setRelevantExperience([...data.accountExperiencePOJO.accountExperienceInfoPOJOList]);
                }
                if (data.accountCertificatePOJOList && data.accountCertificatePOJOList !== null) {
                    const val = cloneDeep(data.accountCertificatePOJOList);
                    setAccountCertificatePOJOList(val);
                }
                if (isUploadUser) return;
                if (data.accountProjectPOJOList && data.accountProjectPOJOList !== null) {
                    setAccountProjectPOJOList([...data.accountProjectPOJOList]);
                }
                const videos = [
                    {
                        tit: 'Self Introduction',
                        url: data.accountInformationPOJO.selfIntroductionUrl,
                        coverPic: data.accountInformationPOJO.selfIntroductionUrlImage,
                        desc: data.accountInformationPOJO?.selfIntroduction || '',
                    },
                    {
                        tit: 'Key Skills and Experience ',
                        url: data.accountInformationPOJO.keySkillsExperiencesUrl,
                        coverPic: data.accountInformationPOJO.keySkillsExperiencesUrlImage,
                        desc: data.accountInformationPOJO?.keySkillsExperiences || '',
                    },
                    {
                        tit: 'Key Personal Strength',
                        url: data.accountInformationPOJO.keyPersonalStrengthUrl,
                        coverPic: data.accountInformationPOJO.keyPersonalStrengthUrlImage,
                        desc: data.accountInformationPOJO?.keyPersonalStrength || '',
                    },
                ];
                setState({ ...state, videoList: videos });
            } else {
                messageApi.error(message);
            }
        });
    };
    const [localUserInfo, setLocalUserInfo] = useState<any>({});
    const getLocalCandidateInfo = () => {
        const info = JSON.parse(window.sessionStorage.getItem('candidateInfo') || "{accountId:''}");
        if (info.accountId) {
            setLoading(true);
            setLocalUserInfo(info);
            getUserInfo(info);
        }
    };
    useEffect(() => {
        getLocalCandidateInfo();
    }, []);
    return (
        <div className="r-pageContent candidate-detailBox r-top">
            {loading ? (
                <div className="no-message-tabBox r-center">
                    <Spin style={{ position: 'static' }}></Spin>
                </div>
            ) : (
                <>
                    <div>
                        {/* 侧边栏 */}
                        <div className="userOPeation-box">
                            <div className="r-top">
                                <Image
                                    width={128}
                                    height={128}
                                    preview={false}
                                    style={{ borderRadius: 12, minWidth: 128 }}
                                    src={
                                        nowUserInfo.accountInformationPOJO.profilePhoto
                                            ? nowUserInfo.accountInformationPOJO.profilePhoto
                                            : ''
                                    }
                                    fallback={require('@/assets/img/default-img.png')}
                                />
                                <div className="marLeft r-left">
                                    <img src={ShareIcon} className="shareIcon-box" alt="" onClick={shareUser} />
                                    <div
                                        className="shareIcon-box r-center"
                                        style={{ marginLeft: 8 }}
                                        onClick={() => setState({ ...state, isBookMark: !state.isBookMark })}
                                    >
                                        {nowUserInfo.businessAccountFavoriteFlag ? (
                                            <img
                                                src={Bookmarked}
                                                onClick={() => changeUserFavorite()}
                                                className="shareIcon-box"
                                                alt=""
                                            />
                                        ) : (
                                            <img
                                                src={Bookmark}
                                                onClick={() => changeUserFavorite()}
                                                className="shareIcon-box"
                                                alt=""
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="jobUser-tip">
                                    {localUserInfo.namelabel || '-'}
                                    {/* {nowUserInfo.accountInformationPOJO.preferredPronounsCustomFlag
                                        ? `(${nowUserInfo.accountInformationPOJO.preferredPronounsCustom})`
                                        : `(${nowUserInfo.accountInformationPOJO.preferredPronounsList})`} */}
                                    {nowUserInfo.accountInformationPOJO.preferredPronounsFlag
                                        ? `(${
                                              nowUserInfo.accountInformationPOJO.preferredPronounsCustomFlag
                                                  ? nowUserInfo.accountInformationPOJO.preferredPronounsCustom
                                                  : nowUserInfo.accountInformationPOJO.preferredPronounsList
                                          })`
                                        : ''}
                                </div>
                                <div className="userMini-msg r-left">
                                    <img src={FrameIcon} className="userInfoIcon-img" alt="" />
                                    <span className="oneLineText">
                                        {nowUserInfo.accountInformationPOJO.jobRole || '-'}
                                    </span>
                                    {/* {nowUserInfo.accountInformationPOJO.jobRoleMatchJobFlag ? (
                                        <img
                                            src={CheckSuccicon}
                                            className="userInfoIcon-img"
                                            style={{ margin: '0 0 0 4px' }}
                                            alt=""
                                        />
                                    ) : null} */}
                                </div>
                                <div className="userMini-msg r-left">
                                    <img src={LocationIcon} className="userInfoIcon-img" alt="" />
                                    {nowUserInfo.accountInformationPOJO.remoteFlag
                                        ? nowUserInfo.accountInformationPOJO.gmt
                                        : nowUserInfo.accountInformationPOJO.location}
                                </div>
                                <div className="userMini-msg r-left">
                                    <img src={MailOIcon} className="userInfoIcon-img" alt="" />
                                    {nowUserInfo?.accountInformationPOJO.email
                                        ? nowUserInfo?.accountInformationPOJO.email
                                        : nowUserInfo?.accountInformationPOJO.emailTwo || '-'}
                                </div>
                                {nowUserInfo.accountInformationPOJO.linkedInUrl && !isUploadUser ? (
                                    <div className="userMini-msg r-left">
                                        <img src={LinkedInMiniIcon} className="userInfoIcon-img" alt="" />
                                        <span
                                            className="oneLineText"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                window.open(
                                                    `https://${nowUserInfo.accountInformationPOJO.linkedInUrl}`,
                                                    '_target'
                                                );
                                            }}
                                        >
                                            {nowUserInfo.accountInformationPOJO.linkedInUrl || '-'}
                                        </span>
                                    </div>
                                ) : null}
                            </div>
                            <div>
                                {!isUploadUser ? (
                                    <>
                                        {' '}
                                        <div className="userOpeation-btn r-left marb12" onClick={() => openUser(1)}>
                                            View CV
                                            <img src={FileIcon} className="iconImg" alt="" />
                                        </div>
                                        <div className="userOpeation-btn r-left marb12" onClick={() => openUser(2)}>
                                            Message
                                            <img src={ChatMiniIcon} className="iconImg" alt="" />
                                        </div>
                                    </>
                                ) : null}
                                <div className="userOpeation-btn r-left" onClick={() => openUser(3)}>
                                    Send Email
                                    <img src={MailIcon} className="iconImg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="candidateInfo-right">
                        {/* video */}
                        {!isUploadUser ? (
                            <>
                                <div className="r-left">
                                    {state.videoList.map((item, i) => {
                                        return (
                                            <div key={`candidateVideo-item${i}`} className="videoCard-box">
                                                <p className="video-tip">{item.tit}</p>
                                                <div
                                                    className="videoCard-bg"
                                                    style={{
                                                        background: `url(${item.coverPic ? item.coverPic : ''})`,
                                                        backgroundPosition: 'center center',
                                                        backgroundSize: 'cover',
                                                        backgroundColor: '#ccc',
                                                    }}
                                                    onClick={() => {
                                                        setVideoModalUrl(item.url);
                                                        setIsVideoModalOpen(true);
                                                    }}
                                                ></div>
                                            </div>
                                        );
                                    })}
                                </div>
                                {/* video desc */}
                                <div className="candidateVideo-info">
                                    <div className="video-infoTit">For the Business Interface</div>
                                    <div className="r-top" style={{ alignItems: 'stretch' }}>
                                        {state.videoList.map((item, i) => {
                                            return (
                                                <div key={`videoInfo-msg_${i}`} className="videoInfo-msg">
                                                    <p className="msg-tit">{item.tit}</p>
                                                    <p className="msg-tip">{item.desc || '-'}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                {/* 工作经验中的其他job */}
                                <div className="candidateVideo-info">
                                    <div className="video-infoTit">Other Roles</div>
                                    <div>
                                        {relevantExperience.map((item, i) => {
                                            return (
                                                <div key={`roleBox-msg_${i}`} className="r-top roleList-box">
                                                    <img src={CompanyIcon} className="roleImg" alt="" />
                                                    <div>
                                                        <p className="msg-tit">
                                                            {item.jobRoles || '-'} (
                                                            {`${item.skills ? item.skills.split(',').length : 0} ${
                                                                item.skills && item.skills.split(',').length > 1
                                                                    ? 'skills'
                                                                    : 'skill'
                                                            }`}
                                                            )
                                                        </p>
                                                        <p className="msg-tip" style={{ marginTop: 8 }}>
                                                            {item.skills || '-'}
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {/* 技能 */}
                        <div className="candidateVideo-info" style={{ margin: isUploadUser ? '0' : '12px 0' }}>
                            <div className="video-infoTit r-left">Skills</div>
                            <div>
                                <p className="msg-tit">Hard Skills</p>
                                <div className="r-left">
                                    {nowUserInfo.hardSkill.map((item: any, i) => {
                                        return (
                                            <div
                                                key={`skillHardBox-msg_${i}`}
                                                className={`r-center skillList-box ${
                                                    item.skillNameMatchJobFlag ? 'active' : ''
                                                }`}
                                            >
                                                {item.skillNameMatchJobFlag ? <i className="check-boxIcon"></i> : null}
                                                <p className="msg-tip">{item.skillName || '-'}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <p className="msg-tit">Soft Skills</p>
                                <div className="r-left">
                                    {nowUserInfo.softSkill.map((item: any, i) => {
                                        return (
                                            <div
                                                key={`skillSoftBox-msg_${i}`}
                                                className={`r-center skillList-box ${
                                                    item.skillNameMatchJobFlag ? 'active' : ''
                                                }`}
                                            >
                                                {item.skillNameMatchJobFlag ? <i className="check-boxIcon"></i> : null}
                                                <p className="msg-tip">{item.skillName || '-'}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="candidateVideo-info">
                            <div className="video-infoTit">About Me</div>
                            <div className="msg-tip break-text" style={{ marginTop: 8 }}>
                                {nowUserInfo.accountInformationPOJO.aboutMe || '-'}
                            </div>
                        </div>
                        {/* 工作经验 */}
                        <div className="candidateVideo-info" style={{ padding: '24px 16px 8px 16px' }}>
                            <div className="video-infoTit r-left">
                                Relevant Experience
                                <span className="marLeft black-color">
                                    {relevantExperienceTime ? relevantExperienceTime : '-'}
                                </span>
                            </div>
                            <div className="r-left relevantExperienceList">
                                {relevantExperience.map((item, i) => {
                                    return (
                                        <div
                                            key={`relevantExperience-profile-item-${i}`}
                                            className={`relevantExperience-item ${
                                                item.occupationalType !== 'Freelancer' ? 'greenBg' : ''
                                            }`}
                                        >
                                            <div className="experie-tit r-left">
                                                <span className="oneLineText">
                                                    {item.occupationalType !== 'Freelancer'
                                                        ? item.companyName
                                                        : 'Freelancer'}
                                                </span>
                                                {item.occupationalType !== 'Freelancer' ? (
                                                    <img src={Check} className="jobCheck-icon" alt="" />
                                                ) : null}
                                            </div>
                                            <div className="experie-job oneLineText">
                                                {item.jobRoles ? item.jobRoles : '-'}
                                            </div>
                                            <div className="experie-jobTime">
                                                {item.currentEmployedHereFlag === 1 ? (
                                                    'Currently Employed Here'
                                                ) : (
                                                    <>
                                                        {item.yearsOfExperience
                                                            ? `${item.yearsOfExperience} ${
                                                                  item.yearsOfExperience > 1 ? ' Years ' : ' Year '
                                                              }`
                                                            : ''}
                                                        {item.monthOfExperience
                                                            ? `${item.monthOfExperience} ${
                                                                  item.monthOfExperience > 1 ? ' Months' : ' month'
                                                              }`
                                                            : ''}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div>
                            {!isUploadUser && accountProjectPOJOList.length ? (
                                <>
                                    {/* 工作项目 */}
                                    <div
                                        className="candidateVideo-info"
                                        style={{
                                            paddingBottom:
                                                accountProjectPOJOList.length > 6 && !showMoreProject ? 24 : 0,
                                        }}
                                    >
                                        <div className="video-infoTit">Portfolio</div>
                                        <div className="r-left portList-box">
                                            {accountProjectPOJOList.map((item, i) => {
                                                return (
                                                    <div
                                                        className="portImg-box"
                                                        key={item.projectId}
                                                        style={{
                                                            display: i > 5 && !showMoreProject ? 'none' : 'initial',
                                                            border: '1px solid #ccc',
                                                            backgroundImage: `url(${item.coverImageUrl})`,
                                                            backgroundPosition: 'center center',
                                                        }}
                                                        onClick={() => {
                                                            setProjectInfo(item);
                                                            setOpenDrawer(true);
                                                        }}
                                                    ></div>
                                                );
                                            })}
                                        </div>
                                        {accountProjectPOJOList.length < 7 || showMoreProject ? null : (
                                            <div
                                                className="viewMore-btn r-center"
                                                onClick={() => setShowMoreProject(true)}
                                            >
                                                View More
                                            </div>
                                        )}
                                    </div>
                                    {/* 学校信息 */}
                                    <div className="candidateVideo-info">
                                        <div className="video-infoTit">Education</div>
                                        <div className="r-top roleList-box">
                                            <img src={SchoolIcon} className="roleImg" alt="" />
                                            <div>
                                                <p className="msg-tit">
                                                    {nowUserInfo.accountInformationPOJO.graduation || '-'}{' '}
                                                    {nowUserInfo.accountInformationPOJO.major
                                                        ? `- ${nowUserInfo.accountInformationPOJO.major}`
                                                        : ''}
                                                </p>
                                                <p className="msg-tip" style={{ marginTop: 8 }}>
                                                    {nowUserInfo.accountInformationPOJO.highestDegree || '-'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </div>
                        {/* 证书信息 */}
                        {accountCertificatePOJOList.length ? (
                            <div className="candidateVideo-info">
                                <div className="video-infoTit">Licenses, Certifications and External Assessments</div>
                                <div>
                                    {accountCertificatePOJOList.length ? (
                                        accountCertificatePOJOList.map((item, i) => {
                                            return (
                                                <div key={`licenseBox-msg_${i}`} className="r-top roleList-box">
                                                    <img src={LicenseIcon} className="roleImg" alt="" />
                                                    <div>
                                                        <p className="msg-tit">
                                                            {item.certificateName ? item.certificateName : '-'}
                                                        </p>
                                                        <p className="msg-tip" style={{ marginTop: 8 }}>
                                                            {dayjs(item.validPeriodFrom, 'MMM YYYY').format(
                                                                'MMMM YYYY'
                                                            )}{' '}
                                                            -{' '}
                                                            {dayjs(item.validPeriodTo, 'MMM YYYY').format('MMMM YYYY')}
                                                        </p>
                                                    </div>
                                                    {item.certificateUrl ? (
                                                        <img
                                                            src={LinkIcon}
                                                            className="roleImg marLeft"
                                                            style={{
                                                                width: 28,
                                                                height: 28,
                                                                marginRight: 0,
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() =>
                                                                window.open(`https://${item.certificateUrl}`, '_target')
                                                            }
                                                            alt=""
                                                        />
                                                    ) : null}
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="no-data-cell op5 r-center">Not yet</div>
                                    )}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </>
            )}

            <VideoPlayModal
                videoUrl={videoModalUrl}
                isModalOpen={isVideoModalOpen}
                handleOk={() => setIsVideoModalOpen(false)}
                handleCancel={() => setIsVideoModalOpen(false)}
            />
            <PortFolioDrawer
                open={openDrawer}
                type="jobUser"
                userInfo={nowUserInfo}
                onClose={() => setOpenDrawer(false)}
                projectInfo={projectInfo}
            />
        </div>
    );
}

export default CandidateDetail;
