import React, { useCallback, useEffect, useState } from 'react';
import './index.scss';
import { Button, Input, Spin } from 'antd';
import { debounce } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import api from '@/api/job';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import { defaultInfo } from '@/typings/allType';
function SelectTemplate() {
    const messageApi = useGlobalMessage();
    const { selectType } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [state, setState] = useState({
        search: '',
        pageNo: 1,
    });
    const [jobArrList, setJobArrList] = useState<any>([]);
    const searchList: any = useCallback(
        debounce(params => {
            getList({ ...params });
        }, 1000),
        []
    );
    const addTemplate = () => {
        navigate(`/job/job-info?f=addFromTem`);
    };
    const opeationList = val => {
        window.sessionStorage.setItem('isTopAdd', '0');
        if (selectType === '1') {
            // 填充模版
            window.sessionStorage.setItem('jobInfoForm', JSON.stringify({ ...val, type: 'tc' }));
            navigate(`/job/job-info`);
        } else if (selectType === '2') {
            // 修改草稿
            window.sessionStorage.setItem('jobInfoForm', JSON.stringify({ ...val, type: 'edit' }));
            navigate(`/job/job-info?f=editDart`);
        } else {
            // 3，4填充工作信息
            window.sessionStorage.setItem('jobInfoForm', JSON.stringify({ ...val, type: 'tc' }));
            navigate(`/job/job-info`);
        }
    };
    const opeationPreviewList = val => {
        window.sessionStorage.setItem('isTopAdd', '0');
        if (selectType === '1') {
            //进入草稿编辑模板页面
            window.sessionStorage.setItem('jobInfoForm', JSON.stringify({ ...val, type: 'edit' }));
            navigate(`/job/job-info?f=addFromTem`);
        } else {
            //进入预览
            window.sessionStorage.setItem('jobInfoForm', JSON.stringify({ ...val }));
            navigate(`/job/job-info?f=preview`);
        }
    };
    const callback = res => {
        setLoading(false);
        setIsSearch(false);
        if (res.code === 200) {
            setJobArrList(res.data);
        } else {
            messageApi.error(res.message);
        }
    };
    const getList = pat => {
        setLoading(true);
        if (selectType === '1') {
            api.getTemplateList(pat, res => callback(res));
        } else if (selectType === '2') {
            api.getDartList(pat, res => callback(res));
        } else if (selectType === '3') {
            api.getArchiveList(pat, res => callback(res));
        } else {
            api.getExistingList(pat, res => callback(res));
        }
    };
    useEffect(() => {
        setState({ ...state, search: '' });
        getList({ keyWord: '' });
    }, [selectType]);
    return (
        <div className="jobInfoMain-box">
            <div className="formBg-box">
                <div className="r-top">
                    <div>
                        <div className="createInfo-tit">
                            Create from{' '}
                            {selectType === '1'
                                ? 'template'
                                : selectType === '2'
                                ? 'draft'
                                : selectType === '3'
                                ? 'archive'
                                : 'existing'}
                        </div>
                        <p className="createInfo-tip">Fill out the details below to post a new job opportunity.</p>
                    </div>
                    {selectType === '1' ? (
                        <Button
                            className="r-primary_btn r-primaryNoBg_btn addTem-btn marLeft"
                            onClick={() => addTemplate()}
                        >
                            + Add New Template
                        </Button>
                    ) : null}
                </div>
                <div style={{ margin: '32px 0 20px 0' }}>
                    <Input
                        placeholder="Search"
                        style={{ flex: 1 }}
                        allowClear
                        value={state.search}
                        onChange={e => {
                            setIsSearch(true);
                            searchList({ keyWord: e.target.value, pageNo: 1 });
                            setState({ ...state, search: e.target.value, pageNo: 1 });
                        }}
                        className="r-input r-input_serach"
                        prefix={<span className="search_icon"></span>}
                    />
                </div>
                <div className="r-left temp-cardList">
                    {loading ? (
                        <div className="r-center no-message-tabBox">
                            <Spin style={{ position: 'static' }}></Spin>
                        </div>
                    ) : jobArrList.length ? (
                        jobArrList.map((val, i) => {
                            return (
                                <div key={`cardBox-temp-${i}`} className="tempCard-box">
                                    <p className="temp-tit oneLineText">{val?.jobsPOJO.jobTitle || '-'}</p>
                                    <p className="temp-job">{val?.jobsPOJO.employerName || '-'}</p>
                                    <div className="line-boxNew" style={{ margin: '12px 0' }}></div>
                                    <div className="r-left">
                                        <Button className="r-primary_btn w87" onClick={() => opeationList(val)}>
                                            Select
                                        </Button>
                                        {selectType !== '2' ? (
                                            <Button
                                                className="r-primary_btn r-primaryNoBg_btn w97-pre"
                                                onClick={() => opeationPreviewList(val)}
                                            >
                                                Preview
                                            </Button>
                                        ) : null}
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="r-center no-message-tabBox">
                            {state.search || isSearch
                                ? 'Sorry, we were unable to find any results based on your conditions.'
                                : 'No data'}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SelectTemplate;
