import config from '@/config/config';
import React, { useEffect, useState } from 'react';
// outlook calendar
const useOutlookAuth = () => {
    const clientId = config.outlookAuthKey;
    const redirectUri = `${config.localUrl}/auth-outlook-callback`;
    const scope = 'https://graph.microsoft.com/calendars.read https://graph.microsoft.com/calendars.readwrite';
    const authEndpoint = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
    const [outlookCode, setOutlookCode] = useState('');
    const handleOutLookLogin = () => {
        const url = `${authEndpoint}?client_id=${clientId}&redirect_uri=${encodeURIComponent(
            redirectUri
        )}&response_type=code&scope=${encodeURIComponent(scope)}&prompt=consent`;
        const popup: any = window.open(url, 'outlook-login', 'width=600,height=600');
        const interval = setInterval(() => {
            if (popup.closed) {
                clearInterval(interval);
            }
        }, 1000);
    };
    useEffect(() => {
        setOutlookCode('');
        const handleMessage = (event: MessageEvent) => {
            if (event.origin !== window.location.origin) return;
            const { code, error, type } = event.data;
            if (type !== 'outlook') return;
            if (error) {
                console.error('Authorization error:', error);
            } else if (code) {
                console.log(code);
                setOutlookCode(code);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);
    return { handleOutLookLogin, outlookCode, setOutlookCode };
};

export default useOutlookAuth;
