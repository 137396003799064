import React, { useEffect, useState } from 'react';
import './index.scss';
import { Button, Form, Input, Select, Spin } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import FormModal from '@/components/modal/form-modal';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import api from '@/api/talentDatsbase';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
function TalentDatabaseInfo() {
    const navigate = useNavigate();
    const messageApi = useGlobalMessage();
    const [active, setActive] = useState(-1);
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [isHaveData, setIsHaveData] = useState(false);
    const [candidateList, setCandidateList] = useState<any>([]);

    const [avatar, setAvatar] = useState({
        url: '',
        key: '',
        size: '',
        base64: '',
        file: null,
        uploading: false,
        dialogVisible: false,
        warncontent: '',
    }) as any;
    const handleFileChange = (e: any) => {
        if (!e) {
            return;
        }
        const fileName = e.target.files[0].name;
        const fileSize = e.target.files[0].size;
        const file = e.target.files[0].name.split('.');
        if (file[file.length - 1].toLowerCase() === 'csv' || file[file.length - 1].toLowerCase() === 'xlsx') {
            if (fileSize > 10 * 1048576) {
                window.format.alert('error', 'The file cannot exceed 10mb');
                (document.getElementById('csvInput') as any).value = null;
                return;
            }
            console.log(e.target.files[0]);
            setAvatar(
                Object.assign({}, avatar, {
                    file: e.target.files[0],
                    key: fileName,
                    size: fileSize,
                    dialogVisible: true,
                })
            );
            (document.getElementById('csvInput') as any).value = null;
            // const reader = new FileReader();
            // reader.readAsDataURL(e.target.files[0]);
            // reader.onload = function (ev: any) {
            //     setAvatar(
            //         Object.assign({}, avatar, {
            //             file: ev.target.result,
            //             key: fileName,
            //             size: fileSize,
            //             dialogVisible: true,
            //         })
            //     );
            //     (document.getElementById('csvInput') as any).value = null;
            // };
        } else {
            window.format.alert('error', 'The file supports only csv and xlsx formats.');
            (document.getElementById('csvInput') as any).value = null;
        }
    };
    // 上传文件
    const uploadCsv = () => {
        setAvatar({ ...avatar, uploading: true });
        const formData = new FormData();
        formData.append('file', avatar.file);
        api.uploadIndexDBList(formData, res => {
            if (res.code === 200) {
                if (res.data) {
                    setAvatar({ ...avatar, uploading: false, file: '', key: '' });
                    setCandidateList(
                        res.data.map(item => {
                            return Object.assign(item, {
                                namelabel: `${item.name}`,
                            });
                        })
                    );
                    setIsFormModalOpen(false);
                    setIsHaveData(true);
                } else {
                    setCandidateList([]);
                    setAvatar({ ...avatar, uploading: false, file: '', key: '' });
                }
            } else {
                messageApi.error(res.message);
                setAvatar({ ...avatar, uploading: false });
            }
        });
    };
    // linkedin list
    const [loading, setLoading] = useState(false);
    const getLinkedin = () => {
        setLoading(true);
        api.getTalentDatabaseLinkedInList({ pageNo: 1, pageSize: 1000 }, res => {
            setLoading(false);
            if (res.code === 200) {
                if (res.data) {
                    setCandidateList(
                        res.data.map(item => {
                            return Object.assign(item, {
                                namelabel: `${item.name}`,
                            });
                        })
                    );
                    setIsHaveData(true);
                } else {
                    setCandidateList([]);
                }
            } else {
                messageApi.error(res.message);
            }
        });
    };
    // sava
    const [saveloading, setSavaLoading] = useState(false);
    const savaList = () => {
        setSavaLoading(true);
        if (active === 0) {
            api.addLinkedInTalenautDatabase({}, res => {
                setSavaLoading(false);
                if (res.code === 200) {
                    messageApi.success('Success');
                    navigate(-1);
                } else {
                    messageApi.error(res.message);
                }
            });
            return;
        }
        const pat = {
            excelAccountPOJOList: [...candidateList],
        };
        api.addTalenautDatabase(pat, res => {
            setSavaLoading(false);
            if (res.code === 200) {
                messageApi.success('Success');
                navigate(-1);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    // useEffect(() => {
    //     getLinkedin();
    // }, []);
    return (
        <div className="talentDatabase-infoBox">
            <div className="r-left">
                <div className="talentdataBast-tit">Add New Candidate</div>
                <Button
                    onClick={() => {
                        window.open(
                            'https://talenaut2024.s3.eu-west-2.amazonaws.com/Templates-ImportNewCandidates.xlsx',
                            '_target'
                        );
                    }}
                    className={`h40Btn r-primaryNoBg_btn ${active !== 1 ? 'hideBtn' : ''}`}
                    style={{ width: 200, marginLeft: 'auto' }}
                >
                    Download .csv Template
                </Button>
                <Button
                    onClick={() => setIsFormModalOpen(true)}
                    className={`h40Btn marL12 r-primaryNoBg_btn ${active !== 1 ? 'hideBtn' : ''}`}
                    style={{ width: 180 }}
                >
                    Upload .csv Template
                </Button>
                <Button
                    onClick={() => {
                        setIsHaveData(false);
                        getLinkedin();
                        setActive(0);
                    }}
                    className={`h40Btn ${
                        active === -1
                            ? 'marLeft r-primaryNoBg_btn'
                            : active === 0
                            ? 'r-primary_btn marLeft'
                            : 'r-primaryNoBg_btn marL12'
                    }`}
                    style={{ width: 102 }}
                >
                    LinkedIn
                </Button>
                <Button
                    onClick={() => {
                        setIsHaveData(false);
                        setActive(1);
                    }}
                    className={`h40Btn marL12 ${active === 1 ? 'r-primary_btn' : 'r-primaryNoBg_btn'}`}
                    style={{ width: 118 }}
                >
                    Internal DB
                </Button>
            </div>
            <div className={`candidate-databaseBox ${isHaveData ? '' : 'minHeightEmpty'}`}>
                {isHaveData ? (
                    <div>
                        <div className="r-left database-tbIit">
                            {active === 0 ? 'LinkedIn' : 'New_candidates.csv'}
                            <i className="removeR-icon marLeft" onClick={() => setIsHaveData(false)}></i>
                        </div>
                        {active === 1 ? <p className="fileSize-box">365 KB</p> : ''}
                        <div className="csvInfo-line"></div>
                        <div className="dataBase-candidatetable">
                            <div className="r-left db-table_th">
                                <div className="db-table_cell">Name</div>
                                <div className="db-table_cell">Role</div>
                                <div className="db-table_cell">Hard skills</div>
                                <div className="db-table_cell">Soft skills</div>
                                <div className="db-table_cell">Experience (years)</div>
                                <div className="db-table_cell">Education</div>
                            </div>
                            <div>
                                {candidateList.length ? (
                                    candidateList.map((val, i) => {
                                        return (
                                            <div className="r-left db-table_tr" key={`db-table_tr-${i}`}>
                                                <div className="db-tr_cell">{val.namelabel || '-'}</div>
                                                <div className="db-tr_cell">{val.jobRole || '-'}</div>
                                                <div className="db-tr_cell">{val.hardSkills || '-'}</div>
                                                <div className="db-tr_cell"> {val.softSkills || '-'}</div>
                                                <div className="db-tr_cell">{val.relevantExperience || '-'}</div>
                                                <div className="db-tr_cell">{val.education || '-'}</div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="r-center db-table_tr no-data-cell">No Data</div>
                                )}
                            </div>
                        </div>
                        <div className="r-end">
                            <Button
                                className="r-primary_btn r-primary_largeBtn conCsv-btn"
                                loading={saveloading}
                                onClick={() => savaList()}
                                disabled={!isHaveData || !candidateList.length}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                ) : loading ? (
                    <>
                        <div className="no-message-tabBox r-center noCandidate-msg">
                            <Spin style={{ position: 'static' }}></Spin>
                        </div>
                    </>
                ) : (
                    <div className="noCandidate-msg">No candidates have been added</div>
                )}
            </div>

            <FormModal
                title="Upload .csv Template"
                width={380}
                isModalOpen={isFormModalOpen}
                handleCancel={() => {
                    setAvatar({
                        url: '',
                        key: '',
                        size: '',
                        base64: '',
                        file: null,
                        uploading: false,
                        dialogVisible: false,
                        warncontent: '',
                    });
                    setIsFormModalOpen(false);
                }}
                children={
                    <div>
                        <div className="r-left uploadMain_box uploadCSV-box">
                            <label className={`upload_csvBox `} htmlFor="csvInput">
                                {
                                    <>
                                        {!avatar.file ? (
                                            <div>
                                                <i className="uploadCSV-icon"></i>
                                                <p className="upload_tip">CSV files can be uploaded</p>
                                            </div>
                                        ) : (
                                            <div>
                                                {/* <LoadingOutlined style={{ fontSize: 22 }} rev={undefined} /> */}
                                                <i className="uploadCSV-icon"></i>
                                                <p className="upload_tip">{avatar.key || '-'}</p>
                                            </div>
                                        )}
                                    </>
                                }
                                <input
                                    type="file"
                                    id="csvInput"
                                    onChange={e => handleFileChange(e)}
                                    accept=".csv, .xlsx"
                                    title=""
                                />
                            </label>
                        </div>
                        <div className="r-center">
                            <Button
                                style={{ width: 106, marginTop: 40 }}
                                className="r-primary_btn r-primary_largeBtn"
                                loading={avatar.uploading}
                                disabled={!avatar.file}
                                onClick={() => uploadCsv()}
                            >
                                Upload
                            </Button>
                        </div>
                    </div>
                }
            />
        </div>
    );
}

export default TalentDatabaseInfo;
