import axios from '../utils/index';
import { Pat, HttpResponse } from '@/typings/request';
const talentDatsbaseApi = {
    getTalentPoolList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/talentPool/talentPool-home-candidates-list`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getMyJobList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/v1/business/talentPool/talentPool-jobTitle-unique-list`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    delPoolJobGroup(params: Pat, callback: (T: any) => void) {
        axios
            .delete(`/v1/business/talentPool/talentPool-delete/${params.poolId}/${params.groupId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getPoolGroupUserList(params: Pat, callback: (T: any) => void) {
        axios
            .post(
                `/v1/business/talentPool/talentPool-candidates-list/${params.poolId}/${params.groupId}/${params.pageNo}/${params.pageSize}`,
                params
            )
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    delPoolGroupUser(params: Pat, callback: (T: any) => void) {
        axios
            .delete(`/v1/business/talentPool/talentPool-delete/${params.poolId}/${params.groupId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    sharePool(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/talentPool/talentPool-share/${params.poolId}/${params.groupId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    delPool(params: Pat, callback: (T: any) => void) {
        axios
            .delete(`/v1/business/talentPool/talentPool-delete/${params.poolId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addTalentPool(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/talentPool/talentPool-add`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    editTalentPool(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/business/talentPool/talentPool-edit/${params.poolId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getUserAddJobList(params: Pat, callback: (T: any) => void) {
        axios
            .post(
                `/v1/business/talentPool/talentPool-candidates-addJob-getJobList-byJobTitle/${params.accountId}`,
                params
            )
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addUserToJob(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/talentPool/talentPool-candidates-addJob/${params.accountId}/${params.jobId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    dleteUserToJob(params: Pat, callback: (T: any) => void) {
        axios
            .delete(
                `/v1/business/talentPool/talentPool-candidates-list-removeCandidates/${params.poolId}/${params.groupId}/${params.accountId}`,
                params
            )
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default talentDatsbaseApi;
