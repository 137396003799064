import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, FormProps, Input, Modal, Select } from 'antd';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import uploadApi from '@/api/upload';
import UploadImg from '@/components/upload/upload-img';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import { useSelector } from 'react-redux';
import api from '@/api/login';
function EditAccount({ isModalOpen, handleOk, handleCancel, title }: any) {
    const messageApi = useGlobalMessage();
    const [loading, setLoading] = useState(false);
    const { profileInfo } = useSelector((state: any) => state.state);
    const [form] = Form.useForm();
    const formRef = useRef<any>(null);
    const [roleList, setRoleList] = useState([]);
    const [state, setState] = useState({
        picUrlShow: '',
        profilePhoto: '',
        firstName: '',
        middleName: '',
        lastName: '',
        business: '',
        position: '',
    });
    const onFinish: FormProps['onFinish'] = values => {
        console.log('Success:', values);
        const pat: any = {
            picUrlShow: state.picUrlShow,
            avatarUrl: state.profilePhoto,
            firstName: state.firstName,
            lastName: state.lastName,
            middleName: state.middleName,
            position: state.position,
            businessName: state.business,
        };
        setLoading(true);
        if (title === 'Edit Position') {
            api.editBusinessPosition(pat, res => {
                setLoading(false);
                if (res.code === 200) {
                    handleOk();
                } else {
                    messageApi.error(res.message);
                }
            });
            return;
        }
        if (title === 'Edit') {
            api.editBusinessInfo(pat, res => {
                setLoading(false);
                if (res.code === 200) {
                    handleOk();
                } else {
                    messageApi.error(res.message);
                }
            });
            return;
        }
        api.editBusinessNameInfo(pat, res => {
            setLoading(false);
            if (res.code === 200) {
                handleOk();
            } else {
                messageApi.error(res.message);
            }
        });
    };

    const onFinishFailed: FormProps['onFinishFailed'] = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    useEffect(() => {
        const initialValues = {
            profilePhoto: profileInfo.avatarUrl,
            firstName: profileInfo.firstName,
            middleName: profileInfo.middleName,
            lastName: profileInfo.lastName,
            position: profileInfo.position,
            picUrlShow: '',
            business: profileInfo.businessInfoPOJO.businessName,
        };
        setState({ ...initialValues });
        form.setFieldsValue(initialValues);
    }, [isModalOpen]);
    return (
        <Modal
            className="r-modal modal_no_padding team-modalBox"
            maskClosable={false}
            centered={true}
            key={'team-member-modal'}
            closeIcon={<i className="r-modal-close"></i>}
            footer={''}
            width={380}
            title={
                <div className="modal-top-title" style={{ marginBottom: 30 }}>
                    {title}
                </div>
            }
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            maskProps={{
                style: {
                    backgroundColor: 'rgba(30, 34, 45, 0.8)',
                    backdropFilter: 'blur(1px)',
                },
            }}
        >
            <Form
                name="team-member"
                layout="vertical"
                style={{ maxWidth: 380 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                form={form}
                ref={formRef}
                validateTrigger="onBlur"
            >
                {title === 'Edit' ? (
                    <>
                        <Form.Item
                            label={null}
                            name="profilePhoto"
                            rules={[{ required: true, message: 'Please upload a photo' }]}
                        >
                            <div className="r-center">
                                <UploadImg
                                    isRadius={true}
                                    imgUrl={state.profilePhoto}
                                    setPicInfo={(val: any) => {
                                        setState(prev => ({
                                            ...prev,
                                            profilePhoto: val.picUrl,
                                            picUrlShow: val.picUrlShow,
                                        }));
                                        form.setFieldsValue({ profilePhoto: val.picUrl });
                                    }}
                                />
                            </div>
                        </Form.Item>
                        <Form.Item
                            label={<div>First Name</div>}
                            name="firstName"
                            rules={[{ required: true, message: 'Please enter a first name' }]}
                        >
                            <Input
                                className="r-input"
                                value={state.firstName}
                                maxLength={20}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const str = e.target.value;
                                    const val = str.charAt(0).toUpperCase() + str.slice(1);
                                    // setState({ ...state, firstName: val });
                                    setState(prev => ({
                                        ...prev,
                                        firstName: val,
                                    }));
                                    form.setFieldsValue({ firstName: val });
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Middle Name (Optional)"
                            name="middleName"
                            rules={[{ required: false, message: ' ' }]}
                        >
                            <Input
                                className="r-input"
                                maxLength={20}
                                value={state.middleName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const str = e.target.value;
                                    const val = str.charAt(0).toUpperCase() + str.slice(1);
                                    setState({ ...state, middleName: val });
                                    form.setFieldsValue({ middleName: val });
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Last Name"
                            name="lastName"
                            rules={[{ required: true, message: 'Please enter a last name' }]}
                        >
                            <Input
                                className="r-input"
                                maxLength={20}
                                value={state.lastName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const str = e.target.value;
                                    const val = str.charAt(0).toUpperCase() + str.slice(1);
                                    setState({ ...state, lastName: val });
                                    form.setFieldsValue({ lastName: val });
                                }}
                            />
                        </Form.Item>
                    </>
                ) : title === 'Edit Position' ? (
                    <>
                        <Form.Item
                            label="Position"
                            name="position"
                            rules={[{ required: true, message: 'Please enter the position' }]}
                        >
                            <Input
                                className="r-input"
                                maxLength={50}
                                value={state.position}
                                onInput={(e: any) => setState({ ...state, position: e.target.value })}
                            />
                        </Form.Item>
                    </>
                ) : (
                    <>
                        <Form.Item
                            label="Business Name"
                            name="business"
                            rules={[{ required: true, message: 'Please enter the business name' }]}
                        >
                            <Input
                                className="r-input"
                                maxLength={50}
                                value={state.business}
                                onInput={(e: any) => setState({ ...state, business: e.target.value })}
                            />
                        </Form.Item>
                    </>
                )}
                <Form.Item style={{ margin: '30px 0 0 0 ' }}>
                    <div className="r-center">
                        <Button
                            className="r-primary_btn teamSubmit_btn"
                            style={{ width: 83, borderRadius: 12 }}
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            disabled={!state.profilePhoto && title === 'Edit'}
                        >
                            Save
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default EditAccount;
