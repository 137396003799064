import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import './Chat.scss';
import { Avatar, Input, Spin } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSendBird } from '@/context/SendBirdProvider';
function MessageChat() {
    const location = useLocation();
    function getQueryParam(param: string, location): string | null {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get(param);
    }
    const type = getQueryParam('type', location);
    const {
        listLoading,
        state,
        handleCreateChannel,
        sendMessage,
        handleSelectChannel,
        channelLoading,
        refrshChannel,
        sortedChannelsArr,
    } = useSendBird();
    // 当前聊天对象
    const { nowChartUser } = useSelector((state: any) => state.state);
    const { profileInfo } = useSelector((state: any) => state.state);
    // 当前用户id
    const chatUserId = profileInfo.accountId;
    // 当前选中频道
    const [activeChanel, setActiveChanel] = useState<any>({});
    const [activeShowInfo, setActiveShowInfo] = useState<any>({
        nickname: '',
        plainProfileUrl: '',
        data: {} as any,
    });
    const messageBox: MutableRefObject<any> = useRef(null);
    const isLoadHistory = useRef(false);
    const getMessage = members => {
        const info = members.find(v => v.userId !== profileInfo.accountId);
        if (info) {
            info.data = info.data && typeof info.data === 'string' ? JSON.parse(info.data) : {};
            setActiveShowInfo(info);
        }
    };
    useEffect(() => {
        if (nowChartUser && nowChartUser.accountId && type === '1') {
            //创建频道
            createChan();
        } else {
            refrshChannel();
        }
        return () => {
            handleSelectChannel('');
        };
    }, []);
    useEffect(() => {
        const bottomElement = document.getElementById('bottom-element');
        if (bottomElement) {
            bottomElement.scrollIntoView({
                behavior: 'smooth', // 平滑滚动
                block: 'end', // 滚动到元素底部
            });
        }
    }, [state.messages]);
    useEffect(() => {
        if (state.currentlyJoinedChannel) {
            setActiveChanel(state.currentlyJoinedChannel);
            getMessage(state.currentlyJoinedChannel.members);
            scrollToBottom();
        }
    }, [state.currentlyJoinedChannel]);
    const scrollToBottom = () => {
        if (!isLoadHistory.current) {
            messageBox.current?.scrollIntoView({
                behavior: 'smooth', // 使滚动平滑过渡
                block: 'end', // 滚动到容器的底部
            });
        }
    };
    // 创建频道
    const createChan = () => {
        const groupChannelParams = {} as any;
        groupChannelParams.invitedUserIds = [chatUserId, nowChartUser.accountId];
        groupChannelParams.operatorUserIds = [chatUserId, nowChartUser.accountId];
        groupChannelParams.data = JSON.stringify({
            nowChartUser: {
                namelabel: nowChartUser.namelabel,
                profilePhoto: nowChartUser.profilePhoto,
                jobRole: nowChartUser.jobRole,
                accountId: nowChartUser.accountId,
                jobShow: nowChartUser.jobShow || '',
                companyShow: nowChartUser?.companyShow || '',
                companyLogo: nowChartUser?.companyLogo || '',
            },
            adminInfo: {
                accountId: profileInfo.accountId,
                avatarUrl: profileInfo.avatarUrl,
                position: profileInfo.position,
                namelabel: profileInfo.namelabel,
            },
        });
        groupChannelParams.isDistinct = true;
        console.log(groupChannelParams);
        handleCreateChannel(groupChannelParams);
        refrshChannel();
    };
    const [messageValue, setMessageValue] = useState('');
    const getTopDate = (time: number, index: number) => {
        const previousDate = index ? dayjs(state.messages[index - 1].createdAt).format('YYYY-MM-DD') : null;
        const currentDate = dayjs(time).format('YYYY-MM-DD');
        const today = dayjs().format('YYYY-MM-DD');
        const yesterday = dayjs().add(-1, 'day').format('YYYY-MM-DD');
        if (previousDate !== currentDate || !index) {
            if (currentDate !== today) {
                if (currentDate !== yesterday) {
                    return dayjs(time).format('DD MMM YYYY, h:mm a');
                } else {
                    return dayjs(time).format('DD MMM YYYY, h:mm a');
                }
            } else {
                return dayjs(time).format('h:mm a');
            }
        }
    };
    return (
        <div className="chat-mainBox">
            <div className="chat-tit">Messaging</div>
            {!channelLoading ? (
                <div className="r-top chat-box">
                    <div className="chanel-list">
                        <div className="chanel-tit">Contact List</div>
                        {sortedChannelsArr.length ? (
                            <>
                                {sortedChannelsArr.map((val: any, i) => {
                                    const info = val.members.find(v => v.userId !== profileInfo.accountId);
                                    let data = { nowChartUser: { jobRole: '' } };
                                    if (val.data) {
                                        data = JSON.parse(val.data);
                                    }
                                    return (
                                        <div
                                            key={`chanel-card-${i}`}
                                            className={`chanel-card r-left ${
                                                activeChanel?._url === val._url ? 'active' : ''
                                            }`}
                                            onClick={() => {
                                                setMessageValue('');
                                                handleSelectChannel(val);
                                            }}
                                        >
                                            <Avatar
                                                size={32}
                                                style={{ minWidth: 32 }}
                                                className="contact-img"
                                                src={info?.plainProfileUrl || ''}
                                                icon={<UserOutlined />}
                                            />
                                            <div>
                                                <p className="contact-name oneLineText">{info?.nickname || '-'}</p>
                                                {/* <p className="contact-job oneLineText">
                                                    {data.nowChartUser.jobRole || '-'}
                                                </p> */}
                                            </div>
                                            {val.unreadMessageCount ? (
                                                <i className="unRead-box">{val.unreadMessageCount || 0}</i>
                                            ) : null}
                                        </div>
                                    );
                                })}
                            </>
                        ) : (
                            <div className="empty-box r-center">You don't have a contact yet</div>
                        )}
                    </div>
                    <div className="message-box">
                        <div className="job-topBox">
                            {!activeChanel._url ? (
                                <></>
                            ) : (
                                <>
                                    <div className="r-center flex1" style={{ marginBottom: 10 }}>
                                        <Avatar
                                            size={48}
                                            className="contact-img"
                                            src={activeShowInfo?.plainProfileUrl}
                                            icon={<UserOutlined />}
                                        />
                                        <p className="top-name">{activeShowInfo?.nickname || '-'}</p>
                                    </div>
                                    {/* {activeChanel.cachedMetaData?.nowChartUser ? (
                                        <div className="r-center flex1">
                                            <i
                                                className="jobChat-icon"
                                                style={{
                                                    background: `url(${
                                                        activeChanel.cachedMetaData?.nowChartUser.companyLogo || ''
                                                    })`,
                                                    backgroundSize: 'cover',
                                                }}
                                            ></i>
                                            <p className="topJOb-tip">
                                                {activeChanel.cachedMetaData?.nowChartUser.companyShow || ''}
                                            </p>
                                            <i className="row-lineBox"></i>
                                            <p className="topJOb-tip oneLineText" style={{ maxWidth: 200 }}>
                                                {activeChanel.cachedMetaData?.nowChartUser.jobShow || ''}
                                            </p>
                                        </div>
                                    ) : null} */}
                                </>
                            )}
                        </div>
                        <div className="message-listBox">
                            <div className="message-list" ref={messageBox}>
                                {listLoading ? (
                                    <>
                                        <div className="empty-box message-empty r-center">
                                            <Spin style={{ position: 'static' }} />
                                        </div>
                                    </>
                                ) : state.messages.length ? (
                                    <>
                                        {state.messages.map((m, i) => {
                                            return (
                                                <div key={`message-textBox-${i}`}>
                                                    {getTopDate(m.createdAt, i) ? (
                                                        <p className="msg-time r-center flex1">
                                                            {getTopDate(m.createdAt, i)}
                                                        </p>
                                                    ) : null}
                                                    {/* <p className="msg-time r-center flex1">{getTopDate(m.createdAt, i)}</p> */}
                                                    {m.sender.userId !== profileInfo.accountId ? (
                                                        // 接受者
                                                        <div className="msg-card r-top">
                                                            <Avatar
                                                                size={48}
                                                                style={{ minWidth: 48 }}
                                                                className="contact-img"
                                                                src={m.sender.plainProfileUrl}
                                                                icon={<UserOutlined />}
                                                            />
                                                            <div className="message-text">
                                                                {' '}
                                                                {RegExp(/\n/).test(m.message)
                                                                    ? m.message.split('\n').map((t, index) => {
                                                                          return (
                                                                              <div key={'message' + index} style={{}}>
                                                                                  {t}
                                                                              </div>
                                                                          );
                                                                      })
                                                                    : m.message}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        // 发送者
                                                        <div className="msg-card r-end">
                                                            {m.sendingStatus === 'pending' ? (
                                                                <i className="send-loadingIcon rotate-element"></i>
                                                            ) : m.sendingStatus === 'fail' ? (
                                                                <i className="send-failIcon"></i>
                                                            ) : (
                                                                ''
                                                            )}
                                                            <div className="message-text myMessage-text">
                                                                {RegExp(/\n/).test(m.message)
                                                                    ? m.message.split('\n').map((t, index) => {
                                                                          return (
                                                                              <div
                                                                                  key={'message' + index}
                                                                                  style={{ minHeight: '0.21rem' }}
                                                                              >
                                                                                  {t}
                                                                              </div>
                                                                          );
                                                                      })
                                                                    : m.message}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                        <div id="bottom-element"></div>
                                    </>
                                ) : (
                                    <div className="empty-box message-empty r-center">You don't have a contact yet</div>
                                )}
                            </div>
                            <div className={`r-left msg-inp ${!activeChanel._url ? 'disabled-inp' : ''}`}>
                                <Input
                                    className={`r-input message-inp`}
                                    maxLength={500}
                                    type="text"
                                    autoComplete=""
                                    placeholder=""
                                    onInput={(e: any) => {
                                        setMessageValue(e.target.value);
                                    }}
                                    onFocus={() => {
                                        state.currentlyJoinedChannel && state.currentlyJoinedChannel.markAsRead();
                                    }}
                                    value={messageValue}
                                />
                                <i
                                    className="send-icon"
                                    style={{ opacity: !messageValue ? '0.5' : '1' }}
                                    onClick={() => {
                                        if (!messageValue) return;
                                        sendMessage(messageValue);
                                        setMessageValue('');
                                    }}
                                ></i>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="r-center">
                    <Spin></Spin>
                </div>
            )}
        </div>
    );
}

export default MessageChat;
