import React, { useEffect, useState } from 'react';
import './style/index.scss';
import { Button, DatePicker, Progress, Select, Spin } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import useChart from './hooks/useChart';
import { DownOutlined } from '@ant-design/icons';
import FileDownIcon from '@/assets/img/file-download.png';
import CalendarIcon from '@/assets/img/calendar.png';
import BarChartIcon from '@/assets/img/bar-chart.png';
import RowTwoIcon from '@/assets/img/row-two.png';
import UserOneIcon from '@/assets/img/user-1.png';
import UserTWoIcon from '@/assets/img/user-2.png';
import WorkTimeIcon from '@/assets/img/work-time.png';
import teamApi from '@/api/team';
import api from '@/api/home';
import { useSelector } from 'react-redux';
import { roleList } from '@/config/role';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import { defaultInfo } from '@/typings/allType';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > dayjs().endOf('day');
};
const { RangePicker } = DatePicker;
function Insights() {
    const { initBarChart } = useChart();
    const messageApi = useGlobalMessage();
    const changeTime = val => {
        if (val && val !== null) {
            const beginTime = +dayjs(val[0]).valueOf().toString() / 1000;
            const endTime = Math.floor(dayjs(val[1]).endOf('day').valueOf() / 1000).toString();
            setState({ ...state, beginTime, endTime, time: val });
            getInsight({ ...state, beginTime, endTime });
            return;
        }
        setState({ ...state, endTime: '', beginTime: '', time: null });
        getInsight({ ...state, endTime: '9999999999', beginTime: '0' });
    };
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        team: '',
        beginTime: '0' as any,
        endTime: '9999999999' as any,
        time: null as any,
    });
    const [exportLoading, setExportLoading] = useState(false);
    const htmlToPdf = () => {
        const element: any = document.getElementById('report-main');
        if (!element || exportLoading) {
            return;
        }
        setExportLoading(true);
        try {
            html2canvas(element, {
                useCORS: true,
                allowTaint: false,
                logging: false,
                scale: 2,
            }).then(canvas => {
                const pageData = canvas.toDataURL('image/jpeg', 1.0);
                const w = 595.28;
                const h = 592.28;
                const pdf = new jsPDF('p', 'pt', [w, (h / canvas.width) * canvas.height]);
                pdf.addImage(pageData, 'JPEG', 0, 0, w, (h / canvas.width) * canvas.height);
                setTimeout(() => {
                    const timeArr =
                        state.time && state.time.length
                            ? [dayjs(state.time[0]).format('YYYY.MM'), dayjs(state.time[1]).format('YYYY.MM')]
                            : '';
                    const timeStr = !timeArr
                        ? ''
                        : timeArr[0] === timeArr[1]
                        ? timeArr[0]
                        : `${timeArr[0]} - ${timeArr[1]}`;
                    pdf.save(`Talenaut Statistics${timeStr ? ` (${timeStr})` : ''}.pdf`);
                    setExportLoading(false);
                }, 1000);
            });
        } catch (error) {
            messageApi.error('Export failed, please try again.');
            setExportLoading(false);
        }
    };
    const [insightInfo, setinsIghtInfo] = useState({
        candidateRate: 0,
        jobsPosted: 0,
        upInterviews: 0,
        hireDurationPOJOListAverage: 0,
        timeToHireByJobPOJOList: [] as any,
        timeToHireByManagerPOJOList: [] as any,
        timeToHireByStagePOJO: {
            inInterviewDays: '',
            preScreeningDays: '',
            sourcingDays: '',
        },
        businessAccountPOJOList: [] as any,
        gandStagesPOJO: {
            beginTime: 0,
            businessId: '',
            endTime: 0,
            inInterview: 0,
            offered: 0,
            preScreening: 0,
            sourcing: 0,
        },
    });
    const [chooseAcId, setChooseAcId] = useState('');
    const [chooseAc, setChooseAc] = useState({
        businessAccountId: '',
        candidatesSourced: 0,
        firstName: '',
        interviewsConducted: 0,
        lastName: '',
        middleName: '',
        offersEextended: 0,
    });
    const getInsight = (val: defaultInfo) => {
        setLoading(true);
        const pat = {
            beginTime: val.beginTime,
            endTime: val.endTime,
        };
        api.getHomeDataChart(pat, res => {
            setLoading(false);
            if (res.code === 200) {
                if (res.data) {
                    if (res.data.businessAccountPOJOList && res.data.businessAccountPOJOList.length) {
                        res.data.businessAccountPOJOList.forEach(item => {
                            item.label = `${item.firstName} ${item.middleName || ''} ${item.lastName}`;
                            item.value = item.businessAccountId;
                        });
                        setChooseAc(res.data.businessAccountPOJOList[0]);
                        setChooseAcId(res.data.businessAccountPOJOList[0].businessAccountId);
                    } else {
                        setChooseAc({
                            businessAccountId: '',
                            candidatesSourced: 0,
                            firstName: '',
                            interviewsConducted: 0,
                            lastName: '',
                            middleName: '',
                            offersEextended: 0,
                        });
                        setChooseAcId('');
                    }
                    setinsIghtInfo({ ...res.data });
                    const xData: any = [];
                    const yData: any = [];
                    if (res.data.hireDurationPOJOList && res.data.hireDurationPOJOList.length) {
                        res.data.hireDurationPOJOList.forEach(item => {
                            xData.push(dayjs(item.month).format("MMM' YY"));
                            yData.push(item.days ? item.days.toFixed(1) : 0);
                        });
                    }
                    const preDay = res.data.hireDurationPOJOListAverage || 0;
                    chartIn(xData, yData, preDay);
                }
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const chartIn = (xData, yData, preDay) => {
        setTimeout(() => {
            initBarChart('barChartBox', xData, yData, preDay);
            initBarChart('barChartBox1', xData, yData, preDay);
        }, 500);
    };
    useEffect(() => {
        getInsight({ ...state });
    }, []);
    return (
        <div className="r-pageContent r-insightPage">
            <div className="r-left top-filterIns">
                <h2>Insights</h2>
                <div className="marLeft r-left">
                    <RangePicker
                        className={`r-datePicker r-date_showLine r-dateMonth`}
                        style={{ width: 320 }}
                        format="MM/YYYY"
                        inputReadOnly
                        picker="month"
                        onChange={changeTime}
                        disabledDate={disabledDate}
                        value={state.time}
                        suffixIcon={<></>}
                        separator={<div></div>}
                        placeholder={['mm/yyyy', 'mm/yyyy']}
                    />
                    <Button
                        className="r-primary_btn job-btn"
                        style={{ width: 110, marginLeft: 15 }}
                        onClick={() => htmlToPdf()}
                        loading={exportLoading}
                        disabled={exportLoading}
                    >
                        Export
                        <DownOutlined style={{ fontSize: 12, fontWeight: 'bold' }} />
                    </Button>
                </div>
            </div>
            {loading ? (
                <div className="no-message-tabBox r-center">
                    <Spin style={{ position: 'static' }}></Spin>
                </div>
            ) : (
                <>
                    <div className="r-top" style={{ background: '#f1f4f8', paddingBottom: 20 }}>
                        <div className="leftCard">
                            <div className="r-left">
                                <div className="cardBox-ins leftNum-card">
                                    <div className="r-left insightCard-tit">
                                        JOBS POSTED
                                        <span className="insightCard-icon r-center marLeft">
                                            <img src={FileDownIcon} alt="" />
                                        </span>
                                    </div>
                                    <div className="valNum">{insightInfo.jobsPosted || 0}</div>
                                </div>
                                <div className="cardBox-ins leftNum-card mar20">
                                    <div className="r-left insightCard-tit">
                                        UP. INTERVIEWS
                                        <span className="insightCard-icon r-center marLeft">
                                            <img src={CalendarIcon} alt="" />
                                        </span>
                                    </div>
                                    <div className="valNum">{insightInfo.upInterviews || 0}</div>
                                </div>
                                <div className="cardBox-ins leftNum-card">
                                    <div className="r-left insightCard-tit">
                                        CANDIDATE RATE
                                        <span className="insightCard-icon r-center marLeft">
                                            <img src={BarChartIcon} alt="" />
                                        </span>
                                    </div>
                                    <div className="valNum">{insightInfo.candidateRate || 0}</div>
                                </div>
                            </div>
                            <div className="r-top" style={{ marginTop: 20 }}>
                                <div className="cardBox-ins leftNum-card" style={{ width: '100%' }}>
                                    <div className="r-left insightCard-tit">
                                        CAND. STAGES
                                        <span className="insightCard-icon r-center marLeft">
                                            <img src={RowTwoIcon} alt="" />
                                        </span>
                                    </div>
                                    <div className="r-left" style={{ marginTop: 10 }}>
                                        <div style={{ flex: 1 }}>
                                            <div className="titBox-m">Sourcing</div>
                                            <div className="valBox-m">{insightInfo.gandStagesPOJO.sourcing || 0}</div>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <div className="titBox-m">Pre-Screening</div>
                                            <div className="valBox-m">
                                                {insightInfo.gandStagesPOJO.preScreening || 0}
                                            </div>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <div className="titBox-m">In Interview</div>
                                            <div className="valBox-m">
                                                {insightInfo.gandStagesPOJO.inInterview || 0}
                                            </div>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <div className="titBox-m">Offered</div>
                                            <div className="valBox-m">{insightInfo.gandStagesPOJO.offered || 0}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cardBox-ins leftChart-card">
                                <div className="r-left insightCard-tit">
                                    HIRE DURATION
                                    <span className="days-box marLeft">
                                        {insightInfo.hireDurationPOJOListAverage || 0}{' '}
                                        {+insightInfo.hireDurationPOJOListAverage > 1 ? 'days' : 'day'}
                                    </span>
                                </div>
                                <div id="barChartBox"></div>
                            </div>
                        </div>
                        <div className="rightCard r-top">
                            <div>
                                <div className="cardBox-ins rightNum-card marb20">
                                    <div className="r-left insightCard-tit">
                                        RECRUITERS VALUES
                                        <span className="insightCard-icon r-center marLeft">
                                            <img src={UserOneIcon} alt="" />
                                        </span>
                                    </div>
                                    <div style={{ marginTop: 12 }}>
                                        <Select
                                            className="r-select r-sel_mini"
                                            suffixIcon={<i className="down-right-icon"></i>}
                                            options={insightInfo.businessAccountPOJOList}
                                            value={chooseAcId}
                                            disabled={insightInfo.businessAccountPOJOList.length <= 1}
                                            onChange={val => {
                                                const info = insightInfo.businessAccountPOJOList.find(
                                                    v => v.businessAccountId === val
                                                );
                                                setChooseAc(info);
                                                setChooseAcId(val);
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <div className="bar-chartItem">
                                            <div className="r-left">
                                                Candidates sourced
                                                <span className="marLeft">{chooseAc.candidatesSourced || 0}</span>
                                            </div>
                                            <Progress
                                                percent={chooseAc.candidatesSourced || 0}
                                                size={{ height: 4 }}
                                                strokeColor={'#9490F1'}
                                                showInfo={false}
                                            />
                                        </div>
                                        <div className="bar-chartItem">
                                            <div className="r-left">
                                                Interviews conducted
                                                <span className="marLeft">{chooseAc.interviewsConducted || 0}</span>
                                            </div>
                                            <Progress
                                                percent={chooseAc.interviewsConducted || 0}
                                                size={{ height: 4 }}
                                                strokeColor={'#9490F1'}
                                                showInfo={false}
                                            />
                                        </div>
                                        <div className="bar-chartItem">
                                            <div className="r-left">
                                                Offers extended
                                                <span className="marLeft">{chooseAc.offersEextended || 0}</span>
                                            </div>
                                            <Progress
                                                percent={chooseAc.offersEextended || 0}
                                                size={{ height: 4 }}
                                                strokeColor={'#9490F1'}
                                                showInfo={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="cardBox-ins rightNum-card">
                                    <div className="r-left insightCard-tit">
                                        HIRES BY HIRING MANAGER
                                        <span className="insightCard-icon r-center marLeft">
                                            <img src={UserTWoIcon} alt="" />
                                        </span>
                                    </div>
                                    {insightInfo.timeToHireByManagerPOJOList.length ? (
                                        <div>
                                            {insightInfo.timeToHireByManagerPOJOList.map((item, i) => {
                                                return (
                                                    <div
                                                        key={`timeToHireByManager-card-${i}`}
                                                        className="bar-chartItem"
                                                    >
                                                        <div className="r-left">
                                                            <div className="" style={{ marginRight: 10 }}>
                                                                {item.firstName} {item.middleName} {item.lastName}
                                                            </div>
                                                            <span
                                                                className="marLeft"
                                                                style={{ minWidth: 'max-content' }}
                                                            >
                                                                {item.sourcing || 0}
                                                            </span>
                                                        </div>
                                                        <Progress
                                                            percent={item.sourcing || 0}
                                                            size={{ height: 4 }}
                                                            strokeColor={'#9490F1'}
                                                            showInfo={false}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <div>-</div>
                                    )}
                                </div>
                            </div>
                            <div style={{ marginLeft: 20 }}>
                                <div className="cardBox-ins rightNum-card marb20">
                                    <div className="r-left insightCard-tit">
                                        TIME TO HIRE BY JOB
                                        <span className="insightCard-icon r-center marLeft">
                                            <img src={WorkTimeIcon} alt="" />
                                        </span>
                                    </div>
                                    {insightInfo.timeToHireByJobPOJOList.length ? (
                                        <div>
                                            {insightInfo.timeToHireByJobPOJOList.map((item, i) => {
                                                return (
                                                    <div className="bar-chartItem r-left" key={`timeByJOb-${i}`}>
                                                        <div className="" style={{ marginRight: 10 }}>
                                                            {item.jobTitle || '-'}
                                                        </div>
                                                        <span
                                                            className="marLeft days-box"
                                                            style={{ minWidth: 'max-content' }}
                                                        >
                                                            {item.days || 0} {item.days > 1 ? 'days' : 'day'}
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <div className="r-left">-</div>
                                    )}
                                </div>
                                <div className="cardBox-ins rightNum-card">
                                    <div className="r-left insightCard-tit">
                                        TIME TO HIRE BY STAGE
                                        <span className="insightCard-icon r-center marLeft">
                                            <img src={WorkTimeIcon} alt="" />
                                        </span>
                                    </div>
                                    <div className="bar-chartItem r-left">
                                        Sourcing
                                        <span className="marLeft days-box">
                                            {insightInfo.timeToHireByStagePOJO.sourcingDays || 0}{' '}
                                            {+insightInfo.timeToHireByStagePOJO.sourcingDays > 1 ? 'days' : 'day'}
                                        </span>
                                    </div>
                                    <div className="bar-chartItem r-left">
                                        Pre-Screening
                                        <span className="marLeft days-box">
                                            {insightInfo.timeToHireByStagePOJO.preScreeningDays || 0}{' '}
                                            {+insightInfo.timeToHireByStagePOJO.preScreeningDays > 1 ? 'days' : 'day'}
                                        </span>
                                    </div>
                                    <div className="bar-chartItem r-left">
                                        In Interview
                                        <span className="marLeft days-box">
                                            {insightInfo.timeToHireByStagePOJO.inInterviewDays || 0}{' '}
                                            {+insightInfo.timeToHireByStagePOJO.inInterviewDays > 1 ? 'days' : 'day'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 导出pdf */}
                    <div id="report-main" style={{ background: '#f1f4f8' }}>
                        <h2>Insights</h2>
                        <div className="leftCard" style={{ margin: '0 0 20px 0', width: '100%' }}>
                            <div className="r-left">
                                <div className="cardBox-ins leftNum-card">
                                    <div className="r-left insightCard-tit">
                                        JOBS POSTED
                                        <span className="insightCard-icon r-center marLeft">
                                            <img src={FileDownIcon} alt="" />
                                        </span>
                                    </div>
                                    <div className="valNum">{insightInfo.jobsPosted || 0}</div>
                                </div>
                                <div className="cardBox-ins leftNum-card mar20">
                                    <div className="r-left insightCard-tit">
                                        UP. INTERVIEWS
                                        <span className="insightCard-icon r-center marLeft">
                                            <img src={CalendarIcon} alt="" />
                                        </span>
                                    </div>
                                    <div className="valNum">{insightInfo.upInterviews || 0}</div>
                                </div>
                                <div className="cardBox-ins leftNum-card">
                                    <div className="r-left insightCard-tit">
                                        CANDIDATE RATE
                                        <span className="insightCard-icon r-center marLeft">
                                            <img src={BarChartIcon} alt="" />
                                        </span>
                                    </div>
                                    <div className="valNum">{insightInfo.candidateRate || 0}</div>
                                </div>
                            </div>
                            <div className="r-top" style={{ marginTop: 20 }}>
                                <div className="cardBox-ins leftNum-card" style={{ width: '100%' }}>
                                    <div className="r-left insightCard-tit">
                                        CAND. STAGES
                                        <span className="insightCard-icon r-center marLeft">
                                            <img src={RowTwoIcon} alt="" />
                                        </span>
                                    </div>
                                    <div className="r-left" style={{ marginTop: 10 }}>
                                        <div style={{ flex: 1 }}>
                                            <div className="titBox-m">Sourcing</div>
                                            <div className="valBox-m">{insightInfo.gandStagesPOJO.sourcing || 0}</div>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <div className="titBox-m">Pre-Screening</div>
                                            <div className="valBox-m">
                                                {insightInfo.gandStagesPOJO.preScreening || 0}
                                            </div>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <div className="titBox-m">In Interview</div>
                                            <div className="valBox-m">
                                                {insightInfo.gandStagesPOJO.inInterview || 0}
                                            </div>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <div className="titBox-m">Offered</div>
                                            <div className="valBox-m">{insightInfo.gandStagesPOJO.offered || 0}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cardBox-ins leftChart-card">
                                <div className="r-left insightCard-tit">
                                    HIRE DURATION
                                    <span className="days-box marLeft">
                                        {insightInfo.hireDurationPOJOListAverage || 0}{' '}
                                        {+insightInfo.hireDurationPOJOListAverage > 1 ? 'days' : 'day'}
                                    </span>
                                </div>
                                <div id="barChartBox1"></div>
                            </div>
                        </div>
                        <div className="rightCard r-top" style={{ width: '100%' }}>
                            <div style={{ flex: 1 }}>
                                <div className="cardBox-ins rightNum-card marb20" style={{ width: 290 }}>
                                    <div className="r-left insightCard-tit">
                                        RECRUITERS VALUES
                                        <span className="insightCard-icon r-center marLeft">
                                            <img src={UserOneIcon} alt="" />
                                        </span>
                                    </div>
                                    <div style={{ marginTop: 12 }}>
                                        <Select
                                            className="r-select r-sel_mini"
                                            suffixIcon={<i className="down-right-icon"></i>}
                                            options={insightInfo.businessAccountPOJOList}
                                            value={chooseAcId}
                                            disabled={insightInfo.businessAccountPOJOList.length === 1}
                                            onChange={val => {
                                                const info = insightInfo.businessAccountPOJOList.find(
                                                    v => v.businessAccountId === val
                                                );
                                                setChooseAc(info);
                                                setChooseAcId(val);
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <div className="bar-chartItem">
                                            <div className="r-left">
                                                Candidates sourced
                                                <span className="marLeft">{chooseAc.candidatesSourced || 0}</span>
                                            </div>
                                            <Progress
                                                percent={chooseAc.candidatesSourced || 0}
                                                size={{ height: 4 }}
                                                strokeColor={'#9490F1'}
                                                showInfo={false}
                                            />
                                        </div>
                                        <div className="bar-chartItem">
                                            <div className="r-left">
                                                Interviews conducted
                                                <span className="marLeft">{chooseAc.interviewsConducted || 0}</span>
                                            </div>
                                            <Progress
                                                percent={chooseAc.interviewsConducted || 0}
                                                size={{ height: 4 }}
                                                strokeColor={'#9490F1'}
                                                showInfo={false}
                                            />
                                        </div>
                                        <div className="bar-chartItem">
                                            <div className="r-left">
                                                Offers extended
                                                <span className="marLeft">{chooseAc.offersEextended || 0}</span>
                                            </div>
                                            <Progress
                                                percent={chooseAc.offersEextended || 0}
                                                size={{ height: 4 }}
                                                strokeColor={'#9490F1'}
                                                showInfo={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="cardBox-ins rightNum-card" style={{ width: 290 }}>
                                    <div className="r-left insightCard-tit">
                                        HIRES BY HIRING MANAGER
                                        <span className="insightCard-icon r-center marLeft">
                                            <img src={UserTWoIcon} alt="" />
                                        </span>
                                    </div>
                                    {insightInfo.timeToHireByManagerPOJOList.length ? (
                                        <div>
                                            {insightInfo.timeToHireByManagerPOJOList.map((item, i) => {
                                                return (
                                                    <div
                                                        key={`timeToHireByManager-card-${i}`}
                                                        className="bar-chartItem"
                                                    >
                                                        <div className="r-left">
                                                            <div className="" style={{ marginRight: 10 }}>
                                                                {item.firstName} {item.middleName} {item.lastName}
                                                            </div>
                                                            <span
                                                                className="marLeft"
                                                                style={{ minWidth: 'max-content' }}
                                                            >
                                                                {item.sourcing || 0}
                                                            </span>
                                                        </div>
                                                        <Progress
                                                            percent={item.sourcing || 0}
                                                            size={{ height: 4 }}
                                                            strokeColor={'#9490F1'}
                                                            showInfo={false}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <div>-</div>
                                    )}
                                </div>
                            </div>
                            <div style={{ marginLeft: 20, flex: 1 }}>
                                <div className="cardBox-ins rightNum-card marb20" style={{ width: 300 }}>
                                    <div className="r-left insightCard-tit">
                                        TIME TO HIRE BY JOB
                                        <span className="insightCard-icon r-center marLeft">
                                            <img src={WorkTimeIcon} alt="" />
                                        </span>
                                    </div>
                                    {insightInfo.timeToHireByJobPOJOList.length ? (
                                        <div>
                                            {insightInfo.timeToHireByJobPOJOList.map((item, i) => {
                                                return (
                                                    <div className="bar-chartItem r-left" key={`timeByJOb-${i}`}>
                                                        <div className="" style={{ marginRight: 10 }}>
                                                            {item.jobTitle || '-'}
                                                        </div>
                                                        <span
                                                            className="marLeft days-box"
                                                            style={{ minWidth: 'max-content' }}
                                                        >
                                                            {item.days || 0} {item.days > 1 ? 'days' : 'day'}
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <div className="r-left">-</div>
                                    )}
                                </div>
                                <div className="cardBox-ins rightNum-card" style={{ width: 300 }}>
                                    <div className="r-left insightCard-tit">
                                        TIME TO HIRE BY STAGE
                                        <span className="insightCard-icon r-center marLeft">
                                            <img src={WorkTimeIcon} alt="" />
                                        </span>
                                    </div>
                                    <div className="bar-chartItem r-left">
                                        Sourcing
                                        <span className="marLeft days-box">
                                            {insightInfo.timeToHireByStagePOJO.sourcingDays || 0}{' '}
                                            {+insightInfo.timeToHireByStagePOJO.sourcingDays > 1 ? 'days' : 'day'}
                                        </span>
                                    </div>
                                    <div className="bar-chartItem r-left">
                                        Pre-Screening
                                        <span className="marLeft days-box">
                                            {insightInfo.timeToHireByStagePOJO.preScreeningDays || 0}{' '}
                                            {+insightInfo.timeToHireByStagePOJO.preScreeningDays > 1 ? 'days' : 'day'}
                                        </span>
                                    </div>
                                    <div className="bar-chartItem r-left">
                                        In Interview
                                        <span className="marLeft days-box">
                                            {insightInfo.timeToHireByStagePOJO.inInterviewDays || 0}{' '}
                                            {+insightInfo.timeToHireByStagePOJO.inInterviewDays > 1 ? 'days' : 'day'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Insights;
