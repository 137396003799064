import axios from '../utils/index';
import { Pat } from '@/typings/request';
const loginApi = {
    getPublicKey(callback: (T: any) => void) {
        axios
            .get(`/v1/public/rsa/get-publicKey`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    async getSendBirdKey() {
        return axios.get(`/v1/public/sendbird/get-sendbird`);
    },
    getGmtList(callback: (T: any) => void) {
        axios
            .get(`/v1/public/gmt/gmt-list`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    login(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/business-login`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    logOut(callback: (T: any) => void) {
        axios
            .post(`/v1/business/portal-logout`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },

    sendEmailCode(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/business-forgotPassword-send-email`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    verifyEmailCode(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/business-forgotPassword-check`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    resetPassword(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/business-password-reset`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    signUp(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/business-signUp`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    signUpSendCode(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/business-signUp-send-email`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    signUpCheckCode(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/business-signUp-email-checkCode`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getBusinessInfo(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/profile`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    editBusinessInfo(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/business/profile-fullName-edit`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    editBusinessNameInfo(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/business/profile-businessName-edit`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    editBusinessPosition(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/business/profile-edit-position`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    // link calendar
    connectCalendarGoogle(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/business-calendarGoogle-connect`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    disconnectCalendarGoogle(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/business/business-calendarGoogle-disconnect`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    connectCalendarOutlook(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/business-calendarOutlook-connect`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    // notication
    disconnectCalendarOutlook(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/business/business-calendarOutlook-disconnect`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getUnReadCount(callback: (T: any) => void) {
        axios
            .get(`/v1/business/notification/notification-unReadCount`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    readAllCount(callback: (T: any) => void) {
        axios
            .put(`/v1/business/notification/notification-readAll`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    delNoticaion(data: Pat, callback: (T: any) => void) {
        axios
            .delete(`/v1/business/notification/notification-delete/${data.id}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getNoticationList(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/notification/notification-list/0/9999999999/${data.pageNo}/${data.pageSize}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    // search
    getSearchList(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/dashboard/dashboard-search`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};

export default loginApi;
