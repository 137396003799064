import React, { useCallback, useEffect, useState } from 'react';
import './index.scss';
import { Button, Input, Image, Select, Spin } from 'antd';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import FormModal from '@/components/modal/form-modal';
import LinkedInIcon from '@/assets/img/LinkedIn_icon.png';
import TalenautIcon from '@/assets/img/logo-mini.png';
import GoogleIcon from '@/assets/img/Google.png';
import CandidateFilter from '../home/components/candidate-filter';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import api from '@/api/talentDatsbase';
import { setNowChartUser } from '@/store/stateSlice';
import { useAppDispatch } from '@/store/hooks';
import useInfiniteScroll from '@/hook/useInfiniteScroll';
const TalentDatabase = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const sendMessage = val => {
        dispatch(setNowChartUser({ ...val }));
        navigate('/message-chat?type=1');
    };
    const messageApi = useGlobalMessage();
    const [loading, setLoading] = useState(false);
    // list get
    const loadMoreItems = () => {
        if (loading || state.pageNo >= total) return;
        setLoading(true);
        setState({ ...state, pageNo: state.pageNo + 1 });
        getList({ ...filterInfo, ...state, pageNo: state.pageNo + 1 });
    };
    const loaderRef = useInfiniteScroll(loadMoreItems, loading);
    const [total, setTotal] = useState(0);
    const [userList, setUserList] = useState<any>([]);
    const [state, setState] = useState({
        search: '',
        pageNo: 1,
    });
    const [filterInfo, setFilterInfo] = useState({
        gmtId: '',
        keyWord: '',
        highestDegree: [],
        location: [],
        remoteFlag: 0,
        hardSkills: [],
        softSkills: [],
        sourceFrom: [],
        yearsOfExperience: [],
    });
    const searchList: any = useCallback(
        debounce(params => {
            getList({ ...params });
        }, 1000),
        []
    );
    const [listLoading, setListLoading] = useState(false);
    const getList = info => {
        setListLoading(true);
        const pat: any = {
            keyWord: info.search || '',
            pageNo: info.pageNo,
            pageSize: 10,
        };
        pat.highestDegree = info.highestDegree;
        pat.location = info.location;
        pat.remoteFlag = info.remoteFlag;
        pat.hardSkills = info.hardSkills;
        pat.softSkills = info.softSkills;
        pat.sourceFrom = info.sourceFrom;
        pat.yearsOfExperience = info.yearsOfExperience;
        api.getTalentDatabaseList(pat, res => {
            setListLoading(false);
            if (res.code === 200) {
                setTotal(res.data.totalPage);
                if (res.data.list) {
                    setUserList(prevItems => [
                        ...prevItems,
                        ...res.data.list.map(item => {
                            return Object.assign(item, {
                                isChecked: false,
                                namelabel: `${item.firstName || '-'} ${item.middleName || ''} ${item.lastName || ''}`,
                                skillShow:
                                    item.accountSkillPOJOList && item.accountSkillPOJOList.length < 2
                                        ? item.accountSkillPOJOList.map(v => v.skillName).join(', ')
                                        : item.accountSkillPOJOList[0].skillName,
                            });
                        }),
                    ]);
                    setLoading(false);
                } else {
                    setUserList([]);
                }
            } else {
                messageApi.error(res.message);
            }
        });
    };
    // add to pool
    const [oploading, setOpLoading] = useState(false);
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [poolList, setPoolList] = useState<any>([]);
    const [groupList, setGroupList] = useState<any>([]);
    const [poolSel, setPoolSel] = useState('');
    const [groupSel, setGroupSel] = useState('');
    const [userSel, setUserSel] = useState('');
    const getPoolList = () => {
        const pat = {
            beginTime: '',
            endTime: '',
            gmtId: '',
            location: '',
            poolStatus: '',
        };
        api.getTalentPoolList(pat, res => {
            setLoading(false);
            if (res.code === 200) {
                if (res.data && res.data.length) {
                    const arr = res.data.map(item => {
                        return Object.assign(item, {
                            label: item.poolName,
                            value: item.poolId,
                            groupPOJOList: item.groupPOJOList
                                ? item.groupPOJOList.map(val => {
                                      return Object.assign(val, {
                                          label: val.jobTitle,
                                          value: val.groupId,
                                      });
                                  })
                                : [],
                        });
                    });
                    console.log(arr);
                    setPoolList([...arr]);
                }
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const addUserToPool = () => {
        setOpLoading(true);
        api.addUserToPoolList({ accountId: userSel, groupId: groupSel, poolId: poolSel }, res => {
            setLoading(false);
            if (res.code === 200) {
                setIsFormModalOpen(false);
                setOpLoading(false);
                setPoolSel('');
                setGroupSel('');
                setUserList([]);
                getList({ ...filterInfo, ...state, pageNo: 1 });
            } else {
                messageApi.error(res.message);
            }
        });
    };
    useEffect(() => {
        getPoolList();
        getList({ ...filterInfo, ...state });
    }, []);
    return (
        <div className="r-pageContent TalentDatabase-box">
            <div className="r-left">
                <h2>Talent Database</h2>
                <Button
                    className="r-primary_btn r-medium40_btn marLeft"
                    onClick={() => navigate('/talent-database-info')}
                    style={{ width: 183 }}
                >
                    + Add New Candidate
                </Button>
            </div>
            <div className="r-left candidate-list-database">
                <div className="filter-box">
                    <CandidateFilter
                        pageNow="pool"
                        getFilterList={val => {
                            setUserList([]);
                            setFilterInfo(val);
                            getList({ ...val, ...state, pageNo: 1, filter: true });
                        }}
                    />
                </div>
                <div className="r-jobUserCard-info">
                    <div className="searchCandidate-box r-left">
                        <Input
                            placeholder=""
                            style={{ width: 380 }}
                            allowClear
                            value={state.search}
                            onChange={e => {
                                setUserList([]);
                                setListLoading(true);
                                searchList({ ...filterInfo, ...state, search: e.target.value, pageNo: 1 });
                                setState({ ...state, search: e.target.value, pageNo: 1 });
                            }}
                            className="r-input r-input_serach"
                            prefix={<span className="search_icon"></span>}
                        />
                    </div>
                    {listLoading ? (
                        <div className="no-message-tabBox r-center">
                            <Spin style={{ position: 'static' }}></Spin>
                        </div>
                    ) : userList.length ? (
                        <>
                            {userList.map((val, i) => {
                                return (
                                    <div key={`r-jobUserCard_d_${i}`} className="r-jobUserCard">
                                        <div>
                                            <div className="jobuserInfo-box r-left">
                                                <Image
                                                    width={42}
                                                    height={42}
                                                    preview={false}
                                                    style={{ borderRadius: '50%', minWidth: 42 }}
                                                    src={val.profilePhoto ? val.profilePhoto : ''}
                                                    fallback={require('@/assets/img/default-min.png')}
                                                />
                                                <div style={{ marginLeft: 12 }}>
                                                    <div className="jobCard-titM r-lfet">{val.jobRole || '-'}</div>
                                                    <p className="jobCard-userNameM">{val.namelabel || '-'}</p>
                                                </div>
                                                <div className="fromTip-box r-center marLeft">
                                                    <img
                                                        src={
                                                            val.sourceFrom === 'LinkedIn'
                                                                ? LinkedInIcon
                                                                : val.sourceFrom === 'Talenaut'
                                                                ? TalenautIcon
                                                                : GoogleIcon
                                                        }
                                                        className="from-imgIcon"
                                                        alt=""
                                                    />
                                                    {val.sourceFrom || 'Talenaut'}
                                                </div>
                                            </div>
                                            <i className="card-lineBox"></i>
                                            <div className="r-left usercardJob-infoBtm">
                                                <div className="info-tit" style={{ width: 'auto' }}>
                                                    Location
                                                    <div className="info-msg">
                                                        {val.remoteFlag ? val.gmt : val.location || '-'}
                                                    </div>
                                                </div>
                                                <div className="info-tit">
                                                    Top Skills
                                                    <div className="info-msg">
                                                        {val.skillShow}
                                                        {val.accountSkillPOJOList.length > 2 ? (
                                                            <span className="primary-color skillNum">
                                                                , +{val.accountSkillPOJOList.length - 1} skills
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="info-tit">
                                                    Experience
                                                    <div className="info-msg">
                                                        {val.yearsOfExperience || '1'}{' '}
                                                        {val.yearsOfExperience > 1 ? 'years' : 'year'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <i className="card-lineBox"></i>
                                        <div className="jobUser-opeation r-end">
                                            {val.inTalentPoolFlag ? (
                                                <Button
                                                    className="r-primary_btn r-primaryDeafult_btn btnMar15"
                                                    style={{ width: 178, cursor: 'initial' }}
                                                >
                                                    Added to Talent Pool
                                                </Button>
                                            ) : (
                                                <Button
                                                    className="r-primary_btn btnMar15"
                                                    style={{ width: 185 }}
                                                    onClick={() => {
                                                        setUserSel(val.accountId);
                                                        setIsFormModalOpen(true);
                                                    }}
                                                >
                                                    Add to Talent Pool
                                                    <i className="addToPool-icon"></i>
                                                </Button>
                                            )}
                                            {val.talenautFlag ? (
                                                <Button
                                                    className="r-primary_btn r-primaryNoBg_btn card-messageBtn btnMar15"
                                                    onClick={() => sendMessage(val)}
                                                >
                                                    Message
                                                    <i className="chart_icon"></i>
                                                </Button>
                                            ) : null}
                                            <Button
                                                className="r-primary_btn r-primaryNoBg_btn card-messageBtn btnMar15"
                                                onClick={() => {
                                                    window.sessionStorage.setItem(
                                                        'candidateInfo',
                                                        JSON.stringify({ ...val, from: '/talent-database' })
                                                    );
                                                    navigate('/candidate-info?from=database');
                                                }}
                                            >
                                                View Profile
                                            </Button>
                                        </div>
                                    </div>
                                );
                            })}
                            {loading && (
                                <div className="r-center">
                                    <Spin></Spin>
                                </div>
                            )}
                            <div ref={loaderRef} style={{ height: '20px' }} />
                        </>
                    ) : (
                        <div className="no-message-tabBox r-center">No yet</div>
                    )}
                </div>
            </div>

            <FormModal
                title="Add to Talent Pool"
                width={380}
                isModalOpen={isFormModalOpen}
                handleCancel={() => {
                    setPoolSel('');
                    setGroupSel('');
                    setIsFormModalOpen(false);
                }}
                children={
                    <div>
                        <p className="form-tip">Talent Pool</p>
                        <Select
                            className="r-select"
                            suffixIcon={<i className="down-right-icon"></i>}
                            value={poolSel}
                            onChange={val => {
                                setPoolSel(val);
                                const info = poolList.find(v => v.poolId === val);
                                setGroupList(info.groupPOJOList || []);
                            }}
                            options={poolList}
                        />
                        <p className="form-tip" style={{ marginTop: 20 }}>
                            Job Group
                        </p>
                        <Select
                            className="r-select"
                            suffixIcon={<i className="down-right-icon"></i>}
                            options={groupList}
                            value={groupSel}
                            onChange={setGroupSel}
                            disabled={!poolSel}
                        />
                        <div className="r-center">
                            <Button
                                style={{ width: 106, marginTop: 40 }}
                                className="r-primary_btn r-primary_largeBtn"
                                loading={oploading}
                                disabled={!poolSel || !groupSel}
                                onClick={() => addUserToPool()}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                }
            />
        </div>
    );
};

export default TalentDatabase;
