import React from 'react';
import { Button, Modal } from 'antd';
function AlertModal({ isModalOpen, handleOk, handleCancel, message, cancelText, confirmText, title, loading }: any) {
    return (
        <Modal
            className="r-modal modal_no_padding"
            maskClosable={false}
            centered={true}
            closeIcon={null}
            footer={''}
            width={380}
            title={null}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            zIndex={1008}
            maskProps={{
                style: {
                    backgroundColor: 'rgba(30, 34, 45, 0.8)',
                    backdropFilter: 'blur(1px)',
                },
            }}
        >
            {title ? <p className="primary-modal-tit">{title}</p> : null}
            <p className="primary-modal-tip" style={{ marginBottom: 30 }}>
                {message}
            </p>
            <div className="r-center">
                {cancelText ? (
                    <Button
                        className="r-primaryNoBg7F_btn r-primary_largeBtn"
                        style={{ marginRight: 20 }}
                        onClick={() => handleCancel()}
                    >
                        {cancelText}
                    </Button>
                ) : null}
                <Button
                    className="r-primary_btn r-primary_largeBtn"
                    // style={{ flex: 1 }}
                    loading={loading}
                    onClick={() => handleOk()}
                >
                    {confirmText}
                </Button>
            </div>
        </Modal>
    );
}

export default AlertModal;
