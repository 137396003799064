import React, { useCallback, useEffect, useState } from 'react';
import './index.scss';
import { Button, Input, Image, Popover, Select, Spin } from 'antd';
import AlertModal from '@/components/modal/alert-modal';
import FormModal from '@/components/modal/form-modal';
import TeamMemberModal from '@/components/modal/team-member-modal';
import { debounce } from 'lodash';
import { defaultInfo } from '@/typings/allType';
import { useNavigate } from 'react-router-dom';
import api from '@/api/team';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import useLoading from '@/hook/useLoading';
import { useSelector } from 'react-redux';
import { roleList } from '@/config/role';
function TeamManagement() {
    const navigate = useNavigate();
    const messageApi = useGlobalMessage();
    const { profileInfo } = useSelector((state: any) => state.state);
    const [totalRecord, setTotalRecord] = useState(0);
    const [seatsNumber, setSeatsNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const { setLoadingStatus } = useLoading();
    const [state, setState] = useState({
        search: '',
        pageNo: 1,
    });
    const searchList: any = useCallback(
        debounce(params => {
            getList({ ...params });
        }, 1000),
        []
    );
    const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [opInfo, setOpInfo] = useState<any>({
        index: -1,
    });
    const [modalType, setModalType] = useState(1); // 1 达到最大席位 2 禁止访问 3 移除团队成员 4 把子管理员角色改为普通成员 5 把团队角色指定为管理 6 移交admin权限
    const alertModalTipList = [
        {
            type: 1,
            title: 'Your team has reached its maximum number of seats.',
            cancelText: 'Cancel',
            confirmText: 'Change subscription',
        },
        {
            type: 2,
            title: 'Are you sure you want to revoke access to this account? This account will not be able to log into Talenaut.',
            cancelText: 'Cancel',
            confirmText: 'Confirm',
        },
        {
            type: 3,
            title: 'Are you sure you want to remove this member from the team?',
            cancelText: 'Cancel',
            confirmText: 'Confirm',
        },
        {
            type: 4,
            title: 'Are you sure you want to revoke the administrator role of this member?',
            cancelText: 'Cancel',
            confirmText: 'Confirm',
        },
        {
            type: 5,
            title: 'Are you sure you want to assign the administrator role of this member?',
            cancelText: 'Cancel',
            confirmText: 'Confirm',
        },
        {
            type: 6,
            title: 'Are you sure you wish to save this changes? After the authority transfer, you need to log in again.',
            cancelText: 'Cancel',
            confirmText: 'Confirm',
        },
    ];
    const [modalText, setModalText] = useState(alertModalTipList[0]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOk = () => {
        // 确认操作
        if (modalType === 2) {
            api.disabledTeam({ accountId: opInfo.accountId }, res => {
                if (res.code === 200) {
                    getList({ ...state });
                } else {
                    messageApi.error(res.message);
                }
            });
        } else if (modalType === 3) {
            api.deleteBusinessTeam({ accountId: opInfo.accountId }, res => {
                if (res.code === 200) {
                    getList({ ...state });
                } else {
                    messageApi.error(res.message);
                }
            });
        } else if (modalType === 4) {
            api.delAdminRole({ accountId: opInfo.accountId }, res => {
                if (res.code === 200) {
                    getList({ ...state });
                } else {
                    messageApi.error(res.message);
                }
            });
        } else if (modalType === 5) {
            api.addAdminRole({ accountId: opInfo.accountId }, res => {
                if (res.code === 200) {
                    getList({ ...state });
                } else {
                    messageApi.error(res.message);
                }
            });
        } else if (modalType === 6) {
            console.log('====================================');
            console.log('移交');
            console.log('====================================');
        } else {
            navigate('/change-subscriptionPlan');
        }
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        console.log(modalType);
    };
    const opeationBtnClcik = (type: number, val?: defaultInfo, i?: number) => {
        if (type === 2 && val?.disabledTime) {
            api.undisabledTeam({ accountId: val.accountId }, res => {
                if (res.code === 200) {
                    getList({ ...state });
                } else {
                    messageApi.error(res.message);
                }
            });
            return;
        }
        setModalType(type);
        setModalText(alertModalTipList[type - 1]);
        setOpInfo({
            index: i,
            ...val,
        });
        setIsFormModalOpen(false);
        setChooseAd('');
        setIsModalOpen(true);
    };
    const [teamList, setTeamList] = useState<any>([]);
    const [teamAdList, setTeamAdList] = useState<any>([]);
    const [chooseAd, setChooseAd] = useState('');
    const [chooseAdInfo, setChooseAdInfo] = useState<any>({});
    const addTeamInfo = (pat: defaultInfo, i?: number) => {
        setIsTeamModalOpen(false);
        if (i) {
            teamList.splice(opInfo.index, 1, pat);
        } else {
            teamList.push(pat);
        }
        setTeamList([...teamList]);
        getList({ ...state });
    };
    const [listloading, setListLoading] = useState(false);
    const getList = val => {
        setListLoading(true);
        api.getBusinessTeamList({ pageNo: val.pageNo, pageSize: 1000, keyWord: val.search }, res => {
            setListLoading(false);
            if (res.code === 200) {
                setSeatsNumber(res.data.seatType === 'unlimited' ? 'Unlimited' : res.data.seatsNumber);
                const data = res.data.businessAccountPageBean;
                setTotalRecord(res.data.teamMembers);
                const arr = data.list.map(item => {
                    return Object.assign(item, {
                        showName: window.utils.getInitials(item.firstName, item.lastName),
                        isMe: item.accountId === profileInfo.accountId ? true : false,
                        value: item.accountId,
                        label: `${item.firstName} ${item.middleName} ${item.lastName}`,
                        roleNum: roleList[item.role],
                    });
                });
                // arr.reverse();
                const adArr = arr.filter(v => v.role === 'Administrator');
                setTeamAdList(
                    adArr.map(val => {
                        return { value: val.value, label: val.label };
                    })
                );
                setTeamList(arr);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    useEffect(() => {
        getList({ ...state });
    }, []);
    return (
        <div className="talent-teamManagementBox">
            <div className="team-topBox">
                <div className="r-left">
                    <h2>Team Management</h2>
                    {profileInfo.roleNum === 0 || profileInfo.roleNum === 1 ? (
                        <Button
                            className="r-primary_btn addTeam-b marLeft"
                            onClick={() => {
                                setOpInfo({ index: -1 });
                                if (seatsNumber !== 'Unlimited' && Number(seatsNumber) <= totalRecord) {
                                    setModalType(1);
                                    setModalText(alertModalTipList[0]);
                                    setIsModalOpen(true);
                                    return;
                                }
                                setIsTeamModalOpen(true);
                            }}
                        >
                            + Add Team Member
                        </Button>
                    ) : null}
                </div>
                <div className="teamNum-box r-left">
                    Team Members: {totalRecord ?? 0}
                    <span className="teamMar30">Team Seats: {seatsNumber ?? 0}</span>
                </div>
                <div>
                    <Input
                        placeholder="Search"
                        style={{ flex: 1 }}
                        allowClear
                        value={state.search}
                        onChange={e => {
                            searchList({ ...state, search: e.target.value, pageNo: 1 });
                            setState({ ...state, search: e.target.value, pageNo: 1 });
                        }}
                        className="r-input r-input_serach"
                        prefix={<span className="search_icon"></span>}
                    />
                </div>
            </div>
            {listloading ? (
                <div className="no-message-tabBox r-center">
                    <Spin style={{ position: 'static' }}></Spin>
                </div>
            ) : (
                <div className="team-table-box">
                    <div className="team-table-th r-left">
                        <div className="thade-cell" style={{ flex: 3 }}>
                            Name
                        </div>
                        <div className="thade-cell">Position</div>
                        <div className="thade-cell">Role</div>
                        <div className="thade-cell">Jobs</div>
                        <div className="thade-cell"></div>
                    </div>
                    <div className="team-table-body">
                        {teamList.length ? (
                            teamList.map((val, i) => {
                                return (
                                    <div className="team-table-tr r-left" key={`team-table-tr-${i}`}>
                                        <div className="team-tb-cell r-left" style={{ flex: 3 }}>
                                            {val.avatarUrl || val.picUrlShow ? (
                                                <Image
                                                    width={32}
                                                    height={32}
                                                    preview={false}
                                                    style={{ borderRadius: '50%', minWidth: 32 }}
                                                    src={val.picUrlShow ? val.picUrlShow : val.avatarUrl}
                                                    fallback={require('@/assets/img/default-min.png')}
                                                />
                                            ) : (
                                                <div className="avatar-textBox">{val.showName}</div>
                                            )}
                                            <span style={{ marginLeft: 10 }}>
                                                {val.isMe
                                                    ? 'You'
                                                    : `${val.firstName} ${val.middleName} ${val.lastName}`}
                                            </span>
                                        </div>
                                        <div className="team-tb-cell">{val.position ?? '-'}</div>
                                        <div className="team-tb-cell">{val.role ?? '-'}</div>
                                        <div
                                            className={`team-tb-cell ${
                                                val.jobs > 0 && profileInfo.roleNum < 2 ? 'job-cell' : ''
                                            }`}
                                            onClick={() => {
                                                if (val.jobs > 0 && profileInfo.roleNum < 2) {
                                                    window.sessionStorage.setItem('teamInfo', JSON.stringify(val));
                                                    window.sessionStorage.setItem('teamList', JSON.stringify(teamList));
                                                    navigate('/employee-detail');
                                                }
                                            }}
                                        >
                                            {val.jobs ?? 0}
                                        </div>
                                        <div className="team-tb-cell r-end">
                                            {profileInfo.roleNum === 0 || profileInfo.roleNum === 1 ? (
                                                <Popover
                                                    placement="bottomRight"
                                                    title={''}
                                                    trigger="hover"
                                                    content={
                                                        val.roleNum !== 0 ? (
                                                            <div className="tableOpeation-box">
                                                                <div
                                                                    className={`tb_item`}
                                                                    onClick={() => {
                                                                        window.sessionStorage.setItem(
                                                                            'teamInfo',
                                                                            JSON.stringify(val)
                                                                        );
                                                                        navigate('/employee-detail');
                                                                    }}
                                                                >
                                                                    View Detail
                                                                </div>
                                                                {val.roleNum !== 1 ? (
                                                                    <div
                                                                        className="tb_item"
                                                                        onClick={() => {
                                                                            opeationBtnClcik(5, val, i);
                                                                        }}
                                                                    >
                                                                        Assign Admin Role
                                                                    </div>
                                                                ) : val.roleNum === 1 ? (
                                                                    <div
                                                                        className="tb_item danger"
                                                                        onClick={() => {
                                                                            opeationBtnClcik(4, val, i);
                                                                        }}
                                                                    >
                                                                        Revoke Admin Role
                                                                    </div>
                                                                ) : null}
                                                                <div
                                                                    className={`tb_item ${
                                                                        val.disabledTime ? 'success' : 'danger'
                                                                    }`}
                                                                    onClick={() => {
                                                                        opeationBtnClcik(2, val, i);
                                                                    }}
                                                                >
                                                                    {val.disabledTime
                                                                        ? 'Restore Access'
                                                                        : 'Revoke Access'}
                                                                </div>
                                                                {val.roleNum !== 1 ? (
                                                                    <div
                                                                        className="tb_item danger"
                                                                        onClick={() => {
                                                                            opeationBtnClcik(3, val, i);
                                                                        }}
                                                                    >
                                                                        Permanently Remove User
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {profileInfo.roleNum !== 0 ? (
                                                                    <div className="tableOpeation-box auth-opBox">
                                                                        <div
                                                                            className={`tb_item`}
                                                                            onClick={() => {
                                                                                window.sessionStorage.setItem(
                                                                                    'teamInfo',
                                                                                    JSON.stringify(val)
                                                                                );
                                                                                navigate('/employee-detail');
                                                                            }}
                                                                        >
                                                                            View Detail
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="tableOpeation-box auth-opBox">
                                                                        <div
                                                                            className="tb_item danger"
                                                                            onClick={() => setIsFormModalOpen(true)}
                                                                        >
                                                                            Transfer Authority
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )
                                                    }
                                                    arrow={false}
                                                >
                                                    <i
                                                        className={`more_icon ${
                                                            val.popoverOpen ? 'more_iconSeled' : ''
                                                        }`}
                                                    ></i>
                                                </Popover>
                                            ) : null}
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="r-center no-message-tabBox">No data</div>
                        )}
                    </div>
                </div>
            )}

            <TeamMemberModal
                isModalOpen={isTeamModalOpen}
                handleOk={() => setIsTeamModalOpen(false)}
                handleCancel={() => setIsTeamModalOpen(false)}
                opInfo={opInfo}
                addTeamInfo={addTeamInfo}
            />

            <AlertModal
                title=""
                message={modalText.title}
                cancelText={modalText.cancelText}
                confirmText={modalText.confirmText}
                isModalOpen={isModalOpen}
                loading={loading}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />

            <FormModal
                title="Transfer Authority"
                width={380}
                marBtm={10}
                isModalOpen={isFormModalOpen}
                handleOk={() => setIsFormModalOpen(false)}
                handleCancel={() => setIsFormModalOpen(false)}
                children={
                    <div className="auth-modal-box">
                        <p className="auth-changeTip">
                            You can transfer master administrative rights to another administrator.
                        </p>
                        <p className="form-tip">Current Master Administrator</p>
                        <div className="auth-cardBox r-left">
                            {profileInfo.avatarUrl ? (
                                <Image
                                    width={32}
                                    height={32}
                                    preview={false}
                                    style={{ borderRadius: '50%', minWidth: 32 }}
                                    src={profileInfo.avatarUrl}
                                    fallback={require('@/assets/img/default-min.png')}
                                />
                            ) : (
                                <div className="auth-img r-center">{profileInfo.showName ?? '-'}</div>
                            )}
                            <div style={{ marginLeft: 10 }}>
                                <p className="auth-name">{`${profileInfo.firstName} ${profileInfo.middleName} ${profileInfo.lastName}`}</p>
                                <p className="auth-email">{profileInfo.email ?? '-'}</p>
                            </div>
                        </div>
                        <p className="form-tip" style={{ marginTop: 20 }}>
                            Transfer Ownership To
                        </p>
                        <Select
                            className="r-select"
                            allowClear
                            onChange={val => {
                                const info = teamList.find(v => v.accountId === val);
                                console.log(info);
                                setChooseAd(val);
                                setChooseAdInfo(info);
                            }}
                            value={chooseAd}
                            suffixIcon={<i className="down-right-icon"></i>}
                            options={teamAdList}
                        />
                        {chooseAd ? (
                            <div className="auth-cardBox authSelect-cardBox r-left">
                                {chooseAdInfo.avatarUrl ? (
                                    <Image
                                        width={32}
                                        height={32}
                                        preview={false}
                                        style={{ borderRadius: '50%', minWidth: 32 }}
                                        src={chooseAdInfo.avatarUrl}
                                        fallback={require('@/assets/img/default-min.png')}
                                    />
                                ) : (
                                    <div className="auth-img r-center">{profileInfo.showName ?? '-'}</div>
                                )}
                                <div style={{ marginLeft: 10 }}>
                                    <p className="auth-name">{chooseAdInfo.label ?? '-'}</p>
                                    <p className="auth-email">{chooseAdInfo.email ?? '-'}</p>
                                </div>
                            </div>
                        ) : null}
                        <div className="r-center">
                            <Button
                                style={{ width: 106, marginTop: 40 }}
                                className="r-primary_btn r-primary_largeBtn"
                                onClick={() => opeationBtnClcik(6)}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                }
            />
        </div>
    );
}

export default TeamManagement;
