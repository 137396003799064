import { Popover, Spin } from 'antd';
import React, { useState } from 'react';
import Company from '@/assets/img/company-logo.png';
import '../style/index.scss';
import { useNavigate } from 'react-router-dom';
import useInfiniteScroll from '@/hook/useInfiniteScroll';
import AlertModal from '@/components/modal/alert-modal';
import api from '@/api/job';
import dayjs from 'dayjs';
function JobList({
    data,
    setData,
    selectedType,
    pageType,
    listLoading,
    noMore,
    loading,
    setLoading,
    getList,
    getListReset,
    transferJob,
    isNoLoad,
    isSearch,
}: any) {
    const navigate = useNavigate();
    const toDetail = val => {
        window.sessionStorage.setItem('jobInfo', JSON.stringify(val));
        navigate(pageType !== 'team' ? '/job-detail' : '/job-detail?from=team');
    };
    const [opType, setOpType] = useState(1);
    const archivejob = (val, i) => {
        setOpInfo(val);
        setOpType(i);
        setIsModalOpen(true);
    };
    const deletejob = val => {
        // 删除工作和草稿
        setOpInfo(val);
        setIsModalOpen(true);
    };
    const editjob = val => {
        console.log(val);
        window.sessionStorage.setItem('isTopAdd', '0');
        if (selectedType === 1) {
            //
            window.sessionStorage.setItem('jobInfoForm', JSON.stringify({ ...val, type: 'edit' }));
            navigate(`/job/job-info?f=editJob`);
            return;
        }
        if (selectedType === 3) {
            //
            window.sessionStorage.setItem('jobInfoForm', JSON.stringify({ ...val, type: 'tc' }));
            navigate(`/job/job-info`);
            return;
        }
        window.sessionStorage.setItem('jobInfoForm', JSON.stringify({ ...val, type: 'edit' }));
        navigate(`/job/job-info?f=editDart`);
    };
    const loadMoreItems = () => {
        if (isNoLoad) return;
        if (loading || noMore) return;
        setLoading(true);
        getList();
    };
    const loaderRef = useInfiniteScroll(loadMoreItems, loading);
    const [opInfo, setOpInfo] = useState<any>({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [opLoading, setOpLoading] = useState(false);
    const confirmDel = () => {
        setOpLoading(true);
        if (selectedType == 1) {
            if (opType === 1) {
                api.setJobToInInterview({ jobId: opInfo.jobsPOJO.jobId }, res => {
                    if (res.code === 200) {
                        setOpLoading(false);
                        setIsModalOpen(false);
                        getListReset();
                    } else {
                        setOpLoading(false);
                        window.utils.alert('error', res.message);
                    }
                });
                return;
            }
            api.setJobToArchive({ jobId: opInfo.jobsPOJO.jobId }, res => {
                if (res.code === 200) {
                    setOpLoading(false);
                    setIsModalOpen(false);
                    getListReset();
                } else {
                    setOpLoading(false);
                    window.utils.alert('error', res.message);
                }
            });
        } else {
            // 删除归档工作 // 删除草稿
            api.deleteJob({ jobId: opInfo.jobsPOJO.jobId }, res => {
                if (res.code === 200) {
                    setOpLoading(false);
                    setIsModalOpen(false);
                    getListReset();
                } else {
                    setOpLoading(false);
                    window.utils.alert('error', res.message);
                }
            });
        }
    };
    return (
        <div className="job-listItem">
            {listLoading ? (
                <div className="no-message-tabBox r-center">
                    <Spin style={{ position: 'static' }}></Spin>
                </div>
            ) : data.length ? (
                <>
                    {data.map((item, i) => {
                        return (
                            <div
                                key={`jobItem-card-${i}`}
                                className="jobItem-card r-left"
                                style={{ cursor: 'pointer' }}
                                onClick={e => {
                                    e.stopPropagation();
                                    toDetail(item);
                                }}
                            >
                                <i
                                    className={`job-status ${
                                        selectedType === 1 && item.jobsPOJO?.jobStepStatus !== 'In Interview'
                                            ? 'sourcingBox'
                                            : selectedType === 2
                                            ? 'draftBox'
                                            : selectedType === 3
                                            ? 'archiveBox'
                                            : ''
                                    }`}
                                ></i>
                                <div className="job-infoBox">
                                    <div className="r-left job-tit">
                                        {item.jobsPOJO.jobTitle || '-'}
                                        {selectedType === 1 ? (
                                            <span
                                                className={`job-statusTipBox marLeft ${
                                                    item.jobsPOJO.jobStepStatus !== 'In Interview'
                                                        ? 'sourcingBox-tip'
                                                        : ''
                                                }`}
                                            >
                                                {item.jobsPOJO.jobStepStatus !== 'In Interview'
                                                    ? 'Sourcing'
                                                    : 'Interviewing'}
                                            </span>
                                        ) : null}
                                        <Popover
                                            placement="bottomRight"
                                            title={''}
                                            trigger="hover"
                                            content={
                                                <div
                                                    className="tableOpeation-box job-opBox"
                                                    onClick={e => e.stopPropagation()}
                                                >
                                                    {selectedType !== 2 && (
                                                        <div
                                                            className="tb_item"
                                                            style={{ width: 150 }}
                                                            onClick={() => toDetail(item)}
                                                        >
                                                            View details
                                                        </div>
                                                    )}
                                                    {selectedType === 1 && (
                                                        <>
                                                            <div
                                                                className="tb_item"
                                                                style={{ width: 150 }}
                                                                onClick={() => editjob(item)}
                                                            >
                                                                Manage job
                                                            </div>
                                                            {pageType === 'team' ? (
                                                                // team 页面 转移工作
                                                                <div
                                                                    className="tb_item"
                                                                    style={{ width: 150 }}
                                                                    onClick={() => transferJob(item)}
                                                                >
                                                                    Transfer job
                                                                </div>
                                                            ) : null}
                                                            {selectedType === 1 &&
                                                            item.jobsPOJO?.jobStepStatus !== 'In Interview' ? (
                                                                <div
                                                                    className="tb_item"
                                                                    style={{ width: 150 }}
                                                                    onClick={() => archivejob(item, 1)}
                                                                >
                                                                    In Interview
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className="tb_item"
                                                                    style={{ width: 150 }}
                                                                    onClick={() => archivejob(item, 2)}
                                                                >
                                                                    Archive
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                    {selectedType === 2 && (
                                                        <div
                                                            className="tb_item"
                                                            style={{ width: 150 }}
                                                            onClick={() => editjob(item)}
                                                        >
                                                            Edit draft
                                                        </div>
                                                    )}
                                                    {selectedType === 3 && (
                                                        <>
                                                            <div
                                                                className="tb_item"
                                                                style={{ width: 150 }}
                                                                onClick={() => editjob(item)}
                                                            >
                                                                Duplicate
                                                            </div>
                                                            {pageType === 'team' ? (
                                                                // team 页面 转移工作
                                                                <div
                                                                    className="tb_item"
                                                                    style={{ width: 150 }}
                                                                    onClick={() => transferJob(item)}
                                                                >
                                                                    Transfer job
                                                                </div>
                                                            ) : null}
                                                        </>
                                                    )}
                                                    {selectedType !== 1 && (
                                                        <div
                                                            className="tb_item"
                                                            style={{ width: 150 }}
                                                            onClick={() => deletejob(item)}
                                                        >
                                                            Delete
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            arrow={false}
                                        >
                                            <div
                                                className={`r-left logout-popTip ${
                                                    selectedType !== 1 ? 'marLeft' : ''
                                                }`}
                                                onClick={e => e.stopPropagation()}
                                            >
                                                <i className="job-moreIcon"></i>
                                            </div>
                                        </Popover>
                                    </div>
                                    <div className="company-info r-left">
                                        <i
                                            className="company-icon"
                                            style={{
                                                borderRadius: '50%',
                                                backgroundImage: `url(${item.jobsPOJO.employerLogo})`,
                                                backgroundSize: 'cover',
                                            }}
                                        ></i>
                                        {item.jobsPOJO.employerName || '-'}
                                    </div>
                                    <div className="r-left job-infoBtm">
                                        <div className="info-tit" style={{ width: 100 }}>
                                            Location
                                            <div className="info-msg">
                                                {item.jobsPOJO.remoteFlag ? 'Remote' : item.jobsPOJO.location || '-'}
                                            </div>
                                        </div>
                                        <div className="info-tit" style={{ width: 130 }}>
                                            Type
                                            <div className="info-msg">{item.jobTypeShow || '-'}</div>
                                        </div>
                                        {selectedType === 1 ? (
                                            <div className="info-tit" style={{ width: 130 }}>
                                                Release Date
                                                <div className="info-msg">
                                                    {item?.jobsPOJO?.publishTime
                                                        ? dayjs(item.jobsPOJO.publishTime * 1000).format('DD MMM YYYY')
                                                        : '-'}
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        <div className="info-tit" style={{ width: 170 }}>
                                            Deadline
                                            <div className="info-msg">{item.deadlineTimeShow || '-'}</div>
                                        </div>
                                        {selectedType !== 2 ? (
                                            <div className="info-tit">
                                                Candidates ({item.applications > 999 ? '999+' : item.applications || 0}){' '}
                                                {item.applicationsUnScreening ? (
                                                    <span className="newNum-box">
                                                        {item.applicationsUnScreening}{' '}
                                                        {`${item.applicationsUnScreening > 1 ? 'news' : 'new'}`}
                                                    </span>
                                                ) : null}
                                                <div className="info-msg r-left">
                                                    {item.applicationsPOJOList && item.applicationsPOJOList.length
                                                        ? item.applicationsPOJOList.map((item, k) => {
                                                              return (
                                                                  <img
                                                                      key={`user-img-${k}`}
                                                                      src={
                                                                          item.accountPOJO.accountInformationPOJO
                                                                              .profilePhoto
                                                                      }
                                                                      className="userImg-box"
                                                                      alt=""
                                                                  />
                                                              );
                                                          })
                                                        : '-'}
                                                </div>
                                            </div>
                                        ) : null}
                                        {selectedType === 3 ? (
                                            <div className="info-tit">
                                                Successful Candidates ({item.applicationsOffered || 0})
                                                <div className="info-msg r-left">
                                                    {item.applicationsSuccessPOJOList &&
                                                    item.applicationsSuccessPOJOList.length
                                                        ? item.applicationsSuccessPOJOList.map((item, j) => {
                                                              return (
                                                                  <img
                                                                      key={`new-img-${j}`}
                                                                      src={
                                                                          item.accountPOJO.accountInformationPOJO
                                                                              .profilePhoto
                                                                      }
                                                                      className="userImg-box"
                                                                      alt=""
                                                                  />
                                                              );
                                                          })
                                                        : '-'}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    {loading && (
                        <div className="r-center">
                            <Spin style={{ position: 'static' }}></Spin>
                        </div>
                    )}
                    {isNoLoad ? null : <div ref={loaderRef} style={{ height: '20px' }} />}
                </>
            ) : (
                <>
                    <div className="no-message-tabBox r-center">
                        {isSearch
                            ? 'Sorry, we were unable to find any results based on your conditions.'
                            : 'No job yet'}
                    </div>
                </>
            )}

            <AlertModal
                title=""
                message={
                    selectedType === 1 && opType == 1
                        ? 'Are you sure you want to take the recruitment process for this job to the interview stage?'
                        : selectedType === 1 && opType == 2
                        ? 'Are you sure you want to close this job?'
                        : selectedType === 2
                        ? 'Are you sure you want to delete this draft?'
                        : 'Are you sure you want to delete this job?'
                }
                cancelText={'Cancel'}
                confirmText={'Confirm'}
                isModalOpen={isModalOpen}
                loading={opLoading}
                handleOk={() => confirmDel()}
                handleCancel={() => {
                    setOpInfo({});
                    setIsModalOpen(false);
                }}
            />
        </div>
    );
}

export default JobList;
