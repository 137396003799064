import React, { useEffect } from 'react';

function RCheckBox({ width, checkInfo, setCheckInfo, index }: any) {
    return (
        <div>
            <div className="r-left">
                <div className="r-left">
                    <div
                        className="r-left"
                        onClick={e => {
                            e.stopPropagation();
                            setCheckInfo(index);
                        }}
                    >
                        <i className={`check_icon ${checkInfo.isChecked ? 'checked_icon' : ''}`}></i>
                        <span className="r-radioText" style={{ width: width ? width : 'auto', marginLeft: 10 }}>
                            {checkInfo.label}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RCheckBox;
