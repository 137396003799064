import React, { useEffect, useState } from 'react';
import './index.scss';
import { Button, Space, Table, TableProps, Image } from 'antd';
import TeamMemberModal from '@/components/modal/team-member-modal';
import { useNavigate } from 'react-router-dom';
import VideoPlayModal from '@/components/modal/video-play-modal';
import { defaultInfo } from '@/typings/allType';
import api from '@/api/team';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import useLoading from '@/hook/useLoading';
function TeamManagement() {
    const navigate = useNavigate();
    const messageApi = useGlobalMessage();
    const [loading, setLoading] = useState(false);
    const { setLoadingStatus } = useLoading();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(true);
    const [opInfo, setOpInfo] = useState({
        index: -1,
    });
    const [teamList, setTeamList] = useState<any>([]);
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const continueClick = () => {
        setLoading(true);
        api.teamNextStep({}, res => {
            setLoading(false);
            if (res.code === 200) {
                navigate('/subscription-plan');
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const addTeamInfo = (pat: defaultInfo, i?: number) => {
        handleCancel();
        if (i) {
            teamList.splice(opInfo.index, 1, pat);
        } else {
            teamList.push(pat);
        }
        setTeamList([...teamList]);
        getList();
    };
    const opeationTeam = (type: number, val: defaultInfo, index: number) => {
        if (type) {
            setOpInfo({ ...val, index });
            setIsModalOpen(true);
            return;
        }
        api.deleteBusinessTeam({ accountId: val.accountId }, res => {
            if (res.code === 200) {
                teamList.splice(index, 1);
                setTeamList([...teamList]);
                getList();
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const getList = () => {
        setLoadingStatus(true);
        api.getBusinessTeamList({ pageNo: 1, pageSize: 1000 }, res => {
            setLoadingStatus(false);
            if (res.code === 200) {
                const arr = res.data.businessAccountPageBean.list.map(item => {
                    return Object.assign(item, {
                        showName: window.utils.getInitials(item.firstName, item.lastName),
                    });
                });
                setTeamList(arr);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    useEffect(() => {
        getList();
    }, []);
    const columns: TableProps<defaultInfo>['columns'] = [
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: 'firstName',
            width: 350,
            render: (_, record) => (
                <div className="r-left">
                    {record.avatarUrl || record.picUrlShow ? (
                        <Image
                            width={32}
                            height={32}
                            preview={false}
                            style={{ borderRadius: '50%', minWidth: 32 }}
                            src={record.picUrlShow ? record.picUrlShow : record.avatarUrl}
                            fallback={require('@/assets/img/default-min.png')}
                        />
                    ) : (
                        <div className="avatar-textBox">{record.showName}</div>
                    )}
                    <span style={{ marginLeft: 10 }}>
                        {record.role === 'Master Administrator'
                            ? 'You'
                            : `${record.firstName} ${record.middleName} ${record.lastName}`}
                    </span>
                </div>
            ),
        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: '',
            key: 'action',
            width: 80,
            render: (_, record, index) => (
                <div className="r-end">
                    {record.role === 'Master Administrator' ? null : (
                        <>
                            <i
                                className="editR-icon"
                                style={{ marginRight: 20 }}
                                onClick={() => opeationTeam(1, record, index)}
                            ></i>
                            <i className="delR-icon" onClick={() => opeationTeam(0, record, index)}></i>
                        </>
                    )}
                </div>
            ),
        },
    ];
    return (
        <div className="information-main">
            <div className="top-nav">
                <img src={require('@/assets/img/Logo.png')} className="logo-img" alt="" />
            </div>
            <div className="content-box r-center">
                <div className="team-card">
                    <h2>Team Management</h2>
                    <div className="r-center" style={{ margin: '32px 0 20px 0' }}>
                        <Button
                            className="r-primary_btn addMember-btn"
                            onClick={() => {
                                setOpInfo({ index: -1 });
                                setIsModalOpen(true);
                            }}
                        >
                            + Add Team Member
                        </Button>
                        <i
                            className="tip-icon marLeft"
                            onClick={() => {
                                setIsVideoModalOpen(true);
                            }}
                        ></i>
                    </div>
                    <Table
                        className="r-table"
                        rowClassName="r-tableRow"
                        size="middle"
                        pagination={false}
                        rowHoverable={false}
                        columns={columns}
                        dataSource={teamList}
                    />
                    <Button
                        className="r-primary_btn continueMember-btn"
                        loading={loading}
                        onClick={() => continueClick()}
                    >
                        Continue
                    </Button>
                </div>
            </div>

            <TeamMemberModal
                opInfo={opInfo}
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                addTeamInfo={addTeamInfo}
                handleCancel={handleCancel}
            />

            <VideoPlayModal
                videoUrl=""
                autoPlay={true}
                isModalOpen={isVideoModalOpen}
                handleOk={() => setIsVideoModalOpen(false)}
                handleCancel={() => setIsVideoModalOpen(false)}
            />
        </div>
    );
}

export default TeamManagement;
