import ReactDOM from 'react-dom/client';
import App from './App';
import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// import '@/assets/fonts/font.scss';
import 'antd/dist/reset.css';
import '@/style/antd-base.scss';
import '@/style/vant-base.scss';
import '@/style/var.scss';
import '@/style/default.scss';
import { ConfigProvider } from 'antd';
import store from '@/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import Utils from '@/utils/utils';
import { AliveScope } from 'react-activation';
import {
    createBrowserRouter,
    createHashRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from 'react-router-dom';
(window as any).utils = Utils;
(window as any).format = Utils;
const persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
//  basename={process.env.REACT_APP_BASE_NAME || ''}
// const router = createBrowserRouter(createRoutesFromElements(<Route path="*" element={<App />} />), {
//     basename: process.env.REACT_APP_BASE_NAME || '',
// });
const router =
    process.env.REACT_APP_NODE_ENV === 'test'
        ? createHashRouter(createRoutesFromElements(<Route path="*" element={<App />} />))
        : createBrowserRouter(createRoutesFromElements(<Route path="*" element={<App />} />), {
              basename: process.env.REACT_APP_BASE_NAME || '',
          });
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ConfigProvider theme={{ token: { colorPrimary: '#4C47DE' } }} wave={{ disabled: true }}>
                <AliveScope>
                    {/* <App /> */}
                    <RouterProvider router={router} />
                </AliveScope>
            </ConfigProvider>
        </PersistGate>
    </Provider>
);
