import React, { useEffect, useState } from 'react';
import './index.scss';
import { Button, Spin, Table } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import SliderTabs from '@/components/slideeTabs';
import SubscriptionCard from '@/components/subscription-card';
import AlertModal from '@/components/modal/alert-modal';
import SubscriptionModal from './components/subscription-modal';
import SubscriptionInfomation from './components/subscription-infomation';
import api from '@/api/subscription';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import { loadStripe } from '@stripe/stripe-js';
import apiUser from '@/api/login';
import { roleList } from '@/config/role';
import { setProfileInfo } from '@/store/stateSlice';
import { useAppDispatch } from '@/store/hooks';
import config from '@/config/config';
import useLoading from '@/hook/useLoading';
import SubscriptionDetailByTeamNumModal from './components/subscriptionByTeamNum-modal';
const stripePromise = loadStripe(
    config.stripeKey ||
        'pk_test_51PoQxiEqaprY8t0qMmFHFd2rTVCINlyfvqCGvSrzPcc8hWa5jWOx7Y2AAJbbv3RBOAOeBOJLqVeLArsMmnCsjJoG001KuJ26ku'
);

function ChangeSubscriptionPlan() {
    const navigate = useNavigate();
    const messageApi = useGlobalMessage();
    const { setLoadingStatus } = useLoading();
    const [loading, setLoading] = useState(false);
    const [planloading, setPlanLoading] = useState(false);
    const [activePlan, setActivePlan] = useState<any>({});
    const [selectedType, setSelectedType] = useState(1);
    const [userTeamNum, setUserTeamNum] = useState(0);
    const [defaultMonthPlan, setDefaultMonthPlan] = useState(0);
    const [defaultYearPlan, setDefaultYearPlan] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isShowDetail, setIsShowDetail] = useState(false);
    const [isShowDetail1, setIsShowDetail1] = useState(false);
    const [state, setState] = useState({
        connectOutlook: false,
        connectGoogle: true,
        isSub: true,
        isExpired: false,
        isCancel: true,
        subType: 1, // 0 正常订阅 1 按席位
        isUpgrade: true, // 是否是升级
        subTitle: 'Membership Upgrade',
        alertType: 1, // 0 席位超出 1 升级，还有生效中的套餐
    });
    const changeTab = val => {
        setSelectedType(val);
        choosePlan(val === 1 ? defaultMonthPlan : defaultYearPlan, val);
    };
    const handleOk = () => {
        setIsModalOpen(false);
        choosePlan(selectedType === 1 ? defaultMonthPlan : defaultYearPlan, selectedType);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        navigate('/talent-teamManagement');
    };
    const [updateSubInfo, setUpdateSubInfo] = useState({
        total: '',
        stripePaySessionId: '', //支付差价
        subscriptionNowPOJO: {
            periodEnd: '',
        },
    });
    // 订阅
    const subscription = () => {
        console.log(activePlan);
        if (businessInfo.businessInfoPOJO.subscriptionExpireFlag) {
            // 过期
            setLoading(true);
            if (activePlan.seatType === 'bySeats') {
                // 按数量订阅
                setState({ ...state, subType: 1, isUpgrade: false, subTitle: 'Subscription Details' });
                setIsShowDetail1(true);
                return;
            } else {
                setState({ ...state, isUpgrade: false, subTitle: 'Subscription Details' });
                api.createSubscriptionPlan(
                    {
                        cancel_url: `${config.localUrl}/change-subscriptionPlan`,
                        planId: activePlan.planId,
                        priceCurrency: activePlan.priceCurrency,
                        success_url: `${config.localUrl}/change-subscriptionPlan?status=success`,
                    },
                    res => {
                        if (res.code === 200) {
                            console.log(res.data);
                            window.localStorage.setItem('orderId', res.data.orderId);
                            selectPlan(res.data.sessionId);
                        } else if (res.code === 50009) {
                            setState({ ...state, alertType: 0 });
                            setLoading(false);
                            setIsModalOpen(true);
                        } else {
                            setLoading(false);
                            messageApi.error(res.message);
                        }
                    }
                );
            }
            return;
        }
        if (
            activePlan.planType !== businessInfo.businessInfoPOJO.subscriptionPlanType ||
            ((activePlan.seatType === 'limited' || activePlan.seatType === 'Unlimited') &&
                businessInfo.businessInfoPOJO.seatType !== 'limited') ||
            (activePlan.seatType === 'bySeats' && businessInfo.businessInfoPOJO.seatType !== 'bySeats') ||
            (activePlan.planId === businessInfo.businessInfoPOJO.subscriptionPlanId &&
                activePlan.seatType !== 'bySeats') ||
            (activePlan.seatType === 'limited' &&
                businessInfo.businessInfoPOJO.seatType === 'limited' &&
                activePlan.seatsNumber <= businessInfo.businessInfoPOJO.seatsNumber)
        ) {
            // 非升级。直接订阅
            console.log('订阅');
            messageApi.error('You currently have an ongoing subscription.');
            return;
        }
        if (activePlan.seatType === 'bySeats') {
            // 按数量订阅
            setState({ ...state, subType: 1, subTitle: 'Membership Upgrade', isUpgrade: true });
            setIsShowDetail1(true);
            return;
        }
        setLoading(true);
        api.upgradeSubscription(
            {
                cancel_url: `${config.localUrl}/change-subscriptionPlan`,
                planId: activePlan.planId,
                priceCurrency: activePlan.priceCurrency,
                success_url: `${config.localUrl}/change-subscriptionPlan?status=success`,
            },
            res => {
                setLoading(false);
                if (res.code === 200) {
                    console.log(res.data);
                    setUpdateSubInfo(res.data);
                    setState({ ...state, subType: 0, subTitle: 'Membership Upgrade' });
                    setIsShowDetail(true);
                    window.localStorage.setItem('orderId', res.data.orderId);
                } else if (res.code === 50009) {
                    setState({ ...state, alertType: 0, subTitle: 'Membership Upgrade' });
                    setLoading(false);
                    setIsModalOpen(true);
                } else {
                    messageApi.error(res.message);
                }
            }
        );
    };
    // 数量订阅
    const subForNum = () => {
        const pat = {
            cancel_url: `${config.localUrl}/change-subscriptionPlan`,
            planId: activePlan.planId,
            quantity: Number(userTeamNum) + Number(businessInfo.businessInfoPOJO.seatsNumber),
            priceCurrency: activePlan.priceCurrency,
            success_url: `${config.localUrl}/change-subscriptionPlan?status=success`,
        };
        api.upgradeSubscription({ ...pat }, res => {
            setLoading(false);
            if (res.code === 200) {
                setUpdateSubInfo(res.data);
                setState({ ...state, isUpgrade: true, subType: 0 });
                setIsShowDetail1(true);
                // setState({ ...state, subType: 0 });
                window.localStorage.setItem('orderId', res.data.orderId);
                // selectPlan(res.data.stripePaySessionId);
            } else if (res.code === 50009) {
                setState({ ...state, alertType: 0 });
                setLoading(false);
                setIsModalOpen(true);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const subConfirm = () => {
        setLoading(true);
        if (state.subType) {
            // 数量
            subForNum();
            return;
        }
        selectPlan(updateSubInfo.stripePaySessionId);
    };
    const [subscriptionPlanList, setSubscriptionPlanList] = useState<any>([]);
    const [subscriptionYearPlanList, setSubscriptionYearPlanList] = useState<any>([]);
    // 支付
    const selectPlan = async (CHECKOUT_SESSION_ID: string) => {
        const stripe = await stripePromise;
        const { error } = await stripe!.redirectToCheckout({
            sessionId: CHECKOUT_SESSION_ID,
        });
        setLoading(false);
        // 如果redirectToCheckout失败，显示错误信息
        if (error) {
            console.error('Redirect to Checkout error:', error.message);
        }
    };
    const choosePlan = (i: number, val: number) => {
        if (val === 1) {
            subscriptionPlanList.forEach((item, k) => {
                item.recommendFlag = k == i ? true : false;
            });
            setSubscriptionPlanList(JSON.parse(JSON.stringify([...subscriptionPlanList])));
            setActivePlan(subscriptionPlanList[i]);
            return;
        }
        subscriptionYearPlanList.forEach((item, k) => {
            item.recommendFlag = k == i ? true : false;
        });
        setSubscriptionYearPlanList(JSON.parse(JSON.stringify([...subscriptionYearPlanList])));
        setActivePlan(subscriptionYearPlanList[i]);
    };
    const getAnnualList = () => {
        api.getSubscriptionAnnualPlan({}, res => {
            if (res.code === 200) {
                setSubscriptionYearPlanList(res.data);
                const planDefaultIndex = res.data.findIndex(v => v.recommendFlag);
                setDefaultYearPlan(planDefaultIndex);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const getMonthlList = () => {
        setPlanLoading(true);
        api.getSubscriptionMonthPlan({}, res => {
            setPlanLoading(false);
            if (res.code === 200) {
                setSubscriptionPlanList(res.data);
                const planDefault = res.data.find(v => v.recommendFlag);
                const planDefaultIndex = res.data.findIndex(v => v.recommendFlag);
                setDefaultMonthPlan(planDefaultIndex);
                setActivePlan(planDefault);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const dispatch = useAppDispatch();
    const [businessInfo, setBusinessInfo] = useState({
        businessInfoPOJO: {
            subscriptionPlanType: '',
            seatType: 'limited',
            subscriptionPlanId: '',
            seatsNumber: 0,
            subscriptionExpireFlag: 0,
        },
    });
    const getInfo = () => {
        // setLoading(true);
        apiUser.getBusinessInfo({}, res => {
            setLoading(false);
            const { code, data, message } = res;
            if (code === 200) {
                setBusinessInfo({ ...data });
                data.roleNum = roleList[data.role];
                data.showName = window.utils.getInitials(data.firstName, data.lastName);
                dispatch(setProfileInfo(data));
            } else {
                window.utils.alert('error', message);
            }
        });
    };
    const checkPay = () => {
        window.utils.updateUrlParams('status', null);
        // setLoading(true);
        api.checkPayStatus({ orderId: window.localStorage.getItem('orderId') }, res => {
            setLoading(false);
            setLoadingStatus(false);
            if (res.code === 200) {
                getInfo();
                messageApi.success('Success');
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const location = useLocation();
    function getQueryParam(param: string, location): string | null {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get(param);
    }
    useEffect(() => {
        setLoadingStatus(false);
        getAnnualList();
        getMonthlList();
        getInfo();
        const status = getQueryParam('status', location);
        if (status === 'success') {
            setLoadingStatus(true);
            setTimeout(() => {
                checkPay();
            }, 5000);
        }
    }, []);
    return (
        <div className="change-subscriptionBox">
            <div className="subscriptionContent-box">
                <h2 className="sub-titTop">Subscription</h2>
                <p className="changeSub-tip">
                    The new subscription plan will occur at the end of your current subscription.
                </p>
                <SubscriptionInfomation
                    type="capage"
                    getInfo={getInfo}
                    isSub={state.isSub}
                    isExpired={state.isExpired}
                    isCancel={state.isCancel}
                />
                <p className="sub-titMax">Subscription Plan</p>
                <div>
                    {planloading ? (
                        <div className="r-center no-message-tabBox">
                            <Spin></Spin>
                        </div>
                    ) : (
                        <>
                            <div style={{ margin: '30px 0' }}>
                                <SliderTabs
                                    setSelectedType={val => changeTab(val)}
                                    selectedType={selectedType}
                                    tabList={[
                                        { label: 'Monthly Plan', key: 1 },
                                        { label: 'Annual Plan', key: 2 },
                                    ]}
                                />
                            </div>
                            <div>
                                <SubscriptionCard
                                    choosePlan={(i: number) => choosePlan(i, selectedType)}
                                    selectedType={selectedType}
                                    subscriptionPlanList={
                                        selectedType === 1 ? subscriptionPlanList : subscriptionYearPlanList
                                    }
                                />
                            </div>
                            <div className="r-end">
                                <Button
                                    className="r-primary_btn r-primary_largeBtn"
                                    style={{ width: 208 }}
                                    onClick={() => subscription()}
                                    loading={loading}
                                >
                                    Subscribe
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <AlertModal
                message={
                    state.alertType === 0
                        ? 'You have more team members than your planned number of seats. Need to recommend a new plan for you?'
                        : 'Do you need to replace your pending plan with a new pending plan?'
                }
                cancelText={state.alertType === 0 ? 'Modify Team' : 'Cancel'}
                confirmText={state.alertType === 0 ? 'Recommended' : 'Confirm'}
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
            {/* 普通套餐升级 */}
            <SubscriptionModal
                isModalOpen={isShowDetail}
                title={state.subTitle}
                isUpgrade={state.isUpgrade}
                subType={state.subType}
                planInfo={activePlan}
                setUserTeamNum={setUserTeamNum}
                handleOk={() => {
                    setIsShowDetail(false);
                    // if (state.isUpgrade) {
                    //     setIsModalOpen(true);
                    // }
                    subConfirm();
                }}
                handleCancel={() => setIsShowDetail(false)}
                updateSubInfo={updateSubInfo}
            />
            {/* 按席位套餐 */}
            <SubscriptionDetailByTeamNumModal
                isModalOpen={isShowDetail1}
                title={state.subTitle}
                isUpgrade={state.isUpgrade}
                subType={state.subType}
                planInfo={activePlan}
                setUserTeamNum={setUserTeamNum}
                handleOk={() => {
                    setIsShowDetail1(false);
                    // if (state.isUpgrade) {
                    //     setIsModalOpen(true);
                    // }
                    subConfirm();
                }}
                handleCancel={() => setIsShowDetail1(false)}
                updateSubInfo={updateSubInfo}
            />
        </div>
    );
}

export default ChangeSubscriptionPlan;
