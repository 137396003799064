import uploadApi from '@/api/upload';
import React, { useEffect, useState } from 'react';
import Tailor from '@/components/tailor/tailor';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { useS3Upload } from '@/hook/useS3Upload';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import api from '@/api/upload';
function UploadImg({ setPicInfo, setPicShowInfo, imgUrl, isRadius, type, resetForm }: any) {
    const messageApi = useGlobalMessage();
    const { uploadFileToS3, uploadProgress, uploadSuccess, uploadError, picUrl, picUrlShow } = useS3Upload();
    const [dataURL, setDataURL] = useState<any>('');
    const [avatar, setAvatar] = useState({
        url: '',
        key: '',
        base64: '',
        file: null,
        uploading: false,
        dialogVisible: false,
        warncontent: '',
        name: '',
    }) as any;
    const handleFileChange = (e: any) => {
        if (!e || avatar.uploading) {
            return;
        }
        const file = e.target.files[0].name.split('.');
        if (file[file.length - 1].toLowerCase() === 'png' || file[file.length - 1].toLowerCase() === 'jpg') {
            if (e.target.files[0].size > 50 * 1048576) {
                window.format.alert('error', 'File cannot exceed 50MB');
                (document.getElementById('imgInput') as any).value = null;
                resetForm && resetForm();
                return;
            }
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = function (ev: any) {
                setAvatar(
                    Object.assign({}, avatar, {
                        file: ev.target.result,
                        dialogVisible: true,
                        name: e.target.files[0].name,
                    })
                );
                (document.getElementById('imgInput') as any).value = null;
            };
        } else {
            resetForm && resetForm();
            window.format.alert('error', 'File format is incorrect');
            (document.getElementById('imgInput') as any).value = null;
        }
    };
    const getResult = (e: any) => {
        const param = new FormData();
        param.append('file', e);
        const reader = new FileReader();
        reader.readAsDataURL(e);
        reader.onload = function (ev: any) {
            const dataURL = ev.target.result;
            setDataURL(dataURL);
            const formData = new FormData();
            formData.append('file', e);
            // uploadFileToS3({ name: avatar.name, file: e });
            setAvatar(
                Object.assign({}, avatar, {
                    url: '',
                    key: '',
                    base64: '',
                    dialogVisible: false,
                    file: null,
                    uploading: true,
                })
            );
            api.uploadFile(formData, res => {
                if (res.code === 200) {
                    const picUrl = res.data;
                    setAvatar(
                        Object.assign({}, avatar, {
                            url: picUrl,
                            dialogVisible: false,
                            file: null,
                            base64: dataURL,
                            uploading: false,
                        })
                    );
                    setPicInfo({ picUrl, picUrlShow: dataURL });
                } else {
                    resetForm && resetForm();
                    messageApi.error(res.message);
                    setAvatar({
                        url: '',
                        key: '',
                        base64: '',
                        file: null,
                        uploading: false,
                        dialogVisible: false,
                        warncontent: '',
                        name: '',
                    });
                }
            });
        };
    };
    const stopUplaod = (url?: string) => {
        setAvatar({
            url: url ? url : '',
            key: '',
            base64: '',
            file: null,
            uploading: false,
            dialogVisible: false,
        });
    };
    useEffect(() => {
        if (uploadSuccess) {
            setAvatar(
                Object.assign({}, avatar, {
                    url: picUrl,
                    dialogVisible: false,
                    file: null,
                    base64: dataURL,
                    uploading: false,
                })
            );
            setPicInfo({ picUrl, picUrlShow });
        }
    }, [uploadSuccess]);
    useEffect(() => {
        if (uploadError) {
            setAvatar({
                url: '',
                key: '',
                base64: '',
                file: null,
                uploading: false,
                dialogVisible: false,
                warncontent: '',
                name: '',
            });
            messageApi.error(uploadError);
        }
    }, [uploadError]);
    useEffect(() => {
        setAvatar({
            url: '',
            key: '',
            base64: '',
            file: null,
            uploading: false,
            dialogVisible: false,
            warncontent: '',
            name: '',
        });
    }, [imgUrl]);
    return (
        <>
            <div className="r-left uploadMain_box">
                <label
                    className={`upload_imgBox ${type === 'job' ? 'jobImg-box' : ''}`}
                    htmlFor="imgInput"
                    style={{
                        backgroundImage: `url(${
                            avatar.uploading ? '' : avatar.base64 ? avatar.base64 : avatar.url ? avatar.url : imgUrl
                        })`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center center',
                        borderRadius: isRadius || type === 'job' ? '50%' : '10px',
                    }}
                >
                    {(avatar.base64 || avatar.url || imgUrl) && type !== 'job' ? (
                        <>{avatar.uploading ? <LoadingOutlined style={{ fontSize: 22 }} rev={undefined} /> : ''}</>
                    ) : (
                        <>
                            {!avatar.uploading ? (
                                <div>
                                    <i
                                        className={`upload-icon ${
                                            avatar.base64 || avatar.url || imgUrl ? 'editImg-icon' : ''
                                        }`}
                                    ></i>
                                    {type === 'job' ? null : <p className="upload_tip">Add Image</p>}
                                </div>
                            ) : (
                                <LoadingOutlined style={{ fontSize: 22 }} rev={undefined} />
                            )}
                        </>
                    )}
                    <input type="file" id="imgInput" onChange={e => handleFileChange(e)} accept=".png, .jpg" title="" />
                </label>
            </div>
            <Tailor
                dialogVisible={avatar.dialogVisible}
                imgFile={avatar.file}
                aspectRatioWidth="180"
                aspectRatioHeight="180"
                getResult={getResult}
                isFile={true}
                closeWindow={stopUplaod}
            />
        </>
    );
}

export default UploadImg;
