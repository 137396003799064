import React from 'react';
import SignUp from '@/views/login/signup';
import Login from '@/views/login/login';
import ResetPassword from '@/views/login/resetPassword';
import TeamManagement from '@/views/login/teamManagement';
import SubscriptionPlan from '@/views/login/subscriptionPlan';
import Index from '@/views/pages/index';
import Home from '@/views/pages/home';
import Insights from '@/views/pages/home/insights';
import Interview from '@/views/pages/home/interview';
import JobDetail from '@/views/pages/home/job-detail';
import CandidateDetail from '@/views/pages/home/candidate-detail';
import SearchResult from '@/views/pages/searchResult/search-result';
import SearchResultAll from '@/views/pages/searchResult/search-result-all';
import Job from '@/views/pages/job/index';
import Talentpool from '@/views/pages/talentpool/talentpool';
import TalentPoolInfo from '@/views/pages/talentpool/talentpool-info';
import PipelinesBoard from '@/views/pages/talentpool/pipelines-board';
import TalentDatabase from '@/views/pages/talentDatabase/talentDatabase';
import TalentDatabaseInfo from '@/views/pages/talentDatabase/talentDatabase-info';
import CandidateInfo from '@/views/pages/talentDatabase/candidate-info';
import TeamManagementHome from '@/views/pages/team-management/index';
import EmployeeDetail from '@/views/pages/team-management/employee-detail';
import AdminSettings from '@/views/pages/settings';
import ChangeSubscriptionPlan from '@/views/pages/settings/change-subscriptionPlan';
import NotificationList from '@/views/pages/notification/index';
import MessageChat from '@/views/pages/message/message';
import AuthCallback from '@/views/auth/AuthCallback';
import LinkedinCallback from '@/views/auth/linkedinCallback';
import OutlookCallback from '@/views/auth/outlookCallback';
import GoogleCallback from '@/views/auth/googleCallback';

export interface RouteConfigDeclaration {
    path: string;
    name?: string;
    exact?: boolean;
    auth?: boolean;
    isRedirect?: boolean;
    isDynamic?: boolean;
    loadingFallback?: string;
    component: any;
    childrens?: RouteConfigDeclaration[];
    isShowOnNav?: boolean;
    KeepAlive?: boolean;
    icon?: any;
    key?: number;
}
const routes: RouteConfigDeclaration[] = [
    {
        path: '/login',
        component: Login,
        exact: true,
        auth: false,
    },
    {
        path: '/sign-up',
        component: SignUp,
        exact: true,
        auth: false,
    },
    {
        path: '/reset-password',
        component: ResetPassword,
        exact: true,
        auth: false,
    },
    {
        path: '/team-management',
        component: TeamManagement,
        exact: true,
        auth: false,
    },
    {
        path: '/subscription-plan',
        component: SubscriptionPlan,
        exact: true,
        auth: false,
    },
    {
        path: '/auth-callback',
        component: AuthCallback,
        exact: true,
        auth: false,
    },
    {
        path: '/auth-linkedin-callback',
        component: LinkedinCallback,
        exact: true,
        auth: false,
    },
    {
        path: '/auth-outlook-callback',
        component: OutlookCallback,
        exact: true,
        auth: false,
    },
    {
        path: '/auth-google-callback',
        component: GoogleCallback,
        exact: true,
        auth: false,
    },
    {
        path: '/*',
        component: Index,
        exact: false,
        childrens: [
            {
                name: 'NotificationList',
                path: '/notification',
                component: NotificationList,
                exact: true,
                KeepAlive: false,
                key: 0,
            },
            {
                name: 'Home',
                path: '/home',
                component: Home,
                exact: true,
                KeepAlive: false,
                key: 0,
            },
            {
                name: 'Insights',
                path: '/insights',
                component: Insights,
                exact: true,
                KeepAlive: false,
                key: 0,
            },
            {
                name: 'Interview',
                path: '/interview',
                component: Interview,
                exact: true,
                KeepAlive: false,
                key: 0,
            },
            {
                name: 'JobDetail',
                path: '/job-detail',
                component: JobDetail,
                exact: true,
                KeepAlive: false,
                key: 0,
            },
            {
                name: 'CandidateDetail',
                path: '/candidate-detail',
                component: CandidateDetail,
                exact: true,
                KeepAlive: false,
                key: 0,
            },
            {
                name: 'SearchResult',
                path: '/search-result',
                component: SearchResult,
                exact: true,
                KeepAlive: false,
                key: 0,
            },
            {
                name: 'SearchResultAll',
                path: '/search-result-all/:typeId',
                component: SearchResultAll,
                exact: true,
                KeepAlive: false,
                key: 0,
            },
            {
                name: 'Job',
                path: '/job/*',
                component: Job,
                exact: true,
                KeepAlive: false,
                key: 0,
            },
            {
                name: 'Talentpool',
                path: '/talent-pool',
                component: Talentpool,
                exact: true,
                KeepAlive: false,
                key: 1,
            },
            {
                name: 'TalentpoolInfo',
                path: '/talent-pool-info',
                component: TalentPoolInfo,
                exact: true,
                KeepAlive: false,
                key: 1,
            },
            {
                name: 'PipelinesBoard',
                path: '/pipelines-board',
                component: PipelinesBoard,
                exact: true,
                KeepAlive: false,
                key: 1,
            },
            {
                name: 'TalentDatabase',
                path: '/talent-database',
                component: TalentDatabase,
                exact: true,
                KeepAlive: false,
                key: 2,
            },
            {
                name: 'TalentDatabaseInfo',
                path: '/talent-database-info',
                component: TalentDatabaseInfo,
                exact: true,
                KeepAlive: false,
                key: 2,
            },
            {
                name: 'CandidateInfo',
                path: '/candidate-info',
                component: CandidateInfo,
                exact: true,
                KeepAlive: false,
                key: 2,
            },
            {
                name: 'MessageChat',
                path: '/message-chat',
                component: MessageChat,
                exact: true,
                KeepAlive: false,
                key: 3,
            },
            {
                name: 'TeamManagementHome',
                path: '/talent-teamManagement',
                component: TeamManagementHome,
                exact: true,
                KeepAlive: false,
                key: 4,
            },
            {
                name: 'EmployeeDetail',
                path: '/employee-detail',
                component: EmployeeDetail,
                exact: true,
                KeepAlive: false,
                key: 4,
            },
            {
                name: 'AdminSettings',
                path: '/admin-settings',
                component: AdminSettings,
                exact: true,
                KeepAlive: false,
                key: 5,
            },
            {
                name: 'ChangeSubscriptionPlan',
                path: '/change-subscriptionPlan',
                component: ChangeSubscriptionPlan,
                exact: true,
                KeepAlive: false,
                key: 5,
            },
        ],
    },
];

export default routes;
