import React, { useEffect, useState } from 'react';
import { Button, Form, FormProps, Input } from 'antd';
import VerificationCodeDialog from '@/components/modal/verification-code-dialog';
import { useNavigate } from 'react-router-dom';
import api from '@/api/login';
import { useAppDispatch } from '@/store/hooks';
import { setChartInfo, setProfileInfo } from '@/store/stateSlice';
import useLoading from '@/hook/useLoading';
import { useCountDown } from 'ahooks';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import usePasswordEncrypt from '@/hook/usePasswordEncrypt';
import { roleList } from '@/config/role';
function Login() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { encryptPassword, contextHolder } = usePasswordEncrypt();
    const messageApi = useGlobalMessage();
    const { setLoadingStatus } = useLoading();
    const [loading, setLoading] = useState(false);
    const [eyeOpen, setEyeOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [oldEmail, setOldEmail] = useState('');
    const [state, setState] = useState({
        email: '',
        password: '',
    });
    const [seconds, setSeconds] = useState<number>();
    const [codeArr, setCodeArr] = useState(['', '', '', '', '', '']);
    const [countdown] = useCountDown({
        targetDate: seconds,
        onEnd: () => {
            setSeconds(0);
            setIsActive(false);
        },
    });
    const sendForgetEmail = () => {
        if (seconds || isActive) return;
        api.sendEmailCode({ email: state.email }, res => {
            if (res.code === 200) {
                setOldEmail(state.email);
                setIsModalOpen(true);
                setSeconds(Date.now() + 60000);
                setIsActive(true);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const verificationEmailCode = () => {
        console.log(codeArr);
        api.verifyEmailCode(
            {
                email: state.email,
                code: codeArr.join(''),
            },
            res => {
                const { code, data } = res;
                if (code === 200) {
                    setCodeArr(['', '', '', '', '', '']);
                    handleCancel();
                    window.sessionStorage.setItem('code', codeArr.join(''));
                    window.sessionStorage.setItem('email', state.email);
                    navigate('/reset-password');
                } else {
                    window.utils.alert('error', res.message);
                }
            }
        );
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish: FormProps['onFinish'] = values => {
        setLoading(true);
        api.login({ email: state.email, password: encryptPassword(state.password) }, loginRes => {
            if (loginRes.code === 200) {
                window.sessionStorage.setItem('admin-satoken', loginRes.data.token);
                window.sessionStorage.setItem('accountId', loginRes.data.accountId);
                getSendBirdKey(loginRes.data.accountId);
                const val = JSON.parse(JSON.stringify(loginRes.data.businessAccountAdminPOJO));
                val.showName = window.utils.getInitials(val.firstName, val.lastName);
                val.roleNum = roleList[val.role];
                dispatch(setProfileInfo({ ...val }));
                if (
                    loginRes.data.businessAccountAdminPOJO &&
                    loginRes.data.businessAccountAdminPOJO.businessInfoPOJO.registerStepNumber !== 2
                ) {
                    window.sessionStorage.setItem(
                        'accountInfo',
                        JSON.stringify(loginRes.data.businessAccountAdminPOJO)
                    );
                    const step = loginRes.data.businessAccountAdminPOJO.businessInfoPOJO.registerStepNumber;
                    navigate(step === 0 ? '/team-management' : step === 1 ? '/subscription-plan' : '');
                    return;
                }
                navigate('/home');
            } else {
                messageApi.error(loginRes.message);
            }
            setLoading(false);
        });
    };
    const validatePassword = (_: any, value: string) => {
        const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z\d]{8,12}$/;
        if (!value || !passwordPattern.test(value)) {
            return Promise.reject(
                new Error(
                    'Please enter a password of 8-12, which must contain one uppercase letter, one lowercase letter, and one number.'
                )
            );
        }
        return Promise.resolve();
    };
    const getSendBirdKey = async id => {
        try {
            const res = await api.getSendBirdKey();
            const { code, data, message } = res.data;
            if (code === 200) {
                dispatch(
                    setChartInfo({
                        adminId: id,
                        sendbirdId: data.APPLICATION_ID,
                        sendbirdAccessToken: data.API_TOKEN,
                    })
                );
            } else {
                window.utils.alert('error', message);
            }
        } catch (error) {
            window.utils.alert('error', error);
        }
    };
    useEffect(() => {
        window.sessionStorage.clear();
        setLoadingStatus(false);
    }, []);
    return (
        <div className="login-main r-center">
            {contextHolder}
            <div className="center-box">
                <img className="logo-img" src={require('@/assets/img/Logo.png')} alt="" />
                <div className="login-formBox">
                    <Form
                        name="basic-login"
                        layout="vertical"
                        style={{ maxWidth: 380 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        autoComplete="off"
                        validateTrigger="onBlur"
                    >
                        <Form.Item
                            label="Email Address"
                            name="username"
                            rules={[
                                { required: true, message: 'Please enter a valid email address' },
                                {
                                    type: 'email',
                                    message: 'Please enter a valid email address',
                                },
                            ]}
                        >
                            <Input
                                className="r-input"
                                value={state.email}
                                onInput={(e: any) => setState({ ...state, email: e.target.value })}
                            />
                        </Form.Item>
                        <Form.Item label="Password" name="password" rules={[{ validator: validatePassword }]}>
                            <Input
                                className={`r-input `}
                                maxLength={12}
                                autoComplete="off"
                                placeholder={' '}
                                value={state.password}
                                onInput={(e: any) => setState({ ...state, password: e.target.value })}
                                type={eyeOpen ? 'text' : 'password'}
                                suffix={
                                    eyeOpen ? (
                                        <i className="eye-open" onClick={() => setEyeOpen(false)}></i>
                                    ) : (
                                        <i className="eye-close" onClick={() => setEyeOpen(true)}></i>
                                    )
                                }
                            />
                        </Form.Item>
                        <Form.Item>
                            <div className="forgetBox">
                                <span
                                    onClick={() => {
                                        if (!state.email || !window.utils.isEmail(state.email)) {
                                            messageApi.error('Please enter a valid email address');
                                            return;
                                        }
                                        if (oldEmail !== state.email || !isActive) {
                                            sendForgetEmail();
                                        } else {
                                            messageApi.error(
                                                'The operation is too frequent. Please try again later.  60s'
                                            );
                                        }
                                    }}
                                >
                                    Forgot password?
                                </span>
                            </div>
                            <Button
                                className="r-primary_btn"
                                htmlType="submit"
                                loading={loading}
                                disabled={!state.email || !state.password}
                            >
                                Log In
                            </Button>
                        </Form.Item>
                    </Form>

                    <div className="r-center btm-tip">
                        Don't have an account yet?
                        <div className="forgetBox" style={{ margin: '0 0 0 8px' }}>
                            <span onClick={() => navigate('/sign-up')}>Sign Up</span>
                        </div>
                    </div>
                </div>
            </div>
            <VerificationCodeDialog
                setCodeArr={setCodeArr}
                sendForgetEmail={sendForgetEmail}
                seconds={countdown}
                codeArr={codeArr}
                isActive={isActive}
                verificationEmailCode={verificationEmailCode}
                isModalOpen={isModalOpen}
                handleCancel={handleCancel}
            />
        </div>
    );
}

export default Login;
