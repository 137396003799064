import React, { useCallback, useEffect, useState } from 'react';
import './index.scss';
import { Button, DatePicker, Select, AutoComplete, Spin } from 'antd';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import PoolCardImage from './components/poolCardImage';
import AlertModal from '@/components/modal/alert-modal';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import jobApi from '@/api/job';
import api from '@/api/talentPool';
import { defaultInfo } from '@/typings/allType';
import SharePoolModal from './components/sharePool';
import LocationInp from '@/components/autoComplate-inp/locationInp';
const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > dayjs().endOf('day');
};
const { RangePicker } = DatePicker;
const TalentPool = () => {
    const navigate = useNavigate();
    const messageApi = useGlobalMessage();
    const [GMTList, setGMTList] = useState<defaultInfo[]>([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [shareModal, setShareModal] = useState(false);
    const [state, setState] = useState({
        search: '',
        pageNo: 1,
        gmtId: 'Any',
        status: 'Any',
        date: [] as any,
    });
    const [opInfo, setOpInfo] = useState<defaultInfo>({});
    const [opLoading, setOpLoading] = useState(false);
    const confirmDel = () => {
        setOpLoading(true);
        api.delPoolJobGroup({ ...opInfo }, res => {
            setOpLoading(false);
            if (res.code === 200) {
                setIsModalOpen(false);
                getList({ ...state, address: address });
            } else {
                messageApi.error(res.message);
            }
        });
    };
    // location
    const [address, setAddress] = useState('');
    // list
    const [data, setData] = useState<any>([]);
    const getList = val => {
        const pat = {
            beginTime: val.date && val.date[0] ? dayjs(val.date[0]).valueOf() / 1000 : '',
            endTime: val.date && val.date[1] ? Math.floor(dayjs(val[1]).endOf('day').valueOf() / 1000).toString() : '',
            gmtId: val.gmtId !== 'Any' ? val.gmtId : '',
            location: val.address !== 'Any' ? val.address : '',
            poolStatus: val.status !== 'Any' ? val.status : '',
        };
        setLoading(true);
        api.getTalentPoolList(pat, res => {
            setLoading(false);
            if (res.code === 200) {
                console.log(data);
                if (res.data && res.data.length) {
                    const arr = res.data.map(item => {
                        let newArr = [];
                        let pageMax = 1;
                        if (item.groupPOJOList && item.groupPOJOList.length) {
                            const { paginatedData, maxPage } = window.utils.paginateArray(item.groupPOJOList, 1, 3);
                            newArr = paginatedData;
                            pageMax = maxPage;
                        }
                        return Object.assign(item, {
                            pageNo: 1,
                            showList: newArr,
                            pageMax: pageMax,
                        });
                    });
                    setData([...arr]);
                }
            } else {
                messageApi.error(res.message);
            }
        });
    };
    useEffect(() => {
        jobApi.getGmtList(res => {
            if (res.code === 200) {
                const arr = res.data.map(item => Object.assign(item, { label: item.gmt, value: item.id }));
                setGMTList([{ label: 'Any', value: 'any' }, ...arr]);
            } else {
                messageApi.error(res.message);
            }
        });
        getList({ ...state, address: '' });
    }, []);
    return (
        <div className="r-pageContent talent-poolBox">
            <div className="r-left">
                <h2>Talent Pool</h2>
                <Button
                    className="r-primary_btn r-medium40_btn marLeft"
                    onClick={() => navigate('/talent-pool-info?type=add')}
                    style={{ width: 195 }}
                >
                    Create New Talent Pool
                </Button>
            </div>
            <div className="r-left filterPool-top">
                <div className="sel-filterPool">
                    <p className="poolfilter-tit">Timezone</p>
                    <Select
                        className="r-select_pool"
                        placeholder=""
                        suffixIcon={<i className="down-right-icon"></i>}
                        options={GMTList}
                        value={state.gmtId}
                        allowClear
                        onChange={val => {
                            if (!val) {
                                setState({ ...state, gmtId: 'Any' });
                                getList({ ...state, gmtId: 'Any' });
                                return;
                            }
                            setState({ ...state, gmtId: val });
                            getList({ ...state, gmtId: val });
                        }}
                    />
                </div>
                <div className="sel-filterPool">
                    <p className="poolfilter-tit">Status</p>
                    <Select
                        className="r-select_pool"
                        placeholder=""
                        defaultValue={'1'}
                        suffixIcon={<i className="down-right-icon"></i>}
                        value={state.status}
                        allowClear
                        onChange={val => {
                            if (!val) {
                                setState({ ...state, status: 'Any' });
                                getList({ ...state, status: 'Any' });
                                return;
                            }
                            setState({ ...state, status: val });
                            getList({ ...state, status: val });
                        }}
                        options={[
                            { label: 'Any', value: 'Any' },
                            { label: 'Actively Interested', value: 'activelyInterested' },
                            { label: 'Passively Interested', value: 'passivelyInterested' },
                        ]}
                    />
                </div>
                <div className="sel-filterPool">
                    <p className="poolfilter-tit">Location</p>
                    <LocationInp
                        setLocation={val => {
                            setAddress(val.address);
                            getList({ ...state, address: val.address });
                        }}
                        suffixIcon={<i className="downPool-right-icon"></i>}
                        classStr="r-autoComplate r-autoComplate-pool"
                    />
                </div>
                <div className="sel-filterPool">
                    <p className="poolfilter-tit">Date Added</p>
                    <RangePicker
                        className="r-input_pool"
                        placeholder={['Any', '']}
                        disabledDate={disabledDate}
                        value={state.date}
                        format={'MMM DD YYYY'}
                        separator={<></>}
                        onChange={val => {
                            setState({ ...state, date: val });
                            getList({ ...state, date: val });
                        }}
                        suffixIcon={<i className="down-right-icon"></i>}
                    />
                </div>
            </div>
            {loading ? (
                <>
                    <div className="r-center no-message-tabBox">
                        <Spin></Spin>
                    </div>
                </>
            ) : (
                <div className="pool-listBox">
                    {data.length ? (
                        data.map((val, i) => {
                            return (
                                <div className="poolJobList-box" key={`poolJobList-box-${i}`}>
                                    <div className="r-left poolCard-top">
                                        {val.poolName ?? '-'}
                                        {val.shareFlag ? null : (
                                            <Button
                                                className="r-primary_btn r-primaryNoBg_btn"
                                                style={{ width: 68, marginLeft: 20 }}
                                                onClick={() => {
                                                    window.sessionStorage.setItem('poolInfo', JSON.stringify(val));
                                                    navigate('/talent-pool-info?type=edit');
                                                }}
                                            >
                                                Manage
                                            </Button>
                                        )}
                                        {val.pageMax > 1 ? (
                                            <div className="r-left marLeft">
                                                <i
                                                    className="coverLeft-icon"
                                                    style={{ opacity: val.pageNo === 1 ? '0.5' : '' }}
                                                    onClick={() => {
                                                        if (val.pageNo === 1) return;
                                                        const { paginatedData } = window.utils.paginateArray(
                                                            val.groupPOJOList,
                                                            val.pageNo - 1,
                                                            3
                                                        );
                                                        data[i].pageNo = val.pageNo - 1;
                                                        data[i].showList = paginatedData;
                                                        setData([...data]);
                                                    }}
                                                ></i>
                                                <i
                                                    className="coverRight-icon"
                                                    style={{ opacity: val.pageNo === val.pageMax ? '0.5' : '' }}
                                                    onClick={() => {
                                                        if (val.pageNo === val.pageMax) return;
                                                        const { paginatedData } = window.utils.paginateArray(
                                                            val.groupPOJOList,
                                                            val.pageNo + 1,
                                                            3
                                                        );
                                                        data[i].pageNo = val.pageNo + 1;
                                                        data[i].showList = paginatedData;
                                                        setData([...data]);
                                                    }}
                                                ></i>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="r-left">
                                        {val.groupPOJOList.length ? (
                                            val.showList.map((poolVal, k) => {
                                                return (
                                                    <div className="poolcardkey-box" key={`poolcardkey${i}-${k}`}>
                                                        <div
                                                            className="cursorPointer"
                                                            onClick={() => {
                                                                window.sessionStorage.setItem(
                                                                    'poolJobInfo',
                                                                    JSON.stringify({
                                                                        val: poolVal,
                                                                        poolId: val.shareFlag
                                                                            ? poolVal.poolId
                                                                            : val.poolId,
                                                                    })
                                                                );
                                                                navigate('/pipelines-board');
                                                            }}
                                                        >
                                                            <PoolCardImage userList={poolVal.vtalentPoolAccounts} />
                                                            <p className="poolJob-name oneLineText">
                                                                {poolVal.jobTitle ?? '-'}
                                                            </p>
                                                            <p className="poolJobuser-num">
                                                                {poolVal.candidates}{' '}
                                                                {poolVal.candidates > 1 ? 'Candidates' : 'Candidate'}{' '}
                                                            </p>
                                                            <p className="line-boxNew"></p>
                                                        </div>
                                                        <div className="r-left" style={{ marginTop: 12 }}>
                                                            <Button
                                                                className="r-primary_btn r-medium14_btn"
                                                                style={{
                                                                    width: 95,
                                                                    display: poolVal.shareFrom ? 'none' : 'flex',
                                                                }}
                                                                onClick={() => {
                                                                    setOpInfo({ poolId: val.poolId, val: poolVal });
                                                                    setShareModal(true);
                                                                }}
                                                            >
                                                                <i className="share-icon"></i>
                                                                Share
                                                            </Button>
                                                            {poolVal.shareWith && poolVal.shareWithMembers > 0 ? (
                                                                <div style={{ margin: '0 5px 0 10px' }}>
                                                                    <p className="share-tipBox">Shared with</p>
                                                                    <div className="shareNum-box oneLineText">
                                                                        {poolVal.shareWithMembers || 0} team{' '}
                                                                        {poolVal.shareWithMembers > 1
                                                                            ? 'members'
                                                                            : 'member'}
                                                                    </div>
                                                                </div>
                                                            ) : poolVal.shareFrom ? (
                                                                <div style={{ margin: '0 5px 0 0px' }}>
                                                                    <div className="share-tipBox">
                                                                        Sharing from team members
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                            <Button
                                                                onClick={() => {
                                                                    setOpInfo({
                                                                        poolId: val.poolId,
                                                                        groupId: poolVal.groupId,
                                                                    });
                                                                    setIsModalOpen(true);
                                                                }}
                                                                className="r-danger_btn r-medium14_btn marLeft"
                                                            >
                                                                Remove
                                                            </Button>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className="noJobGroup-tip r-center">No job group yet</div>
                                        )}
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <>
                            <div className="no-message-tabBox r-center">No yet</div>
                        </>
                    )}
                </div>
            )}

            <AlertModal
                title=""
                message={'Are you sure you want to remove the talent pool for this job?'}
                cancelText={'Cancel'}
                confirmText={'Confirm'}
                isModalOpen={isModalOpen}
                loading={opLoading}
                handleOk={() => confirmDel()}
                handleCancel={() => {
                    setOpInfo({});
                    setIsModalOpen(false);
                }}
            />

            <SharePoolModal
                shareModal={shareModal}
                setShareModal={setShareModal}
                info={opInfo}
                handOK={() => {
                    setOpInfo({});
                    getList({ ...state, address });
                }}
            />
        </div>
    );
};

export default TalentPool;
