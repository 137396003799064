import { Button, Image, Input, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import './index.scss';
import { roleList } from '@/config/role';
import SliderTabs from '@/components/slideeTabs';
import JobList from '../home/components/job-list';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AlertModal from '@/components/modal/alert-modal';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import api from '@/api/team';
import { defaultInfo } from '@/typings/allType';
import TeamMemberModal from '@/components/modal/team-member-modal';
import FormModal from '@/components/modal/form-modal';

function EmployeeDetail() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isUserModalOpen, setIsUserModalOpen] = useState(false);
    const messageApi = useGlobalMessage();
    const { profileInfo } = useSelector((state: any) => state.state);
    const [userInfo, setUserInfo] = useState<any>({});
    const [editInfo, setEditInfo] = useState<any>({});
    const [modalType, setModalType] = useState(1); // 1 达到最大席位 2 禁止访问 3 移除团队成员 4 把子管理员角色改为普通成员 5 把团队角色指定为管理 6 移交admin权限
    const alertModalTipList = [
        {
            type: 1,
            title: 'Your team has reached its maximum number of seats.',
            cancelText: 'Cancel',
            confirmText: 'Change subscription',
        },
        {
            type: 2,
            title: 'Are you sure you want to revoke access to this account? This account will not be able to log into Talenaut.',
            cancelText: 'Cancel',
            confirmText: 'Confirm',
        },
        {
            type: 3,
            title: 'Are you sure you want to remove this member from the team?',
            cancelText: 'Cancel',
            confirmText: 'Confirm',
        },
        {
            type: 4,
            title: 'Are you sure you want to revoke the administrator role of this member?',
            cancelText: 'Cancel',
            confirmText: 'Confirm',
        },
        {
            type: 5,
            title: 'Are you sure you want to assign the administrator role of this member?',
            cancelText: 'Cancel',
            confirmText: 'Confirm',
        },
        {
            type: 6,
            title: 'Are you sure you wish to save this changes? After the authority transfer, you need to log in again.',
            cancelText: 'Cancel',
            confirmText: 'Confirm',
        },
    ];
    const [modalText, setModalText] = useState(alertModalTipList[0]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const opeationBtnClcik = (type: number) => {
        if (type === 2 && userInfo?.disabledTime) {
            api.undisabledTeam({ accountId: userInfo.accountId }, res => {
                if (res.code === 200) {
                    // getList({ ...state });
                    setUserInfo({ ...userInfo, disabledTime: 0 });
                } else {
                    messageApi.error(res.message);
                }
            });
            return;
        }
        setModalType(type);
        setModalText(alertModalTipList[type - 1]);
        setIsModalOpen(true);
    };
    const handleOk = () => {
        // 确认操作
        setOpLoading(true);
        if (modalType === 2) {
            api.disabledTeam({ accountId: userInfo.accountId }, res => {
                setOpLoading(false);
                if (res.code === 200) {
                    // getList({ ...state });
                    setUserInfo({ ...userInfo, disabledTime: '999' });
                    setIsModalOpen(false);
                } else {
                    messageApi.error(res.message);
                }
            });
        } else if (modalType === 3) {
            api.deleteBusinessTeam({ accountId: userInfo.accountId }, res => {
                setOpLoading(false);
                if (res.code === 200) {
                    setIsModalOpen(false);
                    navigate('/talent-teamManagement');
                } else {
                    messageApi.error(res.message);
                }
            });
        } else if (modalType === 4 || modalType === 5) {
            editInfo.accountId = userInfo.accountId;
            api.editBusinessTeam(editInfo, res => {
                setOpLoading(false);
                setLoading(false);
                if (res.code === 200) {
                    setIsModalOpen(false);
                    setIsUserModalOpen(false);
                    setUserInfo({ ...userInfo, ...editInfo, roleNum: roleList[editInfo.role] });
                } else {
                    messageApi.error(res.message);
                }
            });
        } else if (modalType === 7) {
            // 移交工作
            api.transferJob({ jobId: opInfo.jobId, businessAccountId: chooseAdInfo.accountId }, res => {
                setOpLoading(false);
                if (res.code === 200) {
                    setIsModalOpen(false);
                    setIsUserModalOpen(false);
                    setIsFormModalOpen(false);
                    // 移交后重新获取
                    getList({
                        ...state,
                        pageNo: 1,
                        accountId: userInfo.accountId,
                        status: jobStatusTab[selectedType == 1 ? 0 : 1].value,
                    });
                } else {
                    messageApi.error(res.message);
                }
            });
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        console.log(modalType);
    };
    const editUserInfo = val => {
        if (val.role !== userInfo.role) {
            setEditInfo(val);
            if (val.role === 'Administrator' && userInfo.role !== 'Administrator') {
                // 把团队角色指定为管理
                opeationBtnClcik(5);
                return;
            }
            if (val.role !== 'Administrator' && userInfo.role === 'Administrator') {
                // 把子管理员角色改为普通成员
                opeationBtnClcik(4);
                return;
            }
        }
        val.accountId = userInfo.accountId;
        api.editBusinessTeam(val, res => {
            setLoading(false);
            if (res.code === 200) {
                setIsUserModalOpen(false);
                setUserInfo({ ...userInfo, ...val, roleNum: roleList[val.role] });
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const [state, setState] = useState({
        search: '',
        pageNo: 1,
    });
    const searchList: any = useCallback(
        debounce(params => {
            setData([]);
            getList({ ...params });
        }, 1000),
        []
    );
    const [selectedType, setSelectedType] = useState(1);
    const [jobStatusTab, setJobStatusTab] = useState([
        { value: 'Active', label: 'Active (0)', key: 1 },
        { value: 'Archived', label: 'Archive (0)', key: 3 },
    ]);
    const changeJobStatus = (key: number) => {
        setSelectedType(key);
        setState({ ...state, pageNo: 1, search: '' });
        getList({
            ...state,
            search: '',
            status: jobStatusTab[key === 1 ? 0 : 1].value,
            pageNo: 1,
            accountId: userInfo.accountId,
        });
        setData([]);
    };
    const [data, setData] = useState<any>([]);
    const [noMore, setNoMore] = useState(false);
    useEffect(() => {
        setNoMore(state.pageNo >= total);
    }, [state.pageNo]);
    const [total, setTotal] = useState(0);
    const [listLoading, setListLoading] = useState(false);

    const getList = val => {
        setListLoading(true);
        const pat = {
            businessAccountId: val.accountId,
            jobStatus: val.status,
            pageNo: val.pageNo,
            pageSize: 10,
            keyWord: val.search,
        };
        api.getTeamJobList({ ...pat }, res => {
            setListLoading(false);
            if (res.code === 200) {
                setLoading(false);
                setTotal(res.data.totalPage);
                setNoMore(state.pageNo >= res.data.totalPage);
                setJobStatusTab([
                    { value: 'Active', label: `Active (${res.data.pojo.activeJobs || 0})`, key: 1 },
                    { value: 'Archived', label: `Archive (${res.data.pojo.archiveJobs || 0})`, key: 3 },
                ]);
                if (res.data.list) {
                    setData(prevItems => [
                        ...prevItems,
                        ...res.data.list.map(item => {
                            return Object.assign(item, {
                                jobTypeShow:
                                    item.jobsPOJO.jobType === '1'
                                        ? 'Full-Time'
                                        : item.jobsPOJO.jobType === '2'
                                        ? 'Part-Time'
                                        : item.jobsPOJO.jobType === '3'
                                        ? 'Contractor'
                                        : 'Full-Time',
                                deadlineTimeShow: item.jobsPOJO.deadline
                                    ? window.utils.formatTimeDifference(item.jobsPOJO.deadline)
                                    : '-',
                                businessNameLabel: `${item.jobsPOJO.businessAccountPOJO.firstName} ${item.jobsPOJO.businessAccountPOJO.middleName} ${item.jobsPOJO.businessAccountPOJO.lastName}`,
                            });
                        }),
                    ]);
                } else {
                    setData([]);
                }
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const [teamList, setTeamList] = useState<any>([]);
    useEffect(() => {
        const info = JSON.parse(window.sessionStorage.getItem('teamInfo') ?? "{accountId:''}");
        const list = JSON.parse(window.sessionStorage.getItem('teamList') ?? '[]');
        if (info.accountId) {
            setUserInfo(info);
            getList({ ...state, accountId: info.accountId, status: jobStatusTab[selectedType == 1 ? 0 : 1].value });
            const index = list.findIndex(v => v.accountId === info.accountId);
            list.splice(index, 1);
            setTeamList([...list]);
        }
    }, []);
    // 移交job
    const transferJob = val => {
        console.log(val);
        const obj = {
            ...val,
            namelabel: `${val.jobsPOJO.businessAccountPOJO.firstName} ${val.jobsPOJO.businessAccountPOJO.middleName} ${val.jobsPOJO.businessAccountPOJO.lastName}`,
        };
        setOpInfo({ ...obj });
        setIsFormModalOpen(true);
    };
    const [opInfo, setOpInfo] = useState<any>({ namelabel: '' });
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [opLoading, setOpLoading] = useState(false);
    const [chooseAd, setChooseAd] = useState('');
    const [chooseAdInfo, setChooseAdInfo] = useState<any>({ role: '' });
    const openTransJobModal = () => {
        setModalType(7);
        setModalText({
            type: 7,
            title: `Are you sure you want to transfer the job to ${chooseAdInfo.label}?`,
            cancelText: 'Cancel',
            confirmText: 'Confirm',
        });
        setIsModalOpen(true);
    };
    useEffect(() => {
        if (!isFormModalOpen) {
            setChooseAd('');
            setChooseAdInfo({ role: '' });
        }
    }, [isFormModalOpen]);
    const [isSearch, setIsSearch] = useState(false);
    return (
        <div className="employ-detailBox">
            <div className="jobItem-card r-left">
                <div className="job-infoBox">
                    <div className="r-left job-tit">
                        <div>
                            {userInfo.avatarUrl || userInfo.picUrlShow ? (
                                <Image
                                    width={32}
                                    height={32}
                                    preview={false}
                                    style={{ borderRadius: '50%', minWidth: 32 }}
                                    src={userInfo.picUrlShow ? userInfo.picUrlShow : userInfo.avatarUrl}
                                    fallback={require('@/assets/img/default-min.png')}
                                />
                            ) : (
                                <div className="avatar-textBox">{userInfo.showName}</div>
                            )}
                        </div>
                        <span
                            style={{ marginLeft: 10 }}
                        >{`${userInfo.firstName} ${userInfo.middleName} ${userInfo.lastName}`}</span>
                        {profileInfo.roleNum > 1 ? null : (
                            <div className="marLeft r-left">
                                {userInfo.roleNum > 1 ? (
                                    <Button
                                        className="r-danger_btn marLeft"
                                        style={{ width: 92 }}
                                        onClick={() => {
                                            opeationBtnClcik(3);
                                        }}
                                    >
                                        Remove
                                    </Button>
                                ) : null}
                                {/* 管理员不显示禁用 */}
                                {userInfo.roleNum !== 0 ? (
                                    <Button
                                        className={`r-danger_btn  ${
                                            userInfo.disabledTime ? 'r-success_btn' : 'r-dangerNoBg_btn'
                                        }`}
                                        style={{ width: 143, margin: '0 16px' }}
                                        loading={loading}
                                        onClick={() => {
                                            opeationBtnClcik(2);
                                        }}
                                    >
                                        {userInfo.disabledTime ? 'Restore Access' : 'Revoke Access'}
                                    </Button>
                                ) : null}
                                <Button
                                    className="r-primary_btn r-primaryNoBg_btn r-center"
                                    style={{ width: 116 }}
                                    onClick={() => setIsUserModalOpen(true)}
                                >
                                    Edit Profile
                                </Button>
                            </div>
                        )}
                    </div>
                    <div className="r-left job-infoBtm_new">
                        <div className="r-left">
                            Email
                            <div className="info-msg">{userInfo.email ?? '-'}</div>
                        </div>
                        <div className="r-left" style={{ margin: '0 35px' }}>
                            Position
                            <div className="info-msg">{userInfo.position ?? '-'}</div>
                        </div>
                        <div className="r-left">
                            Role
                            <div className="info-msg">{userInfo.role ?? '-'}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="job-list">
                <div className="r-left job-tit">
                    Jobs
                    <div className="marLeft r-left">
                        <Input
                            placeholder=""
                            style={{ width: 483, marginRight: 16 }}
                            allowClear
                            value={state.search}
                            onChange={e => {
                                if (listLoading || loading) return;
                                searchList({
                                    ...state,
                                    search: e.target.value,
                                    pageNo: 1,
                                    accountId: userInfo.accountId,
                                    status: jobStatusTab[selectedType === 1 ? 0 : 1].value,
                                });
                                setIsSearch(e.target.value ? true : false);
                                setState({ ...state, search: e.target.value, pageNo: 1 });
                            }}
                            className="r-input r-input_serach"
                            prefix={<span className="search_icon"></span>}
                        />
                        <SliderTabs
                            setSelectedType={key => {
                                if (listLoading || loading) return;
                                changeJobStatus(key);
                            }}
                            selectedType={selectedType}
                            tabList={jobStatusTab}
                        />
                    </div>
                </div>
                <JobList
                    data={data}
                    setData={setData}
                    transferJob={val => transferJob(val)}
                    selectedType={selectedType}
                    noMore={noMore}
                    pageType={'team'}
                    listLoading={listLoading}
                    loading={loading}
                    setLoading={setLoading}
                    setState={setState}
                    isSearch={isSearch || false}
                    getList={() => {
                        setState({ ...state, pageNo: state.pageNo + 1 });
                        getList({ ...state, pageNo: state.pageNo + 1, status: jobStatusTab[selectedType - 1].value });
                    }}
                    getListReset={() => {
                        setData([]);
                        getList({
                            ...state,
                            status: jobStatusTab[selectedType - 1].value,
                        });
                    }}
                />
            </div>
            {/* 弹窗 */}
            <AlertModal
                title=""
                message={modalText.title}
                cancelText={modalText.cancelText}
                confirmText={modalText.confirmText}
                isModalOpen={isModalOpen}
                loading={loading || opLoading}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
            {/* 修改信息 */}
            <TeamMemberModal
                opInfo={userInfo}
                type={'homeTeam'}
                isModalOpen={isUserModalOpen}
                addTeamInfo={editUserInfo}
                handleCancel={() => {
                    setIsUserModalOpen(false);
                }}
            />
            {/* 移交工作 */}
            <FormModal
                title="Transfer Job"
                width={380}
                marBtm={10}
                isModalOpen={isFormModalOpen}
                handleOk={() => setIsFormModalOpen(false)}
                handleCancel={() => setIsFormModalOpen(false)}
                children={
                    <div className="auth-modal-box">
                        <p className="form-tip" style={{ marginTop: 20 }}>
                            Current Recruiter
                        </p>
                        <Input className="r-input" value={opInfo.namelabel} readOnly />
                        <p className="form-tip" style={{ marginTop: 20 }}>
                            Transfer Ownership To
                        </p>
                        <Select
                            className="r-select"
                            allowClear
                            onChange={val => {
                                if (!val) {
                                    setChooseAd('');
                                    setChooseAdInfo({ role: '' });
                                    return;
                                }
                                const info = teamList.find(v => v.accountId === val);
                                setChooseAd(val);
                                setChooseAdInfo(info);
                            }}
                            value={chooseAd}
                            suffixIcon={<i className="down-right-icon"></i>}
                            options={teamList}
                        />
                        <p className="form-tip" style={{ marginTop: 20 }}>
                            Role
                        </p>
                        <Input className="r-input" readOnly value={chooseAdInfo.role} />
                        <div className="r-center">
                            <Button
                                style={{ width: 106, marginTop: 40 }}
                                className="r-primary_btn r-primary_largeBtn"
                                disabled={!chooseAd}
                                onClick={() => openTransJobModal()}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                }
            />
        </div>
    );
}

export default EmployeeDetail;
