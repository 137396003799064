import React, { useCallback, useEffect, useState } from 'react';
import './style/index.scss';
import LinkedInIcon from '@/assets/img/LinkedIn_icon.png';
import TalenautIcon from '@/assets/img/logo-mini.png';
import GoogleIcon from '@/assets/img/Google.png';
import { Button, DatePicker, Image, Input, Select, Spin, TimePicker } from 'antd';
import SliderTabs from '@/components/slideeTabs';
import CandidateFilter from './components/candidate-filter';
import RCheckBox from '@/components/checkbox';
import { debounce } from 'lodash';
import AlertModal from '@/components/modal/alert-modal';
import FormModal from '@/components/modal/form-modal';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import RoundDrawer from '@/components/r-drawer/r-drawer';
import { useNavigate } from 'react-router-dom';
import api from '@/api/job';
import { defaultInfo } from '@/typings/allType';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import { useSelector } from 'react-redux';
import { setNowChartUser } from '@/store/stateSlice';
import { useAppDispatch } from '@/store/hooks';
const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current < dayjs().startOf('day');
};
function JobDetail() {
    const navigate = useNavigate();
    const messageApi = useGlobalMessage();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedType, setSelectedType] = useState(1);
    const [selectedScheduledType, setSelectedScheduledType] = useState(1);
    const [pageLabel, setPageLabel] = useState([
        { val: 'Sourcing', label: 'Sourcing (0)', key: 1 },
        { val: 'Screening', label: 'Screening (0)', key: 2 },
        { val: 'Interviewing', label: 'Interviewing (0)', key: 3 },
        { val: 'Offered', label: 'Offered (0)', key: 4 },
        { val: 'Rejected', label: 'Rejected (0)', key: 5 },
    ]);
    const [pageSchedLabel, setPageSchedLabel] = useState([
        { val: 'Un-Scheduled', label: 'Un-Scheduled (0)', key: 1 },
        { val: 'Scheduled', label: 'Scheduled (0)', key: 2 },
    ]);
    const [filterInfo, setFilterInfo] = useState({
        gmtId: '',
        keyWord: '',
        highestDegree: [],
        location: [],
        remoteFlag: 0,
        hardSkills: [],
        softSkills: [],
        sourceFrom: [],
        yearsOfExperience: [],
    });
    const [state, setState] = useState({
        search: '',
        pageNo: 1,
    });
    // 切换状态
    const changeTabLink = key => {
        setSelectedType(key);
        if (key > 2) {
            setFilterInfo({
                gmtId: '',
                keyWord: '',
                highestDegree: [],
                location: [],
                remoteFlag: 0,
                hardSkills: [],
                softSkills: [],
                sourceFrom: [],
                yearsOfExperience: [],
            });
        }
        setState({ ...state, search: '', pageNo: 1 });
        setSelectedScheduledType(1);
        setUserList([]);
        getJobAppUsers({
            ...state,
            gmtId: '',
            keyWord: '',
            highestDegree: [],
            location: [],
            remoteFlag: 0,
            hardSkills: [],
            softSkills: [],
            sourceFrom: [],
            yearsOfExperience: [],
            pageNo: 1,
            search: '',
            jobId: localJobInfo.jobId,
            applicationsStatus: pageLabel[key - 1].val,
            interviewingStatus: key === 3 ? (selectedScheduledType === 1 ? 'Un-Scheduled' : 'Scheduled') : 'all',
        });
    };
    const searchList: any = useCallback(
        debounce(params => {
            setUserList([]);
            getJobAppUsers({ ...params });
        }, 1000),
        []
    );
    const changeScheduleType = (type: number) => {
        setUserList([]);
        setSelectedScheduledType(type);
    };
    // 面试时间
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [interViewTime, setInterviewTime] = useState({
        day: '',
        startTime: '' as any,
        endTime: '' as any,
    });
    const [modalType, setModalType] = useState(1); // 1 修改工作状态进入下一阶段(开始面试) 2 拒绝候选人 3 提示绑定日历 4 面试日有期冲突 5 聊天  6<selectType =1 下一步。selectType=2 约面试> 7 发offer 8 标记已面试
    const alertModalTipList = [
        {
            type: 1,
            title: 'Are you sure you want to take the recruitment process for this job to the interview stage?',
            cancelText: 'Cancel',
            confirmText: 'Confirm',
        },
        {
            type: 2,
            title: 'Are you sure you want to reject this candidate?',
            cancelText: 'Cancel',
            confirmText: 'Confirm',
        },
        {
            type: 3,
            title: 'You can start by linking Google with your Outlook calendar to alert you when an interview is coming up.',
            cancelText: 'Cancel',
            confirmText: 'Connected Calendar',
        },
        {
            type: 4,
            title: 'You already have an interview scheduled between 12:45 pm to 1:30 pm on January 30, 2024. Do you need to reschedule this interview?',
            cancelText: 'Modification time',
            confirmText: 'Confirmation interview ',
        },
    ];
    const [modalText, setModalText] = useState(alertModalTipList[0]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toEditJob = () => {
        window.sessionStorage.setItem('jobInfoForm', JSON.stringify({ ...localJobInfo, type: 'edit' }));
        navigate(`/job/job-info?f=editJob`);
    };
    // 提示弹窗二次确认
    const handleOk = () => {
        setOpLoading(true);
        if (modalType === 1) {
            // 修改顶部工作卡片状态
            // status =source 开始面试 & status= interview 归档 & status=archived 复制工作
            if (nowJobInfo.jobsPOJO.jobStepStatus === 'In Interview') {
                api.setJobToArchive({ jobId: localJobInfo.jobId }, res => {
                    setOpLoading(false);
                    const { code, data, message } = res;
                    if (code === 200) {
                        getJobInfo({ jobId: localJobInfo.jobId });
                        setIsModalOpen(false);
                    } else {
                        messageApi.error(message);
                    }
                });
                return;
            }
            api.setJobToInInterview({ jobId: localJobInfo.jobId }, res => {
                setOpLoading(false);
                const { code, data, message } = res;
                if (code === 200) {
                    getJobInfo({ jobId: localJobInfo.jobId });
                    setIsModalOpen(false);
                } else {
                    messageApi.error(message);
                }
            });
        }
        if (modalType === 2) {
            // 拒绝候选人
            api.setRejectToUser({ applicationsId: opInfo.applicationsId }, res => {
                setOpLoading(false);
                const { code, data, message } = res;
                if (code === 200) {
                    setUserList([]);
                    getJobAppUsers({
                        ...state,
                        ...filterInfo,
                        jobId: localJobInfo.jobId,
                        applicationsStatus: pageLabel[selectedType - 1].val,
                        interviewingStatus: pageSchedLabel[selectedScheduledType - 1].val,
                    });
                } else {
                    messageApi.error(message);
                }
            });
        } else if (modalType === 3) {
            navigate('/admin-settings');
        }
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        if (modalType === 3) {
            setIsFormModalOpen(true);
        }
        setOpLoading(false);
    };
    const { profileInfo } = useSelector((state: any) => state.state);
    const [opInfo, setOpInfo] = useState<any>({});
    const [opLoading, setOpLoading] = useState(false);
    // 操作工作 & 招聘者 按钮
    const dispatch = useAppDispatch();
    const changeModalType = (type: number, val?: any) => {
        setModalType(type);
        setOpInfo(val);
        console.log(val);
        if (type === 5) {
            const pat = {
                namelabel: val.namelabel,
                profilePhoto: val.accountPOJO.accountInformationPOJO.profilePhoto,
                jobRole: val.accountPOJO.accountInformationPOJO.jobRole,
                accountId: val.accountId,
                jobShow: localJobInfo.jobsPOJO.jobTitle || '',
                companyLogo: localJobInfo.jobsPOJO.employerLogo || '',
                companyShow: localJobInfo.jobsPOJO.employerName || '',
            };
            console.log('====================================');
            console.log(pat);
            console.log('====================================');
            dispatch(setNowChartUser({ ...pat }));
            navigate('/message-chat?type=1');
            return;
        }
        if (type == 6) {
            // 前2个状态操作按钮
            if (selectedType === 1) {
                // 把候选人放到screening列表
                api.setScreenToUser({ applicationsId: val.applicationsId }, res => {
                    const { code, data, message } = res;
                    if (code === 200) {
                        setUserList([]);
                        getJobAppUsers({
                            ...state,
                            ...filterInfo,
                            jobId: localJobInfo.jobId,
                            applicationsStatus: pageLabel[selectedType - 1].val,
                            interviewingStatus: 'all',
                        });
                    } else {
                        messageApi.error(message);
                    }
                });
                return;
            }
            const isAlertLinkCalendar = window.localStorage.getItem('isAlertLinkCalendar') || '0';
            if (isAlertLinkCalendar === '0' && !profileInfo.calendarGoogle && !profileInfo.calendarOutlook) {
                // 提示绑定日历
                changeModalType(3);
                window.localStorage.setItem('isAlertLinkCalendar', '1');
            } else {
                // 打开面试时间表单
                setIsFormModalOpen(true);
            }
            return;
        }
        if (type === 8) {
            // 标记面试完成
            api.setInterviewFinshToUser({ applicationsId: val.applicationsId }, res => {
                const { code, data, message } = res;
                if (code === 200) {
                    setUserList([]);
                    getJobAppUsers({
                        ...state,
                        ...filterInfo,
                        jobId: localJobInfo.jobId,
                        applicationsStatus: pageLabel[selectedType - 1].val,
                        interviewingStatus: pageSchedLabel[selectedScheduledType - 1].val,
                    });
                } else {
                    messageApi.error(message);
                }
            });
            return;
        }
        if (type === 7) {
            // 发offer
            api.setOfferToUser({ applicationsId: val.applicationsId }, res => {
                const { code, data, message } = res;
                if (code === 200) {
                    setUserList([]);
                    getJobAppUsers({
                        ...state,
                        ...filterInfo,
                        jobId: localJobInfo.jobId,
                        applicationsStatus: pageLabel[selectedType - 1].val,
                        interviewingStatus: pageSchedLabel[selectedScheduledType - 1].val,
                    });
                } else {
                    messageApi.error(message);
                }
            });
            return;
        }
        if (type === 1) {
            // 顶部工作card 修改工作状态
            if (nowJobInfo.jobsPOJO.jobStepStatus === 'Archvie') {
                messageApi.success('复制工作');
                return;
            }
            const obj = alertModalTipList[type - 1];
            obj.title =
                nowJobInfo.jobsPOJO.jobStepStatus === 'In Interview'
                    ? 'Are you sure you want to close this job?'
                    : obj.title;
            setModalText(obj);
        } else {
            setModalText(alertModalTipList[type - 1]);
        }
        setIsModalOpen(true);
    };
    const opUserToNext = val => {
        if (selectedType === 1 || selectedType === 2) {
            changeModalType(6, val);
        } else if (selectedType == 3) {
            changeModalType(8, val);
        }
    };
    // 发送面试邀请
    const [isInterViewModalOpen, setIsInterViewModalOpen] = useState(false);
    const [interViewModalMsg, setinterViewModalMsg] = useState(
        <>
            You already have an interview scheduled between 12:45 pm to 1:30 pm on January 30, 2024. Do you need to
            reschedule this interview?
        </>
    );
    const confirmSendInterview = () => {
        const { startTime, endTime } = interViewTime;
        if (dayjs(startTime).isAfter(endTime)) {
            messageApi.error('Please set the right time for the interview.');
            return;
        }
        const day = dayjs(interViewTime.day).format('YYYY-MM-DD');
        const start = dayjs(interViewTime.startTime).format('HH:mm');
        const end = dayjs(interViewTime.endTime).format('HH:mm');
        const pat = {
            applicationsId: opInfo.applicationsId,
            interviewStartTime: dayjs(`${day} ${start}`).valueOf() / 1000,
            interviewEndTime: dayjs(`${day} ${end}`).valueOf() / 1000,
        };
        setOpLoading(true);
        api.setInterviewToUserCheck(pat, res => {
            if (res.code === 200) {
                confirmInterView();
            } else if (res.code === 50000) {
                setOpLoading(false);
                messageApi.error(res.message);
            } else {
                setOpLoading(false);
                // messageApi.error(res.message);
                setinterViewModalMsg(
                    <>
                        You already have an interview scheduled between{' '}
                        <span className="blue-text">{res.data.startDateTime || '-'}</span> to{' '}
                        <span className="blue-text">{res.data.endDateTime || '-'}</span> on{' '}
                        <span className="blue-text">{res.data.date || '-'}</span>. Do you need to reschedule this
                        interview?
                    </>
                );
                setIsInterViewModalOpen(true);
            }
        });
    };
    const confirmInterView = () => {
        setOpLoading(true);
        const day = dayjs(interViewTime.day).format('YYYY-MM-DD');
        const start = dayjs(interViewTime.startTime).format('HH:mm');
        const end = dayjs(interViewTime.endTime).format('HH:mm');
        console.log(`${day} ${start}`);
        const pat = {
            applicationsId: opInfo.applicationsId,
            interviewStartTime: dayjs(`${day} ${start}`).valueOf() / 1000,
            interviewEndTime: dayjs(`${day} ${end}`).valueOf() / 1000,
        };
        api.setInterviewToUser(pat, res => {
            setOpLoading(false);
            const { code, data, message } = res;
            if (code === 200) {
                setIsInterViewModalOpen(false);
                setIsFormModalOpen(false);
                setInterviewTime({ day: '', startTime: '', endTime: '' });
                setOpInfo({});
                setUserList([]);
                getJobAppUsers({
                    ...state,
                    ...filterInfo,
                    jobId: localJobInfo.jobId,
                    applicationsStatus: pageLabel[selectedType - 1].val,
                    interviewingStatus: 'all',
                });
            } else {
                messageApi.error(message);
            }
        });
    };
    // 去用户详情
    const toUserDetail = (val: defaultInfo) => {
        window.sessionStorage.setItem('candidateUserInfo', JSON.stringify({ ...val, from: '/job-detail' }));
        navigate('/candidate-detail');
    };
    const [loading, setLoading] = useState(false);
    const [localJobInfo, setLocalJobInfo] = useState<any>({
        jobsPOJO: {
            businessAccountPOJO: {} as any,
        } as any,
    });
    const [nowJobInfo, setNowJobInfo] = useState<any>({
        jobsPOJO: {
            businessAccountPOJO: {} as any,
        } as any,
    });
    const getJobInfo = (val: defaultInfo) => {
        setLoading(true);
        api.getJobInfoUserList({ jobId: val.jobId }, res => {
            setLoading(false);
            const { code, data, message } = res;
            if (code === 200) {
                data.jobTypeShow =
                    data.jobsPOJO.jobType === '1'
                        ? 'Full-Time'
                        : data.jobsPOJO.jobType === '2'
                        ? 'Part-Time'
                        : data.jobsPOJO.jobType === '3'
                        ? 'Contractor'
                        : 'Full-Time';
                data.deadlineTimeShow = data.jobsPOJO.deadline
                    ? window.utils.formatTimeDifference(data.jobsPOJO.deadline)
                    : '-';
                data.businessNameLabel = `${data.jobsPOJO.businessAccountPOJO.firstName} ${data.jobsPOJO.businessAccountPOJO.middleName} ${data.jobsPOJO.businessAccountPOJO.lastName}`;
                if (data.hardSkillPOJOList && data.hardSkillPOJOList.length) {
                    data.hardSkillShow = data.hardSkillPOJOList.map(item => item.skillName).join(', ');
                }
                if (data.softSkillPOJOList && data.softSkillPOJOList.length) {
                    data.softSkillShow = data.softSkillPOJOList.map(item => item.skillName).join(', ');
                }
                setNowJobInfo({ ...data });
            } else {
                messageApi.error(message);
            }
        });
    };
    const [userActiveNum, setUserActiveNum] = useState(0);
    const [userList, setUserList] = useState<any>([]);
    useEffect(() => {
        setUserActiveNum(userList.filter(v => v.isChecked).length);
    }, [userList]);
    const [listLoading, setListLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const getJobAppUsers = val => {
        setListLoading(true);
        const pat: any = {
            keyWord: val.search || '',
            pageNo: val.pageNo,
            pageSize: 20,
            jobId: val.jobId,
            applicationsStatus: val.applicationsStatus,
            interviewingStatus: val.interviewingStatus,
        };
        pat.highestDegree = val.highestDegree;
        pat.location = val.location;
        pat.remoteFlag = val.remoteFlag;
        pat.hardSkills = val.hardSkills;
        pat.softSkills = val.softSkills;
        pat.sourceFrom = val.sourceFrom;
        pat.yearsOfExperience = val.yearsOfExperience;
        api.getJobApplicationUserList(pat, res => {
            setListLoading(false);
            const { code, data, message } = res;
            if (code === 200) {
                setTotal(res.data.totalPage);
                setPageLabel([
                    { val: 'Sourcing', label: `Sourcing (${data.applicationsSourcing || 0})`, key: 1 },
                    { val: 'Screening', label: `Screening (${data.applicationsScreening || 0})`, key: 2 },
                    { val: 'Interviewing', label: `Interviewing (${data.applicationsInterviewing || 0})`, key: 3 },
                    { val: 'Offered', label: `Offered (${data.applicationsOffered || 0})`, key: 4 },
                    { val: 'Rejected', label: `Rejected (${data.applicationsRejected || 0})`, key: 5 },
                ]);
                setPageSchedLabel([
                    {
                        val: 'Un-Scheduled',
                        label: `Un-Scheduled (${data.applicationsInterviewingUnScheduled || 0})`,
                        key: 1,
                    },
                    { val: 'Scheduled', label: `Scheduled (${data.applicationsInterviewingScheduled || 0})`, key: 2 },
                ]);
                if (data.applicationsPOJOList && data.applicationsPOJOList.length) {
                    setUserList(prevItems => [
                        ...prevItems,
                        ...data.applicationsPOJOList.map(item => {
                            const hardSkill: any = [];
                            const softSkill: any = [];
                            if (item.accountPOJO.accountSkillPOJOList && item.accountPOJO.accountSkillPOJOList.length) {
                                item.accountPOJO.accountSkillPOJOList.forEach(item => {
                                    if (item.skillType === 'softSkills') {
                                        softSkill.push(item);
                                    } else {
                                        hardSkill.push(item);
                                    }
                                });
                            }
                            return Object.assign(item, {
                                isChecked: false,
                                namelabel: `${item.accountPOJO.accountInformationPOJO.firstName || '-'} ${
                                    item.accountPOJO.accountInformationPOJO.middleName || ''
                                } ${item.accountPOJO.accountInformationPOJO.lastName || ''}`,
                                skillShow:
                                    item.accountPOJO.accountSkillPOJOList &&
                                    item.accountPOJO.accountSkillPOJOList.length < 3
                                        ? item.accountPOJO.accountSkillPOJOList.map(v => v.skillName).join(', ')
                                        : item.accountPOJO.accountSkillPOJOList[0].skillName,
                                hardSkill,
                                softSkill,
                            });
                        }),
                    ]);
                } else {
                    setUserList([]);
                }
            } else {
                messageApi.error(message);
            }
        });
    };
    const getInfoMethod = () => {
        const info = JSON.parse(window.sessionStorage.getItem('jobInfo') || "{jobId:''}");
        if (info.jobId) {
            setLocalJobInfo(info);
            setUserList([]);
            getJobAppUsers({
                ...state,
                ...filterInfo,
                jobId: info.jobId,
                applicationsStatus: pageLabel[selectedType - 1].val,
                interviewingStatus:
                    selectedType === 3 ? (selectedScheduledType === 1 ? 'Un-Scheduled' : 'Scheduled') : 'all',
            });
            getJobInfo({ jobId: info.jobId });
        }
    };
    // useEffect(() => {
    //     getInfoMethod();
    // }, []);
    const { dataBaseInfo } = useSelector((state: any) => state.state);
    useEffect(() => {
        getInfoMethod();
    }, [dataBaseInfo]);
    // 禁用时间
    const getDisabledHours = () => {
        if (!interViewTime.day) return [];
        const now = dayjs();
        if (dayjs(interViewTime.day).isSame(now, 'day')) {
            const limitHour = now.add(2, 'hour').hour();
            return Array.from({ length: limitHour }, (_, i) => i);
        }
        return [];
    };
    const getDisabledMinutes = selectedHour => {
        if (!interViewTime.day) return [];
        const now = dayjs();
        if (dayjs(interViewTime.day).isSame(now, 'day')) {
            const currentHour = now.hour();
            const limitMinutes = now.minute();
            if (selectedHour === currentHour + 2) {
                return Array.from({ length: limitMinutes }, (_, i) => i);
            }
        }
        return [];
    };
    return (
        <div className="r-pageContent job-detailBox">
            <div>
                {loading ? (
                    <div className="no-message-tabBox r-center">
                        <Spin style={{ position: 'static' }}></Spin>
                    </div>
                ) : (
                    <>
                        <div className="job-listItem">
                            {/* 工作信息 */}
                            <div key={`jobItem-cardDetail`} className="jobItem-card r-left">
                                <div className="job-infoBox mar0">
                                    <div className="r-left job-tit">
                                        {localJobInfo.jobsPOJO.jobTitle || '-'}
                                        <span
                                            className={`job-statusTipBox marLeft ${
                                                nowJobInfo.jobsPOJO.jobStepStatus === 'Archvie'
                                                    ? 'archiveBox-tip'
                                                    : nowJobInfo.jobsPOJO.jobStepStatus !== 'In Interview'
                                                    ? 'sourcingBox-tip'
                                                    : ''
                                            }`}
                                        >
                                            {nowJobInfo.jobsPOJO.jobStepStatus === 'Archvie'
                                                ? 'Archvie'
                                                : nowJobInfo.jobsPOJO.jobStepStatus !== 'In Interview'
                                                ? 'Sourcing'
                                                : 'Interviewing'}
                                        </span>
                                        <div className="marLeft r-left">
                                            {nowJobInfo.jobsPOJO.jobStepStatus === 'Archvie' ? (
                                                <Button
                                                    className="r-primary_btn"
                                                    style={{ width: 120 }}
                                                    onClick={() => changeModalType(1)}
                                                >
                                                    Duplicate
                                                </Button>
                                            ) : (
                                                <>
                                                    <Button
                                                        className="r-primary_btn"
                                                        style={{ width: 120 }}
                                                        onClick={() => changeModalType(1)}
                                                    >
                                                        {nowJobInfo.jobsPOJO.jobStepStatus !== 'In Interview'
                                                            ? 'In Interview'
                                                            : 'Archive'}
                                                    </Button>
                                                    <Button
                                                        className="r-primary_btn r-primaryNoBg_btn"
                                                        style={{ width: 120, marginLeft: 16 }}
                                                        onClick={() => toEditJob()}
                                                    >
                                                        Manage Job
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="company-info r-left">
                                        <i
                                            className="company-icon"
                                            style={{
                                                borderRadius: '50%',
                                                backgroundImage: `url(${nowJobInfo.jobsPOJO.employerLogo})`,
                                                backgroundSize: 'cover',
                                            }}
                                        ></i>
                                        {nowJobInfo.jobsPOJO.employerName || '-'}
                                    </div>
                                    <div className="r-left job-infoBtm">
                                        <div className="info-tit">
                                            Location
                                            <div className="info-msg">
                                                {nowJobInfo.jobsPOJO.remoteFlag
                                                    ? 'Remote'
                                                    : nowJobInfo.jobsPOJO.location || '-'}
                                            </div>
                                        </div>
                                        <div className="info-tit">
                                            Type
                                            <div className="info-msg">{nowJobInfo?.jobTypeShow || '-'}</div>
                                        </div>
                                        <div className="info-tit">
                                            Department
                                            <div className="info-msg">{nowJobInfo.jobsPOJO.department || '-'}</div>
                                        </div>
                                        <div className="info-tit">
                                            Experience Required
                                            <div className="info-msg">
                                                {nowJobInfo.jobsPOJO.experienceRequiredYear
                                                    ? `${
                                                          nowJobInfo.jobsPOJO.experienceRequiredYear > 1
                                                              ? `${nowJobInfo.jobsPOJO.experienceRequiredYear} years +`
                                                              : `${nowJobInfo.jobsPOJO.experienceRequiredYear} year`
                                                      }`
                                                    : 'No experience'}
                                            </div>
                                        </div>
                                        {nowJobInfo.jobsPOJO.jobStepStatus !== 'Archvie' &&
                                        nowJobInfo.jobsPOJO.jobStepStatus !== 'In Interview' ? (
                                            <div className="info-tit" style={{ width: 80 }}>
                                                Release Date
                                                <div className="info-msg">
                                                    {nowJobInfo?.jobsPOJO.publishTime
                                                        ? dayjs(nowJobInfo.jobsPOJO.publishTime * 1000).format(
                                                              'DD MMM YYYY'
                                                          )
                                                        : '-'}
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        <div className="info-tit">
                                            Deadline
                                            <div className="info-msg">{nowJobInfo.deadlineTimeShow || '-'}</div>
                                        </div>
                                        <div className="info-tit">
                                            Recruiters
                                            <div className="info-msg">{nowJobInfo.businessNameLabel || '-'}</div>
                                        </div>
                                        <div className="info-tit">
                                            Role
                                            <div className="info-msg">
                                                {nowJobInfo.jobsPOJO.businessAccountPOJO.position || '-'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 申请状态类型切换 */}
                        <div className="r-left user-topBox">
                            <h3 className="h3-tit">Candidates</h3>
                            {selectedType < 3 ? (
                                <Button
                                    className="r-primary_btn r-medium14_btn compare-btn"
                                    onClick={() => setOpenDrawer(true)}
                                    style={{ width: 127 }}
                                    disabled={userActiveNum < 2}
                                >
                                    Compare ({userActiveNum ? userActiveNum : 0})
                                </Button>
                            ) : null}
                            <div className="marLeft">
                                <SliderTabs
                                    height={38}
                                    setSelectedType={key => {
                                        changeTabLink(key);
                                    }}
                                    selectedType={selectedType}
                                    tabList={pageLabel}
                                />
                            </div>
                        </div>
                        {/* 状态切换为面试显示 */}
                        {selectedType > 2 ? (
                            <div className="searchCandidate-box r-left">
                                <Input
                                    placeholder=""
                                    style={{ width: 380 }}
                                    allowClear
                                    value={state.search}
                                    onChange={e => {
                                        searchList({
                                            ...state,
                                            ...filterInfo,
                                            search: e.target.value,
                                            jobId: localJobInfo.jobId,
                                            applicationsStatus: pageLabel[selectedType - 1].val,
                                            interviewingStatus:
                                                selectedType === 3
                                                    ? selectedScheduledType === 1
                                                        ? 'Un-Scheduled'
                                                        : 'Scheduled'
                                                    : 'all',
                                        });
                                        setState({ ...state, search: e.target.value, pageNo: 1 });
                                    }}
                                    className="r-input r-input_serach"
                                    prefix={<span className="search_icon"></span>}
                                />
                                {/* 归档之后不显示对比按钮 */}
                                {nowJobInfo.jobsPOJO.jobStepStatus === 'Archvie' ? null : (
                                    <Button
                                        className="r-primary_btn r-medium14_btn compare-btn"
                                        style={{ width: 110, marginLeft: 16 }}
                                        onClick={() => setOpenDrawer(true)}
                                        disabled={userActiveNum < 2}
                                    >
                                        Compare
                                    </Button>
                                )}
                                {selectedType === 3 ? (
                                    <div className="r-left marLeft">
                                        {pageSchedLabel.map((item, i) => {
                                            return (
                                                <div
                                                    className={`schedule-typeBtn ${
                                                        selectedScheduledType === item.key ? 'activeType' : ''
                                                    }`}
                                                    style={{ marginLeft: 10 }}
                                                    onClick={() => {
                                                        changeScheduleType(item.key);
                                                        setUserList([]);
                                                        getJobAppUsers({
                                                            ...state,
                                                            ...filterInfo,
                                                            jobId: localJobInfo.jobId,
                                                            applicationsStatus: pageLabel[selectedType - 1].val,
                                                            interviewingStatus:
                                                                selectedType === 3
                                                                    ? item.key === 1
                                                                        ? 'Un-Scheduled'
                                                                        : 'Scheduled'
                                                                    : 'all',
                                                        });
                                                    }}
                                                >
                                                    {item.label}
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        ) : null}

                        <div className="r-left candidate-list">
                            {/* 侧边条件筛选 */}
                            {selectedType < 3 ? (
                                <div className="filter-box">
                                    <CandidateFilter
                                        jobId={localJobInfo.jobId}
                                        getFilterList={val => {
                                            setUserList([]);
                                            setFilterInfo(val);
                                            getJobAppUsers({
                                                ...state,
                                                ...val,
                                                jobId: localJobInfo.jobId,
                                                applicationsStatus: pageLabel[selectedType - 1].val,
                                                interviewingStatus:
                                                    selectedType === 3
                                                        ? selectedScheduledType === 1
                                                            ? 'Un-Scheduled'
                                                            : 'Scheduled'
                                                        : 'all',
                                            });
                                        }}
                                    />
                                </div>
                            ) : null}
                            {/* 申请人列表 */}
                            <div className="r-jobUserCard-info">
                                {listLoading ? (
                                    <div className="no-message-tabBox r-center">
                                        <Spin style={{ position: 'static' }}></Spin>
                                    </div>
                                ) : userList.length ? (
                                    <>
                                        {userList.map((val, i) => {
                                            return (
                                                <div key={`r-jobUserCard_d_${i}`} className="r-jobUserCard">
                                                    <div
                                                        className="cursorPointer"
                                                        onClick={() => {
                                                            toUserDetail(val);
                                                        }}
                                                    >
                                                        <div className="jobuserInfo-box r-left">
                                                            {nowJobInfo.jobsPOJO.jobStepStatus === 'Archvie' ? null : (
                                                                <RCheckBox
                                                                    checkInfo={val}
                                                                    index={i}
                                                                    setCheckInfo={i => {
                                                                        if (
                                                                            userList.filter(v => v.isChecked).length >=
                                                                                5 &&
                                                                            !userList[i].isChecked
                                                                        ) {
                                                                            messageApi.error(
                                                                                'You can only select up to 5 candidates'
                                                                            );
                                                                            return;
                                                                        }
                                                                        userList[i].isChecked = !userList[i].isChecked;
                                                                        setUserList([...userList]);
                                                                    }}
                                                                />
                                                            )}
                                                            <Image
                                                                width={42}
                                                                height={42}
                                                                preview={false}
                                                                style={{ borderRadius: '50%', minWidth: 42 }}
                                                                src={
                                                                    val.accountPOJO.accountInformationPOJO.profilePhoto
                                                                        ? val.accountPOJO.accountInformationPOJO
                                                                              .profilePhoto
                                                                        : ''
                                                                }
                                                                fallback={require('@/assets/img/default-min.png')}
                                                            />
                                                            <div style={{ marginLeft: 12 }}>
                                                                <div className="jobCard-titM r-left">
                                                                    {localJobInfo.jobsPOJO.jobTitle || '-'}
                                                                    {!val.reviewTime || val.reviewTime === 0 ? (
                                                                        <div className="new-tipBox r-center">New</div>
                                                                    ) : null}
                                                                </div>
                                                                <p className="jobCard-userNameM">
                                                                    {val.namelabel || '-'}
                                                                </p>
                                                            </div>
                                                            <div className="match-box r-center">
                                                                Match - {val.matchScore || 0}%
                                                            </div>
                                                            <div className="fromTip-box r-center">
                                                                <img
                                                                    src={
                                                                        val.accountPOJO.accountLoginType === 'linkedIn'
                                                                            ? LinkedInIcon
                                                                            : val.accountPOJO.accountLoginType ===
                                                                              'email'
                                                                            ? TalenautIcon
                                                                            : GoogleIcon
                                                                    }
                                                                    className="from-imgIcon"
                                                                    alt=""
                                                                />
                                                                {val.accountPOJO.accountLoginType === 'linkedIn'
                                                                    ? 'LinkedIn'
                                                                    : val.accountPOJO.accountLoginType === 'email'
                                                                    ? 'Talenaut'
                                                                    : 'Google'}
                                                            </div>
                                                        </div>
                                                        <i className="card-lineBox"></i>
                                                        <div className="r-left usercardJob-infoBtm">
                                                            <div className="info-tit">
                                                                Status
                                                                {val.status === 'Actively Interested' ? (
                                                                    <div className="info-msg success-color">
                                                                        Actively Interested
                                                                    </div>
                                                                ) : (
                                                                    <div className="info-msg success-color">
                                                                        Passively Interested
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="info-tit">
                                                                Location
                                                                <div className="info-msg">
                                                                    {val.accountPOJO.accountInformationPOJO.remoteFlag
                                                                        ? 'Remote'
                                                                        : val.accountPOJO.accountInformationPOJO
                                                                              .location || '-'}
                                                                </div>
                                                            </div>
                                                            <div className="info-tit">
                                                                Top Skills
                                                                <div className="info-msg">
                                                                    {val.skillShow}
                                                                    {val.accountPOJO.accountSkillPOJOList.length > 2 ? (
                                                                        <span className="primary-color skillNum">
                                                                            , +
                                                                            {val.accountPOJO.accountSkillPOJOList
                                                                                .length - 1}{' '}
                                                                            skills
                                                                        </span>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="info-tit">
                                                                Experience
                                                                <div className="info-msg">
                                                                    {val.accountPOJO.accountInformationPOJO
                                                                        .yearsOfExperience || '1'}{' '}
                                                                    {val.accountPOJO.accountInformationPOJO
                                                                        .yearsOfExperience > 1
                                                                        ? 'years'
                                                                        : 'year'}
                                                                </div>
                                                            </div>
                                                            <div className="info-tit">
                                                                Preferred Role
                                                                <div className="info-msg">
                                                                    {val.accountPOJO.accountInformationPOJO.jobRole ||
                                                                        '-'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 已经归档不显示操作按钮 */}
                                                    {nowJobInfo.jobsPOJO.jobStepStatus === 'Archvie' ? null : (
                                                        <>
                                                            <i className="card-lineBox"></i>
                                                            {selectedType < 3 ? (
                                                                <div className="jobUser-opeation r-end">
                                                                    <Button
                                                                        className="r-danger_btn reject-btn"
                                                                        onClick={() => changeModalType(2, val)}
                                                                    >
                                                                        Reject <i className="reject_icon"></i>
                                                                    </Button>
                                                                    <Button
                                                                        className="r-primary_btn r-primaryNoBg_btn card-messageBtn btnMar15"
                                                                        onClick={() => changeModalType(5, val)}
                                                                    >
                                                                        Message
                                                                        <i className="chart_icon"></i>
                                                                    </Button>
                                                                    <Button
                                                                        className="r-primary_btn moveStep-btn btnMar15"
                                                                        onClick={() => changeModalType(6, val)}
                                                                    >
                                                                        Move to{' '}
                                                                        {selectedType === 1 ? ' Stage' : ' Interview'}
                                                                        <i className="step_icon"></i>
                                                                    </Button>
                                                                </div>
                                                            ) : selectedType === 3 ? (
                                                                <div className="jobUser-opeation r-end">
                                                                    {selectedScheduledType === 2 ? (
                                                                        <Button
                                                                            className="r-danger_btn reject-btn"
                                                                            onClick={() => changeModalType(2, val)}
                                                                        >
                                                                            Reject <i className="reject_icon"></i>
                                                                        </Button>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                    <Button
                                                                        className="r-primary_btn r-primaryNoBg_btn card-messageBtn btnMar15"
                                                                        onClick={() => changeModalType(5, val)}
                                                                    >
                                                                        Message
                                                                        <i className="chart_icon"></i>
                                                                    </Button>
                                                                    {selectedScheduledType === 1 ? (
                                                                        <>
                                                                            <Button
                                                                                className="r-primary_btn moveStep-btn btnMar15 wAuto"
                                                                                onClick={() => changeModalType(8, val)}
                                                                            >
                                                                                Move to Scheduled
                                                                                <i className="step_icon"></i>
                                                                            </Button>
                                                                            <Button
                                                                                className="r-danger_btn reject-btn btnMar15"
                                                                                onClick={() => changeModalType(2, val)}
                                                                            >
                                                                                Reject <i className="reject_icon"></i>
                                                                            </Button>
                                                                        </>
                                                                    ) : null}
                                                                    <Button
                                                                        className="r-primary_btn moveStep-btn btnMar15"
                                                                        onClick={() => changeModalType(7, val)}
                                                                    >
                                                                        Offer Position
                                                                        <i className="step_icon"></i>
                                                                    </Button>
                                                                </div>
                                                            ) : (
                                                                <div className="jobUser-opeation r-end">
                                                                    {selectedType === 4 ? (
                                                                        <>
                                                                            <Button
                                                                                className="r-success_btn succ-btn"
                                                                                style={{ cursor: 'initial' }}
                                                                            >
                                                                                Offer Accepted
                                                                            </Button>
                                                                        </>
                                                                    ) : null}
                                                                    <Button
                                                                        className="r-primary_btn r-primaryNoBg_btn card-messageBtn btnMar15"
                                                                        onClick={() => changeModalType(5, val)}
                                                                    >
                                                                        Message
                                                                        <i className="chart_icon"></i>
                                                                    </Button>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <div className="no-message-tabBox r-center">No yet</div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>

            <AlertModal
                title=""
                message={modalText.title}
                cancelText={modalText.cancelText}
                confirmText={modalText.confirmText}
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                loading={opLoading}
                handleCancel={handleCancel}
            />
            {/* 面试时间冲突弹窗 */}
            <AlertModal
                title=""
                message={interViewModalMsg}
                cancelText="Modification time"
                confirmText="Confirmation interview"
                isModalOpen={isInterViewModalOpen}
                handleOk={confirmInterView}
                loading={opLoading}
                handleCancel={() => {
                    setIsInterViewModalOpen(false);
                }}
            />

            <FormModal
                title="Schedule An Interview"
                width={380}
                isModalOpen={isFormModalOpen}
                handleCancel={() => {
                    setInterviewTime({ day: '', startTime: '', endTime: '' });
                    setIsFormModalOpen(false);
                    setOpLoading(false);
                }}
                children={
                    <div>
                        <p className="form-tip">Interview Date</p>
                        <DatePicker
                            placeholder=""
                            disabledDate={disabledDate}
                            className="r-selDatePicker"
                            value={interViewTime.day ? interViewTime.day : null}
                            format={'DD MMM YYYY'}
                            onChange={val => setInterviewTime({ ...interViewTime, day: val })}
                            suffixIcon={<i className="downIcon"></i>}
                        />
                        <p className="form-tip" style={{ marginTop: 20 }}>
                            Interview Start Time
                        </p>
                        <TimePicker
                            placeholder=""
                            type="time"
                            className="r-selDatePicker"
                            disabled={!interViewTime.day}
                            value={interViewTime.startTime}
                            format={'h:mm a'}
                            disabledTime={() => ({
                                disabledHours: getDisabledHours,
                                disabledMinutes: getDisabledMinutes,
                            })}
                            onChange={val => setInterviewTime({ ...interViewTime, startTime: val })}
                            suffixIcon={<i className="downIcon"></i>}
                        />
                        <p className="form-tip" style={{ marginTop: 20 }}>
                            Interview Finish Time
                        </p>
                        <TimePicker
                            placeholder=""
                            type="time"
                            disabled={!interViewTime.day}
                            value={interViewTime.endTime}
                            format={'h:mm a'}
                            disabledTime={() => ({
                                disabledHours: getDisabledHours,
                                disabledMinutes: getDisabledMinutes,
                            })}
                            onChange={val => setInterviewTime({ ...interViewTime, endTime: val })}
                            className="r-selDatePicker"
                            suffixIcon={<i className="downIcon"></i>}
                        />
                        <div className="r-center">
                            <Button
                                style={{ width: 106, marginTop: 40 }}
                                className="r-primary_btn r-primary_largeBtn"
                                disabled={!interViewTime.day || !interViewTime.startTime || !interViewTime.endTime}
                                onClick={() => {
                                    confirmSendInterview();
                                }}
                                loading={opLoading}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                }
            />

            <RoundDrawer
                open={openDrawer}
                className="comparingCandidateDrawer"
                onClose={() => setOpenDrawer(false)}
                children={
                    <div className="comparing-box">
                        <p className="comparing-tit">
                            Comparing Candidates ({userList.filter(v => v.isChecked).length || 0})
                        </p>
                        <div className="r-top">
                            <div>
                                <div className="comparingItem-tit r-left h75">Job Requirements</div>
                                <div className="comparingItem-tit r-left h48">Match % to Role</div>
                                <div className="comparingItem-tit r-left h48">Status</div>
                                <div className="comparingItem-tit h75">
                                    Role
                                    <p className="size12">{localJobInfo.jobsPOJO.jobTitle || '-'}</p>
                                </div>
                                <div className="comparingItem-tit h75">
                                    Location
                                    <p className="size12">{localJobInfo.jobsPOJO.location || '-'}</p>
                                </div>
                                <div className="comparingItem-tit h75">
                                    Experience
                                    <p className="size12">
                                        <p className="size12">
                                            {localJobInfo.jobsPOJO?.experienceRequiredYear
                                                ? `${localJobInfo.jobsPOJO?.experienceRequiredYear} ${
                                                      localJobInfo.jobsPOJO?.experienceRequiredYear > 1
                                                          ? 'years +'
                                                          : 'year'
                                                  }`
                                                : '1 year'}
                                        </p>
                                    </p>
                                </div>
                                <div className="comparingItem-tit h75">
                                    Hard Skills
                                    <p className="size12">{nowJobInfo.hardSkillShow || '-'}</p>
                                </div>
                                <div className="comparingItem-tit h75">
                                    Soft Skills
                                    <p className="size12">{nowJobInfo.softSkillShow || '-'}</p>
                                </div>
                                <div className="comparingItem-tit h75">
                                    Education
                                    <p className="size12">-</p>
                                </div>
                                <div className="comparingItem-tit r-left h48">Certifications</div>
                                {selectedType <= 3 && selectedScheduledType !== 2 ? (
                                    <div className="comparingItem-tit r-left h48">Action</div>
                                ) : null}{' '}
                            </div>
                            <div className="candidateDrawer-list r-top">
                                {userList
                                    .filter(v => v.isChecked)
                                    .map((val, i) => {
                                        return (
                                            <div className="candidateInfo-box">
                                                <div className="comparingItem-tit r-left h75 w100">
                                                    <Image
                                                        width={40}
                                                        height={40}
                                                        preview={false}
                                                        style={{ borderRadius: '50%', minWidth: 40 }}
                                                        src={
                                                            val.accountPOJO.accountInformationPOJO.profilePhoto
                                                                ? val.accountPOJO.accountInformationPOJO.profilePhoto
                                                                : ''
                                                        }
                                                        fallback={require('@/assets/img/default-min.png')}
                                                    />
                                                    <span style={{ marginLeft: 8 }}>{val.namelabel || '-'}</span>
                                                    <i
                                                        className="removeR-icon marLeft"
                                                        onClick={() => {
                                                            const index = userList.findIndex(
                                                                v => v.accountId === val.accountId
                                                            );
                                                            userList[index].isChecked = false;
                                                            setUserList([...userList]);
                                                            if (!userList.filter(v => v.isChecked).length) {
                                                                setOpenDrawer(false);
                                                            }
                                                        }}
                                                    ></i>
                                                </div>
                                                <div className="comparingItem-tit r-left h48 w100 noBg">
                                                    {val.matchScore || 0}%
                                                </div>
                                                <div className="comparingItem-tit r-left h48 w100 noBg">
                                                    {val.status || '-'}
                                                </div>
                                                <div className="comparingItem-tit r-left w100 noBg h75">
                                                    {val.accountPOJO.accountInformationPOJO.jobRole || '-'}
                                                </div>
                                                <div className="comparingItem-tit r-left w100 noBg h75">
                                                    {val.accountPOJO.accountInformationPOJO.remoteFlag === 1
                                                        ? 'Remote'
                                                        : val.accountPOJO.accountInformationPOJO.location || '-'}
                                                </div>
                                                <div className="comparingItem-tit r-left w100 noBg h75">
                                                    {val.accountPOJO.accountInformationPOJO.yearsOfExperience
                                                        ? `${val.accountPOJO.accountInformationPOJO.yearsOfExperience} years`
                                                        : '1 year'}
                                                </div>
                                                <div className="comparingItem-tit r-left w100 noBg h75">
                                                    {val.hardSkill
                                                        ? val.hardSkill.map(item => item.skillName).join(', ')
                                                        : '-'}
                                                </div>
                                                <div className="comparingItem-tit r-left w100 noBg  h75">
                                                    {val.softSkill
                                                        ? val.softSkill.map(item => item.skillName).join(', ')
                                                        : '-'}
                                                </div>
                                                <div className="comparingItem-tit r-left w100 noBg h75">
                                                    {val.accountPOJO.accountInformationPOJO.highestDegree || '-'}
                                                </div>
                                                <div className="comparingItem-tit r-left h48 w100 noBg">
                                                    {val.accountPOJO.accountCertificatePOJOList &&
                                                    val.accountPOJO.accountCertificatePOJOList.length
                                                        ? val.accountPOJO.accountCertificatePOJOList
                                                              .map(item => {
                                                                  return item.certificateName;
                                                              })
                                                              .join(', ')
                                                        : '-'}
                                                </div>
                                                {selectedType <= 3 && selectedScheduledType !== 2 ? (
                                                    <div>
                                                        <Button
                                                            className="r-primary_btn w100"
                                                            style={{ height: 48, borderRadius: 12 }}
                                                            onClick={() => opUserToNext(val)}
                                                        >
                                                            Move to Next Stage
                                                        </Button>
                                                    </div>
                                                ) : null}
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                }
            />
        </div>
    );
}

export default JobDetail;
