import React, { useEffect, useState } from 'react';
import './style/index.scss';
import { Button, DatePicker, Image, Select, Spin } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import SliderTabs from '@/components/slideeTabs';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import api from '@/api/home';
import { useNavigate } from 'react-router-dom';
const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > dayjs().endOf('day');
};
const { RangePicker } = DatePicker;
function Interview() {
    const navigate = useNavigate();
    const [selectedType, setSelectedType] = useState(1);
    const messageApi = useGlobalMessage();
    const [state, setState] = useState({
        team: '',
        beginTime: '0',
        endTime: '9999999999',
        time: null as any,
        pageNo: 1,
    });
    const changeTime = val => {
        if (val && val !== null) {
            const beginTime = (dayjs(val[0]).valueOf() / 1000).toString();
            const endTime = Math.floor(dayjs(val[1]).endOf('day').valueOf() / 1000).toString();
            setState({ ...state, beginTime, endTime, time: val });
            getInterview({ ...state, beginTime, endTime, status: pageLabel[selectedType - 1].val });
            return;
        }
        setState({ ...state, endTime: '', beginTime: '', time: null });
        getInterview({ ...state, endTime: '9999999999', beginTime: '0', status: pageLabel[selectedType - 1].val });
    };
    const tableLabel = [
        { label: 'Candidate', key: 1 },
        { label: 'Job', key: 2 },
        { label: 'Employer Company', key: 3 },
        { label: 'Interview Time', key: 4 },
        { label: 'Interviewer', key: 5 },
    ];
    const [pageLabel, setPageLabel] = useState([
        { val: 'UpcomingInterviewsToday', label: 'Upcoming Interviews Today (0)', key: 1 },
        { val: 'AllInterviews', label: 'All Interviews (0)', key: 2 },
        { val: 'AllTeamInterviews', label: 'All Team Interviews (0)', key: 3 },
    ]);
    const [loading, setLoading] = useState(false);
    const [interViewTotal, setInterViewTotal] = useState(0);
    const [interView, setInterview] = useState<any>([]);
    const getInterview = val => {
        setLoading(true);
        api.getHomeInterviewList(
            {
                flag: val.status,
                beginTime: val.beginTime || '0',
                endTime: val.endTime || '9999999999',
                pageNo: val.pageNo,
                pageSize: 1000,
            },
            res => {
                setLoading(false);
                if (res.code === 200) {
                    setInterViewTotal(res.data.totalRecord);
                    setPageLabel([
                        {
                            val: 'UpcomingInterviewsToday',
                            label: `Upcoming Interviews Today (${res.data.pojo.upcomingInterviewsToday || 0})`,
                            key: 1,
                        },
                        {
                            val: 'AllInterviews',
                            label: `All Interviews (${res.data.pojo.allInterviews || 0})`,
                            key: 2,
                        },
                        {
                            val: 'AllTeamInterviews',
                            label: `All Team Interviews (${res.data.pojo.allTeamInterviews || 0})`,
                            key: 3,
                        },
                    ]);
                    if (res.data.list) {
                        setInterview(
                            res.data.list.map((item, i) => {
                                return Object.assign(item, {
                                    nameLabel: `${item.accountPOJO.accountInformationPOJO?.firstName} ${item.accountPOJO.accountInformationPOJO?.middleName} ${item.accountPOJO.accountInformationPOJO?.lastName}`,
                                    nameLabelIn: `${item.interviewBusinessAccountPOJO?.firstName} ${item.interviewBusinessAccountPOJO?.middleName} ${item.interviewBusinessAccountPOJO?.lastName}`,
                                    inReviewTimeShow: item.interviewStartTime
                                        ? window.utils.foramtTimeDMYhm(item.interviewStartTime * 1000)
                                        : '-',
                                });
                            })
                        );
                    } else {
                        setInterview([]);
                    }
                } else {
                    messageApi.error(res.message);
                }
            }
        );
    };
    useEffect(() => {
        getInterview({ ...state, status: pageLabel[selectedType - 1].val });
    }, []);
    return (
        <div className="r-pageContent r-interview">
            <div className="r-left">
                <h2>Interview</h2>
            </div>
            <div className="r-left top-opaBox">
                <RangePicker
                    className={`r-datePicker r-date_showLine r-w180`}
                    style={{ width: 320, height: 40 }}
                    format="DD/MM/YYYY"
                    inputReadOnly
                    onChange={changeTime}
                    value={state.time}
                    // disabledDate={disabledDate}
                    suffixIcon={<></>}
                    separator={<div></div>}
                    placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']}
                />
                <div className="marLeft">
                    <SliderTabs
                        setSelectedType={key => {
                            if (loading) return;
                            getInterview({
                                ...state,
                                status: pageLabel[key - 1].val,
                                beginTime: '',
                                endTime: '',
                                pageNo: 1,
                            });
                            setState({ ...state, time: null, pageNo: 1 });
                            setSelectedType(key);
                        }}
                        selectedType={selectedType}
                        tabList={pageLabel}
                    />
                </div>
            </div>
            {loading ? (
                <div className="no-message-tabBox r-center">
                    <Spin style={{ position: 'static' }}></Spin>
                </div>
            ) : (
                <div>
                    <div className="intterview-list r-table_card">
                        <div className="r-left ant-table-thead Tmarb12">
                            {tableLabel.map((item, i) => {
                                return (
                                    <div key={`tableHeader-row_${i}`} className="ant-table-cell">
                                        {item.label}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="ant-table-tbody">
                            {interView.length ? (
                                interView.map((item, i) => {
                                    return (
                                        <div className="r-tableRow_card r-left" key={`tableRow_card-${i}`}>
                                            <span className="ant-table-cell">
                                                <Image
                                                    width={32}
                                                    height={32}
                                                    preview={false}
                                                    style={{ borderRadius: '50%', minWidth: 32 }}
                                                    src={
                                                        item.accountPOJO.accountInformationPOJO.profilePhoto
                                                            ? item.accountPOJO.accountInformationPOJO.profilePhoto
                                                            : ''
                                                    }
                                                    fallback={require('@/assets/img/default-min.png')}
                                                />
                                                <span style={{ marginLeft: 8 }}>{item.nameLabel || '-'}</span>
                                            </span>
                                            <span
                                                className="ant-table-cell oneLineText"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    window.sessionStorage.setItem(
                                                        'jobInfo',
                                                        JSON.stringify({ ...item, ...item.jobsPOJO })
                                                    );
                                                    navigate('/job-detail');
                                                }}
                                            >
                                                {item.jobsPOJO.jobTitle || '-'}
                                            </span>
                                            <span className="ant-table-cell">
                                                <Image
                                                    width={32}
                                                    height={32}
                                                    preview={false}
                                                    style={{ borderRadius: '50%', minWidth: 32 }}
                                                    src={item.jobsPOJO.employerLogo ? item.jobsPOJO.employerLogo : ''}
                                                    fallback={require('@/assets/img/default-min.png')}
                                                />
                                                <span style={{ marginLeft: 8 }}>
                                                    {item.jobsPOJO.employerName || '-'}
                                                </span>
                                            </span>
                                            <span className="ant-table-cell">{item.inReviewTimeShow || '-'}</span>
                                            <span className="ant-table-cell">
                                                <Image
                                                    width={32}
                                                    height={32}
                                                    preview={false}
                                                    style={{ borderRadius: '50%', minWidth: 32 }}
                                                    src={
                                                        item.interviewBusinessAccountPOJO?.avatarUrl
                                                            ? item.interviewBusinessAccountPOJO.avatarUrl
                                                            : ''
                                                    }
                                                    fallback={require('@/assets/img/default-min.png')}
                                                />
                                                <span style={{ marginLeft: 8 }}>{item.nameLabelIn || '-'}</span>
                                            </span>
                                        </div>
                                    );
                                })
                            ) : (
                                <>
                                    <div className="no-message-tabBox r-center">
                                        {state.time
                                            ? 'Sorry, we were unable to find any results based on your conditions.'
                                            : 'No yet'}
                                    </div>
                                </>
                                // <div className="r-tableRow_card r-center">
                                //     <span className="ant-table-cell no-message r-center">
                                //         Sorry, we were unable to find any results based on your conditions.
                                //     </span>
                                // </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Interview;
