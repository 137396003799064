import React, { useCallback, useEffect, useState } from 'react';
import './index.scss';
import LinkedInIcon from '@/assets/img/LinkedIn_icon.png';
import TalenautIcon from '@/assets/img/logo-mini.png';
import GoogleIcon from '@/assets/img/Google.png';
import { Button, Image, Input, Select, Spin } from 'antd';
import CandidateFilter from '../home/components/candidate-filter';
import RCheckBox from '@/components/checkbox';
import { debounce } from 'lodash';
import AlertModal from '@/components/modal/alert-modal';
import FormModal from '@/components/modal/form-modal';
import dayjs from 'dayjs';
import RoundDrawer from '@/components/r-drawer/r-drawer';
import { useNavigate } from 'react-router-dom';
import api from '@/api/talentPool';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import SharePoolModal from './components/sharePool';
import useInfiniteScroll from '@/hook/useInfiniteScroll';
// import { setUserInfo } from '@/store/stateSlice';
function PipelinesBoard() {
    const navigate = useNavigate();
    const messageApi = useGlobalMessage();
    const [poolGroup, setPoolGroup] = useState<any>({
        poolId: '',
        val: {
            jobTitle: '',
            groupId: '',
        },
        JobInfo: {
            jobTitle: '',
            location: '',
            experienceRequiredYear: '1',
        },
    });
    const [shareModal, setShareModal] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [modalType, setModalType] = useState(1); // 1 删除所有 2 删除单个
    const alertModalTipList = [
        {
            type: 1,
            title: 'Are you sure you want to remove the talent pool for this job?',
            cancelText: 'Cancel',
            confirmText: 'Confirm',
        },
        {
            type: 2,
            title: 'Are you sure you want to remove this candidate?',
            cancelText: 'Cancel',
            confirmText: 'Confirm',
        },
    ];
    const [modalText, setModalText] = useState(alertModalTipList[0]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOk = () => {
        setLoading(true);
        if (modalType === 1) {
            api.delPool({ poolId: poolGroup.poolId }, res => {
                setLoading(false);
                if (res.code === 200) {
                    setIsModalOpen(false);
                    navigate(-1);
                } else {
                    messageApi.error(res.message);
                }
            });
            return;
        }
        api.dleteUserToJob(
            { poolId: poolGroup.poolId, groupId: poolGroup.val.groupId, accountId: selUserInfo.accountId },
            res => {
                setLoading(false);
                if (res.code === 200) {
                    setIsModalOpen(false);
                    setSelUserInfo({});
                    setUserList([]);
                    getList({ ...filterInfo, ...state, ...poolGroup });
                } else {
                    messageApi.error(res.message);
                }
            }
        );
    };
    const handleCancel = () => {
        // setUserInfo({});
        setIsModalOpen(false);
    };
    const changeModalType = (type: number, val?: any) => {
        if (type === 2) {
            setSelUserInfo(val);
        }
        setModalType(type);
        setModalText(alertModalTipList[type - 1]);
        setIsModalOpen(true);
    };
    const [loading, setLoading] = useState(false);
    const [loadMoreing, setLoadMoreing] = useState(false);
    const loadMoreItems = () => {
        if (loading || state.pageNo >= total) return;
        setLoadMoreing(true);
        setState({ ...state, pageNo: state.pageNo + 1 });
        getList({ ...filterInfo, ...poolGroup, pageNo: state.pageNo + 1 });
    };
    const loaderRef = useInfiniteScroll(loadMoreItems, loading);
    const [total, setTotal] = useState(0);
    const [userList, setUserList] = useState<any>([]);
    const [userActiveNum, setUserActiveNum] = useState(0);
    const [state, setState] = useState({
        search: '',
        pageNo: 1,
    });
    const [filterInfo, setFilterInfo] = useState({
        gmtId: '',
        keyWord: '',
        highestDegree: [],
        location: [],
        remoteFlag: 0,
        hardSkills: [],
        softSkills: [],
        sourceFrom: [],
        yearsOfExperience: [],
    });
    const searchList: any = useCallback(
        debounce(params => {
            setUserList([]);
            console.log(poolGroup);
            getList({ ...filterInfo, ...params, ...poolGroup });
        }, 1000),
        []
    );
    const getPoolGroupInfo = () => {
        const info = JSON.parse(window.sessionStorage.getItem('poolJobInfo') ?? "{poolId:''}");
        console.log('====================================');
        console.log(info);
        console.log('====================================');
        if (info.poolId) {
            let jobInfo: any = {};
            if (info.val.jobsPOJOList && info.val.jobsPOJOList.length) {
                jobInfo = info.val.jobsPOJOList[0];
            }
            console.log({ jobInfo: jobInfo, ...info });
            setPoolGroup({ jobInfo: jobInfo, ...info });
            getList({ ...filterInfo, ...info, ...state });
        }
    };
    useEffect(() => {
        setUserActiveNum(userList.filter(v => v.isChecked).length);
    }, [userList]);
    const [listLoading, setListLoading] = useState(false);
    const getList = info => {
        setListLoading(true);
        const pat: any = {
            poolId: info.poolId,
            groupId: info.val.groupId,
            keyWord: info.search || '',
            pageNo: info.pageNo,
            pageSize: 10,
        };
        pat.highestDegree = info.highestDegree;
        pat.location = info.location;
        pat.remoteFlag = info.remoteFlag;
        pat.hardSkills = info.hardSkills;
        pat.softSkills = info.softSkills;
        pat.sourceFrom = info.sourceFrom;
        pat.yearsOfExperience = info.yearsOfExperience;
        api.getPoolGroupUserList(pat, res => {
            setListLoading(false);
            if (res.code === 200) {
                setTotal(res.data.totalPage);
                if (res.data.list) {
                    setLoadMoreing(false);
                    setUserList(prevItems => [
                        ...prevItems,
                        ...res.data.list.map(item => {
                            const hardSkill: any = [];
                            const softSkill: any = [];
                            if (item.accountSkillPOJOList && item.accountSkillPOJOList.length) {
                                item.accountSkillPOJOList.forEach(item => {
                                    if (item.skillType === 'softSkills') {
                                        softSkill.push(item);
                                    } else {
                                        hardSkill.push(item);
                                    }
                                });
                            }
                            return Object.assign(item, {
                                isChecked: false,
                                skillShow:
                                    item.accountSkillPOJOList && item.accountSkillPOJOList.length < 2
                                        ? item.accountSkillPOJOList.map(v => v.skillName).join(', ')
                                        : item.accountSkillPOJOList[0].skillName,
                                namelabel: `${item.firstName || '-'} ${item.middleName || ''} ${item.lastName || ''}`,
                                jobGetLoading: false,
                                hardSkill,
                                softSkill,
                            });
                        }),
                    ]);
                } else {
                    setUserList([]);
                }
            } else {
                messageApi.error(res.message);
            }
        });
    };
    // add user to job
    const [addJobList, setAddJobList] = useState<any>([]);
    const [selJob, setSelJob] = useState('');
    const [selUserInfo, setSelUserInfo] = useState<any>({});
    const [selJobInfo, setSelJobInfo] = useState<any>({});
    const [jobGetLoading, setJobGetLoading] = useState(false);
    const getCanAddJobList = (info, i) => {
        // setJobGetLoading(true);
        setSelUserInfo(info);
        const pat = {
            accountId: info.accountId,
            jobTitle: info.jobRole,
        };
        api.getUserAddJobList(pat, res => {
            // setJobGetLoading(false);
            userList[i].jobGetLoading = false;
            setUserList([...userList]);
            if (res.code === 200) {
                if (res.data) {
                    setAddJobList(
                        res.data.map(item => {
                            return Object.assign(item, {
                                value: item.jobId,
                                label: `${item.jobTitle}(${item.businessName})`,
                            });
                        })
                    );
                } else {
                    setAddJobList([]);
                }
                setIsFormModalOpen(true);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const addUserToJob = () => {
        setLoading(true);
        api.addUserToJob({ jobId: selJob, accountId: selUserInfo.accountId }, res => {
            setLoading(false);
            const { code, data, message } = res;
            if (code === 200) {
                setIsFormModalOpen(false);
                setSelJob('');
                setSelJobInfo({});
                setSelUserInfo({});
            } else {
                messageApi.error(message);
            }
        });
    };
    useEffect(() => {
        getPoolGroupInfo();
    }, []);
    return (
        <div className="r-pageContent board-detailBox">
            <div className="job-listItem">
                <div key={`jobItem-cardDetail`} className="jobItem-card r-left">
                    <div className="job-infoBox mar0">
                        <div className="r-left job-tit">
                            {poolGroup.val.jobTitle || '-'}
                            <div className="marLeft r-left">
                                {!poolGroup.val.shareFrom ? (
                                    <Button
                                        className="r-primary_btn r-center"
                                        style={{ width: 100 }}
                                        onClick={() => setShareModal(true)}
                                    >
                                        Share
                                        <i className="share-icon"></i>
                                    </Button>
                                ) : null}
                                <Button
                                    className="r-danger_btn"
                                    style={{ width: 100, marginLeft: 16 }}
                                    onClick={() => changeModalType(1)}
                                >
                                    Remove
                                </Button>
                            </div>
                        </div>
                        <div className="r-left job-infoBtm_new">
                            Location:
                            <div className="info-msg">Worldwide</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="r-left candidate-list">
                <div className="filter-box">
                    <CandidateFilter
                        pageNow="pool"
                        getFilterList={val => {
                            setUserList([]);
                            setFilterInfo(val);
                            getList({ ...val, ...poolGroup, ...state, pageNo: 1, filter: true });
                        }}
                    />
                </div>
                <div className="r-jobUserCard-info">
                    <div className="searchCandidate-box r-left">
                        <Input
                            placeholder=""
                            style={{ width: 380 }}
                            allowClear
                            value={state.search}
                            onChange={e => {
                                searchList({ ...state, search: e.target.value, pageNo: 1, filter: false });
                                setState({ ...state, search: e.target.value, pageNo: 1 });
                            }}
                            className="r-input r-input_serach"
                            prefix={<span className="search_icon"></span>}
                        />
                        <Button
                            className="r-primary_btn job-btn"
                            style={{ width: 110, marginLeft: 16 }}
                            disabled={userActiveNum < 2}
                            onClick={() => {
                                setOpenDrawer(true);
                            }}
                        >
                            Compare
                        </Button>
                    </div>
                    {listLoading ? (
                        <div className="no-message-tabBox r-center">
                            <Spin></Spin>
                        </div>
                    ) : userList.length ? (
                        <>
                            {userList.map((val, i) => {
                                return (
                                    <div key={`r-jobUserCard_d_${i}`} className="r-jobUserCard">
                                        <div
                                            className="cursorPointer"
                                            onClick={() => {
                                                window.sessionStorage.setItem(
                                                    'candidateInfo',
                                                    JSON.stringify({ ...val, from: '/talent-pool' })
                                                );
                                                navigate('/candidate-info?from=pool');
                                            }}
                                        >
                                            <div className="jobuserInfo-box r-left">
                                                <RCheckBox
                                                    checkInfo={val}
                                                    index={i}
                                                    setCheckInfo={i => {
                                                        if (
                                                            userList.filter(v => v.isChecked).length >= 5 &&
                                                            !userList[i].isChecked
                                                        ) {
                                                            messageApi.error('You can only select up to 5 candidates');
                                                            return;
                                                        }
                                                        userList[i].isChecked = !userList[i].isChecked;
                                                        setUserList([...userList]);
                                                    }}
                                                />
                                                <Image
                                                    width={42}
                                                    height={42}
                                                    preview={false}
                                                    style={{ borderRadius: '50%', minWidth: 42 }}
                                                    src={val.profilePhoto ? val.profilePhoto : ''}
                                                    fallback={require('@/assets/img/default-min.png')}
                                                />
                                                <div style={{ marginLeft: 12 }}>
                                                    <div className="jobCard-titM r-lfet">{val.jobRole || '-'}</div>
                                                    <p className="jobCard-userNameM">{val.namelabel || '-'}</p>
                                                </div>
                                                <div className="match-box r-center">Match - {val.matchScore || 0}%</div>
                                                <div className="fromTip-box r-center">
                                                    <img
                                                        src={
                                                            val.sourceFrom === 'LinkedIn'
                                                                ? LinkedInIcon
                                                                : val.sourceFrom === 'Talenaut'
                                                                ? TalenautIcon
                                                                : GoogleIcon
                                                        }
                                                        className="from-imgIcon"
                                                        alt=""
                                                    />
                                                    {val.sourceFrom || 'Talenaut'}
                                                </div>
                                            </div>
                                            <i className="card-lineBox"></i>
                                            <div className="r-left usercardJob-infoBtm">
                                                <div className="info-tit">
                                                    Status
                                                    <div className="info-msg success-color">
                                                        {val.poolStatus || 'Actively Interested'}
                                                        {/* Passively Interested */}
                                                    </div>
                                                </div>
                                                <div className="info-tit">
                                                    Location
                                                    <div className="info-msg">
                                                        {val.remoteFlag ? val.gmt : val.location || '-'}
                                                    </div>
                                                </div>
                                                <div className="info-tit">
                                                    Top Skills
                                                    <div className="info-msg">
                                                        {val.skillShow}
                                                        {val.accountSkillPOJOList.length > 2 ? (
                                                            <span className="primary-color skillNum">
                                                                , +{val.accountSkillPOJOList.length - 1} skills
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="info-tit">
                                                    Experience
                                                    <div className="info-msg">
                                                        {val.yearsOfExperience || '1'}{' '}
                                                        {val.yearsOfExperience > 1 ? 'years' : 'year'}
                                                    </div>
                                                </div>
                                                <div className="info-tit">
                                                    Preferred Role
                                                    <div className="info-msg">{val.jobRole || '-'}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <i className="card-lineBox"></i>
                                        <div className="jobUser-opeation r-end">
                                            <Button
                                                className="r-danger_btn"
                                                style={{ width: 100 }}
                                                onClick={() => changeModalType(2, val)}
                                            >
                                                Remove
                                            </Button>
                                            <Button
                                                className="r-primary_btn btnMar15"
                                                style={{ width: 115 }}
                                                loading={val.jobGetLoading}
                                                onClick={() => {
                                                    val.jobGetLoading = true;
                                                    setUserList([...userList]);
                                                    getCanAddJobList(val, i);
                                                }}
                                            >
                                                Add to job
                                            </Button>
                                        </div>
                                    </div>
                                );
                            })}
                            {loadMoreing && (
                                <div className="r-center">
                                    <Spin></Spin>
                                </div>
                            )}
                            <div ref={loaderRef} style={{ height: '20px' }} />
                        </>
                    ) : (
                        <div className="no-message-tabBox r-center">No yet</div>
                    )}
                </div>
            </div>

            <AlertModal
                title=""
                message={modalText.title}
                cancelText={modalText.cancelText}
                confirmText={modalText.confirmText}
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                loading={loading}
                handleCancel={handleCancel}
            />

            <SharePoolModal
                shareModal={shareModal}
                setShareModal={setShareModal}
                info={poolGroup}
                pageType="detail"
                handOK={() => {
                    getPoolGroupInfo();
                }}
            />

            <FormModal
                title="Add To Job"
                width={380}
                isModalOpen={isFormModalOpen}
                handleCancel={() => {
                    setSelJob('');
                    setSelJobInfo({});
                    setIsFormModalOpen(false);
                }}
                children={
                    <div>
                        <p className="form-tip">Candidate</p>
                        <div className="addCandidate-card r-left">
                            <Image
                                width={32}
                                height={32}
                                preview={false}
                                style={{ borderRadius: '50%', minWidth: 32 }}
                                src={selUserInfo.profilePhoto ? selUserInfo.profilePhoto : ''}
                                fallback={require('@/assets/img/default-min.png')}
                            />
                            <div>
                                <p className="addCandidate-card-name">{selUserInfo.namelabel || '-'}</p>
                                <p className="addCandidate-card-job">{selUserInfo.jobRole || '-'}</p>
                            </div>
                        </div>
                        <p className="form-tip" style={{ marginTop: 20 }}>
                            Add to job
                        </p>
                        <Select
                            className="r-select"
                            suffixIcon={<i className="down-right-icon"></i>}
                            options={addJobList}
                            value={selJob}
                            onChange={val => {
                                setSelJob(val);
                                const info = addJobList.find(v => v.jobId === val);
                                setSelJobInfo(info);
                            }}
                        />
                        {selJob ? (
                            <div className="addCandidateToJob-card">
                                <div className="addCandidateToJob-name">{selJobInfo.jobTitle || '-'}</div>
                                <div className="r-left" style={{ alignItems: 'baseline' }}>
                                    <Image
                                        width={20}
                                        height={20}
                                        preview={false}
                                        style={{ borderRadius: '50%', minWidth: 20 }}
                                        src={selJobInfo.employerLogo ? selJobInfo.employerLogo : ''}
                                        fallback={require('@/assets/img/default-min.png')}
                                    />
                                    <span className="addCandidateToJob-user">{selJobInfo.businessName || '-'}</span>
                                </div>
                            </div>
                        ) : null}
                        <div className="r-center">
                            <Button
                                style={{ width: 106, marginTop: 40 }}
                                className="r-primary_btn r-primary_largeBtn"
                                loading={loading}
                                disabled={!selJob}
                                onClick={() => addUserToJob()}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                }
            />

            <RoundDrawer
                open={openDrawer}
                className="comparingCandidateDrawer"
                onClose={() => setOpenDrawer(false)}
                children={
                    <div className="comparing-box">
                        <p className="comparing-tit">
                            Comparing Candidates ({userList.filter(v => v.isChecked).length || 0})
                        </p>
                        <div className="r-top">
                            <div>
                                <div className="comparingItem-tit r-left h75">Job Requirements</div>
                                <div className="comparingItem-tit r-left h48">Match % to Role</div>
                                <div className="comparingItem-tit r-left h48">Status</div>
                                <div className="comparingItem-tit h75">
                                    Role
                                    <p className="size12">{poolGroup.jobInfo?.jobTitle || '-'}</p>
                                </div>
                                <div className="comparingItem-tit h75">
                                    Location
                                    <p className="size12">{poolGroup.jobInfo?.location || '-'}</p>
                                </div>
                                <div className="comparingItem-tit h75">
                                    Experience
                                    <p className="size12">
                                        {poolGroup.jobInfo?.experienceRequiredYear
                                            ? `${poolGroup.jobInfo?.experienceRequiredYear} ${
                                                  poolGroup.jobInfo?.experienceRequiredYear > 1 ? 'years' : 'year'
                                              }`
                                            : '1 year'}
                                    </p>
                                </div>
                                <div className="comparingItem-tit h75">
                                    Hard Skills
                                    <p className="size12">-</p>
                                </div>
                                <div className="comparingItem-tit h75">
                                    Soft Skills
                                    <p className="size12">-</p>
                                </div>
                                <div className="comparingItem-tit h75">
                                    Education
                                    <p className="size12">-</p>
                                </div>
                                <div className="comparingItem-tit r-left h48">Certifications</div>
                            </div>
                            <div className="candidateDrawer-list r-top">
                                {userList
                                    .filter(v => v.isChecked)
                                    .map((val, i) => {
                                        return (
                                            <div className="candidateInfo-box">
                                                <div className="comparingItem-tit r-left h75 w100">
                                                    <Image
                                                        width={40}
                                                        height={40}
                                                        preview={false}
                                                        style={{ borderRadius: '50%', minWidth: 40 }}
                                                        src={val.profilePhoto ? val.profilePhoto : ''}
                                                        fallback={require('@/assets/img/default-min.png')}
                                                    />
                                                    <span style={{ marginLeft: 8 }}>{val.namelabel || '-'}</span>
                                                    <i
                                                        className="removeR-icon marLeft"
                                                        onClick={() => {
                                                            const index = userList.findIndex(
                                                                v => v.accountId === val.accountId
                                                            );
                                                            userList[index].isChecked = false;
                                                            setUserList([...userList]);
                                                            if (!userList.filter(v => v.isChecked).length) {
                                                                setOpenDrawer(false);
                                                            }
                                                        }}
                                                    ></i>
                                                </div>
                                                <div className="comparingItem-tit r-left h48 w100 noBg">
                                                    {val.matchScore || 0}%
                                                </div>
                                                <div className="comparingItem-tit r-left h48 w100 noBg">
                                                    {val.poolStatus || '-'}
                                                </div>
                                                <div className="comparingItem-tit r-left w100 noBg h75">
                                                    {val.jobRole || '-'}
                                                </div>
                                                <div className="comparingItem-tit r-left w100 noBg h75">
                                                    {val.remoteFlag === 1 ? 'Remote' : val.location || '-'}
                                                </div>
                                                <div className="comparingItem-tit r-left w100 noBg h75">
                                                    {val.yearsOfExperience
                                                        ? `${val.yearsOfExperience} years`
                                                        : '1 year'}
                                                </div>
                                                <div className="comparingItem-tit r-left w100 noBg h75">
                                                    {val.hardSkill
                                                        ? val.hardSkill.map(item => item.skillName).join(', ')
                                                        : '-'}
                                                </div>
                                                <div className="comparingItem-tit r-left w100 noBg  h75">
                                                    {val.softSkill
                                                        ? val.softSkill.map(item => item.skillName).join(', ')
                                                        : '-'}
                                                </div>
                                                <div className="comparingItem-tit r-left w100 noBg h75">
                                                    {val.highestDegree || '-'}
                                                </div>
                                                <div className="comparingItem-tit r-left h48 w100 noBg">
                                                    {val?.accountCertificatePOJOList
                                                        ? val?.accountCertificatePOJOList
                                                              .map(item => {
                                                                  return item.certificateName;
                                                              })
                                                              .join(', ')
                                                        : '-'}
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                }
            />
        </div>
    );
}

export default PipelinesBoard;
