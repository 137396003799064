import React, { useEffect, useState } from 'react';
import { Image } from 'antd';
import './index.scss';
import JobList from './components/job-list';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '@/api/login';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import { useSelector } from 'react-redux';
function SearchResult() {
    const messageApi = useGlobalMessage();
    const { profileInfo } = useSelector((state: any) => state.state);
    const navigate = useNavigate();
    const [state, setState] = useState({
        search: '',
        list: [
            {
                type: 'Candidates',
                list: [],
            },
            {
                type: 'Jobs',
                list: [],
            },
            {
                type: 'Team Members',
                list: [],
            },
            {
                type: 'Talent Database',
                list: [],
            },
        ] as any,
        total: 0,
    });
    const toAllResult = type => {
        navigate(`/search-result-all/${type}`);
    };
    const toCandidateInfo = val => {
        window.sessionStorage.setItem(
            'candidateInfo',
            JSON.stringify({ ...val, accountId: val.accountPOJO.accountId, from: '/talent-database' })
        );
        navigate('/candidate-info?from=database');
    };
    const [jobList, setJobList] = useState<any>([]);
    const location = useLocation();
    useEffect(() => {
        getSearach();
    }, [location]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [resultList, setResultList] = useState<any>([]);
    const getList = () => {
        setResultList([]);
        setSearchLoading(true);
        api.getSearchList({ keyWord: state.search }, res => {
            setSearchLoading(false);
            const { code, data, message } = res;
            if (code === 200) {
                if (!data) {
                    setResultList([]);
                    return;
                }
                const list = [
                    {
                        type: 'Candidates',
                        list:
                            data.candidatesPOJOList && data.candidatesPOJOList.length
                                ? data.candidatesPOJOList.map(item => {
                                      let time = '';
                                      if (item.accountPOJO.accountExperiencePOJO) {
                                          const year = item.accountPOJO.accountExperiencePOJO.yearsOfExperience;
                                          const month = item.accountPOJO.accountExperiencePOJO.monthOfExperience;
                                          time = `${year ? `${year}${year > 1 ? 'Years' : 'Year'}` : ''}${
                                              month ? ` ${month}${month > 1 ? ' Months' : ' Month'}` : ''
                                          }`;
                                      }
                                      return Object.assign(item, {
                                          namelabel: `${item.accountPOJO.accountInformationPOJO.firstName} ${item.accountPOJO.accountInformationPOJO.middleName} ${item.accountPOJO.accountInformationPOJO.lastName}`,
                                          pic: item.accountPOJO.accountInformationPOJO.profilePhoto
                                              ? item.accountPOJO.accountInformationPOJO.profilePhoto
                                              : '',
                                          time,
                                          skillShow:
                                              item.accountPOJO.accountSkillPOJOList &&
                                              item.accountPOJO.accountSkillPOJOList.length < 3
                                                  ? item.accountPOJO.accountSkillPOJOList
                                                        .map(v => v.skillName)
                                                        .join(', ')
                                                  : item.accountPOJO.accountSkillPOJOList[0].skillName,
                                      });
                                  })
                                : [],
                    },
                    {
                        type: 'Jobs',
                        list:
                            data.jobsInfoPOJOList && data.jobsInfoPOJOList.length
                                ? data.jobsInfoPOJOList.map(item => {
                                      let applicationsPOJOListList: any = [];
                                      if (item.applicationsPOJOList && item.applicationsPOJOList.length) {
                                          applicationsPOJOListList =
                                              item.applicationsPOJOList.length > 11
                                                  ? item.applicationsPOJOList.slice(-11)
                                                  : item.applicationsPOJOList;
                                      }
                                      return Object.assign(item, {
                                          namelabel: `${item.jobsPOJO.jobTitle}`,
                                          pic: item.employerLogo ? item.employerLogo : '',
                                          jobTypeShow:
                                              item.jobsPOJO.jobType === '1'
                                                  ? 'Full-Time'
                                                  : item.jobsPOJO.jobType === '2'
                                                  ? 'Part-Time'
                                                  : item.jobsPOJO.jobType === '3'
                                                  ? 'Contractor'
                                                  : 'Full-Time',
                                          deadlineTimeShow: item.jobsPOJO.deadline
                                              ? window.utils.formatTimeDifference(item.jobsPOJO.deadline)
                                              : '-',
                                          businessNameLabel: `${item.jobsPOJO.businessAccountPOJO.firstName} ${item.jobsPOJO.businessAccountPOJO.middleName} ${item.jobsPOJO.businessAccountPOJO.lastName}`,
                                          applicationsPOJOList: applicationsPOJOListList,
                                      });
                                  })
                                : [],
                    },
                    {
                        type: 'Team Members',
                        list:
                            data.businessAccountPOJOList && data.businessAccountPOJOList.length
                                ? data.businessAccountPOJOList.map(item => {
                                      return Object.assign(item, {
                                          namelabel: `${item.firstName} ${item.middleName} ${item.lastName}`,
                                          pic: item.profilePhoto ? item.profilePhoto : '',
                                      });
                                  })
                                : [],
                    },
                    {
                        type: 'Talent Database',
                        list:
                            data.vtalentDatabaseAccountPOJOList && data.vtalentDatabaseAccountPOJOList.length
                                ? data.vtalentDatabaseAccountPOJOList.map(item => {
                                      return Object.assign(item, {
                                          namelabel: `${item.firstName} ${item.middleName} ${item.lastName}`,
                                          pic: item.profilePhoto ? item.profilePhoto : '',
                                          skillShow: !item.accountSkillPOJOList
                                              ? '-'
                                              : item.accountSkillPOJOList && item.accountSkillPOJOList.length < 3
                                              ? item.accountSkillPOJOList.map(v => v.skillName).join(', ')
                                              : item.accountSkillPOJOList[0].skillName,
                                      });
                                  })
                                : [],
                    },
                ];
                window.sessionStorage.setItem('searchReault', JSON.stringify({ search: state.search, list: list }));
                getSearach();
            } else {
                messageApi.error(message);
            }
        });
    };
    const getSearach = () => {
        const searchReault = JSON.parse(window.sessionStorage.getItem('searchReault') || "{search:''}");
        console.log('====================================');
        console.log(searchReault);
        console.log('====================================');
        if (searchReault.search) {
            let num = 0;
            num += searchReault.list[0].list.length;
            num += searchReault.list[1].list.length;
            num += searchReault.list[2].list.length;
            num += searchReault.list[3].list.length;
            if (searchReault.list[1].list.length > 2) {
                setJobList([{ ...searchReault.list[1].list[0] }, { ...searchReault.list[1].list[1] }]);
            } else {
                setJobList([...searchReault.list[1].list]);
            }
            setState({ ...searchReault, total: num });
        }
    };
    useEffect(() => {
        getSearach();
    }, []);
    return (
        <div className="r-pageContent searchResult-box">
            <div className="search-tit r-left">
                Search Result{' '}
                <span className="primary-color" style={{ marginLeft: 8 }}>
                    "{state.search || ''}"
                </span>{' '}
            </div>
            <div className="result-num">
                {state.total || 0} {state.total > 1 ? 'Results' : 'Result'}
            </div>
            {/* 用户 */}
            {state.list[0].list.length ? (
                <>
                    <div className="result-typeBox r-left">
                        Candidates
                        {state.list[0].list.length > 4 ? (
                            <span className="viewAll-btn marLeft r-center" onClick={() => toAllResult(1)}>
                                View All
                            </span>
                        ) : null}
                    </div>
                    <div className="r-left" style={{ alignItems: 'stretch' }}>
                        {state.list[0].list.map((item, i) => {
                            return (
                                <div
                                    key={`candidates-card-${i}`}
                                    style={{ display: i > 3 ? 'none' : '', margin: i > 2 ? '0' : '' }}
                                    className="candidate-card"
                                    onClick={() => toCandidateInfo(item)}
                                >
                                    <div className="candidate-topInfo r-left">
                                        <Image
                                            width={40}
                                            height={40}
                                            preview={false}
                                            style={{ borderRadius: '50%', minWidth: 40 }}
                                            src={item.pic}
                                            fallback={require('@/assets/img/user-default.png')}
                                        />
                                        <div style={{ marginLeft: 8, width: '70%' }}>
                                            <p className="fz14-medium oneLineText">{item.namelabel || '-'}</p>
                                            <p className="candidate-jobTip oneLineText" style={{ flex: 1 }}>
                                                {item.accountPOJO.accountInformationPOJO.jobRole || '-'}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="line-boxNew"></div>
                                    <div>
                                        <p className="userLabel-box">Status</p>
                                        <p className="fz14 valMarb16 success-color">{item.status || '-'}</p>
                                        <p className="userLabel-box">Location</p>
                                        <p className="fz14 valMarb16">
                                            {item.accountPOJO.accountInformationPOJO.remoteFlag === 0
                                                ? item.accountPOJO.accountInformationPOJO.location || '-'
                                                : 'Remote'}
                                        </p>
                                        <p className="userLabel-box">Experience</p>
                                        <p className="fz14 valMarb16">{item.time || '-'}</p>
                                        <p className="userLabel-box">Preferred Role</p>
                                        <p className="fz14 valMarb16">
                                            {item.accountPOJO.accountInformationPOJO.jobRole || '-'}
                                        </p>
                                        <p className="userLabel-box">Top Skills</p>
                                        <div className="fz14 valMarb16">
                                            {item.skillShow || '-'}
                                            {item.accountPOJO.accountSkillPOJOList &&
                                            item.accountPOJO.accountSkillPOJOList.length > 2 ? (
                                                <span className="primary-color skillNum-box">
                                                    +{item.accountPOJO.accountSkillPOJOList.length - 1}
                                                </span>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            ) : null}
            {/* 工作 */}
            {state.list[1].list.length ? (
                <>
                    <div className="result-typeBox r-left">
                        Jobs
                        {state.list[1].list.length > 2 ? (
                            <span className="viewAll-btn marLeft r-center" onClick={() => toAllResult(2)}>
                                View All
                            </span>
                        ) : null}
                    </div>
                    <div>
                        <JobList data={jobList} getList={getList} isNoLoad={true} />
                    </div>
                </>
            ) : null}
            {/* 团队 */}
            {state.list[2].list.length ? (
                <>
                    <div className="result-typeBox r-left">
                        Team Members
                        {state.list[2].list.length > 6 ? (
                            <span className="viewAll-btn marLeft r-center" onClick={() => toAllResult(3)}>
                                View All
                            </span>
                        ) : null}
                    </div>
                    <div className="r-left">
                        {state.list[2].list.map((item, i) => {
                            return (
                                <div
                                    key={`teamMembers-card-${i}`}
                                    style={{
                                        display: i > 5 ? 'none' : '',
                                        margin: i > 4 ? '0' : '',
                                        cursor: profileInfo.roleNum > 1 ? 'initial' : 'pointer',
                                    }}
                                    className="candidate-card team-cardBox"
                                    onClick={() => {
                                        if (profileInfo.roleNum > 1) {
                                            return;
                                        }
                                        window.sessionStorage.setItem('teamInfo', JSON.stringify(item));
                                        navigate('/employee-detail');
                                    }}
                                >
                                    <div className="candidate-topInfo">
                                        <Image
                                            width={40}
                                            height={40}
                                            preview={false}
                                            style={{ borderRadius: '50%', minWidth: 40 }}
                                            src={item.pic}
                                            fallback={require('@/assets/img/user-default.png')}
                                        />
                                        <div style={{ marginTop: 12 }}>
                                            <p className="fz14-medium oneLineText">{item.namelabel || '-'}</p>
                                            <p className="candidate-jobTip oneLineText">{item.position || '-'}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            ) : null}
            {/* 人才库 */}
            {state.list[3].list.length ? (
                <>
                    <div className="result-typeBox r-left">
                        Talent Database
                        {state.list[3].list.length > 4 ? (
                            <span className="viewAll-btn marLeft r-center" onClick={() => toAllResult(4)}>
                                View All
                            </span>
                        ) : null}
                    </div>
                    <div className="r-left">
                        {state.list[3].list.map((item, i) => {
                            return (
                                <div
                                    key={`candidates-card-${i}`}
                                    className="candidate-card"
                                    style={{ display: i > 3 ? 'none' : '', margin: i > 2 ? '0' : '' }}
                                    onClick={() => {
                                        toCandidateInfo({ ...item, accountPOJO: { accountId: item.accountId } });
                                    }}
                                >
                                    <div className="candidate-topInfo r-left">
                                        <Image
                                            width={40}
                                            height={40}
                                            preview={false}
                                            style={{ borderRadius: '50%', minWidth: 40 }}
                                            src={item.pic}
                                            fallback={require('@/assets/img/user-default.png')}
                                        />
                                        <div style={{ marginLeft: 8, width: '70%' }}>
                                            <p className="fz14-medium oneLineText">{item.namelabel || '-'}</p>
                                            <p className="candidate-jobTip oneLineText">{item.jobRole || '-'}</p>
                                        </div>
                                    </div>
                                    <div className="line-boxNew"></div>
                                    <div>
                                        <p className="userLabel-box">Location</p>
                                        <p className="fz14 valMarb16">
                                            {item.remoteFlag ? 'Remote' : item.location || '-'}
                                        </p>
                                        <p className="userLabel-box">Experience</p>
                                        <p className="fz14 valMarb16">{item.yearsOfExperience || 0} years</p>
                                        <p className="userLabel-box">Top Skills</p>
                                        <div className="fz14 valMarb16">
                                            {item.skillShow || '-'}
                                            {item.accountSkillPOJOList.length > 2 ? (
                                                <span className="primary-color skillNum-box">
                                                    +{item.accountSkillPOJOList.length - 1}
                                                </span>
                                            ) : null}
                                            {/* User Interface (UI) Design,
                                            <span className="primary-color skillNum-box">+3</span> */}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            ) : null}
        </div>
    );
}

export default SearchResult;
