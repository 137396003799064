import React, { useState } from 'react';
import './index.scss';
import RCheckBox from '@/components/checkbox';
import { Button } from 'antd';
import RTabs from './tabs';
import LocationInp from '@/components/autoComplate-inp/locationInp';
import SkillInp from '@/components/autoComplate-inp/skillInp';
function CandidateFilter({ pageNow, getFilterList, jobId }: any) {
    const [city, setCity] = useState('');
    const [state, setState] = useState<any>({
        isRemote: false,
        isCity: false,
        cityList: [],
        hardSkill: [],
        softSkill: [],
    });
    const [sourceSel, setSourceSel] = useState([
        { label: 'Internal', isChecked: false },
        { label: 'LinkedIn', isChecked: false },
        { label: 'Talenaut', isChecked: false },
    ]);
    const [educationSel, setEducationSel] = useState([
        { label: 'Bachelor’s Degree', isChecked: false },
        { label: 'Master’s Degree', isChecked: false },
        { label: 'Doctorate', isChecked: false },
        { label: 'Certifications', isChecked: false },
    ]);
    const [yearSel, setYearSel] = useState([
        { label: '0-2 years', isChecked: false },
        { label: '3-5 years', isChecked: false },
        { label: '6-10 years', isChecked: false },
        { label: '10+ years', isChecked: false },
    ]);
    const clearAll = () => {
        setState({
            isRemote: false,
            isCity: false,
            cityList: [],
            hardSkill: [],
            softSkill: [],
        });
        setSourceSel([
            { label: 'Internal', isChecked: false },
            { label: 'LinkedIn', isChecked: false },
            { label: 'Talenaut', isChecked: false },
        ]);
        setEducationSel([
            { label: 'Bachelor’s Degree', isChecked: false },
            { label: 'Master’s Degree', isChecked: false },
            { label: 'Doctorate', isChecked: false },
            { label: 'Certifications', isChecked: false },
        ]);
        setYearSel([
            { label: '0-2 years', isChecked: false },
            { label: '3-5 years', isChecked: false },
            { label: '6-10 years', isChecked: false },
            { label: '10+ years', isChecked: false },
        ]);
        const params = {
            gmtId: '',
            keyWord: '',
            highestDegree: [],
            location: [],
            remoteFlag: 0,
            hardSkills: [],
            softSkills: [],
            sourceFrom: [],
            yearsOfExperience: [],
        };
        getFilterList(params);
    };
    const filterList = () => {
        const educationAll: any = [];
        educationSel.forEach(item => {
            if (item.isChecked) {
                educationAll.push(item.label);
            }
        });
        const sourceAll: any = [];
        sourceSel.forEach(item => {
            if (item.isChecked) {
                sourceAll.push(item.label);
            }
        });
        const yearAll: any = [];
        yearSel.forEach(item => {
            if (item.isChecked) {
                yearAll.push(item.label);
            }
        });
        const params = {
            gmtId: '',
            keyWord: '',
            highestDegree: educationAll,
            location: state.isCity ? state.cityList : [],
            remoteFlag: state.isRemote ? 1 : 0,
            hardSkills: state.hardSkill,
            softSkills: state.softSkill,
            sourceFrom: pageNow === 'pool' ? sourceAll : [],
            yearsOfExperience: yearAll,
        };
        getFilterList(params);
    };
    return (
        <div className="candidateFilter-box">
            <div className="filter-tit">Filter</div>
            {pageNow === 'pool' ? (
                <div>
                    <p className="filter-tip">Source</p>
                    {sourceSel.map((item, i) => {
                        return (
                            <div className="filter-marb8" key={`sourceSel-${i}`}>
                                <RCheckBox
                                    checkInfo={item}
                                    index={i}
                                    setCheckInfo={i => {
                                        sourceSel[i].isChecked = !sourceSel[i].isChecked;
                                        setSourceSel([...sourceSel]);
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
            ) : null}
            <p className="filter-tip">Location</p>
            <div className="filter-marb8">
                <RCheckBox
                    checkInfo={{
                        isChecked: state.isRemote,
                        label: 'Remote',
                    }}
                    setCheckInfo={() => setState({ ...state, isRemote: !state.isRemote })}
                />
            </div>
            <div className="filter-marb8">
                <RCheckBox
                    checkInfo={{
                        isChecked: state.isCity,
                        label: 'City',
                    }}
                    setCheckInfo={() => setState({ ...state, isCity: !state.isCity, cityList: [] })}
                />
                {state.isCity ? (
                    <div>
                        <div style={{ marginTop: 8 }}>
                            <LocationInp
                                setLocation={val => {
                                    if (!val.address) return;
                                    state.cityList.push(val.address);
                                    setState({ ...state });
                                }}
                                type="search"
                                placeholder=" "
                                isFinshClear={true}
                                value={city}
                                height={36}
                                disabled={state.cityList.length >= 5}
                                suffixIcon={<i></i>}
                                classStr="r-autoComplate"
                            />
                        </div>
                        <div>
                            <RTabs
                                tabLists={state.cityList}
                                onDelTab={(i: number) => {
                                    state.cityList.splice(i, 1);
                                    setState({ ...state });
                                }}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
            <p className="filter-tip" style={{ marginTop: state.cityList.length ? 10 : 20 }}>
                Hard Skills
            </p>
            <div>
                <SkillInp
                    jobId={jobId}
                    setInfo={val => {
                        if (state.hardSkill.findIndex(v => v === val.val) !== -1) {
                            return;
                        }
                        state.hardSkill.push(val.val);
                        setState({ ...state, hardSkill: [...state.hardSkill] });
                    }}
                    type={'hard'}
                    placeholder="Start typing..."
                    isFinshClear={true}
                    height={36}
                    disabled={state.hardSkill.length >= 5}
                    suffixIcon={<i></i>}
                    classStr="r-autoComplate"
                />
                <div>
                    <RTabs
                        tabLists={state.hardSkill}
                        onDelTab={(i: number) => {
                            state.hardSkill.splice(i, 1);
                            setState({ ...state });
                        }}
                    />
                </div>
            </div>
            <p className="filter-tip" style={{ marginTop: state.hardSkill.length ? 10 : 20 }}>
                Soft Skills
            </p>
            <div>
                <SkillInp
                    jobId={jobId}
                    setInfo={val => {
                        if (state.softSkill.findIndex(v => v === val.val) !== -1) {
                            return;
                        }
                        state.softSkill.push(val.val);
                        setState({ ...state, softSkill: [...state.softSkill] });
                    }}
                    type={'soft'}
                    placeholder="Start typing..."
                    isFinshClear={true}
                    height={36}
                    disabled={state.softSkill.length >= 5}
                    suffixIcon={<i></i>}
                    classStr="r-autoComplate"
                />
                <div>
                    <RTabs
                        tabLists={state.softSkill}
                        onDelTab={(i: number) => {
                            state.softSkill.splice(i, 1);
                            setState({ ...state });
                        }}
                    />
                </div>
            </div>
            <p className="filter-tip" style={{ marginTop: state.softSkill.length ? 10 : 20 }}>
                Education
            </p>
            {educationSel.map((item, i) => {
                return (
                    <div className="filter-marb8" key={`yearSel-${i}`}>
                        <RCheckBox
                            checkInfo={item}
                            index={i}
                            setCheckInfo={i => {
                                // educationSel.forEach(val => {
                                //     val.isChecked = false;
                                // });
                                educationSel[i].isChecked = !educationSel[i].isChecked;
                                setEducationSel([...educationSel]);
                            }}
                        />
                    </div>
                );
            })}
            <p className="filter-tip">Years of Experience</p>
            {yearSel.map((item, i) => {
                return (
                    <div className="filter-marb8" key={`yearSel-${i}`}>
                        <RCheckBox
                            checkInfo={item}
                            index={i}
                            setCheckInfo={i => {
                                // yearSel.forEach(val => {
                                //     val.isChecked = false;
                                // });
                                yearSel[i].isChecked = !yearSel[i].isChecked;
                                setYearSel([...yearSel]);
                            }}
                        />
                    </div>
                );
            })}
            <div className="r-end" style={{ marginTop: 20 }}>
                <Button className="r-primary_btn r-primaryNoBg_btn filter-clear_btn" onClick={() => clearAll()}>
                    Clear
                </Button>
                <Button className="r-primary_btn" style={{ width: 118 }} onClick={() => filterList()}>
                    Apply Filter
                </Button>
            </div>
        </div>
    );
}

export default CandidateFilter;
