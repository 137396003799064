import React, { useCallback, useEffect, useRef, useState } from 'react';
import './index.scss';
import {
    AutoComplete,
    AutoCompleteProps,
    Button,
    DatePicker,
    Form,
    Image,
    Input,
    Modal,
    Select,
    Spin,
    Switch,
    Tooltip,
} from 'antd';
import debounce from 'lodash/debounce';
import RCheckBox from '@/components/checkbox';
import UploadImg from '@/components/upload/upload-img';
import { useLocation, useNavigate } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import RTabs from '../../home/components/tabs';
import NoteImg1 from '@/assets/img/candidate-img/note1.png';
import NoteImg2 from '@/assets/img/candidate-img/note2.png';
import NoteImg3 from '@/assets/img/candidate-img/note3.png';
import NoteImg4 from '@/assets/img/candidate-img/note4.png';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { defaultInfo } from '@/typings/allType';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import api from '@/api/job';
import { useSelector } from 'react-redux';
import { usePrompt } from '@/hook/usePrompt';
import AlertModal from '@/components/modal/alert-modal';
import JobSkillAutoComplate from './jobSkillAutoComplate';
const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current < dayjs().endOf('day');
};
function JobInfo() {
    const navigate = useNavigate();
    const location = useLocation(); // 获取 location 对象
    const { profileInfo } = useSelector((state: any) => state.state);
    const messageApi = useGlobalMessage();
    const queryParams = new URLSearchParams(location.search);
    const formPage = queryParams.get('f');
    const [GMTList, setGMTList] = useState([]);
    const [form] = Form.useForm();
    const formRef = useRef<any>(null);
    const [formInfo, setFormInfo] = useState({
        jobId: '',
        employerLogo: '',
        picUrlShow: '',
        employerName: '',
        about: '',
        perksTitle: '',
        perksDescription: '',
        isEditPerk: false,
        perkIndex: -1,
        // general
        isRemote: false,
        jobTitle: '',
        jobTitleInfo: {} as any,
        department: '',
        location: '',
        locationInfo: {} as any,
        gmtStart: '',
        gmtEnd: '',
        deadline: '',
        experience: '',
        jobType: '',
        salaryFrom: '',
        salaryEnd: '',
        // skill
        skillList: [] as defaultInfo[],
        softSkillList: [] as defaultInfo[],
        assocRole: [] as any,
        //
        jobDescription: '',
        keyResponsibilities: '',
    });
    // job title
    const [autocomplateLoading, setAutocomplateLoading] = useState(false);
    const [jobTitle, setJobTitle] = useState('');
    const [jobOptions, setJobOptions] = useState<any[]>([]);
    const getJobOption = search => {
        if (!search) {
            setJobOptions([]);
            return;
        }
        setAutocomplateLoading(true);
        api.getJobRoleList({ keyWord: search }, res => {
            setAutocomplateLoading(false);
            if (res.code === 200) {
                const results = res.data.map((item: any) => ({
                    value: item.title,
                    code: item.code,
                    label: item.title,
                }));
                setJobOptions(results);
            } else {
                setJobOptions([]);
                messageApi.error(res.message);
            }
        });
    };
    const searchList: any = useCallback(
        debounce(params => {
            getJobOption(params);
            console.log('search');
        }, 1000),
        []
    );
    // location
    const [address, setAddress] = useState('');
    const [locationOptions, setLocationOptions] = useState<any[]>([]);
    const getLocationOption = search => {
        if (!search) {
            setLocationOptions([]);
            return;
        }
        setAutocomplateLoading(true);
        api.getLocationList({ keyWord: search }, res => {
            setAutocomplateLoading(false);
            if (res.code === 200) {
                const results = res.data.map((item: defaultInfo, i: number) =>
                    Object.assign(item, { value: `${item.location}-${i}`, label: item.location })
                );
                setLocationOptions(results);
            } else {
                setLocationOptions([]);
                messageApi.error(res.message);
            }
        });
    };
    const searchLocationList: any = useCallback(
        debounce(params => {
            getLocationOption(params);
            console.log('search');
        }, 1000),
        []
    );
    // skill search
    const [skillOptions, setSkillOptions] = useState<{ value: string }[]>([]);
    const getSkillOption = params => {
        setAutocomplateLoading(true);
        api.getSkillList({ keyWord: params.search, code: params.code }, res => {
            setAutocomplateLoading(false);
            if (res.code === 200) {
                const results = res.data.map((item: any) => ({ value: item.title, label: item.title }));
                setSkillOptions(results);
            } else {
                setSkillOptions([]);
                messageApi.error(res.message);
            }
        });
    };
    const searchSkillList: any = useCallback(
        debounce(params => {
            // if (!params.search) {
            //     setSkillOptions([]);
            //     return;
            // }
            getSkillOption(params);
            console.log('search');
        }, 1000),
        []
    );
    // ai
    const [descAiType, setDescAiType] = useState('');
    const [descAiLoading, setDescAiLoading] = useState(false);
    const [keyReAiType, setKeyReAiType] = useState('');
    const [keyReAiLoading, setKeyReAiLoading] = useState(false);
    const [questionAiLoading, setQuestionAiLoading] = useState(false);
    const [interviewAiLoading, setInterViewAiLoading] = useState(false);
    const addAiWrite = (type: string) => {
        const tonesStr = {
            '1': 'Quirky and cool vibes',
            '2': 'Warm and Friendly',
            '3': 'Informative and Professional',
        };
        const pat = {
            keyword: formInfo.jobTitle,
            tones: '',
            type: type,
            jobTitle: formInfo.jobTitle,
        };
        if (type === 'Job Description') {
            if (!formInfo.jobDescription && !formInfo.jobTitle) {
                messageApi.error('Please select a job title first.');
                return;
            }
            setDescAiLoading(true);
            pat.tones = tonesStr[descAiType] ? tonesStr[descAiType] : '';
            pat.keyword =
                formInfo.jobDescription || `Summarize the job description of ${formInfo.jobTitle} in 1000 characters`;
        } else if (type === 'Key Responsibilities') {
            if (!formInfo.keyResponsibilities && !formInfo.jobTitle) {
                messageApi.error('Please select a job title first.');
                return;
            }
            setKeyReAiLoading(true);
            pat.tones = tonesStr[keyReAiType] ? tonesStr[keyReAiType] : '';
            pat.keyword =
                formInfo.keyResponsibilities ||
                `Summarize ${formInfo.jobTitle}'s key job responsibilities in 1000 characters`;
        } else if (type === 'Screening Questions') {
            if (!question.description && !formInfo.jobTitle) {
                messageApi.error('Please select a job title first.');
                return;
            }
            setQuestionAiLoading(true);
            pat.tones = '';
            pat.keyword =
                question.description ||
                `I need to recruit some candidates for ${formInfo.jobTitle} and provide a job screening question`;
        } else {
            if (!questionInterView.question && !formInfo.jobTitle) {
                messageApi.error('Please select a job title first.');
                return;
            }
            setInterViewAiLoading(true);
            pat.tones = '';
            pat.keyword =
                questionInterView.question ||
                `As an interviewer, I asked ${formInfo.jobTitle} candidate a question during the interview`;
        }
        if (!pat.keyword) {
            messageApi.error('Please select a job title first.');
            setDescAiLoading(false);
            setKeyReAiLoading(false);
            setQuestionAiLoading(false);
            setInterViewAiLoading(false);
            return;
        }
        if (type === 'Screening Questions') {
            const params = {
                jobName: formInfo.jobTitle,
                questionType:
                    question.type === 'Multiple Choice'
                        ? 'multipleChoice'
                        : question.type === 'Single Choice'
                        ? 'signalChoice'
                        : 'shortAnswer',
                x: question.options.length || 0,
            };
            api.aiWriteQuestion(params, res => {
                setQuestionAiLoading(false);
                if (res.code === 200) {
                    question.description = res.data.question
                        ? res.data.question.replace(/^\n+/, '').substring(0, 200)
                        : '';
                    if (res.data.answerList && res.data.answerList.length) {
                        res.data.answerList.forEach((val, i) => {
                            if (i > question.options.length - 1) return;
                            question.options[i].value = val;
                        });
                    }
                    setQuestion({ ...question });
                } else {
                    messageApi.error(res.message);
                }
            });
            return;
        }
        if (type === 'Interview Scorecard Questions') {
            const params = {
                jobName: formInfo.jobTitle,
                x: 4,
            };
            api.aiWriteInterviewQuestion(params, res => {
                setInterViewAiLoading(false);
                if (res.code === 200) {
                    questionInterView.question = res.data.question
                        ? res.data.question.replace(/^\n+/, '').substring(0, 200)
                        : '';
                    if (res.data.answerList && res.data.answerList.length) {
                        res.data.answerList.forEach((val, i) => {
                            if (i > questionInterView.options.length - 1) return;
                            questionInterView.options[i].value = val;
                        });
                    }
                    setQuestionInterView({ ...questionInterView });
                } else {
                    messageApi.error(res.message);
                }
            });
            return;
        }
        api.aiWrite(pat, res => {
            if (res.code === 200) {
                if (type === 'Job Description') {
                    // formInfo.jobDescription = res.data;
                    setFormInfo({ ...formInfo, jobDescription: res.data });
                    form.setFieldsValue({ jobDescription: res.data });
                } else if (type === 'Key Responsibilities') {
                    formInfo.keyResponsibilities = res.data;
                    setFormInfo({ ...formInfo });
                    form.setFieldsValue({ keyResponsibilities: res.data });
                } else if (type === 'Screening Questions') {
                    question.description = res.data.replace(/^\n+/, '').substring(0, 200);
                    setQuestion({ ...question });
                    // form.setFieldsValue({ keyResponsibilities: res.data });
                } else {
                    questionInterView.question = res.data.replace(/^\n+/, '').substring(0, 200);
                    setQuestionInterView({ ...questionInterView });
                }
            } else {
                messageApi.error(res.message);
            }
            setDescAiLoading(false);
            setKeyReAiLoading(false);
        });
    };
    // associated role
    const [associatedRole, setAssociatedRole] = useState('');
    const [roleOptions, setRoleOptions] = useState<{ value: string }[]>([]);
    const getRoleOption = search => {
        if (!search) {
            setRoleOptions([]);
            return;
        }
        setAutocomplateLoading(true);
        api.getJobRoleList({ keyWord: search }, res => {
            setAutocomplateLoading(false);
            if (res.code === 200) {
                const results = res.data.map((item: any) => ({ value: item.title }));
                setRoleOptions(results);
            } else {
                setRoleOptions([]);
                messageApi.error(res.message);
            }
        });
    };
    const searchRoleList: any = useCallback(
        debounce(params => {
            getRoleOption(params);
            console.log('search');
        }, 1000),
        []
    );
    // perks
    const [errorFields, setErrorFields] = React.useState({
        perksTitle: false,
        perksDescription: false,
    });
    const [perkList, setPerkList] = useState<any>([]);
    const savePerks = () => {
        // setErrorFields({ perksTitle: !formInfo.perksTitle, perksDescription: !formInfo.perksDescription });
        if (!formInfo.perksTitle && !formInfo.perksDescription) {
            window.utils.alert('error', 'Please complete the perks information.');
            return;
        }
        const perkInfo = { perksTitle: formInfo.perksTitle, perksDescription: formInfo.perksDescription };
        if (!formInfo.isEditPerk) {
            perkList.push(perkInfo);
        } else {
            perkList.splice(formInfo.perkIndex, 1, { ...perkInfo });
        }
        setErrorFields({ perksTitle: false, perksDescription: false });
        setFormInfo({ ...formInfo, perksTitle: '', perksDescription: '', isEditPerk: false, perkIndex: -1 });
        setPerkList([...perkList]);
    };
    const opeationPerk = (val, i, type) => {
        if (!type) {
            perkList.splice(i, 1);
            setPerkList([...perkList]);
            return;
        }
        setFormInfo({
            ...formInfo,
            perksTitle: val.perksTitle,
            perksDescription: val.perksDescription,
            isEditPerk: true,
            perkIndex: i,
        });
    };
    // price
    const [value, setValue] = useState('');
    const [value1, setValue1] = useState('');
    const formatNumber = num => {
        if (!num) return '';
        const parts = num.split('.');
        const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        const decimalPart = parts[1] ? `.${parts[1].slice(0, 2)}` : '';
        return integerPart + decimalPart;
    };
    const handleBlur = (e, type) => {
        let nowPrice = '0';
        const v = Number(value.split(',').join('') || '');
        const v1 = Number(value1.split(',').join('') || '');
        if (type === 1) {
            if (v1 && v > v1) {
                nowPrice = v1.toString();
                setValue1(formatNumber(value));
            } else {
                nowPrice = !v ? '' : v.toString();
            }
            const formattedValue = nowPrice ? formatNumber(parseFloat(nowPrice).toString()) : '';
            setValue(formattedValue);
            form.setFieldsValue({ amount: formattedValue });
            return;
        }
        if (v && v1 < v) {
            nowPrice = v.toString();
            setValue(formatNumber(value1));
        } else {
            nowPrice = !v1 ? '' : v1.toString();
        }
        const formattedValue = nowPrice ? formatNumber(parseFloat(nowPrice).toString()) : '';
        setValue1(formattedValue);
        form.setFieldsValue({ amount1: formattedValue });
    };
    const handleFocus = (e, type) => {
        if (type === 1) {
            const str = value.split(',').join('');
            setValue(str);
            form.setFieldsValue({ amount: str });
            return;
        }
        const str = value1.split(',').join('');
        setValue1(str);
        form.setFieldsValue({ amount1: str });
    };
    const handleChange = (e, type) => {
        const inputValue = e.target.value;
        // Only allow numbers and up to two decimal places
        const reg = /^(?!0\d)\d{0,9}(\.\d{0,2})?$/;
        if (reg.test(inputValue) || inputValue === '') {
            if (type === 1) {
                setValue(inputValue);
                form.setFieldsValue({ amount: inputValue });
            } else {
                setValue1(inputValue);
                form.setFieldsValue({ amount1: inputValue });
            }
        }
    };
    //screening question
    const [question, setQuestion] = useState({
        type: 'Short Answer',
        description: '',
        options: [{ value: '' }, { value: '' }],
    });
    const [questionEditIndex, setQuestionEditIndex] = useState(-1);
    const [questionList, setQuestionList] = useState<any>([]);
    const [errorQuestionFields, setErrorQuestionFields] = useState({
        questionType: false,
        questionDescription: false,
        option: false,
    });
    const saveQuestion = () => {
        setErrorQuestionFields({
            questionType: !question.type,
            questionDescription: !question.description,
            option: question.options.findIndex(v => !v.value) !== -1 && question.type !== 'Short Answer',
        });
        if (
            !question.type ||
            !question.description ||
            (question.options.filter(v => v.value).length < 2 && question.type !== 'Short Answer')
        ) {
            window.utils.alert('error', 'Please first set the content of the question and at least 2 answer options.');
            return;
        }
        const arr = question.options.filter(v => v.value);
        question.options = arr;
        if (questionEditIndex === -1) {
            questionList.push({ ...question });
        } else {
            questionList.splice(questionEditIndex, 1, { ...question });
        }
        setErrorQuestionFields({ questionType: false, questionDescription: false, option: false });
        setQuestion({
            type: 'Short Answer',
            description: '',
            options: [{ value: '' }, { value: '' }],
        });
        setQuestionEditIndex(-1);
        setQuestionList([...questionList]);
    };
    const opeationQuestion = (val, i, type) => {
        if (!type) {
            questionList.splice(i, 1);
            setQuestionList([...questionList]);
            return;
        }
        setQuestionEditIndex(i);
        setQuestion({ ...val });
    };
    // interview question
    const [questionInterView, setQuestionInterView] = useState({
        question: '',
        options: [
            { value: '', icon: NoteImg1, text: 'Excellent', isSel: false },
            { value: '', icon: NoteImg2, text: 'Good', isSel: false },
            { value: '', icon: NoteImg3, text: 'Average', isSel: false },
            { value: '', icon: NoteImg4, text: 'Below Average', isSel: false },
        ],
    });
    const [questionInterViewEditIndex, setQuestionInterViewEditIndex] = useState(-1);
    const [questionInterViewList, setQuestionInterViewList] = useState<any>([]);
    const [errorQuestionInterViewFields, setErrorQuestionInterViewFields] = useState({
        question: false,
        option: false,
    });
    const saveQuestionInterView = () => {
        setErrorQuestionInterViewFields({
            question: !questionInterView.question,
            option:
                questionInterView.options.findIndex(v => !v.value && v.isSel) !== -1 ||
                questionInterView.options.filter(v => !v.isSel).length > 2,
        });
        if (
            !questionInterView.question ||
            questionInterView.options.findIndex(v => !v.value && v.isSel) !== -1 ||
            questionInterView.options.filter(v => !v.isSel).length > 2
        ) {
            window.utils.alert('error', 'Please first set the content of the question and at least 2 answer options.');
            return;
        }
        if (questionInterViewEditIndex === -1) {
            questionInterViewList.push({ ...questionInterView });
        } else {
            questionInterViewList.splice(questionInterViewEditIndex, 1, { ...questionInterView });
        }
        setErrorQuestionInterViewFields({ question: false, option: false });
        setQuestionInterView({
            question: '',
            options: [
                { value: '', icon: NoteImg1, text: 'Excellent', isSel: false },
                { value: '', icon: NoteImg2, text: 'Good', isSel: false },
                { value: '', icon: NoteImg3, text: 'Average', isSel: false },
                { value: '', icon: NoteImg4, text: 'Below Average', isSel: false },
            ],
        });
        setQuestionInterViewEditIndex(-1);
        setQuestionInterViewList([...questionInterViewList]);
    };
    const opeationQuestionInterView = (val, i, type) => {
        if (!type) {
            questionInterViewList.splice(i, 1);
            setQuestionInterViewList([...questionInterViewList]);
            return;
        }
        setQuestionInterViewEditIndex(i);
        setQuestionInterView({ ...val });
    };
    // team member add
    const getTeamList = () => {
        api.getBusinessTeamList({ pageNo: 1, pageSize: 1000, keyWord: '' }, res => {
            if (res.code === 200) {
                const data = res.data.businessAccountPageBean;
                const arr: defaultInfo[] = [];
                data.list.map(item => {
                    const obj = Object.assign(item, {
                        showName: window.utils.getInitials(item.firstName, item.lastName),
                        isMe: item.accountId === profileInfo.accountId ? true : false,
                        value: item.accountId,
                        label: `${item.firstName} ${item.middleName} ${item.lastName}`,
                    });
                    if (item.accountId !== profileInfo.accountId) {
                        arr.push(obj);
                    }
                });
                setTeamOptions([...arr]);
                setTeamOptionsAll([...arr]);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const [teamVal, setTeamVal] = useState('');
    const [teamSelOptions, setTeamSelOptions] = useState<any>([]);
    const [teamOptions, setTeamOptions] = useState<AutoCompleteProps['options']>([]);
    const [teamOptionsAll, setTeamOptionsAll] = useState<AutoCompleteProps['options']>([]);
    const getPanelValue = (searchText: string) =>
        !searchText ? teamOptionsAll : window.utils.filterArrayByString(teamOptionsAll, searchText);

    const onTeamSelect = data => {
        if (teamSelOptions.length >= 5) {
            messageApi.error('You can add a maximum of 5 team members.');
            setTeamVal('');
            return;
        }
        const val = teamOptionsAll?.find(v => v.accountId === data);
        if (val && teamSelOptions.findIndex(v => v.accountId === data) === -1) {
            teamSelOptions.push(val);
            setTeamSelOptions([...teamSelOptions]);
        }
        setTeamVal('');
    };
    const onTeamChange = (data: string) => {
        setTeamVal(data);
    };
    // push & save
    const [savaLoading, setSaveLoading] = useState(false);
    const [dartLoading, setDartLoading] = useState(false);
    const scrollToError = () => {
        const firstErrorField = document.querySelector('.ant-form-item-has-error');
        if (firstErrorField) {
            firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };
    // 获取提交数据
    const getFormInfo = type => {
        if (formInfo.skillList.length < 1 && type) {
            messageApi.error('You need to add at least 1 hard skill.');
            return false;
        }
        if (formInfo.skillList.findIndex(v => !v.name || !v.leave) !== -1 && type) {
            messageApi.error('Please fill in the complete hard skills information.');
            return false;
        }
        if (formInfo.softSkillList.findIndex(v => !v.name) !== -1 && type) {
            messageApi.error('Please fill in the complete hard skills information.');
            return false;
        }
        const perkListNow = [...perkList];
        if (formInfo.perksTitle || formInfo.perksDescription) {
            const perkInfo = { perksTitle: formInfo.perksTitle, perksDescription: formInfo.perksDescription };
            if (!formInfo.isEditPerk) {
                perkListNow.push(perkInfo);
            } else {
                perkListNow.splice(formInfo.perkIndex, 1, { ...perkInfo });
            }
        }
        const questionListNow = [...questionList];
        if (
            question.type &&
            question.description &&
            (question.options.findIndex(v => !v.value) === -1 || question.type === 'Short Answer')
        ) {
            if (questionEditIndex === -1) {
                questionListNow.push({ ...question });
            } else {
                questionListNow.splice(questionEditIndex, 1, { ...question });
            }
        }
        if (questionListNow.length < 1 && type) {
            messageApi.error('You need to add at least 1 screening question.');
            return false;
        }
        const questionInterViewListNow = [...questionInterViewList];
        if (
            questionInterView.question &&
            questionInterView.options.findIndex(v => !v.value && v.isSel) === -1 &&
            questionInterView.options.filter(v => v.isSel).length >= 2
        ) {
            if (questionInterViewEditIndex === -1) {
                questionInterViewListNow.push({ ...questionInterView });
            } else {
                questionInterViewListNow.splice(questionInterViewEditIndex, 1, { ...questionInterView });
            }
        }
        if (questionInterViewListNow.length < 1 && type) {
            messageApi.error('You need to add at least 1 interview scoring question.');
            return false;
        }
        const pat = {
            aboutTheCompany: formInfo.about,
            associatedRoles: formInfo.assocRole.length ? formInfo.assocRole.join('-/-') : '',
            businessId: '',
            deadline: dayjs(formInfo.deadline).valueOf() / 1000,
            department: formInfo.department,
            employerLogo: formInfo.employerLogo,
            employerName: formInfo.employerName,
            experienceRequiredYear: formInfo.experience,
            gmtFromId: formInfo.gmtStart,
            gmtToId: formInfo.gmtEnd,
            jobDescription: formInfo.jobDescription,
            jobId: '',
            jobTitle: formInfo.jobTitle,
            jobTitleCode: formInfo.jobTitleInfo.code,
            jobType: formInfo.jobType,
            keyResponsibilities: formInfo.keyResponsibilities,
            location: formInfo.location,
            remoteFlag: formInfo.isRemote ? 1 : 0,
            salaryFrom: value ? value.split(',').join('') : '',
            salaryTo: value1 ? value1.split(',').join('') : '',
            perksVOList: perkListNow.length
                ? perkListNow.map(item => {
                      return {
                          // id: 0,
                          // perksDescription: item,
                          // perksTitle: '',
                          ...item,
                      };
                  })
                : [],
            jobsApprovalTeamVO: {
                businessAccountIdList: teamSelOptions.map(v => v.accountId),
            },
            jobsInterviewScorecardQuestionsVOList: questionInterViewListNow.map(item => {
                return {
                    average: item.options[2].value,
                    averageFlag: item.options[2].isSel ? 1 : 0,
                    belowAverage: item.options[3].value,
                    belowAverageFlag: item.options[3].isSel ? 1 : 0,
                    excellent: item.options[0].value,
                    excellentFlag: item.options[0].isSel ? 1 : 0,
                    good: item.options[1].value,
                    goodFlag: item.options[1].isSel ? 1 : 0,
                    question: item.question,
                    // scoreQuestionsId: '',
                };
            }),
            jobsScreeningQuestionsVOList: questionListNow.length
                ? questionListNow.map(item => {
                      return {
                          jobsScreeningQuestionsOptionsVOList: item.options
                              ? item.options.map(val => {
                                    return {
                                        options: val.value,
                                        // optionsId: '',
                                    };
                                })
                              : [],
                          questionDescription: item.description,
                          questionTypes: item.type,
                          //   questionsId: '',
                          skillName: '',
                      };
                  })
                : [],
            softSkillVOList: formInfo.softSkillList.length
                ? formInfo.softSkillList.map(item => {
                      return {
                          skillLevel: item.leave,
                          mandatoryFlag: item.isCheck ? 1 : 0,
                          // skillId: '1',
                          skillName: item.name,
                      };
                  })
                : [],
            hardSkillVOList: formInfo.skillList.length
                ? formInfo.skillList.map(item => {
                      return {
                          skillLevel: item.leave,
                          mandatoryFlag: item.isCheck ? 1 : 0,
                          // skillId: '1',
                          skillName: item.name,
                      };
                  })
                : [],
        };
        return pat;
    };
    const handleValidate = (type: string, opType: any) => {
        // type 工作类型 optype 1 新增 2修改
        if (type === 'dart') {
            // 保存草稿
            form.resetFields();
            // if (!formInfo.jobTitle || !formInfo.jobTitleInfo.code) {
            if (!formInfo.jobTitle) {
                formRef.current.validateFields(['jobTitle']);
                scrollToError();
                return;
            }
            setDartLoading(true);
            const pat: any = getFormInfo(0);
            if (!pat) return;
            if (opType === 1) {
                // 添加草稿
                api.addDart(pat, res => {
                    setDartLoading(false);
                    if (res.code === 200) {
                        messageApi.success('Success');
                        backPrePage();
                    } else {
                        messageApi.error(res.message);
                    }
                });
            } else {
                // 修改草稿
                pat.jobId = jobInfoForm.jobId;
                api.addDart(pat, res => {
                    setDartLoading(false);
                    if (res.code === 200) {
                        messageApi.success('Success');
                        backPrePage();
                    } else {
                        messageApi.error(res.message);
                    }
                });
            }
            return;
        }
        form.validateFields()
            .then(values => {
                const pat: any = getFormInfo(1);
                if (!pat) return;
                if (type === 'temp') {
                    // 模版
                    setSaveLoading(true);
                    if (opType === 1) {
                        api.addTemplate(pat, res => {
                            setSaveLoading(false);
                            if (res.code === 200) {
                                messageApi.success('Success');
                                backPrePage();
                            } else {
                                messageApi.error(res.message);
                            }
                        });
                        return;
                    }
                    // 修改模版
                    pat.jobId = jobInfoForm.jobId;
                    api.addTemplate(pat, res => {
                        setSaveLoading(false);
                        if (res.code === 200) {
                            messageApi.success('Success');
                            backPrePage();
                        } else {
                            messageApi.error(res.message);
                        }
                    });
                    return;
                } else {
                    setSaveLoading(true);
                    if (opType === 1) {
                        // 新增工作
                        api.addJobInfo(pat, res => {
                            setSaveLoading(false);
                            if (res.code === 200) {
                                messageApi.success('Success');
                                backPrePage();
                            } else {
                                messageApi.error(res.message);
                            }
                        });
                    } else {
                        // 修改
                        pat.jobId = jobInfoForm.jobId;
                        api.addJobInfo(pat, res => {
                            setSaveLoading(false);
                            if (res.code === 200) {
                                messageApi.success('Success');
                                backPrePage();
                            } else {
                                messageApi.error(res.message);
                            }
                        });
                    }
                }
            })
            .catch(errorInfo => {
                scrollToError();
            });
    };
    // 返回上一页
    const backPrePage = () => {
        window.sessionStorage.setItem('isTopAdd', '0');
        window.sessionStorage.removeItem('jobInfoForm');
        setIsBreak(false);
        setTimeout(() => {
            if (isTopAdd) {
                navigate('/home');
                return;
            }
            navigate(-1);
        }, 1000);
    };
    // 获取job详情
    const getInfo = val => {
        api.getJobInfo({ jobId: val.jobId }, res => {
            const { code, data, message } = res;
            if (code === 200) {
                const jobInfo = data.jobsPOJO;
                getSkillOption({ search: '', code: jobInfo.jobTitleCode });
                const initialValues = {
                    jobId: data.jobId,
                    employerLogo: jobInfo.employerLogo,
                    picUrlShow: jobInfo.employerLogo,
                    employerName: jobInfo.employerName,
                    about: jobInfo.aboutTheCompany,
                    perksTitle: '',
                    perksDescription: '',
                    isEditPerk: false,
                    perkIndex: -1,
                    // general
                    isRemote: !!jobInfo.remoteFlag,
                    jobTitle: jobInfo.jobTitle,
                    jobTitleInfo: {
                        code: jobInfo.jobTitleCode,
                    } as any,
                    department: jobInfo.department,
                    location: jobInfo.location,
                    locationInfo: {} as any,
                    gmtStart: jobInfo.gmtFromId ? jobInfo.gmtFromId.toString() : '',
                    gmtEnd: jobInfo.gmtToId ? jobInfo.gmtToId.toString() : '',
                    deadline: dayjs(jobInfo.deadline * 1000 || new Date()) as any,
                    experience: jobInfo.experienceRequiredYear ? jobInfo.experienceRequiredYear.toString() : '0',
                    jobType: jobInfo.jobType,
                    salaryFrom: jobInfo.salaryFrom ? jobInfo.salaryFrom.toString() : '',
                    salaryEnd: jobInfo.salaryTo ? jobInfo.salaryTo.toString() : '',
                    // 描述
                    jobDescription: jobInfo.jobDescription,
                    keyResponsibilities: jobInfo.keyResponsibilities,
                    // skill
                    skillList: [] as defaultInfo[],
                    softSkillList: [] as defaultInfo[],
                    assocRole: jobInfo.associatedRoles ? jobInfo.associatedRoles.split('-/-') : [],
                };
                setValue(jobInfo.salaryFrom ? jobInfo.salaryFrom.toString() : '');
                setValue1(jobInfo.salaryTo ? jobInfo.salaryTo.toString() : '');
                setPerkList(data.jobsPerksPOJOList || []);
                const teamSel =
                    data.jobsApprovalTeamPOJOList && data.jobsApprovalTeamPOJOList.length
                        ? data.jobsApprovalTeamPOJOList.map(item => {
                              return Object.assign(item, {
                                  showName: window.utils.getInitials(item.firstName, item.lastName),
                                  isMe: item.accountId === profileInfo.accountId ? true : false,
                                  value: item.accountId,
                                  label: `${item.firstName} ${item.middleName} ${item.lastName}`,
                              });
                          })
                        : [];
                setTeamSelOptions(teamSel);
                const hardSkill =
                    data.hardSkillPOJOList && data.hardSkillPOJOList.length
                        ? data.hardSkillPOJOList.map(item => {
                              return Object.assign(item, {
                                  leave: item.skillLevel,
                                  isCheck: !!item.mandatoryFlag,
                                  name: item.skillName,
                              });
                          })
                        : [];
                const softSkill =
                    data.softSkillPOJOList && data.softSkillPOJOList.length
                        ? data.softSkillPOJOList.map(item => {
                              return Object.assign(item, {
                                  leave: item.skillLevel,
                                  isCheck: !!item.mandatoryFlag,
                                  name: item.skillName,
                              });
                          })
                        : [];
                const questionListNow = data.screeningQuestionsPOJOList
                    ? data.screeningQuestionsPOJOList.map(item => {
                          return {
                              type: item.questionTypes,
                              description: item.questionDescription,
                              options: item.jobsScreeningQuestionsOptionsPOJOList.map(val => {
                                  return {
                                      value: val.options,
                                  };
                              }),
                          };
                      })
                    : [];
                setQuestionList(questionListNow || []);
                const questionInterViewNow = data.interviewScorecardQuestionsPOJOList
                    ? data.interviewScorecardQuestionsPOJOList.map(item => {
                          return {
                              question: item.question,
                              options: [
                                  {
                                      value: item.excellent,
                                      icon: NoteImg1,
                                      text: 'Excellent',
                                      isSel: !!item.excellentFlag,
                                  },
                                  { value: item.good, icon: NoteImg2, text: 'Good', isSel: !!item.goodFlag },
                                  { value: item.average, icon: NoteImg3, text: 'Average', isSel: !!item.averageFlag },
                                  {
                                      value: item.belowAverage,
                                      icon: NoteImg4,
                                      text: 'Below Average',
                                      isSel: !!item.belowAverageFlag,
                                  },
                              ],
                          };
                      })
                    : [];
                setQuestionInterViewList(questionInterViewNow || []);
                initialValues.skillList = [...hardSkill];
                initialValues.softSkillList = [...softSkill];
                setFormInfo({ ...formInfo, ...initialValues });
                form.setFieldsValue(initialValues);
            } else {
                messageApi.error(message);
            }
        });
    };
    const resetForm = () => {
        const initialValues = {
            jobId: '',
            employerLogo: '',
            picUrlShow: '',
            employerName: '',
            about: '',
            perksTitle: '',
            perksDescription: '',
            isEditPerk: false,
            perkIndex: -1,
            // general
            isRemote: false,
            jobTitle: '',
            jobTitleInfo: {
                code: '',
            } as any,
            department: '',
            location: '',
            locationInfo: {} as any,
            gmtStart: '',
            gmtEnd: '',
            deadline: null,
            experience: '',
            jobType: '',
            salaryFrom: '',
            salaryEnd: '',
            // 描述
            jobDescription: '',
            keyResponsibilities: '',
            // skill
            skillList: [] as defaultInfo[],
            softSkillList: [] as defaultInfo[],
            assocRole: [],
        };
        setQuestionInterViewList([]);
        setQuestionList([]);
        setTeamSelOptions([]);
        setValue('');
        setValue1('');
        setPerkList([]);
        setFormInfo({ ...formInfo, ...initialValues } as any);
        form.setFieldsValue(initialValues);
    };
    const [pageType, setPageType] = useState('');
    const [jobInfoForm, setJobInfoForm] = useState({ jobId: '' });
    const getDataOp = () => {
        const isTopAddStr = window.sessionStorage.getItem('isTopAdd') || '0';
        setIsTopAdd(isTopAddStr === '1' ? true : false);
        const val = window.sessionStorage.getItem('jobInfoForm') || JSON.stringify({ jobId: '' });
        const jobInfoForm = val !== null ? JSON.parse(val) : { jobId: '' };
        if (jobInfoForm.jobId) {
            setJobInfoForm({ ...jobInfoForm });
            if (jobInfoForm.type) {
                //存储操作类型 tc 填充 edit 修改
                setPageType(jobInfoForm.type);
            }
            getInfo({ ...jobInfoForm });
        } else {
            setPageType('');
            resetForm();
        }
    };
    useEffect(() => {
        getTeamList();
        api.getGmtList(res => {
            if (res.code === 200) {
                const arr = res.data.map(item => Object.assign(item, { label: item.gmt, value: item.id }));
                setGMTList(arr);
                getDataOp();
            } else {
                messageApi.error(res.message);
            }
        });
    }, [location.pathname, location.search, location.state]);
    useEffect(() => {
        if (formPage !== 'preview') {
            setIsBreak(true);
        } else {
            setIsBreak(false);
        }
    }, [formPage]);
    // 删除模版
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [opLoading, setOpLoading] = useState(false);
    const confirmDel = () => {
        setOpLoading(true);
        api.deleteJob({ jobId: formInfo.jobId }, res => {
            if (res.code === 200) {
                setIsModalOpen(false);
                setOpLoading(false);
                backPrePage();
            } else {
                setOpLoading(false);
                window.utils.alert('error', res.message);
            }
        });
    };
    // 修改草稿离开
    const dartLeaveConfirm = () => {
        handleValidate('dart', 2);
    };
    const [isBreake, setIsBreak] = useState(true);
    const [isTopAdd, setIsTopAdd] = useState(false);
    const { isLeaveModalOpen, leaveConfirm, leaveCancel } = usePrompt({
        when: isBreake,
    });
    return (
        <div className="jobInfoMain-box">
            <div className="formBg-box">
                {formPage === 'preview' ? (
                    <div className="createInfo-tit">Preview</div>
                ) : (
                    <>
                        {formPage === 'editDart' ? (
                            // dart
                            <div className="createInfo-tit">Edit Draft</div>
                        ) : formPage === 'addFromTem' ? (
                            // templ
                            <div className="createInfo-tit">{pageType === 'edit' ? 'Edit' : 'Create New'} Template</div>
                        ) : (
                            // job
                            <div className="createInfo-tit">{pageType === 'edit' ? 'Edit' : 'Create New'} Job</div>
                        )}
                        <p className="createInfo-tip">
                            The candidates listed in the sourcing section for this job are recommended based on the
                            job's description and requirements. If you edit the job details, the displayed candidates
                            will be updated accordingly.
                        </p>
                    </>
                )}
                <div className={`information-formBox ${formPage === 'preview' ? 'disabledForm' : ''}`}>
                    <Form
                        name="job-form"
                        layout="vertical"
                        // disabled={formPage === 'preview'}
                        // initialValues={{ remember: true }}
                        form={form}
                        ref={formRef}
                        validateTrigger="onBlur"
                        autoComplete="off"
                        onKeyDown={event => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                            }
                        }}
                    >
                        <p className="job-formTit">Employer</p>
                        <div className="r-between">
                            <div className="form-w50">
                                <div className="r-left">
                                    <Form.Item
                                        label="Employer Logo"
                                        name="logo"
                                        rules={[{ required: false, message: '' }]}
                                    >
                                        <UploadImg
                                            type="job"
                                            imgUrl={formInfo.employerLogo}
                                            setPicInfo={(val: any) => {
                                                // setFormInfo({
                                                //     ...formInfo,
                                                //     employerLogo: val.picUrl,
                                                //     picUrlShow: val.picUrlShow,
                                                // });
                                                setFormInfo(prev => ({
                                                    ...prev,
                                                    employerLogo: val.picUrl,
                                                    picUrlShow: val.picUrlShow,
                                                }));
                                                form.setFieldsValue({ profilePhoto: val.picUrl });
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        className="flex1"
                                        label="Employer Name"
                                        name="employerName"
                                        rules={[{ required: true, message: '' }]}
                                    >
                                        <Input
                                            className="r-input"
                                            value={formInfo.employerName}
                                            onChange={(e: any) => {
                                                // setFormInfo({ ...formInfo, employerName: e.target.value });
                                                setFormInfo(prev => ({
                                                    ...prev,
                                                    employerName: e.target.value,
                                                }));
                                                form.setFieldsValue({ employerName: e.target.value });
                                            }}
                                            placeholder=""
                                        />
                                    </Form.Item>
                                </div>
                                <Form.Item
                                    label={
                                        <div className="r-left flex1">
                                            About the Company
                                            <span className="textare-countTip marLeft">
                                                {formInfo.about.length} / 500 characters
                                            </span>
                                        </div>
                                    }
                                    name="about"
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                    ]}
                                >
                                    <TextArea
                                        className={`r-inputTextArea r-input rTextArea114`}
                                        maxLength={500}
                                        autoComplete="off"
                                        autoSize={false}
                                        placeholder=""
                                        value={formInfo.about}
                                        onChange={(e: any) => {
                                            setFormInfo({ ...formInfo, about: e.target.value });
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            <p className="form-rowLine"></p>
                            <div className="form-w50">
                                <Form.Item
                                    className="flex1"
                                    label="Perk Title (optional)"
                                    validateStatus={errorFields.perksTitle ? 'error' : ''}
                                    rules={[{ required: false, message: '' }]}
                                >
                                    <Input
                                        className="r-input"
                                        maxLength={100}
                                        value={formInfo.perksTitle}
                                        onInput={(e: any) => setFormInfo({ ...formInfo, perksTitle: e.target.value })}
                                        placeholder=""
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <div className="r-left flex1">
                                            Perk Description (optional)
                                            <span className="textare-countTip marLeft">
                                                {formInfo.perksDescription.length} / 500 characters
                                            </span>
                                        </div>
                                    }
                                    validateStatus={errorFields.perksDescription ? 'error' : ''}
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <TextArea
                                        className={`r-inputTextArea r-input rTextArea114`}
                                        maxLength={500}
                                        onInput={(e: any) =>
                                            setFormInfo({ ...formInfo, perksDescription: e.target.value })
                                        }
                                        value={formInfo.perksDescription}
                                        autoComplete="off"
                                        autoSize={false}
                                        placeholder=""
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="r-end">
                            <Button
                                className="r-primary_btn r-primaryNoBg_btn"
                                style={{
                                    width: 78,
                                    marginBottom: perkList.length ? 20 : 0,
                                    opacity: formPage === 'preview' ? 0.5 : 1,
                                }}
                                disabled={!formInfo.perksTitle && !formInfo.perksDescription}
                                onClick={() => savePerks()}
                            >
                                Save
                            </Button>
                        </div>
                        {perkList.map((val, i) => {
                            return (
                                <div className="form-addCard r-left" key={`form-addCard-${i}`}>
                                    <div style={{ paddingRight: 15 }}>
                                        <p className="addCard-tit">{val.perksTitle || ''}</p>
                                        <p className="addCard-msg" style={{ marginTop: 8 }}>
                                            {val.perksDescription || ''}
                                        </p>
                                    </div>
                                    <div className="r-left marLeft">
                                        <i className="editR-icon" onClick={() => opeationPerk(val, i, 1)}></i>
                                        <i
                                            className="delR-icon"
                                            style={{ marginLeft: 8 }}
                                            onClick={() => opeationPerk(val, i, 0)}
                                        ></i>
                                    </div>
                                </div>
                            );
                        })}
                        <p className="line-boxNew" style={{ marginTop: 32 }}></p>
                        <p className="job-formTit">General Information</p>
                        <div className="r-left">
                            <Form.Item
                                className="flex1"
                                label="Job Title"
                                name="jobTitle"
                                rules={[{ required: true, message: 'Please select at least the job title' }]}
                            >
                                <AutoComplete
                                    options={jobOptions}
                                    className="r-autoComplate r-autoComplate-bg"
                                    style={{ width: '100%', height: 44 }}
                                    value={jobTitle}
                                    suffixIcon={<i className="down-right-icon"></i>}
                                    onSelect={val => {
                                        const jobTitleInfo: any = jobOptions.find(v => v.label === val);
                                        setJobTitle(jobTitleInfo.label);
                                        setFormInfo({
                                            ...formInfo,
                                            jobTitle: jobTitleInfo.label,
                                            jobTitleInfo: { ...jobTitleInfo },
                                            skillList: [],
                                            softSkillList: [],
                                        });
                                        searchSkillList({ search: '', code: jobTitleInfo.code });
                                    }}
                                    onSearch={val => {
                                        searchList(val);
                                    }}
                                    placeholder=""
                                    notFoundContent={
                                        <>
                                            {autocomplateLoading && (
                                                <div className="r-center">
                                                    <Spin />
                                                </div>
                                            )}
                                            {!autocomplateLoading && <div>No data</div>}
                                        </>
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                className="flex1 marL24"
                                label="Department"
                                name="department"
                                rules={[{ required: true }]}
                            >
                                <Input
                                    className="r-input"
                                    maxLength={50}
                                    value={formInfo.department}
                                    onChange={(e: any) => {
                                        setFormInfo({ ...formInfo, department: e.target.value });
                                    }}
                                    placeholder=""
                                />
                            </Form.Item>
                        </div>
                        {/* location */}
                        <div className="r-left" style={{ minWidth: 'calc(50% - 12px)' }}>
                            <Form.Item
                                className="flex1"
                                label="Location"
                                name="location"
                                style={{ opacity: !formInfo.isRemote ? '1' : '0.4' }}
                                rules={[{ required: !formInfo.isRemote ? true : false }]}
                            >
                                <AutoComplete
                                    options={locationOptions}
                                    disabled={formInfo.isRemote}
                                    className="r-autoComplate r-autoComplate-bg"
                                    style={{ width: '100%', height: 44 }}
                                    value={address}
                                    onChange={setAddress}
                                    suffixIcon={<i className="down-right-icon"></i>}
                                    onSelect={val => {
                                        const info = locationOptions.find(v => v.value === val);
                                        setFormInfo({ ...formInfo, location: info.label, locationInfo: info });
                                        form.setFieldsValue({ location: info.label });
                                    }}
                                    onSearch={val => {
                                        setAddress(val);
                                        searchLocationList(val);
                                    }}
                                    placeholder=""
                                    notFoundContent={
                                        <>
                                            {autocomplateLoading && (
                                                <div className="r-center">
                                                    <Spin />
                                                </div>
                                            )}
                                            {!autocomplateLoading && <div>No data</div>}
                                        </>
                                    }
                                />
                            </Form.Item>
                            <div className="flex1 marL24 r-left" style={{ width: 'calc(50% - 12px)' }}>
                                <div style={{ marginTop: 10 }}>
                                    <RCheckBox
                                        checkInfo={{
                                            isChecked: formInfo.isRemote,
                                            label: 'Remote',
                                        }}
                                        width="75px"
                                        setCheckInfo={() => {
                                            setFormInfo({
                                                ...formInfo,
                                                isRemote: !formInfo.isRemote,
                                                // location: '',
                                                // gmtStart: '',
                                                // gmtEnd: '',
                                            });
                                            // setAddress('');
                                            // form.setFieldsValue({ location: '', gmtStart: '', gmtEnd: '' });
                                            formRef.current.validateFields(['gmtStart', 'gmtEnd', 'location']);
                                        }}
                                    />
                                </div>
                                <div
                                    className="r-left flex1 remote-list"
                                    style={{ opacity: formInfo.isRemote ? '1' : '0.4' }}
                                >
                                    <Form.Item
                                        label="GMT range"
                                        name="gmtStart"
                                        className="gmt-box"
                                        rules={[{ required: formInfo.isRemote ? true : false }]}
                                    >
                                        <Select
                                            className="r-select"
                                            placeholder="From"
                                            suffixIcon={<i className="down-right-icon"></i>}
                                            options={GMTList}
                                            disabled={!formInfo.isRemote}
                                            value={formInfo.gmtStart ? formInfo.gmtStart : undefined}
                                            onChange={val => {
                                                form.setFieldsValue({ gmtStart: val });
                                                setFormInfo({ ...formInfo, gmtStart: val });
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={<div></div>}
                                        name="gmtEnd"
                                        className="gmt-box"
                                        style={{ marginLeft: 12 }}
                                        rules={[{ required: formInfo.isRemote ? true : false }]}
                                    >
                                        <Select
                                            className="r-select"
                                            placeholder="To"
                                            suffixIcon={<i className="down-right-icon"></i>}
                                            options={GMTList}
                                            disabled={!formInfo.isRemote}
                                            value={formInfo.gmtEnd ? formInfo.gmtEnd : undefined}
                                            onChange={val => {
                                                form.setFieldsValue({ gmtEnd: val });
                                                setFormInfo({ ...formInfo, gmtEnd: val });
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <div className="r-left">
                            <Form.Item className="flex1" label="Deadline" name="deadline" rules={[{ required: true }]}>
                                <DatePicker
                                    className="r-datePicker-newForm w100"
                                    placeholder=" "
                                    suffixIcon={<i className="allow_time_icon"></i>}
                                    disabledDate={disabledDate}
                                    format="DD MMM YYYY"
                                    value={formInfo.deadline}
                                    onChange={val => {
                                        form.setFieldsValue({ deadline: val });
                                        setFormInfo({ ...formInfo, deadline: val });
                                    }}
                                    // picker="month"
                                />
                            </Form.Item>
                            <Form.Item
                                className="flex1 marL24"
                                label="Experience Required"
                                name="experience"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    className="r-select"
                                    suffixIcon={<i className="down-right-icon"></i>}
                                    value={formInfo.experience}
                                    onChange={val => {
                                        setFormInfo({ ...formInfo, experience: val });
                                        form.setFieldsValue({ experience: val });
                                    }}
                                    options={[
                                        { value: '0', label: 'No experience' },
                                        { value: '1', label: '1 year +' },
                                        { value: '2', label: '2 year +' },
                                        { value: '3', label: '3 year +' },
                                        { value: '4', label: '5 year +' },
                                        { value: '5', label: '10 year +' },
                                    ]}
                                />
                            </Form.Item>
                        </div>
                        <Form.Item className="flex1" label="Job Type" name="jobType" rules={[{ required: true }]}>
                            <Select
                                className="r-select"
                                suffixIcon={<i className="down-right-icon"></i>}
                                value={formInfo.jobType}
                                onChange={val => {
                                    form.setFieldsValue({ jobType: val });
                                    setFormInfo({ ...formInfo, jobType: val });
                                }}
                                options={[
                                    { value: '1', label: 'Full-Time' },
                                    { value: '2', label: 'Part-Time' },
                                    { value: '3', label: 'Contractor' },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item className="flex1" label="Salary Range (optional)" rules={[{ required: false }]}>
                            <div className="r-left">
                                <Input
                                    className="r-input flex1"
                                    value={value}
                                    onChange={e => handleChange(e, 1)}
                                    onBlur={e => handleBlur(e, 1)}
                                    onFocus={e => handleFocus(e, 1)}
                                    placeholder="From"
                                    maxLength={50}
                                    suffix={<span>£</span>}
                                />
                                <Input
                                    className="r-input flex1 marL24"
                                    maxLength={50}
                                    value={value1}
                                    onChange={e => handleChange(e, 0)}
                                    onBlur={e => handleBlur(e, 0)}
                                    onFocus={e => handleFocus(e, 0)}
                                    placeholder="To"
                                    suffix={<span>£</span>}
                                />
                            </div>
                        </Form.Item>
                        <p className="line-boxNew" style={{ marginTop: 32 }}></p>
                        <div className="job-formTit r-left">
                            Skills
                            <Tooltip
                                overlayClassName="skill-tooltipBox"
                                // placement="topLeft"
                                title={
                                    <div className="tooltip-text">
                                        <div className="title-box">Showcase Your Skills</div>
                                        <div>
                                            Highlight your soft skills (like teamwork and leadership) and hard skills
                                            (like technical expertise) to stand out to employers.
                                        </div>
                                        <br />
                                        <div>Why It Matters</div>
                                        <div>
                                            <span className="title-box">· Better Matches</span>: Talenaut’s AI connects
                                            you with roles that align perfectly with your skills and expertise,
                                            increasing your chances of landing the right job.
                                        </div>
                                        <div>
                                            <span className="title-box">· Stand Out</span>: Go beyond a traditional
                                            resume to showcase your full capabilities, potential, and value. · Appeal to
                                            Employers: Present a well-rounded profile that highlights both technical
                                            expertise and personal attributes, showing how you’ll contribute to their
                                            team.
                                        </div>
                                        <div>
                                            <span className="title-box">· Attract Opportunities</span>: Engage hiring
                                            managers by giving them a comprehensive view of what makes you unique.
                                        </div>
                                    </div>
                                }
                            >
                                <i className="round-tipIcon"></i>
                            </Tooltip>
                        </div>
                        <div>
                            <p className="skill-formTit">Hard Skills</p>
                            {formInfo.skillList.map((item, i) => {
                                return (
                                    <div className="r-left" key={`skillBox-${i}`}>
                                        <Form.Item label="Skill Name" className="flex1">
                                            <JobSkillAutoComplate
                                                skillOptions={skillOptions}
                                                selectOptions={formInfo.skillList}
                                                value={item.name}
                                                index={i}
                                                setInfo={val => {
                                                    // const isHave = JSON.parse(
                                                    //     JSON.stringify(formInfo.skillList)
                                                    // ).findIndex((v, index) => v.name && v.name === val && i !== index);
                                                    // if (isHave !== -1) {
                                                    //     messageApi.error('You have added this skill.');
                                                    //     formInfo.skillList[i].name = '';
                                                    //     setFormInfo({
                                                    //         ...formInfo,
                                                    //         skillList: [...formInfo.skillList],
                                                    //     });
                                                    //     return;
                                                    // }
                                                    formInfo.skillList[i].name = val;
                                                    setFormInfo({ ...formInfo, skillList: [...formInfo.skillList] });
                                                }}
                                                placeholder="Start typing..."
                                                isFinshClear={false}
                                                height={44}
                                                suffixIcon={<i></i>}
                                                classStr="r-autoComplate r-autoComplate-bg"
                                            />
                                            {/* <AutoComplete
                                                options={skillOptions}
                                                className="r-autoComplate r-autoComplate-bg"
                                                style={{ width: '100%', height: 44 }}
                                                value={item.name}
                                                onBlur={() => setSkillOptions([])}
                                                onChange={val => {
                                                    formInfo.skillList[i].name = val;
                                                    setFormInfo({ ...formInfo, skillList: formInfo.skillList });
                                                }}
                                                onSelect={val => {
                                                    // const skillInfo: any = jobOptions.find(v => v.value === val);
                                                    const isHave = JSON.parse(
                                                        JSON.stringify(formInfo.skillList)
                                                    ).findIndex((v, index) => v.name && v.name === val && i !== index);
                                                    if (isHave !== -1) {
                                                        messageApi.error('You have added this skill.');
                                                        formInfo.skillList[i].name = '';
                                                        setFormInfo({ ...formInfo, skillList: formInfo.skillList });
                                                        return;
                                                    }
                                                    formInfo.skillList[i].name = val;
                                                    setFormInfo({ ...formInfo, skillList: formInfo.skillList });
                                                    setSkillOptions([]);
                                                }}
                                                onSearch={val => {
                                                    searchSkillList({ search: val, code: formInfo.jobTitleInfo.code });
                                                }}
                                                placeholder=""
                                                notFoundContent={
                                                    <>
                                                        {autocomplateLoading && (
                                                            <div className="r-center">
                                                                <Spin />
                                                            </div>
                                                        )}
                                                        {!autocomplateLoading && <div>No data</div>}
                                                    </>
                                                }
                                            /> */}
                                        </Form.Item>
                                        <Form.Item label="Level (1-10)" className="marL24">
                                            <div className="r-left">
                                                <Input
                                                    className="r-input"
                                                    value={item.leave}
                                                    maxLength={50}
                                                    onInput={(e: any) => {
                                                        let inputValue = e.target.value;
                                                        inputValue = inputValue
                                                            .replace(/^0+/, '')
                                                            .replace(/[^\d]/g, '');
                                                        formInfo.skillList[i].leave =
                                                            +inputValue > 10 ? 10 : inputValue;
                                                        setFormInfo({ ...formInfo, skillList: formInfo.skillList });
                                                    }}
                                                    placeholder=""
                                                />
                                            </div>
                                        </Form.Item>
                                        <Form.Item label="Mandatory" className="marL24">
                                            <div className="r-left">
                                                <Switch
                                                    defaultChecked
                                                    className="r-switch-max"
                                                    value={item.isCheck}
                                                    onChange={val => {
                                                        formInfo.skillList[i].isCheck = val;
                                                        setFormInfo({ ...formInfo, skillList: formInfo.skillList });
                                                    }}
                                                />
                                                <div className="r-left">
                                                    <i
                                                        className="delR-icon marL24"
                                                        onClick={() => {
                                                            formInfo.skillList.splice(i, 1);
                                                            setFormInfo({ ...formInfo, skillList: formInfo.skillList });
                                                        }}
                                                    ></i>
                                                </div>
                                            </div>
                                        </Form.Item>
                                    </div>
                                );
                            })}
                            <div
                                className={`form-addBtn r-center ${
                                    !formInfo.jobTitle || formPage === 'preview' ? 'disabled' : ''
                                }`}
                                onClick={() => {
                                    if (!formInfo.jobTitle) return;
                                    if (formInfo.skillList.length >= 10) {
                                        messageApi.error('You can add a maximum of 10 hard skills.');
                                        return;
                                    }
                                    formInfo.skillList.push({ name: '', leave: '', isCheck: true });
                                    setFormInfo({ ...formInfo, skillList: formInfo.skillList });
                                }}
                            >
                                + Add
                            </div>
                        </div>
                        <div style={{ marginTop: 24 }}>
                            <p className="skill-formTit">Soft Skills</p>
                            {formInfo.softSkillList.map((item, i) => {
                                return (
                                    <div className="r-left" key={`skillBox-${i}`}>
                                        <Form.Item label="Skill Name" className="flex1">
                                            <JobSkillAutoComplate
                                                skillOptions={skillOptions}
                                                selectOptions={formInfo.softSkillList}
                                                value={item.name}
                                                index={i}
                                                setInfo={val => {
                                                    // const isHave = JSON.parse(
                                                    //     JSON.stringify(formInfo.softSkillList)
                                                    // ).findIndex((v, index) => v.name && v.name === val && i !== index);
                                                    // if (isHave !== -1) {
                                                    //     messageApi.error('You have added this skill.');
                                                    //     formInfo.softSkillList[i].name = '';
                                                    //     setFormInfo({
                                                    //         ...formInfo,
                                                    //         softSkillList: formInfo.softSkillList,
                                                    //     });
                                                    //     return;
                                                    // }
                                                    formInfo.softSkillList[i].name = val;
                                                    setFormInfo({ ...formInfo, softSkillList: formInfo.softSkillList });
                                                }}
                                                placeholder="Start typing..."
                                                isFinshClear={false}
                                                height={44}
                                                suffixIcon={<i></i>}
                                                classStr="r-autoComplate r-autoComplate-bg"
                                            />
                                            {/* <AutoComplete
                                                options={skillOptions}
                                                className="r-autoComplate r-autoComplate-bg"
                                                style={{ width: '100%', height: 44 }}
                                                value={item.name}
                                                onChange={val => {
                                                    formInfo.softSkillList[i].name = val;
                                                    setFormInfo({ ...formInfo, softSkillList: formInfo.softSkillList });
                                                }}
                                                onSelect={val => {
                                                    const isHave = JSON.parse(
                                                        JSON.stringify(formInfo.softSkillList)
                                                    ).findIndex((v, index) => v.name && v.name === val && i !== index);
                                                    if (isHave !== -1) {
                                                        messageApi.error('You have added this skill.');
                                                        formInfo.softSkillList[i].name = '';
                                                        setFormInfo({
                                                            ...formInfo,
                                                            softSkillList: formInfo.softSkillList,
                                                        });
                                                        return;
                                                    }
                                                    formInfo.softSkillList[i].name = val;
                                                    setFormInfo({ ...formInfo, softSkillList: formInfo.softSkillList });
                                                    setSkillOptions([]);
                                                }}
                                                onSearch={val => {
                                                    searchSkillList({ search: val, code: formInfo.jobTitleInfo.code });
                                                }}
                                                placeholder=""
                                                notFoundContent={
                                                    <>
                                                        {autocomplateLoading && (
                                                            <div className="r-center">
                                                                <Spin />
                                                            </div>
                                                        )}
                                                        {!autocomplateLoading && <div>No data</div>}
                                                    </>
                                                }
                                            /> */}
                                        </Form.Item>
                                        <Form.Item label="Mandatory" className="marL24">
                                            <div className="r-left">
                                                <Switch
                                                    defaultChecked
                                                    value={item.isCheck}
                                                    className="r-switch-max"
                                                    onChange={val => {
                                                        formInfo.softSkillList[i].isCheck = val;
                                                        setFormInfo({
                                                            ...formInfo,
                                                            softSkillList: formInfo.softSkillList,
                                                        });
                                                    }}
                                                />
                                                <div className="r-left">
                                                    <i
                                                        className="delR-icon marL24"
                                                        onClick={() => {
                                                            formInfo.softSkillList.splice(i, 1);
                                                            setFormInfo({
                                                                ...formInfo,
                                                                softSkillList: formInfo.softSkillList,
                                                            });
                                                        }}
                                                    ></i>
                                                </div>
                                            </div>
                                        </Form.Item>
                                    </div>
                                );
                            })}
                            <div
                                className={`form-addBtn r-center ${
                                    !formInfo.jobTitle || formPage === 'preview' ? 'disabled' : ''
                                }`}
                                onClick={() => {
                                    if (!formInfo.jobTitle) return;
                                    if (formInfo.softSkillList.length >= 10) {
                                        messageApi.error('You can add a maximum of 10 soft skills.');
                                        return;
                                    }
                                    formInfo.softSkillList.push({ name: '', leave: '', isCheck: true });
                                    setFormInfo({ ...formInfo, softSkillList: formInfo.softSkillList });
                                }}
                            >
                                + Add
                            </div>
                        </div>
                        <p className="line-boxNew" style={{ marginTop: 32 }}></p>
                        <p className="job-formTit">Associated Roles</p>
                        <div>
                            <AutoComplete
                                options={roleOptions}
                                className="r-autoComplate r-autoComplate-bg"
                                style={{ width: '100%', height: 44 }}
                                value={associatedRole}
                                onChange={setAssociatedRole}
                                suffixIcon={<i className="down-right-icon"></i>}
                                onBlur={() => setSkillOptions([])}
                                onSelect={val => {
                                    setAssociatedRole('');
                                    if (formInfo.assocRole.findIndex(v => v === val) !== -1) return;
                                    if (formInfo.assocRole.length >= 10) {
                                        messageApi.error('You can add a maximum of 10 associated roles.');
                                        return;
                                    }
                                    formInfo.assocRole.push(val);
                                    setFormInfo({ ...formInfo, assocRole: [...formInfo.assocRole] });
                                    setRoleOptions([]);
                                }}
                                onSearch={val => {
                                    setAssociatedRole(val);
                                    searchRoleList(val);
                                }}
                                placeholder=""
                                notFoundContent={
                                    <>
                                        {autocomplateLoading && (
                                            <div className="r-center">
                                                <Spin />
                                            </div>
                                        )}
                                        {!autocomplateLoading && <div>No data</div>}
                                    </>
                                }
                            />
                            <RTabs
                                tabLists={formInfo.assocRole}
                                onDelTab={(i: number) => {
                                    formInfo.assocRole.splice(i, 1);
                                    setFormInfo({ ...formInfo });
                                }}
                            ></RTabs>
                        </div>
                        <p className="line-boxNew" style={{ marginTop: 32 }}></p>
                        <p className="job-formTit">Job Description AI-Generated </p>
                        <div>
                            <Form.Item
                                label={
                                    <div className="r-left flex1">
                                        Description
                                        <span className="textare-countTip marLeft">
                                            {formInfo.jobDescription.length} / 5000 characters
                                        </span>
                                    </div>
                                }
                                name="jobDescription"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please fill in the job description.',
                                    },
                                ]}
                            >
                                <TextArea
                                    className={`r-inputTextArea r-input`}
                                    maxLength={5000}
                                    autoComplete="off"
                                    autoSize={false}
                                    value={formInfo.jobDescription}
                                    onInput={(e: any) => {
                                        setFormInfo({ ...formInfo, jobDescription: e.target.value });
                                    }}
                                    placeholder=""
                                />
                            </Form.Item>
                            <Button
                                className={`form-addBtn r-center`}
                                style={{ marginBottom: 16, opacity: formPage === 'preview' ? 0.5 : 1 }}
                                loading={descAiLoading}
                                onClick={() => addAiWrite('Job Description')}
                            >
                                Generate with AI
                            </Button>
                            <Form.Item label="AI Tones Selection">
                                <div className="r-left">
                                    <div
                                        className={`form-addBtn r-center ${descAiType === '1' ? '' : 'notActive'}`}
                                        onClick={() => setDescAiType('1')}
                                    >
                                        Quirky and cool vibes{' '}
                                    </div>
                                    <div
                                        className={`form-addBtn r-center ${descAiType === '2' ? '' : 'notActive'}`}
                                        style={{ margin: '0 8px' }}
                                        onClick={() => setDescAiType('2')}
                                    >
                                        Warm and Friendly
                                    </div>
                                    <div
                                        className={`form-addBtn r-center ${descAiType === '3' ? '' : 'notActive'}`}
                                        onClick={() => setDescAiType('3')}
                                    >
                                        Informative and Professional{' '}
                                    </div>
                                </div>
                            </Form.Item>
                        </div>
                        <p className="line-boxNew" style={{ marginTop: 32 }}></p>
                        <p className="job-formTit">Key Responsibilities AI-Generated </p>
                        <div>
                            <Form.Item
                                label={
                                    <div className="r-left flex1">
                                        Description
                                        <span className="textare-countTip marLeft">
                                            {formInfo.keyResponsibilities.length} / 5000 characters
                                        </span>
                                    </div>
                                }
                                name="keyResponsibilities"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please fill in the key responsibilities of the job.',
                                    },
                                ]}
                            >
                                <TextArea
                                    className={`r-inputTextArea r-input`}
                                    maxLength={5000}
                                    autoComplete="off"
                                    autoSize={false}
                                    value={formInfo.keyResponsibilities}
                                    onInput={(e: any) => {
                                        setFormInfo({ ...formInfo, keyResponsibilities: e.target.value });
                                    }}
                                    placeholder=""
                                />
                            </Form.Item>
                            <Button
                                className={`form-addBtn r-center`}
                                style={{ marginBottom: 16, opacity: formPage === 'preview' ? 0.5 : 1 }}
                                loading={keyReAiLoading}
                                onClick={() => addAiWrite('Key Responsibilities')}
                            >
                                Generate with AI
                            </Button>
                            <Form.Item label="AI Tones Selection">
                                <div className="r-left">
                                    <div
                                        className={`form-addBtn r-center ${keyReAiType === '1' ? '' : 'notActive'}`}
                                        onClick={() => setKeyReAiType('1')}
                                    >
                                        Quirky and cool vibes{' '}
                                    </div>
                                    <div
                                        className={`form-addBtn r-center ${keyReAiType === '2' ? '' : 'notActive'}`}
                                        style={{ margin: '0 8px' }}
                                        onClick={() => setKeyReAiType('2')}
                                    >
                                        Warm and Friendly
                                    </div>
                                    <div
                                        className={`form-addBtn r-center ${keyReAiType === '3' ? '' : 'notActive'}`}
                                        onClick={() => setKeyReAiType('3')}
                                    >
                                        Informative and Professional{' '}
                                    </div>
                                </div>
                            </Form.Item>
                        </div>
                        <p className="line-boxNew" style={{ marginTop: 32 }}></p>
                        <p className="job-formTit">Screening Questions</p>
                        <div>
                            <Button
                                className={`form-addBtn r-center}`}
                                style={{ marginBottom: 16, opacity: formPage === 'preview' ? 0.5 : 1 }}
                                loading={questionAiLoading}
                                onClick={() => addAiWrite('Screening Questions')}
                            >
                                Generate with AI
                            </Button>
                            <div>
                                <Form.Item
                                    className="flex1"
                                    label="Question Types"
                                    validateStatus={errorQuestionFields.questionType ? 'error' : ''}
                                    rules={[{ required: false }]}
                                >
                                    <Select
                                        className="r-select"
                                        value={question.type}
                                        onChange={val => {
                                            setQuestion({
                                                ...question,
                                                type: val,
                                                description: '',
                                                options: [{ value: '' }, { value: '' }],
                                            });
                                        }}
                                        suffixIcon={<i className="down-right-icon"></i>}
                                        options={[
                                            { value: 'Multiple Choice', label: 'Multiple Choice' },
                                            { value: 'Single Choice', label: 'Single Choice' },
                                            { value: 'Short Answer', label: 'Short Answer' },
                                        ]}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<div className="r-left flex1">Question Description</div>}
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                    validateStatus={errorQuestionFields.questionDescription ? 'error' : ''}
                                >
                                    <TextArea
                                        className={`r-inputTextArea r-input rTextArea114`}
                                        maxLength={200}
                                        onInput={(e: any) => setQuestion({ ...question, description: e.target.value })}
                                        value={question.description}
                                        autoComplete="off"
                                        autoSize={false}
                                        placeholder=""
                                    />
                                </Form.Item>
                                {question.type !== 'Short Answer' ? (
                                    <>
                                        {question.options.map((item, i) => {
                                            return (
                                                <Form.Item
                                                    className="flex1"
                                                    validateStatus={
                                                        errorQuestionFields.option && !item.value ? 'error' : ''
                                                    }
                                                    label={`Option ${i + 1}`}
                                                    key={`question-option-item-${i}`}
                                                >
                                                    <Input
                                                        className="r-input"
                                                        maxLength={250}
                                                        value={item.value}
                                                        onInput={(e: any) => {
                                                            question.options[i].value = e.target.value;
                                                            setQuestion({ ...question });
                                                        }}
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                            );
                                        })}
                                    </>
                                ) : null}
                            </div>
                            <div
                                className="r-end"
                                style={{
                                    marginBottom: questionList.length ? 20 : 0,
                                    opacity: formPage === 'preview' ? 0.5 : 1,
                                }}
                            >
                                {question.type !== 'Short Answer' ? (
                                    <Button
                                        className="r-primary_btn r-primaryNoBg_btn"
                                        style={{ width: 130 }}
                                        onClick={() => {
                                            if (question.options.length >= 10) {
                                                messageApi.error('You can add a maximum of 10 options.');
                                                return;
                                            }
                                            question.options.push({ value: '' });
                                            setQuestion({ ...question });
                                        }}
                                    >
                                        + Add Option
                                    </Button>
                                ) : null}
                                <Button
                                    className="r-primary_btn r-primaryNoBg_btn"
                                    style={{ width: 78, marginLeft: 20 }}
                                    onClick={() => saveQuestion()}
                                >
                                    Save
                                </Button>
                            </div>
                            {questionList.map((val, i) => {
                                return (
                                    <div className="form-addCard r-left" key={`form-addCard-${i}`}>
                                        <div style={{ paddingRight: 15 }}>
                                            <p className="addCard-msg">{val.description}</p>
                                        </div>
                                        <div className="r-left marLeft">
                                            <i className="editR-icon" onClick={() => opeationQuestion(val, i, 1)}></i>
                                            <i
                                                className="delR-icon"
                                                style={{ marginLeft: 8 }}
                                                onClick={() => opeationQuestion(val, i, 0)}
                                            ></i>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <p className="line-boxNew" style={{ marginTop: 32 }}></p>
                        <p className="job-formTit">Interview Scorecard Questions</p>
                        <div>
                            <Button
                                className={`form-addBtn r-center}`}
                                style={{ marginBottom: 16, opacity: formPage === 'preview' ? 0.5 : 1 }}
                                loading={interviewAiLoading}
                                onClick={() => addAiWrite('Interview Scorecard Questions')}
                            >
                                Generate with AI
                            </Button>
                            <div>
                                <Form.Item
                                    label={<div className="r-left flex1">Question</div>}
                                    validateStatus={errorQuestionInterViewFields.question ? 'error' : ''}
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <TextArea
                                        className={`r-inputTextArea r-input rTextArea114`}
                                        maxLength={200}
                                        onInput={(e: any) =>
                                            setQuestionInterView({ ...questionInterView, question: e.target.value })
                                        }
                                        value={questionInterView.question}
                                        autoComplete="off"
                                        autoSize={false}
                                        placeholder=""
                                    />
                                </Form.Item>
                                <Form.Item
                                    className="flex1"
                                    style={{ marginBottom: 0 }}
                                    label="Option"
                                    rules={[{ required: false }]}
                                >
                                    {questionInterView.options.map((val, k) => {
                                        return (
                                            <Form.Item
                                                className="flex1"
                                                label=""
                                                key={`errorQuestionInterViewFields-${k}`}
                                                style={{ marginBottom: 0 }}
                                                validateStatus={
                                                    errorQuestionInterViewFields.option && val.isSel && !val.value
                                                        ? 'error'
                                                        : ''
                                                }
                                                rules={[{ required: false }]}
                                            >
                                                <div className="noteQuestion-box">
                                                    <div key={`score-item-box-${k}`} className="score-item-box r-left">
                                                        <i
                                                            onClick={() => {
                                                                // noteScore.scores.forEach(val => (val.isCheck = false));
                                                                questionInterView.options[k].isSel =
                                                                    !questionInterView.options[k].isSel;
                                                                setQuestionInterView({ ...questionInterView });
                                                            }}
                                                            className={`radioBox-icon ${
                                                                val.isSel ? 'radioedBox-icon' : ''
                                                            }`}
                                                        ></i>
                                                        <img src={val.icon} className="scoreImg-box" alt="" />
                                                        <Input
                                                            className="r-input"
                                                            maxLength={150}
                                                            value={val.value}
                                                            onInput={(e: any) => {
                                                                const str = e.target.value;
                                                                questionInterView.options[k].value = str;
                                                                setQuestionInterView({ ...questionInterView });
                                                            }}
                                                            placeholder=""
                                                        />
                                                    </div>
                                                </div>
                                            </Form.Item>
                                        );
                                    })}
                                </Form.Item>
                            </div>
                            <div
                                className="r-end"
                                style={{
                                    marginBottom: questionInterViewList.length ? 20 : 0,
                                    opacity: formPage === 'preview' ? 0.5 : 1,
                                }}
                            >
                                <Button
                                    className="r-primary_btn r-primaryNoBg_btn"
                                    style={{ width: 78, marginLeft: 20 }}
                                    onClick={() => saveQuestionInterView()}
                                >
                                    Save
                                </Button>
                            </div>
                            {questionInterViewList.map((val, i) => {
                                return (
                                    <div className="form-addCard r-left" key={`form-addCard-${i}`}>
                                        <div style={{ paddingRight: 15 }}>
                                            <p className="addCard-msg">{val.question}</p>
                                        </div>
                                        <div className="r-left marLeft">
                                            <i
                                                className="editR-icon"
                                                onClick={() => opeationQuestionInterView(val, i, 1)}
                                            ></i>
                                            <i
                                                className="delR-icon"
                                                style={{ marginLeft: 8 }}
                                                onClick={() => opeationQuestionInterView(val, i, 0)}
                                            ></i>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <p className="line-boxNew" style={{ marginTop: 32 }}></p>
                        <p className="job-formTit">Receive approval from the following team member(s)</p>
                        <div>
                            <AutoComplete
                                options={teamOptions}
                                className="r-autoComplate r-autoComplate-bg"
                                style={{ width: '100%' }}
                                value={teamVal}
                                onChange={onTeamChange}
                                onSelect={onTeamSelect}
                                onSearch={text => setTeamOptions(getPanelValue(text))}
                                placeholder="Start typing..."
                            />
                            <div>
                                {teamSelOptions.map((val, i) => {
                                    return (
                                        <div
                                            className="teamMemberCard-jobinfo r-left"
                                            key={`teamMember-jobInfo-card${i}`}
                                        >
                                            {val.avatarUrl || val.picUrlShow ? (
                                                <Image
                                                    width={42}
                                                    height={42}
                                                    preview={false}
                                                    style={{ borderRadius: '50%', minWidth: 42 }}
                                                    src={val.picUrlShow ? val.picUrlShow : val.avatarUrl}
                                                    fallback={require('@/assets/img/default-min.png')}
                                                />
                                            ) : (
                                                <div className="avatar-textBox">{val.showName}</div>
                                            )}
                                            <div style={{ marginLeft: 8 }}>
                                                <p className="tmc-tit">{val.label}</p>
                                                <p className="tmc-tip">{val.position}</p>
                                            </div>
                                            <i
                                                className="removeR-icon marLeft"
                                                onClick={() => {
                                                    teamSelOptions.splice(i, 1);
                                                    setTeamSelOptions([...teamSelOptions]);
                                                }}
                                            ></i>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            {/* 底部按钮 */}
            <div className="r-end" style={{ marginTop: 40 }}>
                <Button className="r-primary_btn r-dangerNoBg_btn r-primary_largeBtn w208" onClick={() => navigate(-1)}>
                    Cancel
                </Button>
                {formPage !== 'preview' ? (
                    formPage === 'addFromTem' ? (
                        <>
                            {pageType === 'edit' ? (
                                <Button
                                    className="r-primary_btn r-dangerNoBg_btn r-primary_largeBtn w208 marL24"
                                    onClick={() => setIsModalOpen(true)}
                                >
                                    Delete
                                </Button>
                            ) : (
                                ''
                            )}
                            <Button
                                className="r-primary_btn r-primary_largeBtn w208 marL24"
                                loading={savaLoading}
                                onClick={() => handleValidate('temp', pageType === 'edit' ? 2 : 1)}
                            >
                                Save
                            </Button>
                        </>
                    ) : formPage === 'editDart' ? (
                        // 草稿
                        <>
                            <Button
                                className="r-primary_btn r-primaryNoBg7F_btn r-primary_largeBtn w208 marL24"
                                loading={savaLoading}
                                onClick={() => handleValidate('job', 1)}
                            >
                                Publish
                            </Button>
                            <Button
                                className="r-primary_btn r-primary_largeBtn w208 marL24"
                                onClick={() => handleValidate('dart', 2)}
                            >
                                Save
                            </Button>
                        </>
                    ) : (
                        <>
                            {!formInfo.jobId || pageType === 'tc' ? (
                                <>
                                    <Button
                                        className={`r-primary_btn r-primaryNoBg7F_btn r-primary_largeBtn w208 marL24 ${
                                            !formInfo.jobTitle ? 'disabled' : ''
                                        }`}
                                        loading={dartLoading}
                                        onClick={() => handleValidate('dart', 1)}
                                    >
                                        Save as Draft
                                    </Button>
                                    <Button
                                        className="r-primary_btn r-primary_largeBtn w208 marL24"
                                        loading={savaLoading}
                                        onClick={() => handleValidate('job', 1)}
                                    >
                                        Publish
                                    </Button>
                                </>
                            ) : (
                                <Button
                                    className="r-primary_btn r-primary_largeBtn w208 marL24"
                                    onClick={() => handleValidate('job', 2)}
                                >
                                    Save
                                </Button>
                            )}
                        </>
                    )
                ) : null}
            </div>

            <AlertModal
                title=""
                message={'Are you sure you want to delete this job?'}
                cancelText={'Cancel'}
                confirmText={'Confirm'}
                isModalOpen={isModalOpen}
                loading={opLoading}
                handleOk={() => confirmDel()}
                handleCancel={() => {
                    setIsModalOpen(false);
                }}
            />
            <AlertModal
                title=""
                message={
                    formPage === 'editDart'
                        ? 'Do you need to save your changes before you leave?'
                        : 'Are you sure you want to leave? Job information will not be saved.'
                }
                cancelText={formPage === 'editDart' ? "Don't save" : 'Cancel'}
                confirmText={formPage === 'editDart' ? 'Save and leave' : 'Confirm'}
                isModalOpen={isLeaveModalOpen}
                handleOk={() => {
                    if (formPage === 'editDart') {
                        dartLeaveConfirm();
                        return;
                    }
                    window.sessionStorage.removeItem('jobInfoForm');
                    window.sessionStorage.setItem('isTopAdd', '0');
                    if (leaveConfirm) {
                        leaveConfirm();
                    }
                }}
                handleCancel={() => {
                    if (formPage === 'editDart') {
                        window.sessionStorage.removeItem('jobInfoForm');
                        window.sessionStorage.setItem('isTopAdd', '0');
                        if (leaveConfirm) {
                            leaveConfirm();
                        }
                        return;
                    }
                    leaveCancel();
                }}
            />
        </div>
    );
}

export default JobInfo;
