import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import api from '@/api/subscription';
import AlertModal from '@/components/modal/alert-modal';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
function SubscriptionInfomation({ isSub, isExpired, isCancel, type, getInfo }: any) {
    const navigate = useNavigate();
    const messageApi = useGlobalMessage();
    const { profileInfo } = useSelector((state: any) => state.state);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleOk = () => {
        setLoading(true);
        api.cancelSubscription({}, res => {
            const { code, data, message } = res;
            setLoading(false);
            if (code === 200) {
                setIsModalOpen(false);
                getInfo();
            } else {
                messageApi.error(message);
            }
        });
    };
    return (
        <div>
            <div className="subscription-card">
                {profileInfo.businessInfoPOJO.subscriptionFlag ? (
                    <div>
                        <div className="r-left sub-top">
                            <span>{profileInfo.businessInfoPOJO.subscriptionPlanName}</span>
                            <span
                                className={`succ-status ${
                                    profileInfo.businessInfoPOJO.subscriptionExpireFlag ? 'fail-status' : ''
                                }`}
                            >
                                {!profileInfo.businessInfoPOJO.subscriptionExpireFlag ? 'Ongoing' : 'Expired'}
                            </span>
                            <span className="sub-price marLeft r-left">
                                $
                                {window.utils.formatAmount(profileInfo.businessInfoPOJO.subscriptionAmount || 0) || '0'}
                                <span className="sub-time">
                                    per{' '}
                                    {profileInfo.businessInfoPOJO.subscriptionPlanType !== 'annualPlan'
                                        ? 'month'
                                        : 'year'}
                                </span>
                            </span>
                        </div>
                        <div className="sub-opTip sub-endmar">
                            Ends on{' '}
                            {profileInfo.businessInfoPOJO.subscriptionEnd
                                ? dayjs(new Date(profileInfo.businessInfoPOJO.subscriptionEnd * 1000)).format(
                                      'MMMM D, YYYY'
                                  )
                                : '-'}
                        </div>
                        <div>
                            <p>
                                Team Seats:
                                {profileInfo.businessInfoPOJO.seatType === 'bySeats'
                                    ? profileInfo.businessInfoPOJO.seatsNumber
                                    : profileInfo.businessInfoPOJO.seatType === 'limited'
                                    ? profileInfo.businessInfoPOJO.seatsNumber
                                    : 'Unlimited'}
                            </p>
                            <div style={{ margin: '5px 0' }}>
                                Features: Job Posting, AI-driven Matching, ATS, Analytics
                            </div>
                            <p className="sub-opTip">
                                Elevate your hiring process with the Boost plan. Gain enhanced visibility and analytics
                                to refine your recruitment efforts, making it ideal for companies poised for growth.
                            </p>
                            {/* {isSub ? (
                                <div>
                                    <p style={{ margin: '20px 0 5px 0' }}>Credit card</p>
                                    <div className="r-left sub-opTip">
                                        <span>Card Number: ****0090 </span>
                                        <span style={{ marginLeft: 80 }}>End date: November 1, 2033</span>
                                    </div>
                                </div>
                            ) : null} */}
                        </div>
                    </div>
                ) : (
                    <div className="sub-opTip">You haven't subscribed yet</div>
                )}
                {!profileInfo.businessInfoPOJO.subscriptionExpireFlag &&
                profileInfo.businessInfoPOJO.subscriptionFlag ? (
                    <>
                        {profileInfo.businessInfoPOJO.subscriptionCancelFlag ? (
                            <>
                                <div className="r-left sub-opBtm">
                                    <span className="unSub-btn unSub-cancel-btn r-center" style={{ cursor: 'initial' }}>
                                        Subscription cancelled
                                    </span>
                                    {type !== 'capage' ? (
                                        <span
                                            className="unSub-btn changePlan-btn r-center marl16"
                                            onClick={() => navigate('/change-subscriptionPlan')}
                                        >
                                            Change subscription plan
                                        </span>
                                    ) : null}
                                </div>
                            </>
                        ) : (
                            <div className="r-left sub-opBtm">
                                <span className="unSub-btn r-center" onClick={() => setIsModalOpen(true)}>
                                    Unsubscribe
                                </span>
                                {type !== 'capage' ? (
                                    <span
                                        className="unSub-btn changePlan-btn r-center marl16"
                                        onClick={() => navigate('/change-subscriptionPlan')}
                                    >
                                        Change subscription plan
                                    </span>
                                ) : null}
                            </div>
                        )}
                    </>
                ) : (
                    <div className="r-left sub-opBtm">
                        <span
                            className="unSub-btn changePlan-btn r-center"
                            onClick={() => navigate('/change-subscriptionPlan')}
                        >
                            Go to subscribe
                        </span>
                    </div>
                )}
            </div>

            <AlertModal
                title="Do you want to cancel your current subscription plan?"
                message={
                    'There will be no automatic renewal at the end of the subscription period and you will need to purchase a new plan.'
                }
                cancelText={'Cancel'}
                confirmText={'Confirm continue '}
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                disabled={loading}
                loading={loading}
                handleCancel={() => setIsModalOpen(false)}
            />
            {/* {profileInfo.businessInfoPOJO.subscriptionFlag ? (
                <div className="subscription-card" style={{ marginTop: 20 }}>
                    <div>
                        <div className="r-left sub-top">
                            <span>Boost</span>
                            <span className={`succ-status pending-status`}>Pending</span>
                            <span className="sub-price marLeft r-left">
                                $800.00
                                <span className="sub-time">per month</span>
                            </span>
                        </div>
                        <div className="sub-opTip sub-endmar">Ends on November 1, 2024</div>
                        <div>
                            <p>Team Seats: 17</p>
                            <div style={{ margin: '5px 0' }}>
                                Features: Job Posting, AI-driven Matching, ATS, Analytics
                            </div>
                            <p className="sub-opTip">
                                Elevate your hiring process with the Boost plan. Gain enhanced visibility and analytics
                                to refine your recruitment efforts, making it ideal for companies poised for growth.
                            </p>
                        </div>
                    </div>
                </div>
            ) : null} */}
        </div>
    );
}

export default SubscriptionInfomation;
