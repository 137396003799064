import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, FormProps, Input, Modal, Select } from 'antd';
import './index.scss';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import UploadImg from '../upload/upload-img';
import api from '@/api/team';
import { useSelector } from 'react-redux';
function TeamMemberModal({ isModalOpen, handleOk, handleCancel, addTeamInfo, opInfo, type }: any) {
    const { profileInfo } = useSelector((state: any) => state.state);
    const messageApi = useGlobalMessage();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const formRef = useRef<any>(null);
    const [roleList, setRoleList] = useState([]);
    const [picInfo, setPicInfo] = useState({
        picUrlShow: '',
        profilePhoto: '',
    });
    const [state, setState] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        position: '',
        role: '',
        email: '',
    });
    const onFinish: FormProps['onFinish'] = values => {
        console.log('Success:', values);
        if (!picInfo.profilePhoto) {
            messageApi.error('Please upload a profile image');
            return;
        }
        const pat: any = {
            picUrlShow: picInfo.picUrlShow,
            avatarUrl: picInfo.profilePhoto,
            email: state.email,
            firstName: state.firstName,
            lastName: state.lastName,
            middleName: state.middleName,
            position: state.position,
            role: state.role,
        };
        if (type === 'homeTeam') {
            addTeamInfo(pat);
            return;
        }
        setLoading(true);
        if (opInfo.index !== -1) {
            pat.accountId = opInfo.accountId;
            api.editBusinessTeam(pat, res => {
                setLoading(false);
                if (res.code === 200) {
                    addTeamInfo(pat, 1);
                } else {
                    messageApi.error(res.message);
                }
            });
            return;
        }
        api.addBusinessTeam(pat, res => {
            setLoading(false);
            if (res.code === 200) {
                addTeamInfo(pat);
            } else {
                messageApi.error(res.message);
            }
        });
    };

    const onFinishFailed: FormProps['onFinishFailed'] = errorInfo => {
        if (!picInfo.profilePhoto) {
            messageApi.error('Please upload a profile image');
            return;
        }
        console.log('Failed:', errorInfo);
    };
    const handleChange = (value: string) => {
        setState({ ...state, role: value });
    };
    const getRoleList = () => {
        api.getBusinessTeamRole({}, res => {
            if (res.code === 200) {
                const arr = res.data.map(item => {
                    return Object.assign(item, {
                        value: item.roleName,
                        label: item.roleName,
                    });
                });
                setRoleList(arr);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    useEffect(() => {
        getRoleList();
    }, []);
    useEffect(() => {
        let initialValues = {
            // profilePhoto: '',
            firstName: '',
            middleName: '',
            lastName: '',
            email: '',
            position: '',
            role: '',
            // picUrlShow: '',
        };
        let picNewInfo = {
            profilePhoto: '',
            picUrlShow: '',
        };
        if (isModalOpen && opInfo.index !== -1) {
            initialValues = {
                // profilePhoto: opInfo.avatarUrl,
                firstName: opInfo.firstName,
                middleName: opInfo.middleName,
                lastName: opInfo.lastName,
                email: opInfo.email,
                position: opInfo.position,
                role: opInfo.role,
                // picUrlShow: '',
            };
            picNewInfo = {
                profilePhoto: opInfo.avatarUrl,
                picUrlShow: '',
            };
        } else {
            form.resetFields();
        }
        setState({ ...initialValues });
        setPicInfo({ ...picNewInfo });
        form.setFieldsValue({ ...initialValues, ...picNewInfo });
    }, [isModalOpen]);
    return (
        <Modal
            className="r-modal modal_no_padding team-modalBox"
            maskClosable={false}
            centered={true}
            key={'team-member-modal'}
            closeIcon={<i className="r-modal-close"></i>}
            footer={''}
            width={380}
            title={[
                <div className="modal-top-title" style={{ marginBottom: 30 }}>
                    {opInfo.index == -1 ? 'Add' : 'Edit'} {type === 'homeTeam' ? 'Profile' : 'Team Member'}
                </div>,
            ]}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            maskProps={{
                style: {
                    backgroundColor: 'rgba(30, 34, 45, 0.8)',
                    backdropFilter: 'blur(1px)',
                },
            }}
        >
            <Form
                name="team-member-form"
                layout="vertical"
                style={{ maxWidth: 380 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                form={form}
                ref={formRef}
                validateTrigger="onBlur"
            >
                <Form.Item label={null} name="profilePhoto" rules={[{ required: false, message: '' }]}>
                    <div className="r-center uploadMain_box">
                        <UploadImg
                            isRadius={true}
                            imgUrl={picInfo.profilePhoto}
                            resetForm={() => {
                                form.setFieldsValue({ profilePhoto: picInfo.profilePhoto });
                            }}
                            setPicInfo={(val: any) => {
                                setPicInfo({ profilePhoto: val.picUrl, picUrlShow: val.picUrlShow });
                                form.setFieldsValue({ profilePhoto: val.picUrl });
                            }}
                        />
                    </div>
                </Form.Item>
                <Form.Item
                    label={<div>First Name</div>}
                    name="firstName"
                    rules={[{ required: true, message: 'Please enter a first name' }]}
                >
                    <Input
                        className="r-input"
                        value={state.firstName}
                        maxLength={20}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const str = e.target.value;
                            const val = str.charAt(0).toUpperCase() + str.slice(1);
                            form.setFieldsValue({ firstName: val });
                            setState({ ...state, firstName: val });
                        }}
                    />
                </Form.Item>
                <Form.Item label="Middle Name (Optional)" name="middleName" rules={[{ required: false, message: ' ' }]}>
                    <Input
                        className="r-input"
                        maxLength={20}
                        value={state.middleName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const str = e.target.value;
                            const val = str.charAt(0).toUpperCase() + str.slice(1);
                            form.setFieldsValue({ middleName: val });
                            setState({ ...state, middleName: val });
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={[{ required: true, message: 'Please enter a last name' }]}
                >
                    <Input
                        className="r-input"
                        maxLength={20}
                        value={state.lastName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const str = e.target.value;
                            const val = str.charAt(0).toUpperCase() + str.slice(1);
                            form.setFieldsValue({ lastName: val });
                            setState({ ...state, lastName: val });
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        { required: true, message: 'Please enter a valid email address' },
                        {
                            type: 'email',
                            message: 'Please enter a valid email address',
                        },
                    ]}
                >
                    <Input
                        className="r-input"
                        disabled={opInfo.index != -1}
                        value={state.email}
                        onInput={(e: any) => setState({ ...state, email: e.target.value })}
                    />
                </Form.Item>
                <Form.Item
                    label="Position"
                    name="position"
                    rules={[{ required: true, message: 'Please enter the position' }]}
                >
                    <Input
                        className="r-input"
                        maxLength={50}
                        value={state.position}
                        onInput={(e: any) => setState({ ...state, position: e.target.value })}
                    />
                </Form.Item>
                {type === 'homeTeam' && state.role === 'Master Administrator' ? null : (
                    <Form.Item label="Role" name="role" rules={[{ required: true, message: 'Please select a role' }]}>
                        <Select
                            className="r-select"
                            value={state.role}
                            onChange={handleChange}
                            suffixIcon={<i className="down-right-icon"></i>}
                            options={roleList}
                        />
                    </Form.Item>
                )}
                <Form.Item style={{ margin: '30px 0 0 0 ' }}>
                    <div className="r-center">
                        <Button
                            className="r-primary_btn teamSubmit_btn"
                            type="primary"
                            htmlType="submit"
                            disabled={
                                !picInfo.profilePhoto ||
                                !state.firstName ||
                                !state.lastName ||
                                !state.email ||
                                !state.position ||
                                !state.role
                            }
                            loading={loading}
                        >
                            {type !== 'homeTeam' ? `${opInfo.index === -1 ? 'Add' : 'Save'} Team Member` : 'Save'}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default TeamMemberModal;
