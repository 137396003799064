import React, { useCallback, useEffect, useState } from 'react';
import './style/index.scss';
import { Button, Input, Image, Spin } from 'antd';
import Work from '@/assets/img/work.png';
import Leaderboard from '@/assets/img/leaderboard.png';
import PeopleAlt from '@/assets/img/people_alt.png';
import WatchLater from '@/assets/img/watch_later.png';
import SliderTabs from '@/components/slideeTabs';
import { debounce } from 'lodash';
import JobList from './components/job-list';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import api from '@/api/home';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
const Index = () => {
    const navigate = useNavigate();
    const messageApi = useGlobalMessage();
    const { profileInfo } = useSelector((state: any) => state.state);
    const [insights, setInsights] = useState([
        { tit: 'JOBS POSTED', icon: Work, val: '0', tip: 'Total number of all jobs you’ve posted' },
        { tit: 'CANDIDATE RATE', icon: Leaderboard, val: '0', tip: 'Average number of candidates sourced per role' },
        { tit: 'CANDIDATES SOURCED', icon: PeopleAlt, val: '0', tip: 'Total number of candidates sourced' },
        {
            tit: 'HIRE DURATION',
            icon: WatchLater,
            val: '0 day',
            tip: 'Avg. duration it takes to successfully hire an employee',
        },
    ]);
    const [state, setState] = useState({
        search: '',
        pageNo: 1,
    });
    // top data
    const getData = () => {
        api.getHomeData({ beginTime: 0, endTime: 9999999999 }, res => {
            if (res.code === 200) {
                insights[0].val = res.data.jobsPosted;
                insights[1].val = res.data.candidateRate;
                insights[2].val = res.data.candidatesSourced;
                insights[3].val = `${res.data.hireDuration || 0}`;
                setInsights([...insights]);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    // interview
    const [interViewTotal, setInterViewTotal] = useState(0);
    const [interView, setInterview] = useState<any>([]);
    const getInterview = () => {
        api.getHomeInterviewList(
            {
                flag: 'UpcomingInterviewsToday',
                beginTime: 0,
                endTime: 9999999999,
                pageNo: 1,
                pageSize: 3,
            },
            res => {
                if (res.code === 200) {
                    setInterViewTotal(res.data.totalRecord);
                    if (res.data.list) {
                        setInterview(
                            res.data.list.map((item, i) => {
                                return Object.assign(item, {
                                    nameLabel: `${item.accountPOJO.accountInformationPOJO.firstName} ${item.accountPOJO.accountInformationPOJO.middleName} ${item.accountPOJO.accountInformationPOJO.lastName}`,
                                    inReviewTimeShow: item.interviewStartTime
                                        ? window.utils.foramtTimeDMYhm(item.interviewStartTime * 1000)
                                        : '-',
                                });
                            })
                        );
                    } else {
                        setInterview([]);
                    }
                } else {
                    messageApi.error(res.message);
                }
            }
        );
    };
    // job
    const [selectedType, setSelectedType] = useState(1);
    const [jobStatusTab, setJobStatusTab] = useState([
        { value: 'Active', label: 'Active (0)', key: 1 },
        { value: 'Drafts', label: 'Drafts (0)', key: 2 },
        { value: 'Archived', label: 'Archive (0)', key: 3 },
    ]);
    const searchList: any = useCallback(
        debounce(params => {
            setData([]);
            getList({ ...params });
        }, 1000),
        []
    );
    const changeJobStatus = (key: number) => {
        setSelectedType(key);
        setNoMore(false);
        setState({ ...state, pageNo: 1 });
        getList({ ...state, status: jobStatusTab[key - 1].value, pageNo: 1, first: true });
        setData([]);
    };
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [noMore, setNoMore] = useState(false);
    useEffect(() => {
        setNoMore(state.pageNo >= total);
    }, [state.pageNo]);
    const [total, setTotal] = useState(0);
    const [listLoading, setListLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);
    const getList = val => {
        if (val.first) {
            setListLoading(true);
        }
        setLoading(true);
        const pat = {
            status: val.status,
            pageNo: val.pageNo,
            pageSize: 10,
            beginTime: 0,
            endTime: 9999999999,
            keyWord: val.search,
        };
        api.getMyJobList(pat, res => {
            setListLoading(false);
            setFirstLoading(false);
            if (res.code === 200) {
                setLoading(false);
                setTotal(res.data.totalPage);
                setNoMore(state.pageNo >= res.data.totalPage);
                setJobStatusTab([
                    { value: 'Active', label: `Active (${res.data.pojo.activeJobs || 0})`, key: 1 },
                    { value: 'Drafts', label: `Drafts (${res.data.pojo.draftsJobs || 0})`, key: 2 },
                    { value: 'Archived', label: `Archive (${res.data.pojo.archiveJobs || 0})`, key: 3 },
                ]);
                if (res.data.list) {
                    const nowList = res.data.list.map(item => {
                        let applicationsPOJOListList: any = [];
                        if (item.applicationsPOJOList && item.applicationsPOJOList.length) {
                            applicationsPOJOListList =
                                item.applicationsPOJOList.length > 11
                                    ? item.applicationsPOJOList.slice(-11)
                                    : item.applicationsPOJOList;
                        }
                        return Object.assign(item, {
                            jobTypeShow:
                                item.jobsPOJO.jobType === '1'
                                    ? 'Full-Time'
                                    : item.jobsPOJO.jobType === '2'
                                    ? 'Part-Time'
                                    : item.jobsPOJO.jobType === '3'
                                    ? 'Contractor'
                                    : 'Full-Time',
                            deadlineTimeShow: item.jobsPOJO.deadline
                                ? window.utils.formatTimeDifference(item.jobsPOJO.deadline)
                                : '-',
                            businessNameLabel: `${item.jobsPOJO.businessAccountPOJO.firstName} ${item.jobsPOJO.businessAccountPOJO.middleName} ${item.jobsPOJO.businessAccountPOJO.lastName}`,
                            applicationsPOJOList: applicationsPOJOListList,
                        });
                    });
                    if (val.pageNo === 1) {
                        setData([...nowList]);
                        return;
                    }
                    setData(prevItems => [...prevItems, ...nowList]);
                } else {
                    setData([]);
                }
            } else {
                messageApi.error(res.message);
            }
        });
    };
    useEffect(() => {
        setFirstLoading(true);
        getData();
        getInterview();
        // getList({ ...state, status: jobStatusTab[selectedType - 1].value });
        console.log(profileInfo);
    }, []);
    const [isSearch, setIsSearch] = useState(false);
    const { dataBaseInfo } = useSelector((state: any) => state.state);
    useEffect(() => {
        const token = sessionStorage.getItem('admin-satoken');
        if (!token) return;
        setData([]);
        getList({ ...state, status: jobStatusTab[selectedType - 1].value, pageNo: 1 });
    }, [dataBaseInfo]);
    return (
        <div className="r-pageContent">
            <h2>Dashboard</h2>
            {!firstLoading ? (
                <div>
                    <div className="dashboard-top">
                        <div className="r-left dash-tit">
                            Insights
                            {profileInfo.roleNum <= 1 ? (
                                <Button
                                    className="r-primary_btn marLeft r-medium_btn"
                                    onClick={() => navigate('/insights')}
                                >
                                    View All
                                </Button>
                            ) : null}
                        </div>
                        <div className="r-left insight-list">
                            {insights.map((item, i) => {
                                return (
                                    <div key={`insight-card-${i}`} className="insight-card">
                                        <div className="r-left">
                                            <p className="insight-tit">{item.tit}</p>
                                            <div className="imgBox-la marLeft">
                                                <img src={item.icon} className="insight-img" alt="" />
                                            </div>
                                        </div>
                                        <div className="insight-val">
                                            {item.val}
                                            {i === 3 ? (
                                                <span className="mini-valTip">{+item.val > 1 ? 'days' : 'day'}</span>
                                            ) : null}
                                        </div>
                                        <div className="insight-tip">{item.tip}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {/* 非管理员并且没有数据 */}
                    {profileInfo.roleNum > 1 && !interView.length ? null : (
                        <div className="dashboard-top">
                            <div className="r-left dash-tit">
                                Upcoming Interviews Today
                                {/* {interViewTotal > 3 ? (
                                    <Button
                                        className="r-primary_btn marLeft r-medium_btn"
                                        onClick={() => navigate('/interview')}
                                    >
                                        View All
                                    </Button>
                                ) : null} */}
                                <Button
                                    className="r-primary_btn marLeft r-medium_btn"
                                    onClick={() => navigate('/interview')}
                                >
                                    View All
                                </Button>
                            </div>
                            <div className="intterview-list r-table_card">
                                <div className="r-left ant-table-thead">
                                    <span className="ant-table-cell">Candidate</span>
                                    <span className="ant-table-cell">Job</span>
                                    <span className="ant-table-cell">Employer Company</span>
                                    <span className="ant-table-cell">Interview Time</span>
                                </div>
                                <div className="ant-table-tbody">
                                    {interView.length ? (
                                        interView.map((item, i) => {
                                            return (
                                                <div className="r-tableRow_card r-left" key={`tableRow_card-${i}`}>
                                                    <span className="ant-table-cell">
                                                        <Image
                                                            width={32}
                                                            height={32}
                                                            preview={false}
                                                            style={{ borderRadius: '50%', minWidth: 32 }}
                                                            src={
                                                                item.accountPOJO.accountInformationPOJO.profilePhoto
                                                                    ? item.accountPOJO.accountInformationPOJO
                                                                          .profilePhoto
                                                                    : ''
                                                            }
                                                            fallback={require('@/assets/img/default-min.png')}
                                                        />
                                                        <span style={{ marginLeft: 8 }}>{item.nameLabel || '-'}</span>
                                                    </span>
                                                    <span
                                                        className="ant-table-cell oneLineText"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            window.sessionStorage.setItem(
                                                                'jobInfo',
                                                                JSON.stringify({ ...item, ...item.jobsPOJO })
                                                            );
                                                            navigate('/job-detail');
                                                        }}
                                                    >
                                                        {item.jobsPOJO.jobTitle || '-'}
                                                    </span>
                                                    <span className="ant-table-cell">
                                                        <Image
                                                            width={32}
                                                            height={32}
                                                            preview={false}
                                                            style={{ borderRadius: '50%', minWidth: 32 }}
                                                            src={
                                                                item.jobsPOJO.employerLogo
                                                                    ? item.jobsPOJO.employerLogo
                                                                    : ''
                                                            }
                                                            fallback={require('@/assets/img/default-min.png')}
                                                        />
                                                        <span style={{ marginLeft: 8 }}>
                                                            {item.jobsPOJO.employerName || '-'}
                                                        </span>
                                                    </span>
                                                    <span className="ant-table-cell">
                                                        {item.inReviewTimeShow || '-'}
                                                    </span>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="no-message-tabBox r-center">No yet</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="job-list">
                        <div className="r-left job-tit">
                            Jobs
                            <div className="marLeft">
                                <SliderTabs
                                    setSelectedType={key => {
                                        if (listLoading || loading) return;
                                        changeJobStatus(key);
                                    }}
                                    selectedType={selectedType}
                                    tabList={jobStatusTab}
                                />
                            </div>
                        </div>
                        <div className="job-filter r-left">
                            <Input
                                placeholder="Search"
                                style={{ flex: 1 }}
                                allowClear
                                value={state.search}
                                onChange={e => {
                                    if (listLoading || loading) return;
                                    searchList({
                                        ...state,
                                        search: e.target.value,
                                        pageNo: 1,
                                        status: jobStatusTab[selectedType - 1].value,
                                    });
                                    setIsSearch(e.target.value ? true : false);
                                    setState({ ...state, search: e.target.value, pageNo: 1 });
                                }}
                                className="r-input r-input_serach"
                                prefix={<span className="search_icon"></span>}
                            />
                            <Button
                                className="r-primary_btn job-btn"
                                style={{ width: 142, marginLeft: 16 }}
                                onClick={() => {
                                    window.sessionStorage.setItem('isTopAdd', '1');
                                    window.sessionStorage.removeItem('jobInfoForm');
                                    navigate('/job/job-info');
                                }}
                            >
                                Create new job
                            </Button>
                        </div>
                        <JobList
                            data={data}
                            setData={setData}
                            selectedType={selectedType}
                            noMore={noMore}
                            listLoading={listLoading}
                            loading={loading}
                            setLoading={setLoading}
                            setState={setState}
                            isSearch={isSearch || false}
                            getListReset={() => {
                                setData([]);
                                getList({
                                    ...state,
                                    status: jobStatusTab[selectedType - 1].value,
                                });
                            }}
                            getList={() => {
                                setState({ ...state, pageNo: state.pageNo + 1 });
                                getList({
                                    ...state,
                                    pageNo: state.pageNo + 1,
                                    status: jobStatusTab[selectedType - 1].value,
                                });
                            }}
                        />
                    </div>
                </div>
            ) : (
                <div className="no-message-tabBox r-center">
                    <Spin style={{ position: 'static' }}></Spin>
                </div>
            )}
        </div>
    );
};

export default Index;
