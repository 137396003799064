import React from 'react';
import './index.scss';
import Selected from '@/assets/img/selected.png';
import SelectedWhite from '@/assets/img/selected-white.png';

function SubscriptionCard({ subscriptionPlanList, selectedType, choosePlan }: any) {
    const featureList = [
        { text: 'Job Posting', key: 1 },
        { text: 'Automated Screening', key: 2 },
        { text: 'AI Matching', key: 3 },
        { text: 'Talent Pools and Pipelines', key: 4 },
        { text: 'Analytics', key: 5 },
        { text: 'User friendly AI-driven ATS (Applicant Tracking System)', key: 6 },
    ];
    return (
        <div className="subscriptionCard_list r-left">
            {subscriptionPlanList.map((item, i) => {
                return (
                    <div
                        className={`sub_cardItem ${item.recommendFlag ? 'active' : ''}`}
                        key={i}
                        onClick={() => choosePlan(i)}
                    >
                        <div className="sub_name">{item.planName}</div>
                        <div className="sub_teamNum">
                            Team Seats:{' '}
                            {item.seatType === 'bySeats'
                                ? '1+'
                                : item.seatType === 'limited'
                                ? item.seatsNumber
                                : 'Unlimited'}
                        </div>
                        <div className="sub_price">
                            {item.priceCurrency ? item.priceCurrency : '$'}
                            {window.utils.formatAmount(item.price || 0)}
                        </div>
                        <div className="sub_tips">per {selectedType == 1 ? 'month' : 'year'}</div>
                        <div className="sub_features">Features:</div>
                        <div>
                            {featureList.map(val => {
                                return (
                                    <div className="r-left featureItem" key={val.key}>
                                        <img
                                            src={item.recommendFlag ? SelectedWhite : Selected}
                                            className="selected_icon"
                                            alt=""
                                        />
                                        <span>{val.text}</span>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="sub_desc">{item.description}</div>
                    </div>
                );
            })}
        </div>
    );
}

export default SubscriptionCard;
