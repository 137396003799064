import React, { useCallback, useState } from 'react';
import { debounce } from 'lodash';
import { defaultInfo } from '@/typings/allType';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import jobApi from '@/api/job';
import { AutoComplete, Spin } from 'antd';

function LocationInp({ setLocation, classStr, suffixIcon, type, isFinshClear, placeholder, height, disabled }: any) {
    const messageApi = useGlobalMessage();
    const [locationLoading, setlocationLoading] = useState(false);
    const [address, setAddress] = useState('');
    const [locationOptions, setLocationOptions] = useState<any[]>([]);
    const getLocationOption = search => {
        if (!search) {
            setLocationOptions([]);
            return;
        }
        setlocationLoading(true);
        if (type === 'search') {
            jobApi.getSearchLocationList({ keyWord: search }, res => {
                setlocationLoading(false);
                if (res.code === 200) {
                    const results = res.data.map((item: defaultInfo, i: number) =>
                        Object.assign(item, { value: `${item}-${i}`, label: item })
                    );
                    setLocationOptions(results);
                } else {
                    setLocationOptions([]);
                    messageApi.error(res.message);
                }
            });
            return;
        }
        jobApi.getLocationList({ keyWord: search }, res => {
            setlocationLoading(false);
            if (res.code === 200) {
                const results = res.data.map((item: defaultInfo, i: number) =>
                    Object.assign(item, { value: `${item.location}-${i}`, label: item.location })
                );
                setLocationOptions(results);
            } else {
                setLocationOptions([]);
                messageApi.error(res.message);
            }
        });
    };
    const searchLocationList: any = useCallback(
        debounce(params => {
            getLocationOption(params);
            console.log('search');
        }, 1000),
        []
    );
    return (
        <>
            <AutoComplete
                options={locationOptions}
                className={classStr ? classStr : 'r-autoComplate'}
                style={{ width: '100%', height: height || 30 }}
                value={address}
                // allowClear
                disabled={disabled}
                onChange={val => {
                    setAddress(val);
                    if (!val) {
                        setLocation({ address: '' });
                    }
                }}
                suffixIcon={suffixIcon ? suffixIcon : <i className="down-right-icon"></i>}
                onSelect={val => {
                    const info = locationOptions.find(v => v.value === val);
                    if (isFinshClear) {
                        setAddress('');
                        setLocationOptions([]);
                    } else {
                        setAddress(info.label);
                    }
                    setLocation({ address: info.label });
                }}
                onSearch={val => {
                    searchLocationList(val);
                }}
                placeholder={placeholder || 'Any'}
                notFoundContent={
                    <>
                        {locationLoading && (
                            <div className="r-center">
                                <Spin />
                            </div>
                        )}
                        {!locationLoading && <div>No data</div>}
                    </>
                }
            ></AutoComplete>
        </>
    );
}

export default LocationInp;
