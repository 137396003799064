import axios from '../utils/index';
import { Pat, HttpResponse } from '@/typings/request';
const jobApi = {
    aiWrite(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/ai/ai`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    aiWriteQuestion(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/ai/ai-question`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    aiWriteInterviewQuestion(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/ai/ai-question-4-radio`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getBusinessTeamList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/business-team-list/${params.pageNo}/${params.pageSize}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getJobRoleList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/onetcenter/online/search`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getSkillList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/onetcenter/online/search/skills`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getJobAbilitiesList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/onetcenter/online/search/abilities`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getLocationList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/locationiq`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getSearchLocationList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/city-list`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getGmtList(callback: (T: any) => void) {
        axios
            .get(`/v1/public/gmt/gmt-list`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addJobInfo(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/jobs/jobs-add`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addTemplate(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/jobs/jobs-saveAsTemplate`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addDart(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/jobs/jobs-saveAsDraft`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getJobInfo(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/v1/business/jobs/jobs-info/${params.jobId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getTemplateList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/jobs/jobsList-template`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getDartList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/jobs/jobsList-draft`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getArchiveList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/jobs/jobsList-archive`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getExistingList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/jobs/jobsList-existing`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getJobInfoUserList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/v1/business/jobs/jobs-info/${params.jobId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getJobApplicationUserList(params: Pat, callback: (T: any) => void) {
        axios
            .post(
                `/v1/business/applications/jobs-applications/${params.jobId}/${params.applicationsStatus}/${params.interviewingStatus}`,
                params
            )
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    deleteJob(params: Pat, callback: (T: any) => void) {
        axios
            .delete(`/v1/business/jobs/jobs-delete/${params.jobId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getUserInfo(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/v1/business/applications/account-profile/${params.accountId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getUserInfoForJob(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/v1/business/applications/account-profile/${params.applicationsId}/${params.accountId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    readUserInfo(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/business/applications/applications-review/${params.applicationsId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    //修改工作状态
    setJobToArchive(params: Pat, callback: (T: any) => void) {
        //标记工作归档
        axios
            .put(`/v1/business/jobs/jobs-Archive/${params.jobId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    setJobToInInterview(params: Pat, callback: (T: any) => void) {
        //标记工作开始面试
        axios
            .put(`/v1/business/jobs/jobs-InInterview/${params.jobId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    // 修改申请者的状态
    setScreenToUser(params: Pat, callback: (T: any) => void) {
        //进入面试阶段 未安排面试
        axios
            .put(`/v1/business/applications/applications-screening/${params.applicationsId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    setInterviewToUserCheck(params: Pat, callback: (T: any) => void) {
        //发面试安排先检查时间
        axios
            .post(`/v1/business/applications/applications-interviewing-check/${params.applicationsId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    setInterviewToUser(params: Pat, callback: (T: any) => void) {
        //发面试安排
        axios
            .put(`/v1/business/applications/applications-interviewing/${params.applicationsId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    setInterviewFinshToUser(params: Pat, callback: (T: any) => void) {
        //标记面试完成
        axios
            .put(`/v1/business/applications/applications-interviewing-scheduled/${params.applicationsId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    setOfferToUser(params: Pat, callback: (T: any) => void) {
        //发offer
        axios
            .put(`/v1/business/applications/applications-offered/${params.applicationsId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    setRejectToUser(params: Pat, callback: (T: any) => void) {
        //拒绝
        axios
            .put(`/v1/business/applications/applications-rejected/${params.applicationsId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addUserFavorite(params: Pat, callback: (T: any) => void) {
        //收藏
        axios
            .post(`/v1/business/favourite/favourite-account-add/${params.accountId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    removeUserFavorite(params: Pat, callback: (T: any) => void) {
        //取消收藏
        axios
            .delete(`/v1/business/favourite/favourite-account-delete/${params.accountId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addJobNoteInfo(params: Pat, callback: (T: any) => void) {
        //打分
        axios
            .post(`/v1/business/applications/applications-score/${params.applicationsId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getSkillHardListForSearch(params: Pat, callback: (T: any) => void) {
        //获取硬技能，搜索用
        axios
            .post(`/v1/business/skill-list/hardSkills`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getSkillSoftListForSearch(params: Pat, callback: (T: any) => void) {
        //获取软技能，搜索用
        axios
            .post(`/v1/business/skill-list/softSkills`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getSkillHardListForJobSearch(params: Pat, callback: (T: any) => void) {
        //获取硬技能，搜索某个技能用
        axios
            .post(`/v1/business/applications-job/skill-list/hardSkills/${params.jobId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getSkillSoftListForJobSearch(params: Pat, callback: (T: any) => void) {
        //获取软技能，搜索某个技能用
        axios
            .post(`/v1/business/applications-job/skill-list/softSkills/${params.jobId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default jobApi;
