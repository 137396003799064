import axios from '../utils/index';
import { Pat, HttpResponse } from '@/typings/request';
const homeApi = {
    getJobList(params: Pat, callback: (T: any) => void) {
        axios
            .post(
                `/v1/business/jobs/jobs-list/${params.status}/${params.beginTime}/${params.endTime}/${params.pageNo}/${params.pageSize}`,
                params
            )
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getMyJobList(params: Pat, callback: (T: any) => void) {
        axios
            .post(
                `/v1/business/jobs/jobs-list-my/${params.status}/${params.beginTime}/${params.endTime}/${params.pageNo}/${params.pageSize}`,
                params
            )
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getHomeData(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/v1/business/dashboard/dashboard-statics-insights-home`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getHomeDataChart(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/v1/business/dashboard/dashboard-statics-insights/${params.beginTime}/${params.endTime}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getHomeInterviewList(params: Pat, callback: (T: any) => void) {
        axios
            .post(
                `/v1/business/applications/applications-list/${params.flag}/${params.beginTime}/${params.endTime}/${params.pageNo}/${params.pageSize}`,
                params
            )
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getTalentDatabaseLinkedInList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/talentDatabase/talentDatabase-list/${params.pageNo}/${params.pageSize}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    uploadIndexDBList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/talentDatabase/talentDatabase-list`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addTalenautDatabase(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/talentDatabase/talentDatabase-list`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default homeApi;
