import React, { useEffect, useState } from 'react';
import FormModal from '@/components/modal/form-modal';
import { Button, Spin, Image } from 'antd';
import api from '@/api/team';
import poolApi from '@/api/talentPool';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import { useSelector } from 'react-redux';
import { defaultInfo } from '@/typings/allType';
function SharePoolModal({ shareModal, setShareModal, info, handOK, pageType }: any) {
    const messageApi = useGlobalMessage();
    const { profileInfo } = useSelector((state: any) => state.state);
    const [shareList, setShareList] = useState<defaultInfo[]>([]);
    const [shareSelList, setShareSelList] = useState<any>([]);
    const [listLoading, setListLoading] = useState(false);
    const changeShareSel = (type: number, index: number) => {
        const arr = JSON.parse(JSON.stringify(shareList));
        const arr1 = JSON.parse(JSON.stringify(shareSelList));
        const val = type ? arr[index] : arr1[index];
        if (type === 1) {
            arr1.push(val);
            arr.splice(index, 1);
        } else {
            arr.push(val);
            arr1.splice(index, 1);
        }
        setShareList(arr);
        setShareSelList(arr1);
    };
    const getList = () => {
        setListLoading(true);
        api.getBusinessTeamList({ pageNo: 1, pageSize: 1000, keyWord: '' }, res => {
            setListLoading(false);
            if (res.code === 200) {
                const data = res.data.businessAccountPageBean;
                let shareListNow: any = [];
                const arr: any = [];
                if (
                    info.val &&
                    info.val.shareBusinessAccountPOJOList !== null &&
                    info.val.shareBusinessAccountPOJOList.length
                ) {
                    shareListNow = info.val.shareBusinessAccountPOJOList;
                }
                data.list.forEach(item => {
                    if (shareListNow.findIndex(v => v.accountId === item.accountId) === -1) {
                        const obj = Object.assign(item, {
                            showName: window.utils.getInitials(item.firstName, item.lastName),
                            isMe: item.accountId === profileInfo.accountId ? true : false,
                            value: item.accountId,
                            label: `${item.firstName} ${item.middleName} ${item.lastName}`,
                        });
                        arr.push(obj);
                    }
                });
                arr.reverse();
                setShareSelList([...shareListNow]);
                setShareList(arr);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const [loading, setLoading] = useState(false);
    const shareTeam = () => {
        const pat = {
            poolId: info.poolId,
            groupId: info.val.groupId,
            accountIdList: shareSelList.map(item => item.accountId),
        };
        setLoading(true);
        poolApi.sharePool(pat, res => {
            setLoading(false);
            if (res.code === 200) {
                if (pageType === 'detail') {
                    const poolInfo = JSON.parse(window.sessionStorage.getItem('poolJobInfo') ?? "{poolId:''}");
                    if (poolInfo.poolId) {
                        poolInfo.val.shareBusinessAccountPOJOList = [...shareSelList];
                    }
                    setShareModal(false);
                    return;
                }
                setShareModal(false);
                handOK();
            } else {
                messageApi.error(res.message);
            }
        });
    };
    useEffect(() => {
        if (shareModal) {
            setShareSelList([]);
            getList();
        }
    }, [shareModal]);
    return (
        <div>
            <FormModal
                title="Share Board"
                align="left"
                width={780}
                top={28}
                className="share-modal-pool"
                isModalOpen={shareModal}
                handleOk={() => setShareModal(false)}
                handleCancel={() => setShareModal(false)}
                children={
                    <div className="share-modalBox">
                        <div className="shareBoard-tip">{info?.val ? info?.val.jobTitle : '-'}</div>
                        <div className="r-top shareBoard-listBox">
                            {listLoading ? (
                                <div className="r-center no-message-tabBox">
                                    <Spin></Spin>
                                </div>
                            ) : (
                                <>
                                    <div className="boardcard-box">
                                        <div style={{ marginBottom: 12 }}>Has been shared with</div>{' '}
                                        <div className="borderRight-box max425 hide-scrollbar">
                                            {shareSelList.length ? (
                                                shareSelList.map((val, i) => {
                                                    return (
                                                        <div
                                                            key={`candidate-cardBoxLeftSel-${i}`}
                                                            className="candidate-cardBox r-left active-candidateBox"
                                                        >
                                                            {val.avatarUrl || val.picUrlShow ? (
                                                                <Image
                                                                    width={32}
                                                                    height={32}
                                                                    preview={false}
                                                                    style={{ borderRadius: '50%', minWidth: 32 }}
                                                                    src={
                                                                        val.picUrlShow ? val.picUrlShow : val.avatarUrl
                                                                    }
                                                                    fallback={require('@/assets/img/default-min.png')}
                                                                />
                                                            ) : (
                                                                <div className="avatar-textBox">{val.showName}</div>
                                                            )}
                                                            <div style={{ marginLeft: 5, width: '70%' }}>
                                                                <p
                                                                    className="candidatename-box oneLineText"
                                                                    style={{ width: '90%' }}
                                                                >
                                                                    {val.label}
                                                                </p>
                                                                <p className="candidate-minitip oneLineText">
                                                                    {val.position ?? '-'}
                                                                </p>
                                                            </div>
                                                            <i
                                                                className="candidate-delIcon marLeft"
                                                                onClick={() => changeShareSel(0, i)}
                                                            ></i>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <div className="no-selBox r-center">No Selete</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="boardcard-box" style={{ maxWidth: 448 }}>
                                        <div style={{ marginBottom: 12 }}>Add team member</div>
                                        <div className="max425 hide-scrollbar">
                                            {shareList.length ? (
                                                shareList.map((val, i) => {
                                                    return (
                                                        <div
                                                            className="candidate-cardBox r-left"
                                                            key={`candidate-cardBoxRightList-${i}`}
                                                            style={{ display: val.isMe ? 'none' : 'flex' }}
                                                        >
                                                            {val.avatarUrl || val.picUrlShow ? (
                                                                <Image
                                                                    width={32}
                                                                    height={32}
                                                                    preview={false}
                                                                    style={{ borderRadius: '50%', minWidth: 32 }}
                                                                    src={
                                                                        val.picUrlShow ? val.picUrlShow : val.avatarUrl
                                                                    }
                                                                    fallback={require('@/assets/img/default-min.png')}
                                                                />
                                                            ) : (
                                                                <div className="avatar-textBox">{val.showName}</div>
                                                            )}
                                                            <div style={{ marginLeft: 5, width: '80%' }}>
                                                                <p
                                                                    className="candidatename-box oneLineText"
                                                                    style={{ width: '90%' }}
                                                                >
                                                                    {val.label}
                                                                </p>
                                                                <p className="candidate-minitip oneLineText">
                                                                    {val.position ?? '-'}
                                                                </p>
                                                            </div>
                                                            <i
                                                                className="candidate-addIcon marLeft"
                                                                onClick={() => changeShareSel(1, i)}
                                                            ></i>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <div className="no-selBox r-center">No Data</div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="r-end">
                            <Button
                                style={{ width: 100, marginTop: 40 }}
                                className="r-primary_btn r-primary_largeBtn"
                                disabled={!shareSelList.length}
                                loading={loading}
                                onClick={shareTeam}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                }
            />
        </div>
    );
}

export default SharePoolModal;
