import axios from '../utils/index';
import { Pat, HttpResponse } from '@/typings/request';
const talentDatsbaseApi = {
    getTalentPoolList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/talentPool/talentPool-home-candidates-list`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getTalentDatabaseList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/talentDatabase/talentDatabase-list/${params.pageNo}/${params.pageSize}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addUserToPoolList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/talentDatabase/add-to-talentPool`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getTalentDatabaseLinkedInList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/account-list-linkedIn`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addLinkedInTalenautDatabase(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/account-list-linkedIn-insertMyDatabase`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    uploadIndexDBList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/account-list-excel`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addTalenautDatabase(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/account-list-excel-insertMyDatabase`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default talentDatsbaseApi;
