import axios from '../utils/index';
import { Pat, HttpResponse } from '@/typings/request';
const teamApi = {
    getBusinessTeamRole(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/v1/business/business-role-list`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    editBusinessTeamRole(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/business/business-role-edit/${params.accountId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    deleteBusinessTeamRole(params: Pat, callback: (T: any) => void) {
        axios
            .delete(`/v1/business/business-role-delete/${params.accountId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getBusinessTeamList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/business-team-list/${params.pageNo}/${params.pageSize}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addBusinessTeam(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/business/business-team-add`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    deleteBusinessTeam(params: Pat, callback: (T: any) => void) {
        axios
            .delete(`/v1/business/business-team-delete/${params.accountId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    editBusinessTeam(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/business/business-team-edit/${params.accountId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    teamNextStep(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/business/business-team-step-finished`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    disabledTeam(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/business/business-team-suspended/${params.accountId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    undisabledTeam(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/business/business-team-active/${params.accountId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addAdminRole(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/business/business-team-role-admin-add/${params.accountId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    delAdminRole(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/business/business-team-role-admin-revoke/${params.accountId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getTeamJobList(params: Pat, callback: (T: any) => void) {
        axios
            .post(
                `/v1/business/jobs/jobs-list-businessAccount/${params.businessAccountId}/${params.jobStatus}/0/9999999999/${params.pageNo}/${params.pageSize}`,
                params
            )
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    transferJob(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/business/jobs/jobs-transfer/${params.jobId}/${params.businessAccountId}}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default teamApi;
