import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './index.scss';
import KeepAlive from 'react-activation';
import usePrevious from '@/hook/usePrevious';
import { defaultInfo } from '@/typings/allType';
import TalenautLogo from '@/assets/img/talenaut_logo.png';
import HomeIcon from '@/assets/img/home.png';
import UsersIcon from '@/assets/img/users.png';
import TrophyIcon from '@/assets/img/Trophy.png';
import ChatIcon from '@/assets/img/chat.png';
import TeamIcon from '@/assets/img/team.png';
import SettingIcon from '@/assets/img/setting.png';
import ContentTopBar from '@/components/contentTop-bar';
import AlertModal from '@/components/modal/alert-modal';
import { useSelector } from 'react-redux';
import getActiveTabIndex from '@/utils/getActiveTabIndex';
import { useSendBird } from '@/context/SendBirdProvider';
import { useFeedback } from '@/context/notificationContext';
import useFireBase from '@/hook/useFairBase';
import useLoading from '@/hook/useLoading';
import { Button } from 'antd';
const LayoutMain = props => {
    const location = useLocation();
    const navigate = useNavigate();
    const { setLoadingStatus } = useLoading();
    const { feedbackCount, refreshFeedbackCount } = useFeedback();
    const { profileInfo } = useSelector((state: any) => state.state);
    const { initDataBase, offDataBase } = useFireBase();
    const prevLocation: any = usePrevious(location);
    const [activeIndex, setActiveIndex] = useState(-1);
    const navList = [
        { icon: HomeIcon, isActive: true, lebel: 'Home', url: '/home', key: 1 },
        { icon: UsersIcon, isActive: false, lebel: 'Talent Pool', url: '/talent-pool', key: 2 },
        { icon: TrophyIcon, isActive: false, lebel: 'Talent Database', url: '/talent-database', key: 3 },
        { icon: ChatIcon, isActive: false, lebel: 'Messaging', url: '/message-chat', key: 4 },
        { icon: TeamIcon, isActive: false, lebel: 'Team Management', url: '/talent-teamManagement', key: 5 },
    ];
    const changeNav = (val: defaultInfo) => {
        // setActiveIndex(val.key);
        navigate(val.url);
    };
    // 确认退出
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOk = () => {
        setIsModalOpen(false);
        navigate('/login');
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    // 监听路由变化时，右侧滚动容器滚动到顶部
    const rightContainerRef: any = useRef(null);
    useEffect(() => {
        if (profileInfo.businessInfoPOJO && !profileInfo.businessInfoPOJO.subscriptionFlag) {
            navigate(-1);
            return;
        }
        const index = getActiveTabIndex(`${location.pathname}${location.search ? location.search : ''}`);
        setActiveIndex(index);
        if (rightContainerRef.current) {
            rightContainerRef.current.scrollTo(0, 0);
        }
    }, [location]);
    // 初始化聊天
    const { initializeSendBird, state, destroySendBird } = useSendBird();
    const { adminId, sendbirdId, sendbirdAccessToken } = useSelector((state: any) => state.state.chartInfo);
    useEffect(() => {
        setLoadingStatus(false);
        initDataBase(profileInfo.businessId);
        refreshFeedbackCount();
        const userId = window.sessionStorage.getItem('accountId') || adminId;
        console.log(userId);
        const accessToken = sendbirdAccessToken;
        initializeSendBird(userId, accessToken, sendbirdId);
        return () => {
            offDataBase();
            destroySendBird();
        };
    }, []);
    const expirdFilter = ['/change-subscriptionPlan', '/admin-settings', '/talent-teamManagement'];
    return (
        <>
            <div className="layout-container">
                <div className="header_nav">
                    <img src={TalenautLogo} className="logo-img" alt="" />
                    <div>
                        {navList.map((item, i) => {
                            return (
                                <div
                                    key={`navItem-${i}`}
                                    onClick={() => {
                                        changeNav(item);
                                    }}
                                    className={`r-left navItem-box ${activeIndex === item.key ? 'active' : ''}`}
                                >
                                    <img src={item.icon} className="navIcon-img" alt="" />
                                    <span>{item.lebel}</span>
                                    {item.lebel === 'Messaging' && state.count ? (
                                        <span className="count-box">
                                            {(state.count > 99 ? '99+' : state.count) || 0}
                                        </span>
                                    ) : null}
                                </div>
                            );
                        })}
                    </div>
                    <div className="navItem-box setting-box r-left" onClick={() => navigate('/admin-settings')}>
                        <img src={SettingIcon} className="navIcon-img" alt="" />
                        <span>Admin Settings</span>
                    </div>
                </div>
                <div className="layout-content">
                    <div className="content-topBar">
                        <ContentTopBar />
                    </div>
                    <div
                        className={`page-content ${
                            profileInfo.businessInfoPOJO?.subscriptionExpireFlag &&
                            expirdFilter.findIndex(v => v === location.pathname) === -1
                                ? 'isExpird'
                                : ''
                        }`}
                        ref={rightContainerRef}
                    >
                        <Routes>
                            {props.routes.map((route: any, index: any) => {
                                return route.KeepAlive ? (
                                    <Route
                                        path={route.path}
                                        key={index}
                                        element={
                                            <KeepAlive
                                                cacheKey={route.path}
                                                saveScrollPosition="screen"
                                                name={route.name}
                                            >
                                                <route.component
                                                    prevLocation={prevLocation}
                                                    routes={route.childrens ? route.childrens : []}
                                                />
                                            </KeepAlive>
                                        }
                                    ></Route>
                                ) : (
                                    <Route
                                        path={route.path}
                                        key={index}
                                        element={
                                            <route.component
                                                prevLocation={prevLocation}
                                                routes={route.childrens ? route.childrens : []}
                                            />
                                        }
                                    ></Route>
                                );
                            })}
                        </Routes>
                        {/* 过期弹窗 */}
                        {profileInfo.businessInfoPOJO?.subscriptionExpireFlag &&
                        expirdFilter.findIndex(v => v === location.pathname) === -1 ? (
                            <div className="expired-modal">
                                <div className="box-modalShow">
                                    <div className="expired-tit">
                                        {profileInfo.businessInfoPOJO?.subscriptionExpireFlag &&
                                        !profileInfo.businessInfoPOJO?.subscriptionCancelFlag
                                            ? 'Your subscription has expired'
                                            : 'The trial period has expired'}
                                    </div>
                                    <div className="expired-tip">
                                        {profileInfo.roleNum <= 1
                                            ? 'Please purchase a subscription to continue experiencing Talenaut.'
                                            : 'Please contact the administrator to purchase a subscription to continue experiencing Talenaut.'}
                                    </div>
                                    <div>
                                        {profileInfo.roleNum <= 1 ? (
                                            <Button
                                                className="r-primary_btn sub-btnNew"
                                                onClick={() => navigate('/change-subscriptionPlan')}
                                            >
                                                Go to subscribe
                                            </Button>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>

            <AlertModal
                title="Your subscription has expired"
                message="Please purchase a subscription to continue experiencing Talenaut."
                cancelText=""
                confirmText="Recommended"
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
        </>
    );
};

export default LayoutMain;
