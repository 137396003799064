import * as echarts from 'echarts';

const useCharts = () => {
    const initBarChart = (id, xData, yData, preDay) => {
        console.log(id);

        const chartEl: any = document.getElementById(id ? id : 'bar-chart');
        const myChartNow = echarts.init(chartEl, '', { devicePixelRatio: 2 });
        const data = yData;
        const colorlist = [
            'rgba(148, 144, 241, 0.1)',
            'transparent',
            'rgba(148, 144, 241, 0.1)',
            'transparent',
            'rgba(148, 144, 241, 0.1)',
        ];
        const option: any = {
            xAxis: {
                type: 'category',
                data: xData,
                axisLine: {
                    show: false, // 隐藏X轴轴线
                },
                axisTick: {
                    show: false, // 隐藏X轴刻度
                },
                axisLabel: {
                    interval: 0,
                    show: true, // 可以选择隐藏或显示标签
                    color: 'rgba(28, 37, 61, 0.6)', // 字体颜色
                    fontFamaiy: 'Roboto-Medium', // 字体样式
                    fontSize: 8, // 字体大小
                },
            },
            yAxis: {
                type: 'value',
                // max: max,
                axisLabel: {
                    onZero: false,
                    show: true,
                    color: 'rgba(28, 37, 61, 0.5)', // 字体颜色
                    fontFamaiy: 'Roboto-Medium', // 字体样式
                    fontSize: 8, // 字体大小
                    padding: [0, 10, 0, 0], // 标签与轴线的间距
                    formatter: function (value) {
                        return value === 0 ? '' : `${value}d`; // 如果值为0，则返回空字符串，隐藏标签
                    },
                },
                splitLine: {
                    z: 5,
                    color: function (value) {
                        console.log(value);
                        return value === 0 ? 'transparent' : 'rgba(137, 137, 137, 0.3)'; // 隐藏0刻度的线
                    },
                    lineStyle: {
                        show: function (index, value) {
                            return value !== 0; // 隐藏第一个分割线
                        },
                        type: 'dashed', // 设置虚线
                        color: 'rgba(137, 137, 137, 0.1)',
                    },
                },
            },
            grid: {
                zlevel: 0, // 将网格层级设为0
                z: 5, // 将网格层级设为2，使其位于柱子前面
                top: '7%',
                left: '8%',
                right: '5%',
                bottom: '8%',
            },
            series: [
                {
                    data: data,
                    type: 'bar',
                    showBackground: true,
                    itemStyle: {
                        color: '#9490F1',
                        borderRadius: 5,
                    },
                    backgroundStyle: {
                        color: 'rgba(148, 144, 241, 0.1)',
                    },
                    barMinWidth: 18,
                    barMaxWidth: 45,
                    markLine: {
                        symbol: 'none',
                        data: [
                            {
                                yAxis: preDay || 0, // 在 Y 轴为 100 的位置增加一条分割线
                                lineStyle: {
                                    color: '#4C47DE', // 自定义分割线颜色
                                    type: 'dashed', // 分割线样式
                                },
                                label: {
                                    show: false,
                                },
                                emphasis: {
                                    lineStyle: {
                                        color: '#4C47DE', // 可以设置为原始颜色或任何其他颜色
                                        width: 1,
                                        type: 'dashed',
                                    },
                                    label: {
                                        show: false,
                                    },
                                },
                            },
                        ],
                    },
                },
            ],
        };

        chartEl.removeAttribute('_echarts_instance_');
        myChartNow.setOption(option);
        window.addEventListener('resize', function () {
            myChartNow.resize();
        });
    };

    return {
        initBarChart,
    };
};

export default useCharts;
